/**
 * This client will help to http request from api
 */
import axios from "./axios";

const httpClient = {
  /**
   * this will request data from api
   * @param {String} token
   * @param {String} url
   * @returns
   */
  async get(token, url) {
    return await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  /**
   * This will post data to api
   * @param {String} token
   * @param {String} url
   * @param {Object} data
   * @returns
   */
  async post(token, url, data) {
    return await axios.post(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  /**
   * This will update data to api
   * @param {String} token
   * @param {String} url
   * @param {Object} data
   * @returns
   */
  async put(token, url, data) {
    return await axios.put(url, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },

  /**
   * This will delete api data
   * @param {String} token
   * @param {String} url
   * @returns
   */
  async delete(token, url) {
    return await axios.delete(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
};

export default httpClient;
