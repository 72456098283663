import "./Error404.scss";
const Error404 = () => {
  return (
    <div className="primary-error-page">
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 mt-5">
            <div className="container message-wrapper">
              <div className="row no-gutters">
                <div className="col-8 offset-2">
                  <img
                    className="company-logo"
                    src="/img/logotext.png"
                    alt="company logo"
                  />
                  <div className="error-message">
                    <h1>Error 404 :(</h1>
                    <h3>
                      The requested URL not found. Please contact your admin.
                    </h3>
                  </div>

                  <ul className="nav justify-content-center">
                    <li className="nav-item">
                      <a className="nav-link active" href="/">
                        Sign in
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error404;
