import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  title: "",
  format: "",
  data: null,
  isPrintPreview: false,
  printable: false,
};

const PrintPreviewSlice = createSlice({
  name: "print_preview",
  initialState,
  reducers: {
    showPrintPreview: (state, action) => {
      state.isPrintPreview = true;
      state.title = action.payload.title;
      state.format = action.payload.format;
      state.data = action.payload.data;
      state.printable = action.payload.printable;
    },
    hidePrintPreview: (state, action) => {
      state.isPrintPreview = false;
    },
    setPrintable(state, action) {
      state.printable = action.payload;
    },
  },
});

export const { showPrintPreview, hidePrintPreview, setPrintable } =
  PrintPreviewSlice.actions;
export default PrintPreviewSlice.reducer;
