import { Routes, Route, Outlet } from "react-router-dom";
import { hasPrivilege } from "../../Helper";

import UserList from "./UserList";
import UserRoleList from "./UserRoleList";
import Error404 from "../errors/dashboard/Error404";

const User = () => {
  return (
    <>
      <Outlet />
      <Routes>
        {hasPrivilege("Users", 700) && <Route index element={<UserList />} />}
        {/* New user handle by main menu */}
        {hasPrivilege("Users", 704) && (
          <Route path="user-roles" element={<UserRoleList />} />
        )}
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
};

export default User;
