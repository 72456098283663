import "./UserLogin.scss";
import SignInForm from "../../components/form/login/SignInForm";

const UserLogin = () => {
  return (
    <div className="user-login">
      <div className="container">
        <div className="row">
          <div
            id="login-wrapper"
            className="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 mt-5"
          >
            <img
              className="company-logo"
              src="/img/logotext.png"
              alt="company logo"
            />
            <div className="container bg-white shadow-sm login-form-wrapper">
              <div className="row no-gutters">
                <div className="d-none d-sm-none d-md-none d-lg-block col-lg-6">
                  <img
                    className="welcome-ads"
                    src="/img/welcome.png"
                    alt="welcome"
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                  <SignInForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserLogin;
