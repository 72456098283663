import "./Home.scss";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { Scrollbars } from "react-custom-scrollbars-2";
import { hasPrivilege } from "../../Helper";

const Home = () => {
  return (
    <div className="container home">
      <div className="row no-gutters">
        <div className="col-md-8">
          <div className="module-wrapper main-section shadow-sm">
            <h6 className="title">Purchasing</h6>
            <div className="line">
              <Link
                className={classNames("btn", {
                  disabled: !hasPrivilege("Purchases", 301),
                })}
                to="/dashboard/purchases/po-new"
              >
                <img
                  src="/img/icons/purchase-order-lg.png"
                  alt="purchase-order"
                />
                <span className="text">Purchase Order</span>
              </Link>
              <img
                src="/img/icons/arrow-lin-1.png"
                alt="arrow to receive inventory"
              />
              <Link
                className={classNames("btn", {
                  disabled: !hasPrivilege("Purchases", 307),
                })}
                to="/dashboard/purchases/grn-new"
              >
                <img src="/img/icons/delivered.png" alt="receive inventory" />
                <span className="text">Receive Inventory</span>
              </Link>
              <img src="/img/icons/arrow-lin-1.png" alt="arrow to bills" />
              <Link
                className={classNames("btn", {
                  disabled: !hasPrivilege("Purchases", 313),
                })}
                to="/dashboard/suppliers/new-bill"
              >
                <img src="/img/icons/bill-lg.png" alt="enter bill" />
                <span className="text">Enter Bills</span>
              </Link>
              <img src="/img/icons/arrow-lin-1.png" alt="arrow to pay bills" />
              <Link
                className={classNames("btn", {
                  disabled: !hasPrivilege("Purchases", 317),
                })}
                to="/dashboard/suppliers/bill-list"
              >
                <img src="/img/icons/cash-in-hand.png" alt="pay bill" />
                <span className="text">Pay Bills</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="module-wrapper side-section shadow-sm">
            <h6 className="text-center">Entities</h6>
            <div className="d-flex justify-content-start">
              <Link
                className={classNames("btn m-2", {
                  disabled: !hasPrivilege("Customers", 500),
                })}
                to="/dashboard/sales/customers"
              >
                <img src="/img/icons/users.png" alt="user" />
                <span className="text"> Customers </span>
              </Link>
              <Link
                className={classNames("btn m-2", {
                  disabled: !hasPrivilege("Suppliers", 600),
                })}
                to="/dashboard/purchases/suppliers"
              >
                <img src="/img/icons/suppliers.png" alt="suppliers" />
                <span className="text"> Suppliers </span>
              </Link>
            </div>
            <div className="d-flex justify-content-start">
              <Link
                className={classNames("btn m-2", {
                  disabled: !hasPrivilege("Inventory", 400),
                })}
                to="/dashboard/inventory"
              >
                <img src="/img/icons/products.png" alt="suppliers" />
                <span className="text"> Products </span>
              </Link>
            </div>
            <div className="d-flex justify-content-start">
              <Link
                className={classNames("btn m-2", {
                  disabled: !hasPrivilege("Users", 700),
                })}
                to="/dashboard/users"
              >
                <img src="/img/icons/user-role.png" alt="suppliers" />
                <span className="text"> Users </span>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="row no-gutters">
        <div className="col-md-8">
          <div className="module-wrapper main-section shadow-sm">
            <h6 className="title">Sales</h6>
            <div className="line">
              <Link
                className={classNames("btn", {
                  disabled: !hasPrivilege("Sales", 201),
                })}
                to="/dashboard/sales/so-new"
              >
                <img src="/img/icons/quotation-lg.png" alt="Quotation" />
                <span className="text">Quotation</span>
              </Link>
              <img
                src="/img/icons/arrow-lin-1.png"
                alt="arrow to receive inventory"
              />
              <Link
                className={classNames("btn", {
                  disabled: !hasPrivilege("Sales", 207),
                })}
                to="/dashboard/sales/invoice-new"
              >
                <img src="/img/icons/invoice-lg.png" alt="new-invoice" />
                <span className="text">Create Invoice</span>
              </Link>
              <img src="/img/icons/arrow-lin-1.png" alt="arrow to bills" />
              <Link
                className={classNames("btn", {
                  disabled: !hasPrivilege("Sales", 212),
                })}
                to="/dashboard/sales/new-bill"
              >
                <img src="/img/icons/receive-cash-lg.png" alt="enter bill" />
                <span className="text">Receive Payment</span>
              </Link>
              <img src="/img/icons/arrow-lin-1.png" alt="arrow to pay bills" />
              <Link
                className={classNames("btn", {
                  disabled: !hasPrivilege("Sales", 310),
                })}
                to="/dashboard/suppliers/bill-list"
              >
                <img src="/img/icons/safe-ok.png" alt="pay bill" />
                <span className="text">Deposits</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="module-wrapper side-section shadow-sm">
            <h6 className="text-center">Help Center</h6>
            <div className="d-flex justify-content-start">
              <Link className="btn m-2" to="/dashboard/users/user-list">
                <img src="/img/icons/help.png" alt="help" />
                <span className="text"> Video tutorial </span>
              </Link>
              <Link className="btn m-2" to="/dashboard/users/user-list">
                <img src="/img/icons/user-manual.png" alt="suppliers" />
                <span className="text"> Documentation </span>
              </Link>
            </div>
            <div className="d-flex justify-content-start">
              <Link className="btn m-2" to="/dashboard/users/user-list">
                <img src="/img/icons/tiket.png" alt="help" />
                <span className="text"> Create ticket </span>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="row no-gutters">
        <div className="col-md-8">
          <div
            className="module-wrapper main-section shadow-sm"
            style={{ height: 342 }}
          >
            <h3
              className="display-2"
              style={{ fontSize: 24, textAlign: "left" }}
            >
              Insight
            </h3>
            <hr className="m-0" />
          </div>
        </div>
        <div className="col-md-4">
          <div className="module-wrapper side-section shadow-sm">
            <h6 className="text-center">News & Updates</h6>
            <Scrollbars style={{ width: "100%", height: 300 }}>
              <div className="news">
                <h3>New Update Release </h3>
                <p>Version 1.3 published 2023/12/31</p>
                <p>App Update</p>
                <ul>
                  <li>Create invoice with 4 decimal places</li>
                  <li>Update VAT 18%</li>
                  <li>Link Deleted invoice list</li>
                </ul>
                <p>hope you enjoy it.</p>
                <p>Next release on 2024-01-24.</p>
              </div>
            </Scrollbars>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
