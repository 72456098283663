import Category from "./Category";

const CategoryEdit = (props) => {
  const { category, edit_callback } = props;
  return (
    <Category
      action="edit"
      edit_category={category}
      edit_callback={edit_callback}
    />
  );
};
export default CategoryEdit;
