import "./StockItem.scss";
import { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import axios from "../../../api/axios";
import { autoNotify } from "../../../redux/features/notify/NotifySlice";

import FormGeneral from "./FormGeneral";
import FormAdditional from "./FormAdditional";

import { hidePrimaryModel } from "../../../redux/features/modals/PrimaryModalSlice";

const StockItem = (props) => {
  const { callback } = props;
  const dispatch = useDispatch();
  const { token, branch, user } = useSelector((store) => store.auth);
  const newItemForm = useRef(null);
  //locale stats
  const [tab, setTab] = useState("general");
  const [isSubmitting, setIsSubmitting] = useState(false);
  //events
  //actions
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const fd = new FormData(newItemForm.current);
      fd.append("com_id", branch.com_id);
      fd.append("branch_id", branch.branch_id);
      fd.append("item_id", "");
      fd.append("created_by", user.user_id);

      const resp = await axios.post("inventory/inventory-item", fd, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      autoNotify(dispatch, resp);
      setIsSubmitting(false);
      if (callback) {
        callback(resp.data.item);
      }
      dispatch(hidePrimaryModel());
    } catch (error) {
      autoNotify(dispatch, error.response);
      setIsSubmitting(false);
    }
  };

  const handleFormClose = () => {
    dispatch(hidePrimaryModel());
  };

  return (
    <form
      id="new-stock-item"
      method="post"
      onSubmit={handleFormSubmit}
      className="new-stock-item"
      ref={newItemForm}
    >
      <Tabs id="stock-item-tabs" activeKey={tab} onSelect={(t) => setTab(t)}>
        <Tab eventKey="general" title="General">
          <FormGeneral />
        </Tab>
        <Tab eventKey="additional" title="Additional Details">
          <FormAdditional />
        </Tab>
      </Tabs>
      <div className="m-1">
        <button
          id="supp-create-btn"
          className="btn btn-primary float-right save-button ml-2"
          type="submit"
          disabled={isSubmitting}
        >
          Create Item
        </button>
        <button
          id="supp-close-btn"
          type="button"
          className="btn btn-secondary float-right "
          data-dismiss="modal"
          onClick={handleFormClose}
        >
          Close
        </button>
      </div>
    </form>
  );
};

export default StockItem;
