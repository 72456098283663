import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PurchaseOrder from "../../../components/workplace/po/PurchaseOrder";

import {
  getPONumber,
  setPODate,
  newTableRow,
} from "../../../redux/features/purchases/PurchaseOrderSlice";

const PONew = () => {
  const dispatch = useDispatch();
  const { poNo, poDate, tableRowCounter } = useSelector(
    (store) => store.purchase_order
  );

  // set New PO data
  const isNewPONo = poNo === "";
  const isNewPoDate = poDate === "";
  const isZeroRow = tableRowCounter === 0;
  useEffect(() => {
    if (isNewPONo) dispatch(getPONumber());
    if (isNewPoDate) dispatch(setPODate(new Date()));
    if (isZeroRow) dispatch(newTableRow());
  }, [isNewPONo, isNewPoDate, isZeroRow, dispatch]);

  return <PurchaseOrder action="New" />;
};

export default PONew;
