import React, { useState, useEffect } from "react";
import Select from "react-select";

/*select options */
const select_style = {
  control: (base) => ({
    ...base,
    height: 31,
    minHeight: 31,
    borderRadius: 3,
    border: "1px solid #d0d6db",
    boxShadow: "none",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: "31px",
    padding: "0 6px",
  }),
  input: (provided, state) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "31px",
  }),
  menuList: (provided) => ({
    ...provided,
  }),
  menu: (provided) => ({
    ...provided,
    width: "max-content",
    minWidth: "100%",
  }),
};

const taxOptions = [
  { value: "1", label: "None" },
  { value: "2", label: "Taxable" },
];

const SelectTaxable = (props) => {
  const { selected, callback } = props;
  //local stats
  const [tax, setTax] = useState(taxOptions[0]);
  //events
  const handleOnChange = (option) => {
    setTax(option);
    if (callback) {
      callback(option);
    }
  };

  useEffect(() => {
    if (selected !== "1") {
      setTax(taxOptions[1]);
    }
  }, [selected]);

  return (
    <Select
      options={taxOptions}
      styles={select_style}
      name="sales_tax_id"
      id="sales_tax_id"
      value={tax}
      onChange={handleOnChange}
    />
  );
};
export default React.memo(SelectTaxable);
