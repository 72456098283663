import React, { useState, useEffect } from "react";
import axios from "../../../api/axios";
import { useSelector } from "react-redux";

const getPayHistory = async (token, saleId = "") => {
  try {
    const response = await axios.get("customers/receive-payment/" + saleId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    return [];
  }
};

const PayHistoryList = (props) => {
  const { invoice, payment } = props;
  //global
  const { token } = useSelector((store) => store.auth);
  const [payments, setPayments] = useState([]);

  //effect
  useEffect(() => {
    const paymentList = async () => {
      const list = await getPayHistory(token, invoice?.sale_id);
      setPayments(list);
    };
    paymentList();
  }, [invoice, payment, token]);

  return (
    <div className="pay-history-list">
      <table className="table table-sm table-hover pay-history-table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Method</th>
            <th>Amount</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {payments &&
            payments.map((payment) => {
              return (
                <tr key={payment?.pay_id}>
                  <td>{payment?.paid_date}</td>
                  <td>
                    {payment?.pay_method?.method_name}{" "}
                    <div style={{ color: "#5b5b5b", fontSize: "11px" }}>
                      {payment?.payment_ref
                        ? "Ref " + payment?.payment_ref
                        : ""}
                    </div>
                  </td>
                  <td>{payment?.paid_amount}</td>
                  <td></td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};
export default React.memo(PayHistoryList);
