import "./Notifier.scss";
import { memo, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  X,
  InfoCircleFill,
  ExclamationDiamondFill,
  CheckCircleFill,
} from "react-bootstrap-icons";
import { removeNotify } from "../../../redux/features/dashboard/notifierSlice";

const Notifier = (props) => {
  const { id, type, content } = props.notify;
  const dispatch = useDispatch();
  let title = "";
  let messages = [];

  //event
  const handleOnClose = () => {
    dispatch(removeNotify(id));
  };

  //effect
  useEffect(() => {
    setTimeout(() => {
      dispatch(removeNotify(id));
    }, 10000);
  }, [dispatch, id]);

  switch (type) {
    case "INFO":
      return (
        <div className="notifier info">
          <div className="notifier-header">
            <h3 className="notifier-title">
              <InfoCircleFill /> Information
            </h3>
            <button className="close-btn" onClick={handleOnClose}>
              <X />
            </button>
          </div>
          <div className="notifier-content">
            <ul>
              {content.map((message, index) => {
                return <li key={index}>{message}</li>;
              })}
            </ul>
          </div>
        </div>
      );
    case "SUCCESS":
      title = content?.statusText ? content.statusText : "Successful";
      if (content?.data?.message) {
        messages = [content.data.message];
      } else {
        messages = [content.message];
      }
      //console.log(content);
      return (
        <div className="notifier success">
          <div className="notifier-header">
            <h3 className="notifier-title">
              <CheckCircleFill /> {title}
            </h3>
            <button className="close-btn" onClick={handleOnClose}>
              <X />
            </button>
          </div>
          <div className="notifier-content">
            <ul>
              {messages.map((message, index) => {
                return <li key={index}>{message}</li>;
              })}
            </ul>
          </div>
        </div>
      );
    case "ERROR":
      title = content?.response?.statusText
        ? content.response.statusText
        : "Error Found";
      if (content?.response?.data?.messages) {
        messages = Object.values(content.response.data.messages);
      } else {
        messages = [content.message];
      }
      //console.log(content);
      return (
        <div className="notifier error">
          <div className="notifier-header">
            <h3 className="notifier-title">
              <ExclamationDiamondFill /> {title}
            </h3>
            <button className="close-btn" onClick={handleOnClose}>
              <X />
            </button>
          </div>
          <div className="notifier-content">
            <ul>
              {messages.map((message, index) => {
                return <li key={index}>{message}</li>;
              })}
            </ul>
          </div>
        </div>
      );
    default:
      return null;
  }
};
export default memo(Notifier);
