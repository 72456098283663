import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  type: "",
  title: "",
  messages: [],
  isNotify: false,
};

const NotifySlice = createSlice({
  name: "notify",
  initialState,
  reducers: {
    showNotify: (state, action) => {
      state.isNotify = true;
      //from api
      if (action.payload?.status) {
        state.title = action.payload.statusText;
        //set type
        const status = action.payload.status;
        if (status === 200) {
          state.type = "success";
        } else {
          state.type = "error";
        }
        //set message
        if (Object.hasOwn(action.payload.data, "messages")) {
          if (typeof action.payload.data.messages === "object") {
            state.messages = Object.values(action.payload.data.messages);
          } else {
            state.messages[0] = action.payload.data.messages;
          }
        } else if (Object.hasOwn(action.payload.data, "message")) {
          state.messages[0] = action.payload.data.message;
        }
      } else {
        console.log(action.payload);
        state.title = action.payload;
        state.messages[0] = action.payload;
      }
    },
    hideNotify: (state, action) => {
      state.isNotify = false;
      state.title = "";
      state.type = "";
      state.messages = [];
    },
  },
});

export const { showNotify, hideNotify } = NotifySlice.actions;
export default NotifySlice.reducer;

export const autoNotify = (dispatch, response) => {
  dispatch(showNotify(response));
  setTimeout(() => {
    dispatch(hideNotify());
  }, 10000);
};
