import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../api/axios";

const initialState = {
  soId: "",
  soNo: "",
  soDate: "",
  soStatus: "Draft",
  soExpectedDeliveryDate: "",
  customer: null,
  billingAddress: "",
  saleRep: null,
  saleRepName: "",
  tableRowCounter: 0,
  tableRows: [],
  subTotal: 0,
  discount: "",
  discountValue: 0,
  tax: {
    tax_id: "1",
    tax_name: "None",
    tax_rate: "0",
    label: "None",
    value: "1",
  },
  taxValue: 0,
  soTotal: 0,
  reference: "",
  note: "",
  terms: "",
  paymentTerm: {
    term_id: 0,
    term_name: "Due Upon Receipt",
    term_days: 0,
    label: "Due Upon Receipt",
    value: 0,
  },
  paymentDue: new Date(),
  isLoading: true,
};

//get PO Number
export const getSONumber = createAsyncThunk(
  "sales/SONumber",
  async (data, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const token = auth.token;
      const branch = auth.branch;

      const searchParams = new URLSearchParams();
      searchParams.append("branch_id", branch.branch_id);
      searchParams.toString();

      const response = await axios.get(
        "customers/new-so-no?" + searchParams.toString(),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const SaleOrderSlice = createSlice({
  name: "sale_order",
  initialState,
  reducers: {
    setNewSO: () => initialState,
    setEditSO: (state, action) => {
      const so = action.payload;
      state.soId = so.so_id;
      state.soNo = so.so_no;
      state.soDate = so.so_date;
      state.soStatus = so.so_status;
      state.soExpectedDeliveryDate = so.ex_shipment_date;
      state.customer = so.customer?.cust_id
        ? {
            ...so.customer,
            label: so.customer.cust_display_name,
            value: so.customer.cust_id,
          }
        : null;
      state.billingAddress = so.customer_address;
      state.saleRep = so.sales_rep?.rep_id
        ? {
            ...so.sales_rep,
            label: so.sales_rep.rep_name,
            value: so.sales_rep.rep_id,
          }
        : null;
      state.saleRepName = so.sales_rep.rep_name;
      state.tableRows = so.so_item_rows.map((so_row) => {
        state.tableRowCounter++;
        return {
          rowID: state.tableRowCounter,
          item: {
            ...so_row.so_item,
            value: so_row.so_item.batch_id,
            label: so_row.so_item.item_sku,
          },
          itemDes: so_row.so_item_description,
          quantity: so_row.so_item_qty,
          unit: so_row.so_item_unit_id,
          rate: so_row.so_item_rate,
          amount: so_row.so_item_amount,
        };
      });
      state.discount = so.so_discount;
      if (so.so_tax !== null) {
        state.tax = {
          ...so.so_tax,
          value: so.so_tax.tax_id,
          label: so.so_tax.tax_name,
        };
      } else {
        state.tax = initialState.tax;
      }
      state.reference = so.so_reference;
      if (so.payment_term !== null) {
        state.paymentTerm = {
          ...so.payment_term,
          value: so.payment_term.term_id,
          label: so.payment_term.term_name,
        };
      } else {
        state.paymentTerm = initialState.paymentTerm;
      }
      const date = new Date();
      const days = parseInt(state.paymentTerm?.term_days);
      date.setDate(date.getDate() + days);
      state.paymentDue = date;

      state.note = so.customer_note;
      state.terms = so.so_terms;
    },
    setSOStatus: (state, action) => {
      state.soStatus = action.payload;
    },
    setSONumber: (state, action) => {
      state.soNo = action.payload;
    },
    setSODate: (state, action) => {
      state.soDate = action.payload;
    },
    setExpectedDeliveryDate: (state, action) => {
      state.soExpectedDeliveryDate = action.payload;
    },
    setSOCustomer: (state, action) => {
      const customer = action.payload;
      let address = customer.cust_first_name
        ? "Attn: " +
          customer.cust_first_name +
          " " +
          customer.cust_last_name +
          ",\n"
        : "";
      let company = customer?.cust_company
        ? customer.cust_company
        : customer?.cust_display_name;
      address += company + ",\n";
      address += customer.cust_address_street
        ? customer.cust_address_street + ",\n"
        : "";
      address += customer.cust_address_city
        ? customer.cust_address_city + ", "
        : "";
      address += customer.cust_address_country
        ? customer.cust_address_country + ". "
        : "";
      address += customer.cust_address_postcode
        ? customer.cust_address_postcode
        : "";
      address += customer?.cust_phone ? "\nTel : " + customer.cust_phone : "";

      state.customer = customer;
      state.billingAddress = address;
    },
    setSOBillingAddress: (state, action) => {
      state.billingAddress = action.payload;
    },
    setSOSaleRep: (state, action) => {
      const sale_rep = action.payload;
      state.saleRep = sale_rep;
      state.saleRepName = sale_rep?.rep_name;
    },
    setSOSaleRepName: (state, action) => {
      state.saleRepName = action.payload;
    },
    newTableRow: (state, action) => {
      state.tableRowCounter++;
      state.tableRows.push({
        rowID: state.tableRowCounter,
        item: null,
        itemDes: "",
        quantity: 0,
        unit: null,
        rate: 0,
        amount: 0,
      });
    },
    updateTableRow: (state, action) => {
      let editedRow = action.payload;
      const newArray = state.tableRows.map((row) => {
        if (row.rowID === editedRow.rowID) {
          return editedRow;
        }
        return row;
      });
      state.tableRows = newArray;
    },
    deleteTableRow: (state, action) => {
      let deletedRow = action.payload;
      const newArray = state.tableRows.filter((row) => {
        return row.rowID !== deletedRow.rowID;
      });
      state.tableRows = newArray;
    },
    setSubTotal: (state, action) => {
      state.subTotal = action.payload;
    },
    setDiscount: (state, action) => {
      state.discount = action.payload;
    },
    setDiscountValue: (state, action) => {
      state.discountValue = action.payload;
    },
    setSOTax: (state, action) => {
      state.tax = action.payload;
    },
    setSOTaxValue: (state, action) => {
      state.taxValue = action.payload;
    },
    setSOTotal: (state, action) => {
      state.soTotal = action.payload;
    },
    setReference: (state, action) => {
      state.reference = action.payload;
    },
    setNote: (state, action) => {
      state.note = action.payload;
    },
    setTerms: (state, action) => {
      state.terms = action.payload;
    },
    setPaymentTerms: (state, action) => {
      state.paymentTerm = action.payload;
      const date = new Date();
      const days = parseInt(state.paymentTerm?.term_days);
      date.setDate(date.getDate() + days);
      state.paymentDue = date;
    },
    setPaymentDue: (state, action) => {
      state.paymentDue = action.payload;
    },
  },
  extraReducers: (builder) => {
    //
    builder.addCase(getSONumber.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSONumber.fulfilled, (state, action) => {
      state.isLoading = false;
      state.soNo = action.payload.new_so_no;
    });
    builder.addCase(getSONumber.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const {
  setNewSO,
  setEditSO,
  setSONumber,
  setSOStatus,
  setSODate,
  setSOSaleRep,
  setSOSaleRepName,
  setExpectedDeliveryDate,
  setSOCustomer,
  setSOBillingAddress,
  newTableRow,
  updateTableRow,
  deleteTableRow,
  setSubTotal,
  setDiscount,
  setDiscountValue,
  setSOTax,
  setSOTaxValue,
  setSOTotal,
  setReference,
  setNote,
  setTerms,
  setPaymentTerms,
  setPaymentDue,
} = SaleOrderSlice.actions;
export default SaleOrderSlice.reducer;
