import "./GRNList.scss";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import DataTable from "react-data-table-component";
import SearchBar from "../../../../components/searchbar/SearchBar";
import { debounce } from "../../../../Helper";
import TextLoader from "../../../../components/loaders/Loader";
import {
  hideRightPanel,
  showRightPanel,
} from "../../../../redux/features/rightpanel/RightPanelSlice";
import RightSidePanel from "../../../../components/panels/right/RightSidePanel";
import {
  getGRNList,
  updateGRNList,
} from "../../../../redux/features/purchases/GRNListSlice";

import GRNListRight from "./GRNListRight";

/* set supplier list */
const columns = [
  {
    name: "Date",
    selector: (row) => row.grn_date,
    sortable: true,
    width: "15%",
  },
  {
    name: "GRN#",
    selector: (row) => row.grn_no,
    sortable: true,
    cell: (row) => {
      return (
        <div className="tbl-po-no-col" data-tag="allowRowEvents">
          <h6 data-tag="allowRowEvents">{row.grn_no}</h6>
        </div>
      );
    },
    width: "15%",
  },
  {
    name: "Reference#",
    selector: (row) => row.grn_reference,
    sortable: true,
    width: "15%",
  },
  {
    name: "Supplier",
    selector: (row) => row.supplier.supp_id,
    sortable: true,
    cell: (row) => {
      return (
        <div className="tbl-supplier-col" data-tag="allowRowEvents">
          <p data-tag="allowRowEvents">{row.supplier?.supp_display_name}</p>
          {row.supplier?.supp_address_street}, {row.supplier?.supp_address_city}
          , {row.supplier?.supp_address_country}
        </div>
      );
    },
  },
  {
    name: "Status",
    selector: (row) => row.grn_status,
    sortable: true,
  },
];
const conditionalRowStyles = [
  {
    when: (row) => row.isSelected === true,
    style: {
      backgroundColor: "#e3f2fd",
      userSelect: "none",
    },
  },
];

const GRNList = () => {
  const dispatch = useDispatch();
  const { grnList, totalRows, isLoading } = useSelector(
    (store) => store.goods_received_note_list
  );
  const { isPanelShow } = useSelector((store) => store.right_panel);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  /*Events */
  const handleOnSearch = debounce((e) => {
    setSearch(e.target.value);
    dispatch(getGRNList({ search: e.target.value }));
  }, 600);

  const handlePageChange = (page) => {
    const start = (page - 1) * perPage;
    dispatch(getGRNList({ start: start, length: perPage, search: search }));
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    const start = (page - 1) * newPerPage;
    dispatch(getGRNList({ start: start, length: newPerPage, search: search }));
    setPerPage(newPerPage);
    setCurrentPage(page);
  };

  const handleOnSelect = (row) => {
    const updatedList = grnList.map((grn) => {
      //unselect all
      if (row.grn_id !== grn.grn_id) {
        return { ...grn, isSelected: false };
      }
      //remove already selected one
      if (grn?.isSelected === true) {
        dispatch(hideRightPanel());
        return { ...grn, isSelected: false };
      }
      //selected current
      dispatch(showRightPanel({ content: <GRNListRight grn={grn} /> }));
      return { ...grn, isSelected: true };
    });
    dispatch(updateGRNList(updatedList));
  };

  // on mount
  useEffect(() => {
    dispatch(hideRightPanel());
    dispatch(getGRNList({ search: "" }));
  }, [dispatch]);

  return (
    <div
      className={
        isPanelShow
          ? "container grn-list show-right-side-panel"
          : "container grn-list"
      }
    >
      <div className="action-table-wrapper">
        <div className="action-table-title">
          <h3>Good Received Notes</h3>
        </div>
        <div className="row">
          <div className="col-md-4 ">
            <SearchBar
              searchHandler={handleOnSearch}
              placeholder="Search GRN here..."
            />
          </div>
        </div>

        <div className="action-table">
          <DataTable
            dense
            columns={columns}
            data={grnList}
            progressPending={isLoading}
            progressComponent={<TextLoader />}
            highlightOnHover
            onRowClicked={handleOnSelect}
            pagination
            paginationServer
            paginationPerPage={perPage}
            paginationRowsPerPageOptions={[10, 20, 50, 100, 200]}
            paginationTotalRows={totalRows}
            paginationDefaultPage={currentPage}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            conditionalRowStyles={conditionalRowStyles}
            className="list-table"
          />
        </div>
      </div>
      <RightSidePanel />
    </div>
  );
};
export default GRNList;
