import React, { useState } from "react";

const CheckPermission = (props) => {
  const { permission } = props;
  const [checked, setChecked] = useState(permission?.isCheck);
  return (
    <div className="form-check">
      <input
        className="form-check-input"
        type="checkbox"
        name="privileges[]"
        id={`privilege-${permission?.privi_id}`}
        value={permission?.privi_id}
        checked={checked}
        onChange={() => {
          setChecked(!checked);
        }}
      />
      <label
        className="form-check-label"
        htmlFor={`privilege-${permission?.privi_id}`}
      >
        {permission?.privi_name}
      </label>
    </div>
  );
};

export default React.memo(CheckPermission);
