import { Link } from "react-router-dom";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { PersonPlusFill, TagsFill } from "react-bootstrap-icons";
import { hasPrivilege } from "../../../../Helper";

import { showPrimaryModel } from "../../../../redux/features/modals/PrimaryModalSlice";
import { showOptionModel } from "../../../../redux/features/modals/OptionModalSlide";

import CategoryNew from "../../../../containers/sales/customer/CategoryNew";
import CustomerNew from "../../../../containers/sales/customer/CustomerNew";

const CustomersTab = (props) => {
  const dispatch = useDispatch();

  //create new category
  const handleNewCategory = () => {
    const new_cat = {
      cat_id: null,
      cat_name: "",
      cat_description: "",
    };
    const modelData = {
      title: "New Category",
      titleIcon: <TagsFill />,
      content: <CategoryNew category={new_cat} callback={() => {}} />,
    };
    dispatch(showOptionModel(modelData));
  };

  // carate new customer
  const handleNewCustomer = () => {
    const modelData = {
      title: "Add New Customer",
      titleIcon: <PersonPlusFill />,
      content: <CustomerNew />,
    };
    dispatch(showPrimaryModel(modelData));
  };

  return (
    <div className="row no-gutters">
      <div className="col-md-auto ">
        <div className="toolbar-section">
          <div className="row no-gutters">
            <div className="col-auto">
              <Link
                className={classNames("btn toolbar-button", {
                  disabled: !hasPrivilege("Customers", 500),
                })}
                to="/dashboard/sales/customer/list"
              >
                <img src="/img/icons/users.png" alt="customers" />
                <span className="text">Customers</span>
              </Link>
            </div>
            <div className="col-auto">
              <button
                className={classNames("btn toolbar-button", {
                  disabled: !hasPrivilege("Customers", 501),
                })}
                onClick={hasPrivilege("Customers", 501) && handleNewCustomer}
              >
                <img src="/img/icons/add-user.png" alt="add customer" />
                <span className="text">Add Customer</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-auto ">
        <div className="toolbar-section">
          <div className="row no-gutters">
            <div className="col-auto">
              <Link
                className={classNames("btn toolbar-button-inline", {
                  disabled: !hasPrivilege("Customers", 501),
                })}
                to="/dashboard/sales/customer/categories"
              >
                <img src="/img/icons/categorize.png" alt="receive cash" />
                <span className="text"> Categories </span>
              </Link>

              <button
                className={classNames("btn toolbar-button-inline", {
                  disabled: !hasPrivilege("Customers", 501),
                })}
                onClick={hasPrivilege("Customers", 501) && handleNewCategory}
              >
                <img src="/img/icons/add-tag.png" alt="Setting" />
                <span className="text"> Add Category </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomersTab;
