import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { PlusSquareDotted, Save } from "react-bootstrap-icons";
import axios from "../../../api/axios";
import Moment from "moment";

import SelectSupplier from "../../form/select/SelectSupplier";
import SelectPO from "./SelectPO";
import SelectWarehouse from "../../form/select/SelectWarehouse";

import { showPrintPreview } from "../../../redux/features/modals/PrintPreviewSlice";
import { autoNotify } from "../../../redux/features/notify/NotifySlice";
import {
  setGRNSupplier,
  setNewGRN,
  setGrnPO,
  setGRNWarehouse,
} from "../../../redux/features/purchases/GRNSlice";

const GRNCtrl = (prop) => {
  const dispatch = useDispatch();
  const action = prop.action;
  //global status
  const { user, token, branch } = useSelector((store) => store.auth);
  const {
    grnId,
    grnNo,
    grnDate,
    invoiceNo,
    grnStatus,
    supplier,
    supplierAddress,
    deliveryType,
    warehouse,
    deliveryAddress,
    purchaseOrders,
    tableRows,
    subTotal,
    discount,
    discountValue,
    tax,
    taxValue,
    grnTotal,
    reference,
    note,
    terms,
  } = useSelector((store) => store.goods_received_note);
  //locale status
  const [totalItems, setTotalItems] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  //Events
  const supplierCallback = useCallback(
    (selectedOption) => {
      dispatch(setGRNSupplier(selectedOption));
    },
    [dispatch]
  );

  const poOnChangeHandler = useCallback(
    (option) => {
      dispatch(setGrnPO(option));
    },
    [dispatch]
  );

  const warehouseCallback = useCallback(
    (selectedOption) => {
      dispatch(setGRNWarehouse(selectedOption));
    },
    [dispatch]
  );

  /* GRN print */
  const grnOnPrintHandler = () => {
    const previewData = {
      title: "Good Received Note",
      format: "GoodReceivedNote",
      data: getCurrentGRN(),
    };
    dispatch(showPrintPreview(previewData));
  };

  //Save GRN
  const saveGRN = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      let data = getCurrentGRN();
      const resp = await axios.post("purchase/grn-save", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setIsSubmitting(false);
      autoNotify(dispatch, resp);
      if (action === "New") {
        dispatch(setNewGRN());
      }
    } catch (error) {
      setIsSubmitting(false);
      autoNotify(dispatch, error?.response ? error.response : error.message);
    }
  };

  //Sve GRN with receive
  const saveAndReceived = async () => {
    setIsSubmitting(true);
    try {
      let grn = getCurrentGRN();
      let data = {
        ...grn,
        grn_status: "Received",
        items_status: "Received",
        com_id: branch.com_id,
        branch_id: branch.branch_id,
        updated_by: user.user_id,
      };
      const resp = await axios.post("purchase/grn-receive", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setIsSubmitting(false);
      autoNotify(dispatch, resp);
      if (action === "New") {
        dispatch(setNewGRN());
      }
    } catch (error) {
      setIsSubmitting(false);
      autoNotify(dispatch, error?.response ? error.response : error.message);
    }
  };

  //new PO
  const newGRNHandler = () => {
    dispatch(setNewGRN());
  };

  //effect
  //set total items
  useEffect(() => {
    let totalQty = 0;
    for (let i = 0; i < tableRows.length; i++) {
      const element = tableRows[i];
      totalQty = totalQty + parseInt(element.quantity);
    }
    setTotalItems(totalQty);
  }, [tableRows]);

  // this will return current po
  const getCurrentGRN = () => {
    //remove empty row
    let table_row_items = tableRows.filter((row) => {
      return row.item ? true : false;
    });

    let row_id = 0;
    let grn_item_rows = table_row_items.map((row) => {
      row_id++;
      const table_row = {
        row_id: row_id,
        batch_id: row.item.batch_id,
        grn_item: row.item,
        grn_item_description: row.itemDes,
        grn_item_qty: row.quantity,
        grn_item_rate: row.rate,
        grn_item_amount: row.amount,
      };
      return table_row;
    });

    let grn = {
      branch_id: branch.branch_id,
      grn_id: grnId,
      grn_date: Moment(grnDate).format("YYYY-MM-DD"),
      grn_no: grnNo,
      invoice_no: invoiceNo,
      grn_status: grnStatus,
      supplier_id: supplier?.supp_id,
      supplier_address: supplierAddress,
      po_list: purchaseOrders,
      deliver_location_type: deliveryType,
      deliver_location_id: warehouse?.ware_id,
      deliver_address: deliveryAddress,
      total_items: totalItems,
      grn_discount: discount,
      grn_discount_val: discountValue,
      grn_tax_id: tax?.tax_id,
      grn_tax_total: taxValue,
      grn_sub_total: subTotal,
      grn_total: grnTotal,
      supplier_note: note,
      grn_reference: reference,
      grn_terms: terms,
      grn_item_rows: grn_item_rows,
      created_by: user.user_id,
    };
    return grn;
  };

  return (
    <div className="control-content">
      <h5 className="display-4 title">{action} GRN</h5>
      <hr className="mt-0" />
      <div className="form-row">
        <div className="form-group col-md-9 mb-2">
          <label htmlFor="supplier-select">Select Supplier</label>
          <SelectSupplier selected={supplier} callback={supplierCallback} />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-9 mb-2">
          <label htmlFor="supplier-select">Select PO</label>
          <SelectPO
            selected={purchaseOrders[0]}
            supplier={supplier}
            callback={poOnChangeHandler}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-9 mb-2">
          <label htmlFor="supplier-select">Select Warehouse</label>
          <SelectWarehouse selected={warehouse} callback={warehouseCallback} />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-12 mb-1">
          <label htmlFor="sale-tax">Total Items</label>
          <h3 id="po-item-count-lbl">{totalItems}</h3>
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-12 mb-1">
          <label htmlFor="sale-tax">Total Amount</label>
          <h3 id="po-item-total-lbl">{parseFloat(grnTotal).toFixed(2)}</h3>
        </div>
      </div>
      <div className="mt-5 action-control">
        <button
          type="button"
          id="po-save-btn"
          className="btn btn-success b-button"
          onClick={saveGRN}
          disabled={isSubmitting}
        >
          Save
        </button>
        <button
          id="po-save-send-btn"
          className="btn btn-dark b-button"
          type="button"
          onClick={grnOnPrintHandler}
        >
          Preview
        </button>
        <Dropdown>
          <Dropdown.Toggle
            variant="secondary"
            id="more-action"
            className="b-button"
          >
            More
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ margin: 0 }}>
            {action === "New" ? (
              <Dropdown.Item eventKey="2" onClick={newGRNHandler}>
                <PlusSquareDotted /> New GRN
              </Dropdown.Item>
            ) : (
              ""
            )}
            <Dropdown.Item
              eventKey="3"
              onClick={(e) => {
                saveAndReceived();
              }}
              disabled={isSubmitting}
            >
              <Save /> Save & Received
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};
export default GRNCtrl;
