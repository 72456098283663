import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import AsyncCreatableSelect from "react-select/async-creatable";
import { debounce } from "../../../Helper";
import { PlusSquareDotted, BoundingBox } from "react-bootstrap-icons";
import axios from "../../../api/axios";
import { showOptionModel } from "../../../redux/features/modals/OptionModalSlide";
import CategoryEdit from "../../../containers/inventory/category/CategoryEdit";

/*select options */
const select_style = {
  control: (base) => ({
    ...base,
    height: 31,
    minHeight: 31,
    borderRadius: 3,
    border: "1px solid #d0d6db",
    boxShadow: "none",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: "31px",
    padding: "0 6px",
  }),
  input: (provided, state) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "31px",
  }),
  menuList: (provided) => ({
    ...provided,
  }),
  menu: (provided) => ({
    ...provided,
    width: "max-content",
    minWidth: "100%",
  }),
};

const formatItemSelectOption = (option, { context }) => {
  if (option?.__isNew__) {
    return (
      <>
        <PlusSquareDotted /> {option.label}
      </>
    );
  }
  return option.label;
};

const getCategoryList = async (
  token,
  branch,
  start = 0,
  length = 10,
  order = "desc",
  search = ""
) => {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append("com_id", branch.com_id);
    searchParams.append("start", start);
    searchParams.append("length", length);
    searchParams.append("order", order);
    searchParams.append("search", search);
    searchParams.toString();
    const response = await axios.get(
      "inventory/categories?" + searchParams.toString(),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    response.data.data = response.data.data.map((category) => {
      return {
        ...category,
        label: category.cat_name,
        value: category.cat_id,
      };
    });
    return response.data;
  } catch (error) {
    return [];
  }
};

const SelectCategory = (props) => {
  const { selected, callback } = props;
  const { token, branch } = useSelector((store) => store.auth);
  const dispatch = useDispatch();
  //local stats
  const [options, setOptions] = useState();
  const [category, setCategory] = useState();
  const [isLoading, setIsLoading] = useState(false);
  //events
  const loadListOptions = debounce(async (inputValue, callback) => {
    const options = await getCategoryList(
      token,
      branch,
      0,
      10,
      "desc",
      inputValue
    );
    callback(options.data);
  }, 500);

  const handleOnCreate = (inputValue) => {
    const new_category = {
      cat_id: null,
      cat_name: inputValue,
      cat_description: "",
      cat_type: "",
    };
    const modelData = {
      title: "Add New Category",
      titleIcon: <BoundingBox />,
      content: (
        <CategoryEdit
          category={new_category}
          edit_callback={(category) => {
            const option = {
              ...category,
              label: category.cat_name,
              value: category.cat_id,
            };
            setCategory(option);
            setOptions([...options, option]);
            if (callback) {
              callback(option);
            }
          }}
        />
      ),
    };
    dispatch(showOptionModel(modelData));
  };

  const handleOnChange = (option) => {
    setCategory(option);
    if (callback) {
      callback(option);
    }
  };
  //effect
  useEffect(() => {
    const unitData = async () => {
      setIsLoading(true);
      const list = await getCategoryList(token, branch, 0, 10, "desc", "");
      setOptions(list.data);
      setIsLoading(false);
    };
    unitData();
  }, [token, branch]);

  useEffect(() => {
    if (selected) {
      setCategory(selected);
    }
  }, [selected]);

  return (
    <AsyncCreatableSelect
      cacheOptions
      defaultOptions={options}
      loadOptions={loadListOptions}
      styles={select_style}
      onChange={handleOnChange}
      onCreateOption={handleOnCreate}
      value={category}
      formatOptionLabel={formatItemSelectOption}
      formatCreateLabel={(userInput) => `Add "${userInput}"`}
      placeholder=""
      isLoading={isLoading}
      name="category_id"
    />
  );
};
export default React.memo(SelectCategory);
