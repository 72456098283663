import { PencilFill } from "react-bootstrap-icons";
import { Trash2Fill } from "react-bootstrap-icons";
import { PersonFill } from "react-bootstrap-icons";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { hasPrivilege } from "../../../Helper";

import SupplierEdit from "./SupplierEdit";
import SupplierDelete from "./SupplierDelete";
import { showPrimaryModel } from "../../../redux/features/modals/PrimaryModalSlice";
import { showOptionModel } from "../../../redux/features/modals/OptionModalSlide";
import { hideRightPanel } from "../../../redux/features/rightpanel/RightPanelSlice";
import { updateSupplierList } from "../../../redux/features/suppliers/SupplierListSlice";

const SupplierListRight = (props) => {
  const dispatch = useDispatch();
  const [supplier, setSupplier] = useState(props.supplier);
  const { supplierList } = useSelector((store) => store.supplier_list);
  const isFirstRender = useRef(true);

  //set information
  let supp_full_name = supplier.supp_first_name + " " + supplier.supp_last_name;
  let supp_full_address = supplier.supp_address_street
    ? supplier.supp_address_street + ", "
    : "";
  supp_full_address += supplier.supp_address_city
    ? supplier.supp_address_city + ", "
    : "";
  supp_full_address += supplier.supp_address_country
    ? supplier.supp_address_country + ","
    : "";
  supp_full_address += supplier.supp_address_postcode
    ? supplier.supp_address_postcode
    : "";

  const infoList = [
    {
      name: "Name",
      value: supp_full_name,
    },
    {
      name: "Address",
      value: supp_full_address,
    },
    {
      name: "Credit Limit",
      value: supplier.supp_credit_limit,
    },
    {
      name: "Ledger Balance",
      value: supplier.supp_ledger_balance,
    },
  ];

  const info_list_elements = infoList.map((field) => {
    return (
      <div key={field.name} className="row no-gutters info-field">
        <div className="col-sm-3 name">
          <p>{field.name} : </p>
        </div>
        <div className="col-sm-8 value">
          <p>{field.value}</p>
        </div>
      </div>
    );
  });
  // set supplier transaction
  const setSupplierTransactions = () => {
    return <p>No Transactions yet.</p>;
  };

  //handler for edit
  const editCallback = (edited_supplier) => {
    setSupplier(edited_supplier);
    const updatedData = supplierList.map((list_supplier) => {
      if (edited_supplier.supp_id === list_supplier.supp_id) {
        return edited_supplier;
      }
      return list_supplier;
    });
    dispatch(updateSupplierList(updatedData));
  };
  const handleEdit = () => {
    const modelData = {
      title: "Edit Supplier",
      titleIcon: <PersonFill />,
      content: (
        <SupplierEdit edit_supplier={supplier} edit_callback={editCallback} />
      ),
    };
    dispatch(showPrimaryModel(modelData));
  };

  //handler for delete
  const deleteCallback = (supp_id) => {
    const updatedData = supplierList.filter(
      (list_supplier) => supp_id !== list_supplier.supp_id
    );
    dispatch(updateSupplierList(updatedData));
    dispatch(hideRightPanel());
  };
  const handleDelete = () => {
    const modelData = {
      title: "Delete Supplier",
      titleIcon: <PersonFill />,
      content: (
        <SupplierDelete supplier={supplier} delete_callback={deleteCallback} />
      ),
    };
    dispatch(showOptionModel(modelData));
  };

  //set supplier on mount
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    setSupplier(props.supplier);
  }, [props.supplier]);

  return (
    <div className="right-content">
      <div className="header">
        <div className="header-img">
          <img src="/img/icons/cust-lg.png" alt="side header" />
        </div>
        <div className="header-text">
          <h4>{supplier.supp_display_name}</h4>
          <p>{supplier.supp_company}</p>
        </div>
      </div>
      <div className="info-section">
        <h6>Supplier Information</h6>
        {info_list_elements}
      </div>
      <h6>Supplier Transactions</h6>
      <Scrollbars style={{ width: "100%", height: "100%" }}>
        <div className="trans-content">{setSupplierTransactions()}</div>
      </Scrollbars>
      <div className="action-section">
        {hasPrivilege("Suppliers", 602) && (
          <button onClick={handleEdit} className="btn btn-primary b-button">
            <PencilFill /> Edit Supplier
          </button>
        )}
        {hasPrivilege("Suppliers", 603) && (
          <button onClick={handleDelete} className="btn btn-dark b-button">
            <Trash2Fill /> Delete
          </button>
        )}
      </div>
    </div>
  );
};

export default SupplierListRight;
