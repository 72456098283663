import "./SOList.scss";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import DataTable from "react-data-table-component";
import SearchBar from "../../../../components/searchbar/SearchBar";
import { debounce } from "../../../../Helper";
import TextLoader from "../../../../components/loaders/Loader";
import {
  hideRightPanel,
  showRightPanel,
} from "../../../../redux/features/rightpanel/RightPanelSlice";
import RightSidePanel from "../../../../components/panels/right/RightSidePanel";
import {
  getSOList,
  updateSOList,
} from "../../../../redux/features/sales/SaleOrderListSlice";

import SOListRight from "./SOListRight";

/* set supplier list */
const columns = [
  {
    name: "Date",
    selector: (row) => row.so_date,
    sortable: true,
    width: "15%",
  },
  {
    name: "SO#",
    selector: (row) => row.so_no,
    sortable: true,
    cell: (row) => {
      return (
        <div className="tbl-so-no-col" data-tag="allowRowEvents">
          <h6 data-tag="allowRowEvents">{row.so_no}</h6>
        </div>
      );
    },
    width: "15%",
  },
  {
    name: "Reference#",
    selector: (row) => row.so_reference,
    sortable: true,
    width: "15%",
  },
  {
    name: "Customer",
    selector: (row) => row?.customer.cust_id,
    sortable: true,
    cell: (row) => {
      return (
        <div className="tbl-customer-col" data-tag="allowRowEvents">
          <p data-tag="allowRowEvents">{row.customer?.cust_display_name}</p>
          {row.customer?.cust_address_street}, {row.customer?.cust_address_city}
          , {row.customer?.cust_address_country}
        </div>
      );
    },
  },
  {
    name: "Status",
    selector: (row) => row.so_status,
    sortable: true,
    width: "10%",
  },
  {
    name: "Invoice",
    selector: (row) => row.invoice_status,
    sortable: true,
  },
];
const conditionalRowStyles = [
  {
    when: (row) => row.isSelected === true,
    style: {
      backgroundColor: "#e3f2fd",
      userSelect: "none",
    },
  },
];

const SOList = () => {
  const dispatch = useDispatch();
  const { saleOrderList, totalRows, isLoading } = useSelector(
    (store) => store.sale_order_list
  );
  const { isPanelShow } = useSelector((store) => store.right_panel);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  /*Events */
  const handleOnSearch = debounce((e) => {
    setSearch(e.target.value);
    dispatch(getSOList({ search: e.target.value }));
  }, 600);

  const handlePageChange = (page) => {
    const start = (page - 1) * perPage;
    dispatch(getSOList({ start: start, length: perPage, search: search }));
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    const start = (page - 1) * newPerPage;
    dispatch(getSOList({ start: start, length: newPerPage, search: search }));
    setPerPage(newPerPage);
    setCurrentPage(page);
  };

  const handleOnSelect = (row) => {
    const updatedList = saleOrderList.map((so) => {
      //unselect all
      if (row.so_id !== so.so_id) {
        return { ...so, isSelected: false };
      }
      //remove already selected one
      if (so?.isSelected === true) {
        dispatch(hideRightPanel());
        return { ...so, isSelected: false };
      }
      //selected current
      dispatch(showRightPanel({ content: <SOListRight saleOrder={so} /> }));
      return { ...so, isSelected: true };
    });
    dispatch(updateSOList(updatedList));
  };

  // on mount
  useEffect(() => {
    dispatch(hideRightPanel());
    dispatch(getSOList({ search: "" }));
  }, [dispatch]);

  return (
    <div
      className={
        isPanelShow
          ? "container so-list show-right-side-panel"
          : "container so-list"
      }
    >
      <div className="action-table-wrapper">
        <div className="action-table-title">
          <h3>Sale Order List</h3>
        </div>
        <div className="row">
          <div className="col-md-4 ">
            <SearchBar
              searchHandler={handleOnSearch}
              placeholder="Search Sale Order here..."
            />
          </div>
        </div>

        <div className="action-table">
          <DataTable
            dense
            columns={columns}
            data={saleOrderList}
            progressPending={isLoading}
            progressComponent={<TextLoader />}
            highlightOnHover
            onRowClicked={handleOnSelect}
            pagination
            paginationServer
            paginationPerPage={perPage}
            paginationRowsPerPageOptions={[10, 20, 50, 100, 200]}
            paginationTotalRows={totalRows}
            paginationDefaultPage={currentPage}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            conditionalRowStyles={conditionalRowStyles}
            className="list-table"
          />
        </div>
      </div>
      <RightSidePanel />
    </div>
  );
};
export default SOList;
