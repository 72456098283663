import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import axios from "../../../api/axios";
import { autoNotify } from "../../../redux/features/notify/NotifySlice";
import { hideOptionModel } from "../../../redux/features/modals/OptionModalSlide";

const measureOptions = [
  { value: "weight", label: "Weight (g)" },
  { value: "length", label: "Length (cm)" },
  { value: "capacity", label: "Capacity (ml)" },
  { value: "item", label: "Item" },
  { value: "unit", label: "Unit" },
  { value: "piece", label: "Piece" },
];

const select_style = {
  control: (base) => ({
    ...base,
    height: 31,
    minHeight: 31,
    borderRadius: 3,
    border: "1px solid #d0d6db",
    boxShadow: "none",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: "31px",
    padding: "0 6px",
  }),
  input: (provided, state) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "31px",
  }),
};

const Measurement = () => {
  const dispatch = useDispatch();
  const { token, branch, user } = useSelector((store) => store.auth);

  const [measurement, setMeasurement] = useState({
    com_id: branch.com_id,
    measur_id: "",
    measurement: "",
    type: "",
    ratio: 1,
    created_by: user.user_id,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  //events
  const inputsOnChange = (e) => {
    const { name, value } = e.target;
    setMeasurement((prevMeasure) => {
      return { ...prevMeasure, [name]: value };
    });
  };

  const selectOnChange = (name, option) => {
    setMeasurement((prevMeasure) => {
      return { ...prevMeasure, [name]: option.value };
    });
  };

  //action
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const resp = await axios.post("inventory/measurement", measurement, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      autoNotify(dispatch, resp);
      setIsSubmitting(false);
      dispatch(hideOptionModel());
    } catch (error) {
      autoNotify(dispatch, error.response);
      setIsSubmitting(false);
    }
  };

  const handleFormClose = () => {
    dispatch(hideOptionModel());
  };

  return (
    <form>
      <div className="form-row">
        <div className="form-group col-md-8 mb-1">
          <label htmlFor="measurement">Measurement Name</label>
          <input
            type="text"
            className="form-control"
            id="measurement"
            name="measurement"
            value={measurement.measurement}
            onChange={inputsOnChange}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-4 mb-1">
          <label htmlFor="type">type</label>
          <Select
            options={measureOptions}
            styles={select_style}
            name="type"
            id="type"
            value={measureOptions.find((type) => type === measurement.type)}
            onChange={(e) => selectOnChange("type", e)}
          />
        </div>
        <div className="form-group col-md-3 mb-1">
          <label htmlFor="ratio">Ratio</label>
          <input
            type="number"
            className="form-control"
            id="ratio"
            name="ratio"
            min="1"
            step="1"
            value={measurement.ratio}
            onChange={inputsOnChange}
          />
        </div>
      </div>
      <div className="mt-5">
        <button
          id="supp-create-btn"
          className="btn btn-primary float-right save-button ml-2"
          type="submit"
          disabled={isSubmitting}
          onClick={handleFormSubmit}
        >
          <i className="fas fa-save"></i>
          Create
        </button>
        <button
          id="supp-close-btn"
          type="button"
          className="btn btn-secondary float-right "
          data-dismiss="modal"
          onClick={handleFormClose}
        >
          Close
        </button>
      </div>
    </form>
  );
};

export default Measurement;
