import "./UserList.scss";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import DataTable from "react-data-table-component";
import TextLoader from "../../components/loaders/Loader";
import { debounce } from "../../Helper";
import SearchBar from "../../components/searchbar/SearchBar";
import {
  getUserList,
  updateData,
  setPerPage,
  setCurrentPage,
} from "../../redux/features/users/UserListSlice";
import RightSidePanel from "../../components/panels/right/RightSidePanel";
import {
  showRightPanel,
  hideRightPanel,
} from "../../redux/features/rightpanel/RightPanelSlice";
import UserListRight from "./UserListRight";

/*User list table  */
const columns = [
  {
    name: "Full Name",
    selector: (row) => row.user_full_name,
    sortable: true,
    cell: (row) => {
      return (
        <div className="tbl-user-full-name">
          <div className="tbl-user-img">
            <img
              src={process.env.REACT_APP_API_URL + row.user_image}
              alt="user"
              data-tag="allowRowEvents"
            />
          </div>
          <div data-tag="allowRowEvents">
            <h6 data-tag="allowRowEvents">{row.user_full_name}</h6>
            {row.user_email}
          </div>
        </div>
      );
    },
  },
  {
    name: "Role",
    selector: (row) => row.role_name,
    sortable: true,
  },
];

const conditionalRowStyles = [
  {
    when: (row) => row.isSelected === true,
    style: {
      backgroundColor: "#e3f2fd",
      userSelect: "none",
    },
  },
];

const UserList = () => {
  const dispatch = useDispatch();
  const { data, recordsTotal, isLoading, currentPage } = useSelector(
    (store) => store.user_list
  );
  const { isPanelShow } = useSelector((store) => store.right_panel);
  const [search, setSearch] = useState("");

  // on mount
  useEffect(() => {
    dispatch(hideRightPanel());
    dispatch(getUserList({ search: "" }));
  }, [dispatch]);

  //handle table
  const handleOnSearch = debounce((e) => {
    dispatch(getUserList({ search: e.target.value }));
    setSearch(e.target.value);
  }, 600);

  const handlePageChange = (page) => {
    dispatch(setCurrentPage(page));
    dispatch(getUserList({ search: search }));
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    dispatch(setPerPage(newPerPage));
    dispatch(getUserList({ search: search }));
  };

  const handleOnSelect = (row) => {
    const updatedData = data.map((user) => {
      //unselect all
      if (row.user_id !== user.user_id) {
        return { ...user, isSelected: false };
      }
      //remove already selected one
      if (user?.isSelected === true) {
        dispatch(hideRightPanel());
        return { ...user, isSelected: false };
      }
      //selected current
      dispatch(
        showRightPanel({ content: <UserListRight selectedUser={user} /> })
      );
      return { ...user, isSelected: true };
    });

    dispatch(updateData(updatedData));
  };

  //set side panel
  return (
    <div
      className={
        isPanelShow
          ? "container user-list show-right-side-panel"
          : "container user-list"
      }
    >
      <div className="action-table-wrapper">
        <div className="action-table-title">
          <h3>User List</h3>
        </div>
        <div className="row">
          <div className="col-md-4 ">
            <SearchBar
              searchHandler={handleOnSearch}
              placeholder="Search users here..."
            />
          </div>
        </div>

        <div className="action-table">
          <DataTable
            dense
            columns={columns}
            data={data}
            progressPending={isLoading}
            progressComponent={<TextLoader />}
            highlightOnHover
            onRowClicked={handleOnSelect}
            pagination
            paginationServer
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10, 20, 50, 100, 200]}
            paginationTotalRows={recordsTotal}
            paginationDefaultPage={currentPage}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            conditionalRowStyles={conditionalRowStyles}
          />
        </div>
      </div>
      <RightSidePanel />
    </div>
  );
};

export default UserList;
