import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../../../api/axios";
import Moment from "moment";
import { Dropdown } from "react-bootstrap";
import { PlusSquareDotted, Save } from "react-bootstrap-icons";

import { autoNotify } from "../../../../redux/features/notify/NotifySlice";
import { showPrintPreview } from "../../../../redux/features/modals/PrintPreviewSlice";

import SelectCustomer from "./SelectCustomer";
import SelectSalesRep from "./SelectSalesRep";
import SelectSO from "./SelectSO";

import {
  setSaleCustomer,
  setSalesRep,
  setNewSale,
  setSaleOrder,
} from "../../../../redux/features/sales/InvoiceSlice";

const InvoiceCtrl = (prop) => {
  const dispatch = useDispatch();
  const { token, branch, user } = useSelector((store) => store.auth);
  const action = prop.action;
  const {
    saleId,
    saleNo,
    saleDate,
    saleStatus,
    customer,
    salesRep,
    salesRepName,
    saleOrder,
    billingAddress,
    discount,
    discountValue,
    tax,
    taxValue,
    subTotal,
    saleTotal,
    tableRows,
    orderNo,
    orderDate,
    reference,
    note,
    terms,
    paymentTerm,
    paymentDue,
  } = useSelector((store) => store.sale_invoice);

  const [totalItems, setTotalItems] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  //Events
  const customerOnChangeHandler = useCallback(
    (name, customer) => {
      dispatch(setSaleCustomer(customer));
      dispatch(setSaleOrder(null));
    },
    [dispatch]
  );

  const saleRepOnChangeHandler = useCallback(
    (name, salesRep) => {
      dispatch(setSalesRep(salesRep));
    },
    [dispatch]
  );

  const soOnChangeHandler = useCallback(
    (saleOrder) => {
      dispatch(setSaleOrder(saleOrder));
    },
    [dispatch]
  );

  //set total items
  useEffect(() => {
    let totalQty = 0;
    for (let i = 0; i < tableRows.length; i++) {
      const element = tableRows[i];
      totalQty = totalQty + parseInt(element.quantity);
    }
    setTotalItems(totalQty);
  }, [tableRows]);

  //Actions
  //Save PO
  const saveInvoice = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      let data = getCurrentInvoice();
      const resp = await axios.post("customers/new-invoice", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setIsSubmitting(false);
      autoNotify(dispatch, resp);
      if (action === "New") {
        dispatch(setNewSale());
      }
    } catch (error) {
      setIsSubmitting(false);
      autoNotify(dispatch, error?.response ? error.response : error.message);
    }
  };

  //Sve PO with confirm
  const saveAndProcess = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      let data = getCurrentInvoice();
      data.invoice_status = "Invoiced";

      const resp = await axios.post("customers/process-invoice", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setIsSubmitting(false);
      autoNotify(dispatch, resp);
      if (action === "New") {
        dispatch(setNewSale());
      }
    } catch (error) {
      setIsSubmitting(false);
      autoNotify(dispatch, error?.response ? error.response : error.message);
    }
  };

  /* PO print */
  const invoicePrintHandler = () => {
    const previewData = {
      title: "Sale Invoice",
      format: "SaleInvoice",
      data: getCurrentInvoice(),
    };
    dispatch(showPrintPreview(previewData));
  };

  //new PO
  const newInvoiceHandler = () => {
    dispatch(setNewSale());
  };

  // this will return current po
  const getCurrentInvoice = () => {
    //remove empty
    let table_row_items = tableRows.filter((row) => {
      return row.item ? true : false;
    });
    let row_id = 0;
    let sale_item_rows = table_row_items.map((row) => {
      row_id++;
      const table_row = {
        row_id: row_id,
        batch_id: row.item.batch_id,
        sale_item: row.item,
        sale_item_description: row.itemDes,
        sale_item_qty: row.quantity,
        sale_item_rate: row.rate,
        sale_item_amount: row.amount,
      };
      return table_row;
    });

    let sale = {
      com_id: branch.com_id,
      branch_id: branch.branch_id,
      sale_id: saleId,
      sale_date: Moment(saleDate).format("YYYY-MM-DD"),
      sale_no: saleNo,
      invoice_status: saleStatus,
      customer_id: customer?.cust_id,
      customer_address: billingAddress,
      sales_rep_id: salesRep?.rep_id,
      sales_rep_name: salesRepName,
      total_items: totalItems,
      sale_discount: discount,
      sale_discount_val: discountValue,
      sale_tax_id: tax?.tax_id,
      sale_tax_total: taxValue,
      sale_sub_total: subTotal,
      sale_amount: saleTotal,
      sale_order: saleOrder,
      so_id: saleOrder ? saleOrder?.so_id : null,
      so_no: orderNo,
      so_date: orderDate ? Moment(orderDate).format("YYYY-MM-DD") : null,
      payment_terms_id: paymentTerm?.term_id,
      payment_term: paymentTerm,
      payment_due_date: Moment(paymentDue).format("YYYY-MM-DD"),
      side_note: note,
      sale_reference: reference,
      sale_terms: terms,
      sale_item_rows: sale_item_rows,
      created_by: user.user_id,
    };
    return sale;
  };

  return (
    <div className="control-content">
      <h5 className="display-4 title">{action} Invoice</h5>
      <hr className="mt-0" />
      <div className="form-row">
        <div className="form-group col-md-9 mb-2">
          <label htmlFor="customer_id">Select Customer</label>
          <SelectCustomer
            name={"customer_id"}
            value={customer}
            callback={customerOnChangeHandler}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-9 mb-2">
          <label htmlFor="salesRep">Select Sales Rep</label>
          <SelectSalesRep
            name={"salesRep"}
            value={salesRep}
            callback={saleRepOnChangeHandler}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-9 mb-2">
          <label htmlFor="SO-select">Sale Order#</label>
          <SelectSO
            value={saleOrder}
            customer={customer}
            callback={soOnChangeHandler}
          />
        </div>
      </div>

      <div className="form-row mt-5">
        <div className="form-group col-md-12 mb-1">
          <label htmlFor="sale-tax">Total Items</label>
          <h3 id="po-item-count-lbl">{totalItems}</h3>
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-12 mb-1">
          <label htmlFor="sale-tax">Total Amount</label>
          <h3 id="po-item-total-lbl">{parseFloat(saleTotal).toFixed(2)}</h3>
        </div>
      </div>
      <div className="mt-5 action-control">
        <button
          type="button"
          id="po-save-btn"
          className="btn btn-success b-button"
          onClick={saveInvoice}
          disabled={isSubmitting}
        >
          Save
        </button>
        <button
          id="po-save-send-btn"
          className="btn btn-dark b-button"
          type="button"
          onClick={invoicePrintHandler}
        >
          Preview
        </button>
        <Dropdown>
          <Dropdown.Toggle
            variant="secondary"
            id="more-action"
            className="b-button"
          >
            More
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ margin: 0 }}>
            {action === "New" ? (
              <Dropdown.Item eventKey="2" onClick={newInvoiceHandler}>
                <PlusSquareDotted /> New Invoice
              </Dropdown.Item>
            ) : (
              ""
            )}
            <Dropdown.Item
              eventKey="3"
              onClick={(e) => {
                saveAndProcess(e);
              }}
              disabled={isSubmitting}
            >
              <Save /> Save & Process
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default InvoiceCtrl;
