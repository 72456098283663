import "./style.scss";
import ListTable from "./ListTable";

const ActionList = (props) => {
  const {
    listName,
    getListCallback,
    columns,
    listData,
    listTotal,
    isLoading,
    sideContent,
  } = props;

  return (
    <div className="container action-list">
      <div className="row no-gutters">
        <div className="col-md-8">
          <div className="module-wrapper shadow-sm">
            <div className="main-section ">
              <div className="action-table-title">
                <h3>{listName}</h3>
              </div>
              <ListTable
                listName={listName}
                columns={columns}
                listData={listData}
                listTotal={listTotal}
                isLoading={isLoading}
                getListCallback={getListCallback}
              />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="module-wrapper shadow-sm">
            <div className="side-section">{sideContent()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionList;
