import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import AsyncSelect from "react-select/async";
import { debounce } from "../../../Helper";
import axios from "../../../api/axios";

/*select options */
const select_style = {
  control: (base) => ({
    ...base,
    height: 31,
    minHeight: 31,
    borderRadius: 3,
    border: "1px solid #d0d6db",
    boxShadow: "none",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: "31px",
    padding: "0 6px",
  }),
  input: (provided, state) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "31px",
  }),
  menuList: (provided) => ({
    ...provided,
  }),
  menu: (provided) => ({
    ...provided,
    width: "max-content",
    minWidth: "100%",
  }),
};

const getItemList = async (
  token,
  branch,
  start = 0,
  length = 10,
  order = "desc",
  search = ""
) => {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append("com_id", branch.com_id);
    searchParams.append("start", start);
    searchParams.append("length", length);
    searchParams.append("order", order);
    searchParams.append("search", search);
    searchParams.toString();
    const response = await axios.get(
      "inventory/product-list?" + searchParams.toString(),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    response.data.data = response.data.data.map((item) => {
      return {
        ...item,
        label: item.item_sku,
        value: item.item_id,
      };
    });
    return response.data;
  } catch (error) {
    return [];
  }
};

const SelectMaterialItem = (props) => {
  const { selected, callback } = props;
  const { token, branch } = useSelector((store) => store.auth);
  //local stats
  const [options, setOptions] = useState();
  const [item, setItem] = useState();
  const [isLoading, setIsLoading] = useState(false);
  //events
  const loadListOptions = debounce(async (inputValue, callback) => {
    const options = await getItemList(token, branch, 0, 10, "desc", inputValue);
    callback(options.data);
  }, 500);

  const handleOnChange = (option) => {
    setItem(option);
    if (callback) {
      callback(option);
    }
  };

  //effect
  useEffect(() => {
    const unitData = async () => {
      setIsLoading(true);
      const list = await getItemList(token, branch, 0, 10, "desc", "");
      setOptions(list.data);
      setIsLoading(false);
    };
    unitData();
  }, [token, branch]);

  useEffect(() => {
    if (selected) {
      setItem(selected);
    }
  }, [selected]);

  //format
  const formatItemSelectOption = (option, { context }) => {
    if (context === "menu") {
      const label = (
        <div className="drop-item" style={{ display: "flex" }}>
          <div className="drop-item-col" style={{ width: "100px" }}>
            {option.label}
          </div>
          <div className="drop-item-col" style={{ width: "200px" }}>
            {option.item_name}
          </div>
          <div className="drop-item-col" style={{ width: "100px" }}>
            {option.item_type}
          </div>
        </div>
      );
      return label;
    }
    return option.label + " - " + option.item_name;
  };

  return (
    <AsyncSelect
      cacheOptions
      defaultOptions={options}
      loadOptions={loadListOptions}
      styles={select_style}
      onChange={handleOnChange}
      value={item}
      placeholder=""
      isLoading={isLoading}
      formatOptionLabel={formatItemSelectOption}
      name="material_item_id[]"
      menuPortalTarget={document.querySelector(".primary_model")}
    />
  );
};
export default React.memo(SelectMaterialItem);
