import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import AsyncCreatableSelect from "react-select/async-creatable";
import { PlusSquareDotted, PersonPlusFill } from "react-bootstrap-icons";

import { debounce } from "../../../Helper";
import SupplierNew from "../../../containers/purchases/supplier/SupplierNew";

import { showPrimaryModel } from "../../../redux/features/modals/PrimaryModalSlice";
import { getSupplierList } from "../../../redux/features/suppliers/SupplierListSlice";

/*select options */
const select_style = {
  control: (base) => ({
    ...base,
    height: 31,
    minHeight: 31,
    borderRadius: 3,
    border: "1px solid #d0d6db",
    boxShadow: "none",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: "31px",
    padding: "0 6px",
  }),
  input: (provided, state) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "31px",
  }),
  menuList: (provided) => ({
    ...provided,
  }),
  menu: (provided) => ({
    ...provided,
    width: "max-content",
    minWidth: "100%",
  }),
};

const formatItemSelectOption = (option, { context }) => {
  if (option?.__isNew__) {
    return (
      <>
        <PlusSquareDotted /> {option.label}
      </>
    );
  }
  return option.label;
};

const SelectSupplier = (props) => {
  const { selected, callback } = props;

  const dispatch = useDispatch();
  //local stats
  const [options, setOptions] = useState();
  const [isLoading, setIsLoading] = useState(false);
  //events
  const loadListOptions = debounce(async (inputValue, callback) => {
    let options = await dispatch(
      getSupplierList({
        start: 0,
        length: 10,
        order: "desc",
        search: inputValue,
      })
    ).unwrap();
    options = options.data.map((supplier) => {
      return {
        ...supplier,
        label: supplier.supp_display_name,
        value: supplier.supp_id,
      };
    });
    callback(options);
  }, 500);

  const handleOnChange = (option) => {
    if (callback) {
      callback(option);
    }
  };

  const handleOnCreate = (inputValue) => {
    const new_supplier = {
      supp_id: null,
      supp_display_name: inputValue,
    };
    const modelData = {
      title: "Add New Supplier",
      titleIcon: <PersonPlusFill />,
      content: (
        <SupplierNew
          supplier={new_supplier}
          callback={(supplier) => {
            const option = {
              ...supplier,
              label: supplier.supp_display_name,
              value: supplier.supp_id,
            };
            setOptions([...options, option]);
            if (callback) {
              callback(option);
            }
          }}
        />
      ),
    };
    dispatch(showPrimaryModel(modelData));
  };

  //effect
  useEffect(() => {
    const unitData = async () => {
      setIsLoading(true);
      const list = await dispatch(
        getSupplierList({
          start: 0,
          length: 10,
          order: "desc",
          search: "",
        })
      ).unwrap();
      const options = list.data.map((supplier) => {
        return {
          ...supplier,
          label: supplier.supp_display_name,
          value: supplier.supp_id,
        };
      });
      setOptions(options);
      setIsLoading(false);
    };
    unitData();
  }, [dispatch]);

  return (
    <AsyncCreatableSelect
      cacheOptions
      defaultOptions={options}
      loadOptions={loadListOptions}
      styles={select_style}
      onChange={handleOnChange}
      onCreateOption={handleOnCreate}
      value={selected}
      formatOptionLabel={formatItemSelectOption}
      formatCreateLabel={(userInput) => `Add "${userInput}" as new supplier`}
      placeholder="Search..."
      isLoading={isLoading}
      name="supplier_id"
    />
  );
};
export default React.memo(SelectSupplier);
