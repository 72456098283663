import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  PencilFill,
  Trash2Fill,
  PersonLinesFill,
  PersonXFill,
} from "react-bootstrap-icons";
import { Scrollbars } from "react-custom-scrollbars-2";
import { hasPrivilege } from "../../Helper";

import { showPrimaryModel } from "../../redux/features/modals/PrimaryModalSlice";
import { showOptionModel } from "../../redux/features/modals/OptionModalSlide";
import { hideRightPanel } from "../../redux/features/rightpanel/RightPanelSlice";
import UserEdit from "./UserEdit";
import UserDelete from "./UserDelete";
import { updateData } from "../../redux/features/users/UserListSlice";

const UserListRight = (props) => {
  const dispatch = useDispatch();
  const { selectedUser } = props;
  const user_list = useSelector((store) => store.user_list.data);
  const [user, setUser] = useState(selectedUser);
  const isFirstRender = useRef(true);

  //set user info
  const infoList = [
    {
      name: "Username",
      value: user.username,
    },
    {
      name: "Role",
      value: user.role_name,
    },
    {
      name: "Email",
      value: user.user_email,
    },
    {
      name: "Phone",
      value: user.user_mobile + ", " + user.user_phone,
    },
    {
      name: "Address",
      value: user.user_address,
    },
  ];

  const info_list_elements = infoList.map((field) => {
    return (
      <div key={field.name} className="row no-gutters info-field">
        <div className="col-sm-3 name">
          <p>{field.name} : </p>
        </div>
        <div className="col-sm-8 value">
          <p>{field.value}</p>
        </div>
      </div>
    );
  });

  //set user activities
  const setUserActivities = (user, dispatch) => {
    return <p>No activities yet.</p>;
  };

  //handler for edit
  const editCallback = (edited_user) => {
    setUser(edited_user);
    const updatedData = user_list.map((list_user) => {
      if (edited_user.user_id === list_user.user_id) {
        return edited_user;
      }
      return list_user;
    });
    dispatch(updateData(updatedData));
  };
  const handleEdit = () => {
    const modelData = {
      title: "Edit User",
      titleIcon: <PersonLinesFill />,
      content: <UserEdit edit_user={user} edit_callback={editCallback} />,
    };
    dispatch(showPrimaryModel(modelData));
  };

  //handler for delete
  const deleteCallback = (user_id) => {
    const updatedData = user_list.filter(
      (list_user) => user_id !== list_user.user_id
    );
    dispatch(updateData(updatedData));
    dispatch(hideRightPanel());
  };
  const handleDelete = () => {
    const modelData = {
      title: "Delete User",
      titleIcon: <PersonXFill />,
      content: (
        <UserDelete delete_user={user} delete_callback={deleteCallback} />
      ),
    };
    dispatch(showOptionModel(modelData));
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    setUser(selectedUser);
  }, [selectedUser]);

  return (
    <div className="right-content">
      <div className="header">
        <div className="header-img">
          <img
            src={process.env.REACT_APP_API_URL + user.user_image}
            alt="side header"
          />
        </div>
        <div className="header-text">
          <h4>{user.user_full_name}</h4>
          <p>{user.user_email}</p>
        </div>
      </div>
      <div className="info-section">
        <h6>User Information</h6>
        {info_list_elements}
      </div>
      <h6>User Activities</h6>
      <Scrollbars style={{ width: "100%", height: "100%" }}>
        <div className="trans-content">{setUserActivities()}</div>
      </Scrollbars>
      <div className="action-section">
        {hasPrivilege("Users", 702) && (
          <button onClick={handleEdit} className="btn btn-primary b-button">
            <PencilFill /> Edit User
          </button>
        )}
        {hasPrivilege("Users", 703) && (
          <button onClick={handleDelete} className="btn btn-dark b-button">
            <Trash2Fill /> Delete
          </button>
        )}
      </div>
    </div>
  );
};
export default UserListRight;
