import { memo, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "../../../api/axios";

const getBatchList = async (
  token,
  branch,
  start = 0,
  length = 10,
  order = "desc",
  search = "",
  itemId = ""
) => {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append("com_id", branch.com_id);
    searchParams.append("item_id", itemId);
    searchParams.append("start", start);
    searchParams.append("length", length);
    searchParams.append("order", order);
    searchParams.append("search", search);
    searchParams.toString();
    const response = await axios.get(
      "inventory/batch-list?" + searchParams.toString(),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    response.data.data = response.data.data.map((batch) => {
      return {
        ...batch,
        label: "Batch - " + batch.batch_id,
        value: batch.batch_id,
      };
    });
    return response.data;
  } catch (error) {
    return [];
  }
};

const AdjustmentValue = (props) => {
  const { item, callback } = props;
  //global
  const { token, branch } = useSelector((store) => store.auth);
  //local stats
  const [valueForm, setValueForm] = useState({
    current_value: 0,
    change_value: 0,
    adjusted_value: 0,
  });
  const [isLoading, setIsLoading] = useState(false);

  //event
  const handleValueChange = (e) => {
    const newValue = e.target.value ? parseFloat(e.target.value) : 0;
    const adjust = newValue - valueForm.current_value;

    const updated = {
      ...valueForm,
      change_value: e.target.value,
      adjusted_value: adjust,
    };
    setValueForm(updated);
    callback(updated);
  };

  //effect
  useEffect(() => {
    const batchData = async () => {
      setIsLoading(true);
      const list = await getBatchList(
        token,
        branch,
        0,
        50,
        "desc",
        "",
        item?.item_id
      );
      const batchValueList = list.data.map((batch) => {
        const cost_price = batch?.batch_cost_price
          ? parseFloat(batch?.batch_cost_price)
          : 0;
        const quantity = batch?.batch_quantity_on_hand
          ? parseInt(batch?.batch_quantity_on_hand)
          : 0;
        return {
          batch_id: batch?.batch_id,
          inventory_value: cost_price * quantity,
        };
      });

      const total = batchValueList.reduce((accumulator, batch) => {
        return accumulator + batch.inventory_value;
      }, 0);

      setValueForm((valueForm) => ({ ...valueForm, current_value: total }));
      setIsLoading(false);
    };
    batchData();
  }, [branch, item, token]);

  return (
    <>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <div style={{ width: "85%" }}>
          <h6 style={{ fontWeight: 600 }}>Change Inventory Value</h6>
          <div className="form-group form-row">
            <label htmlFor="currentValue" className="col-md-6">
              Current Value
            </label>
            <div className="col-sm-6">
              <input
                type="number"
                className="form-control "
                value={valueForm.current_value}
                disabled
              />
            </div>
          </div>
          <div className="form-group form-row">
            <label htmlFor="currentValue" className="col-md-6">
              Changed Value
            </label>
            <div className="col-sm-6">
              <input
                type="number"
                className="form-control"
                value={valueForm.change_value}
                onChange={handleValueChange}
              />
            </div>
          </div>
          <div className="form-group form-row">
            <label htmlFor="currentValue" className="col-md-6">
              Adjusted Value
            </label>
            <div className="col-sm-6">
              <input
                type="number"
                className="form-control disabled"
                value={valueForm.adjusted_value}
                disabled
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default memo(AdjustmentValue);
