import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  title: "",
  titleIcon: "",
  content: "",
  isOptionModal: false,
  callback: () => {},
};

const OptionModalSlide = createSlice({
  name: "option_modal",
  initialState,
  reducers: {
    showOptionModel: (state, action) => {
      state.isOptionModal = true;
      state.title = action.payload.title;
      state.titleIcon = action.payload.titleIcon;
      state.content = action.payload.content;
    },
    hideOptionModel: (state, action) => {
      state.isOptionModal = false;
    },
  },
});

export const { showOptionModel, hideOptionModel } = OptionModalSlide.actions;
export default OptionModalSlide.reducer;
