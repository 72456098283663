import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import AsyncSelect from "react-select/async";
import { debounce } from "../../../Helper";
import axios from "../../../api/axios";

/*select options */
let select_style = {
  control: (base) => ({
    ...base,
    height: 31,
    minHeight: 31,
    borderRadius: 3,
    border: "1px solid #d0d6db",
    boxShadow: "none",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: "31px",
    padding: "0 6px",
  }),
  input: (provided, state) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "31px",
  }),
  menuList: (provided) => ({
    ...provided,
  }),
  menu: (provided) => ({
    ...provided,
    width: "max-content",
    minWidth: "100%",
  }),
};

const getBatchList = async (
  token,
  branch,
  start = 0,
  length = 10,
  order = "desc",
  search = "",
  itemId = ""
) => {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append("com_id", branch.com_id);
    searchParams.append("item_id", itemId);
    searchParams.append("start", start);
    searchParams.append("length", length);
    searchParams.append("order", order);
    searchParams.append("search", search);
    searchParams.toString();
    const response = await axios.get(
      "inventory/batch-list?" + searchParams.toString(),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    response.data.data = response.data.data.map((batch) => {
      return {
        ...batch,
        label: "Batch - " + batch.batch_id,
        value: batch.batch_id,
      };
    });
    return response.data;
  } catch (error) {
    return [];
  }
};

const SelectBatch = (props) => {
  const { item_id, isNewBatch, callback } = props;
  //global
  const { token, branch } = useSelector((store) => store.auth);
  //local stats
  const [options, setOptions] = useState([]);
  const [batch, setBatch] = useState();
  const [isLoading, setIsLoading] = useState(false);
  //events
  const loadListOptions = debounce(async (inputValue, callback) => {
    const options = await getBatchList(
      token,
      branch,
      0,
      10,
      "desc",
      inputValue,
      item_id
    );
    callback(options.data);
  }, 500);

  const handleOnChange = (option) => {
    setBatch(option);
    callback(option);
  };

  //effect
  useEffect(() => {
    let new_batch = {
      barcode: "",
      batch_cost_price: "",
      batch_expiry_date: "",
      batch_id: "",
      batch_location: "",
      batch_made_date: "",
      batch_quantity_on_hand: 0,
      batch_sale_price: "",
      com_id: branch.com_id,
      inventory_item_id: item_id,
      lot_number: "",
      warehouse_id: "",
      warehouse: 0,
      label: "New Batch",
      value: "",
    };
    const unitData = async () => {
      setIsLoading(true);
      const list = await getBatchList(
        token,
        branch,
        0,
        10,
        "desc",
        "",
        item_id
      );
      setOptions(list.data);
      setOptions((prevBatch) => [...prevBatch, new_batch]);
      setIsLoading(false);
      // set set default to new batch if true
      if (isNewBatch) {
        select_style.valueContainer = (provided, state) => ({
          ...provided,
          height: "31px",
          padding: "0 6px",
          backgroundColor: "#bae0bd",
        });
        setBatch(new_batch);
        callback(new_batch);
      } else {
        select_style.valueContainer = (provided, state) => ({
          ...provided,
          height: "31px",
          padding: "0 6px",
        });
        setBatch(list.data[0]);
        callback(list.data[0]);
      }
    };
    if (item_id) {
      unitData();
    }
  }, [token, branch, item_id, callback, isNewBatch]);

  //format
  const formatItemSelectOption = (option, { context }) => {
    if (context === "menu") {
      const label = (
        <div className="drop-item" style={{ display: "flex" }}>
          <div className="drop-item-col" style={{ width: "150px" }}>
            {option.label}
          </div>
          <div className="drop-item-col" style={{ width: "80px" }}>
            {option.batch_sale_price}
          </div>
          <div className="drop-item-col" style={{ width: "80px" }}>
            Qty - {option.batch_quantity_on_hand}
          </div>
        </div>
      );
      return label;
    }
    return option.label;
  };

  return (
    <AsyncSelect
      cacheOptions
      defaultOptions={options}
      loadOptions={loadListOptions}
      styles={select_style}
      onChange={handleOnChange}
      value={batch}
      placeholder=""
      isLoading={isLoading}
      formatOptionLabel={formatItemSelectOption}
      name="select_batch"
    />
  );
};
export default React.memo(SelectBatch);
