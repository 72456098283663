import "./OptionModal.scss";
import { useSelector, useDispatch } from "react-redux";
import { Modal, ModalHeader, ModalTitle, ModalBody } from "react-bootstrap";
import { hideOptionModel } from "../../redux/features/modals/OptionModalSlide";
import { X } from "react-bootstrap-icons";

const OptionModal = () => {
  const { isOptionModal, title, titleIcon, content } = useSelector(
    (store) => store.option_modal
  );
  const dispatch = useDispatch();

  const handleClose = () => dispatch(hideOptionModel());

  return (
    <Modal
      show={isOptionModal}
      onHide={handleClose}
      backdrop={false}
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      animation={false}
      ria-labelledby="staticBackdropLabel"
      dialogClassName=""
      contentClassName="shadow"
      className="option_model"
    >
      <ModalHeader>
        <ModalTitle>
          {titleIcon} {title}
        </ModalTitle>
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={handleClose}
        >
          <span aria-hidden="true">
            <X />
          </span>
        </button>
      </ModalHeader>
      <ModalBody>{content}</ModalBody>
    </Modal>
  );
};

export default OptionModal;
