import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../api/axios";

const initialState = {
  grnList: [],
  totalRows: 0,
  isLoading: true,
};

// this will get user role
export const getGRNList = createAsyncThunk(
  "purchases/GRNList",
  async ({ start = 0, length = 10, order = "desc", search = "" }, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const token = auth.token;
      const branch = auth.branch;

      const searchParams = new URLSearchParams();
      searchParams.append("branch_id", branch.branch_id);
      searchParams.append("start", start);
      searchParams.append("length", length);
      searchParams.append("order", order);
      searchParams.append("search", search.trim());
      searchParams.toString();
      const response = await axios.get(
        "purchase/grn-list?" + searchParams.toString(),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const GRNListSlice = createSlice({
  name: "goods_received_note_list",
  initialState,
  reducers: {
    updateGRNList: (state, action) => {
      state.grnList = action.payload;
    },
  },
  extraReducers: {
    [getGRNList.pending]: (state) => {
      state.isLoading = true;
    },
    [getGRNList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.grnList = action.payload.data;
      state.totalRows = action.payload.recordsTotal;
    },
    [getGRNList.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
});

export const { updateGRNList } = GRNListSlice.actions;
export default GRNListSlice.reducer;
