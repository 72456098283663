import { memo } from "react";
import Select from "react-select";

const select_style = {
  control: (base) => ({
    ...base,
    height: 31,
    minHeight: 31,
    borderRadius: 3,
    border: "1px solid #d0d6db",
    boxShadow: "none",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: "31px",
    padding: "0 6px",
  }),
  input: (provided, state) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "31px",
  }),
};
const countryOptions = [
  { value: "Sri Lanka", label: "Sri Lanka" },
  { value: "India", label: "India" },
  { value: "Australia", label: "Australia" },
  { value: "Bangladesh", label: "Bangladesh" },
  { value: "China", label: "China" },
  { value: "Denmark", label: "Denmark" },
  { value: "France", label: "France" },
  { value: "Japan", label: "Japan" },
  { value: "United Kingdom", label: "United Kingdom" },
  { value: "United States of America", label: "United States of America" },
  { value: "United Arab Emirates", label: "United Arab Emirates" },
];

const SelectCountry = (props) => {
  const { selectName, onChange, value } = props;
  return (
    <Select
      options={countryOptions}
      styles={select_style}
      name={selectName}
      inputId={selectName}
      onChange={(country) => {
        onChange(selectName, country.value);
      }}
      value={value ? { label: value, value: value } : null}
    />
  );
};
export default memo(SelectCountry);
