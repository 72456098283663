import "./style.scss";
import { useState, useEffect, useCallback } from "react";
import ListTable from "./ListTable";
import RightSidePanel from "./RightSidePanel";

const PrimaryList = (props) => {
  const {
    listName,
    getListCallback,
    selectListCallback,
    columns,
    listData,
    listTotal,
    isLoading,
    sideContent,
  } = props;

  //locale
  const [isPanelShow, setIsPanelShow] = useState(false);
  const [selected, setSelected] = useState({});

  //events
  const handleOnSelect = useCallback(
    (row) => {
      selectListCallback(row);
      if (row?.isSelected) {
        setIsPanelShow(false);
      } else {
        setIsPanelShow(true);
      }
      setSelected(row);
    },
    [selectListCallback]
  );

  const hideRightPanel = useCallback(() => {
    setIsPanelShow(false);
  }, []);

  // effect
  useEffect(() => {
    getListCallback({ search: "" });
    setIsPanelShow(false);
  }, [getListCallback]);

  return (
    <div
      className={
        isPanelShow
          ? "container primary-list show-right-side-panel"
          : "container primary-list"
      }
    >
      <div className="action-table-wrapper">
        <div className="action-table-title">
          <h3>{listName} List</h3>
        </div>
        <ListTable
          listName={listName}
          columns={columns}
          listData={listData}
          listTotal={listTotal}
          isLoading={isLoading}
          getListCallback={getListCallback}
          onSelectCallback={handleOnSelect}
        />
      </div>
      <RightSidePanel
        hideSidePanel={hideRightPanel}
        content={sideContent(selected, hideRightPanel)}
      />
    </div>
  );
};

export default PrimaryList;
