import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import AsyncCreatableSelect from "react-select/async-creatable";
import { PlusSquareDotted, PersonPlusFill } from "react-bootstrap-icons";

import { debounce } from "../../../../Helper";
import CustomerNew from "../../../../containers/sales/customer/CustomerNew";

import { showPrimaryModel } from "../../../../redux/features/modals/PrimaryModalSlice";
import { getCustomers } from "../../../../redux/features/customers/customerSlice";

/*select options */
const select_style = {
  control: (base) => ({
    ...base,
    height: 31,
    minHeight: 31,
    borderRadius: 3,
    border: "1px solid #d0d6db",
    boxShadow: "none",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: "31px",
    padding: "0 6px",
  }),
  input: (provided, state) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "31px",
  }),
  menuList: (provided) => ({
    ...provided,
  }),
  menu: (provided) => ({
    ...provided,
    width: "max-content",
    minWidth: "100%",
  }),
};

const formatItemSelectOption = (option, { context }) => {
  if (option?.__isNew__) {
    return (
      <>
        <PlusSquareDotted /> {option.label}
      </>
    );
  }
  return option.label;
};

const SelectCustomer = (props) => {
  const { name, value, callback } = props;
  const { customers, isLoading } = useSelector((store) => store.customer);
  const dispatch = useDispatch();

  //local stats
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [customer, setCustomer] = useState();

  //events
  /* this will search items */
  const loadListOptions = debounce((inputValue, callback) => {
    dispatch(
      getCustomers({
        start: 0,
        length: 10,
        order: "desc",
        search: inputValue,
      })
    );
    const options = customers.map((customer) => {
      return {
        ...customer,
        label: customer.cust_display_name,
        value: customer.cust_id,
      };
    });
    callback(options);
  }, 500);

  /* this will create new customer */
  const onCreateCallback = useCallback(
    (customer) => {
      const option = {
        ...customer,
        label: customer.cust_display_name,
        value: customer.cust_id,
      };
      setCustomer(option);
      setDefaultOptions([...defaultOptions, option]);

      if (callback) {
        callback(name, option);
      }
    },
    [callback, defaultOptions, name]
  );
  const handleOnCreate = (inputValue) => {
    const modelData = {
      title: "Add New Customer",
      titleIcon: <PersonPlusFill />,
      content: (
        <CustomerNew newInput={inputValue} callback={onCreateCallback} />
      ),
    };
    dispatch(showPrimaryModel(modelData));
  };

  const handleOnChange = (option) => {
    setCustomer(option);
    if (callback) {
      callback(name, option);
    }
  };

  //effect
  /* this will load default customer */
  useEffect(() => {
    const optionData = async () => {
      const list = await dispatch(
        getCustomers({
          start: 0,
          length: 10,
          order: "desc",
          search: "",
        })
      ).unwrap();
      const options = list.data.map((customer) => {
        return {
          ...customer,
          label: customer.cust_display_name,
          value: customer.cust_id,
        };
      });
      setDefaultOptions(options);
    };
    optionData();
  }, [dispatch]);

  /* update with value*/
  useEffect(() => {
    setCustomer(value);
  }, [value]);

  return (
    <AsyncCreatableSelect
      cacheOptions
      defaultOptions={defaultOptions}
      loadOptions={loadListOptions}
      styles={select_style}
      onChange={handleOnChange}
      onCreateOption={handleOnCreate}
      value={customer}
      formatOptionLabel={formatItemSelectOption}
      formatCreateLabel={(userInput) => `Add "${userInput}" as new customer`}
      placeholder="Search or add..."
      isLoading={isLoading}
      name={name}
      inputId={name}
    />
  );
};
export default React.memo(SelectCustomer);
