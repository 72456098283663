import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../api/axios";

const initialState = {
  supplierList: [],
  totalRows: 0,
  isLoading: true,
};

// this will get user role
export const getSupplierList = createAsyncThunk(
  "supplier/supplierList",
  async ({ start = 0, length = 20, order = "desc", search = "" }, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const token = auth.token;
      const branch = auth.branch;

      const searchParams = new URLSearchParams();
      searchParams.append("branch_id", branch.branch_id);
      searchParams.append("start", start);
      searchParams.append("length", length);
      searchParams.append("order", order);
      searchParams.append("search", search.trim());
      searchParams.toString();
      const response = await axios.get(
        "purchase/supplier-list?" + searchParams.toString(),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const SupplierListSlice = createSlice({
  name: "supplier_list",
  initialState,
  reducers: {
    updateSupplierList: (state, action) => {
      state.supplierList = action.payload;
    },
  },
  extraReducers: {
    [getSupplierList.pending]: (state) => {
      state.isLoading = true;
    },
    [getSupplierList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.supplierList = action.payload.data;
    },
    [getSupplierList.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
});

export const { updateSupplierList } = SupplierListSlice.actions;
export default SupplierListSlice.reducer;
