import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import axios from "../../../api/axios";
import { autoNotify } from "../../../redux/features/notify/NotifySlice";
import { hideOptionModel } from "../../../redux/features/modals/OptionModalSlide";

const typeOptions = [
  { value: "Inventory", label: "Inventory" },
  { value: "None Inventory", label: "None Inventory" },
  { value: "Services", label: "Services" },
];

const select_style = {
  control: (base) => ({
    ...base,
    height: 31,
    minHeight: 31,
    borderRadius: 3,
    border: "1px solid #d0d6db",
    boxShadow: "none",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: "31px",
    padding: "0 6px",
  }),
  input: (provided, state) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "31px",
  }),
};

const Category = (props) => {
  const dispatch = useDispatch();
  const { edit_category, edit_callback } = props;
  const { token, branch, user } = useSelector((store) => store.auth);

  //local stats
  const [category, setCategory] = useState({
    com_id: branch.com_id,
    cat_id: null,
    cat_name: "",
    cat_description: "",
    cat_type: "",
    created_by: user.user_id,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  //events
  const inputsOnChange = (e) => {
    const { name, value } = e.target;
    setCategory((prevCategory) => {
      return { ...prevCategory, [name]: value };
    });
  };

  const selectOnChange = (name, option) => {
    setCategory((prevCategory) => {
      return { ...prevCategory, [name]: option.value };
    });
  };

  //action
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const resp = await axios.post("inventory/category", category, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      autoNotify(dispatch, resp);
      setIsSubmitting(false);
      if (edit_callback) {
        edit_callback(resp.data.category);
      }
      dispatch(hideOptionModel());
    } catch (error) {
      autoNotify(dispatch, error.response);
      setIsSubmitting(false);
    }
  };

  const handleFormClose = () => {
    dispatch(hideOptionModel());
  };

  //effect
  useEffect(() => {
    if (edit_category) {
      setCategory((prevCategory) => {
        return {
          ...prevCategory,
          cat_id: edit_category.cat_id,
          cat_name: edit_category.cat_name,
          cat_description: edit_category.cat_description,
          cat_type: edit_category.cat_type,
        };
      });
    }
  }, [edit_category]);

  return (
    <form>
      <div className="form-row">
        <div className="form-group col-md-6 mb-1">
          <label htmlFor="cat_name">Category Name</label>
          <input
            type="text"
            className="form-control"
            id="cat_name"
            name="cat_name"
            value={category.cat_name}
            onChange={inputsOnChange}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-9 mb-1">
          <label htmlFor="cat_description">Category Description</label>
          <input
            type="text"
            className="form-control"
            id="cat_description"
            name="cat_description"
            value={category.cat_description}
            onChange={inputsOnChange}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-6 mb-1">
          <label htmlFor="type">Category for</label>
          <Select
            options={typeOptions}
            styles={select_style}
            name="cat_type"
            id="cat_type"
            value={{ label: category.cat_type, value: category.cat_type }}
            onChange={(e) => selectOnChange("cat_type", e)}
          />
        </div>
      </div>
      <div className="mt-5">
        <button
          id="supp-create-btn"
          className="btn btn-primary float-right save-button ml-2"
          type="submit"
          disabled={isSubmitting}
          onClick={handleFormSubmit}
        >
          Save
        </button>
        <button
          id="supp-close-btn"
          type="button"
          className="btn btn-secondary float-right "
          data-dismiss="modal"
          onClick={handleFormClose}
        >
          Close
        </button>
      </div>
    </form>
  );
};

export default Category;
