import "./ProductList.scss";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import DataTable from "react-data-table-component";
import SearchBar from "../../../components/searchbar/SearchBar";
import { debounce } from "../../../Helper";
import TextLoader from "../../../components/loaders/Loader";
import {
  hideRightPanel,
  showRightPanel,
} from "../../../redux/features/rightpanel/RightPanelSlice";
import RightSidePanel from "../../../components/panels/right/RightSidePanel";
import {
  getProductList,
  updateProductList,
} from "../../../redux/features/inventory/ProductListSlice";

import ProductListRight from "./ProductListRight";

/* set supplier list */
const columns = [
  {
    name: "Image",
    selector: (row) => row.item_image,
    sortable: true,
    cell: (row) => {
      return (
        <div className="tbl-image-col" data-tag="allowRowEvents">
          <img
            src={process.env.REACT_APP_API_URL + row.item_image}
            alt="user"
            data-tag="allowRowEvents"
          />
        </div>
      );
    },
    width: "8%",
  },
  {
    name: "SKU",
    selector: (row) => row.item_sku,
    sortable: true,
    cell: (row) => {
      return (
        <div className="tbl-po-no-col" data-tag="allowRowEvents">
          <h6 data-tag="allowRowEvents">{row.item_sku}</h6>
        </div>
      );
    },
    width: "15%",
  },
  {
    name: "Name",
    selector: (row) => row.item_name,
    sortable: true,
  },
  {
    name: "Type",
    selector: (row) => row.item_type,
    sortable: true,
  },
  {
    name: "QTY",
    selector: (row) => row.item_quantity_on_hand,
    sortable: true,
    width: "10%",
  },
  {
    name: "Unit",
    selector: (row) => row.unit.unit,
    sortable: true,
    width: "10%",
  },
];
const conditionalRowStyles = [
  {
    when: (row) => row.isSelected === true,
    style: {
      backgroundColor: "#e3f2fd",
      userSelect: "none",
    },
  },
];

const ProductList = () => {
  const dispatch = useDispatch();
  const { productList, totalRows, isLoading } = useSelector(
    (store) => store.inventory_product_list
  );
  const { isPanelShow } = useSelector((store) => store.right_panel);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  /*Events */
  const handleOnSearch = debounce((e) => {
    setSearch(e.target.value);
    dispatch(getProductList({ search: e.target.value }));
  }, 600);

  const handlePageChange = (page) => {
    const start = (page - 1) * perPage;
    dispatch(getProductList({ start: start, length: perPage, search: search }));
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    const start = (page - 1) * newPerPage;
    dispatch(
      getProductList({ start: start, length: newPerPage, search: search })
    );
    setPerPage(newPerPage);
    setCurrentPage(page);
  };

  const handleOnSelect = (row) => {
    const updatedList = productList.map((product) => {
      //unselect all
      if (row.item_id !== product.item_id) {
        return { ...product, isSelected: false };
      }
      //remove already selected one
      if (product?.isSelected === true) {
        dispatch(hideRightPanel());
        return { ...product, isSelected: false };
      }
      //selected current
      dispatch(
        showRightPanel({ content: <ProductListRight product={product} /> })
      );
      return { ...product, isSelected: true };
    });
    dispatch(updateProductList(updatedList));
  };

  // on mount
  useEffect(() => {
    dispatch(hideRightPanel());
    dispatch(getProductList({ search: "" }));
  }, [dispatch]);

  return (
    <div
      className={
        isPanelShow
          ? "container product-list show-right-side-panel"
          : "container product-list"
      }
    >
      <div className="action-table-wrapper">
        <div className="action-table-title">
          <h3>Product List</h3>
        </div>
        <div className="row">
          <div className="col-md-4 ">
            <SearchBar
              searchHandler={handleOnSearch}
              placeholder="Search products here..."
            />
          </div>
        </div>

        <div className="action-table">
          <DataTable
            dense
            columns={columns}
            data={productList}
            progressPending={isLoading}
            progressComponent={<TextLoader />}
            highlightOnHover
            onRowClicked={handleOnSelect}
            pagination
            paginationServer
            paginationPerPage={perPage}
            paginationRowsPerPageOptions={[10, 20, 50, 100, 200]}
            paginationTotalRows={totalRows}
            paginationDefaultPage={currentPage}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            conditionalRowStyles={conditionalRowStyles}
            className="list-table"
          />
        </div>
      </div>
      <RightSidePanel />
    </div>
  );
};
export default ProductList;
