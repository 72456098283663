import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import AsyncCreatableSelect from "react-select/async-creatable";
import { PlusSquareDotted, PersonAdd } from "react-bootstrap-icons";
import { debounce } from "../../../../Helper";

import SalesRepNew from "../../../../containers/sales/sales_rep/SalesRepNew";
import { getSalesReps } from "../../../../redux/features/sales/salesRepSlice";
import { showOptionModel } from "../../../../redux/features/modals/OptionModalSlide";

/*select options */
const select_style = {
  control: (base) => ({
    ...base,
    height: 31,
    minHeight: 31,
    borderRadius: 3,
    border: "1px solid #d0d6db",
    boxShadow: "none",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: "31px",
    padding: "0 6px",
  }),
  input: (provided, state) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "31px",
  }),
  menuList: (provided) => ({
    ...provided,
  }),
  menu: (provided) => ({
    ...provided,
    width: "max-content",
    minWidth: "100%",
  }),
};

const formatItemSelectOption = (option, { context }) => {
  if (option?.__isNew__) {
    return (
      <>
        <PlusSquareDotted /> {option.label}
      </>
    );
  }
  return option.label;
};

const SelectSalesRep = (props) => {
  const { name, value, callback } = props;
  const { salesReps, isLoading } = useSelector((store) => store.salesRep);
  const dispatch = useDispatch();
  //local stats
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [salesRep, setSalesRep] = useState();

  //events
  const loadListOptions = debounce(async (inputValue, callback) => {
    dispatch(
      getSalesReps({
        start: 0,
        length: 10,
        order: "desc",
        search: inputValue,
      })
    );
    const options = salesReps.map((rep) => {
      return {
        ...rep,
        label: rep.rep_name,
        value: rep.rep_id,
      };
    });
    callback(options);
  }, 500);

  /* this will create new sales rep */
  const onCreateCallback = useCallback(
    (salesRep) => {
      const option = {
        ...salesRep,
        label: salesRep.rep_name,
        value: salesRep.rep_id,
      };
      setSalesRep(option);
      setDefaultOptions([...defaultOptions, option]);

      if (callback) {
        callback(name, option);
      }
    },
    [callback, defaultOptions, name]
  );

  const handleOnCreate = (inputValue) => {
    const modelData = {
      title: "Add Sales Representative",
      titleIcon: <PersonAdd />,
      content: (
        <SalesRepNew newInput={inputValue} callback={onCreateCallback} />
      ),
    };
    dispatch(showOptionModel(modelData));
  };

  const handleOnChange = (option) => {
    setSalesRep(option);
    if (callback) {
      callback(name, option);
    }
  };

  //effect
  /* this will load default salesReps */
  useEffect(() => {
    const optionData = async () => {
      const list = await dispatch(
        getSalesReps({
          start: 0,
          length: 10,
          order: "desc",
          search: "",
        })
      ).unwrap();
      const options = list.data.map((rep) => {
        return {
          ...rep,
          label: rep.rep_name,
          value: rep.rep_id,
        };
      });
      setDefaultOptions(options);
    };
    optionData();
  }, [dispatch]);

  /* update with value*/
  useEffect(() => {
    setSalesRep(value);
  }, [value]);

  return (
    <AsyncCreatableSelect
      cacheOptions
      defaultOptions={defaultOptions}
      loadOptions={loadListOptions}
      styles={select_style}
      onChange={handleOnChange}
      onCreateOption={handleOnCreate}
      value={salesRep}
      formatOptionLabel={formatItemSelectOption}
      formatCreateLabel={(userInput) => `Add "${userInput}"`}
      placeholder="Search or add ..."
      isLoading={isLoading}
      name={name}
      inputId={name}
    />
  );
};
export default React.memo(SelectSalesRep);
