import "./style.scss";
const Loader = () => {
  return (
    <div className="text-loader">
      <img src="/img/loader.gif" alt="loader" />
    </div>
  );
};

export default Loader;
