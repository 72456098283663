import "./App.scss";
import "animate.css";
import { BrowserRouter } from "react-router-dom";
import Router from "./router";
import { useSelector } from "react-redux";
import Notify from "./components/notification/notify/Notify";
import PrimaryModal from "./components/modals/PrimaryModal";
import OptionModal from "./components/modals/OptionModal";
import PrintPreview from "./components/print-preview/PrintPreview";

function App() {
  const { isNotify } = useSelector((store) => store.notify);
  const { isPrimaryModal } = useSelector((store) => store.primary_modal);
  const { isOptionModal } = useSelector((store) => store.option_modal);
  const { isPrintPreview } = useSelector((store) => store.print_preview);

  return (
    <>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
      {isNotify && <Notify />}
      {isPrimaryModal && <PrimaryModal />}
      {isOptionModal && <OptionModal />}
      {isPrintPreview && <PrintPreview />}
    </>
  );
}

export default App;
