import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import AsyncSelect from "react-select/async";
import { debounce } from "../../../Helper";
import axios from "../../../api/axios";

/*select options */
const select_style = {
  control: (base) => ({
    ...base,
    height: 31,
    minHeight: 31,
    borderRadius: 3,
    border: "1px solid #d0d6db",
    boxShadow: "none",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: "31px",
    padding: "0 6px",
  }),
  input: (provided, state) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "31px",
  }),
  menuList: (provided) => ({
    ...provided,
  }),
  menu: (provided) => ({
    ...provided,
    width: "max-content",
    minWidth: "100%",
  }),
};
const getPayMethods = async (token) => {
  try {
    const response = await axios.get("customers/payment-method", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    response.data = response.data.map((method) => {
      return {
        ...method,
        label: method.method_name,
        value: method.method_id,
      };
    });
    return response.data;
  } catch (error) {
    return [];
  }
};

const SelectPayMethod = (props) => {
  const { method_name, selected, callback } = props;
  const { token } = useSelector((store) => store.auth);
  //local stats
  const [options, setOptions] = useState();
  const [method, setMethod] = useState();
  const [isLoading, setIsLoading] = useState(false);

  //events
  const loadListOptions = debounce(async (inputValue, callback) => {
    const options = await getPayMethods(token);
    callback(options.data);
  }, 500);

  const handleOnChange = (option) => {
    setMethod(option);
    if (callback) {
      callback(option);
    }
  };

  //effect
  useEffect(() => {
    const getOptions = async () => {
      setIsLoading(true);
      const list = await getPayMethods(token);
      setOptions(list);
      setIsLoading(false);
    };
    getOptions();
  }, [token]);

  useEffect(() => {
    if (selected) {
      setMethod(selected);
    } else {
      //set default
      if (options && method_name) {
        const option = options.find((method) => {
          return method.label === method_name;
        });
        setMethod(option);
        if (callback) {
          callback(option);
        }
      }
    }
  }, [callback, method_name, options, selected]);

  return (
    <AsyncSelect
      cacheOptions
      defaultOptions={options}
      loadOptions={loadListOptions}
      styles={select_style}
      onChange={handleOnChange}
      value={method}
      placeholder=""
      isLoading={isLoading}
      name="pay_method_id"
    />
  );
};
export default React.memo(SelectPayMethod);
