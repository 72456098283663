import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import SalesRepService from "../../services/sales/SalesRepService";

const initialState = {
  salesReps: [],
  recordsFiltered: 0,
  recordsTotal: 0,
  isLoading: false,
  isSubmitting: false,
  error: null,
  success: null,
};

export const getSalesReps = createAsyncThunk(
  "customer/getSalesReps",
  async ({ start = 0, length = 10, order = "desc", search = "" }, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const params = {
        branch_id: auth.branch.branch_id,
        start: start,
        length: length,
        order: order,
        search: search,
      };
      const response = await SalesRepService.getSalesReps(auth.token, params);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const createSalesRep = createAsyncThunk(
  "customer/createSalesRep",
  async (salesRep, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const { token, branch, user } = auth;
      const data = {
        ...salesRep,
        branch_id: branch.branch_id,
        created_by: user.user_id,
      };
      return await SalesRepService.createSalesRep(token, data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const editSalesRep = createAsyncThunk(
  "customer/editSalesRep",
  async (salesRep, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const { token, branch, user } = auth;
      const data = {
        ...salesRep,
        branch_id: branch.branch_id,
        created_by: user.user_id,
      };
      return await SalesRepService.updateSalesRep(token, data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteSalesRep = createAsyncThunk(
  "customer/deleteSalesRep",
  async (id, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const { token, user } = auth;
      return await SalesRepService.deleteSalesRep(token, id, user.user_id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const salesRepSlice = createSlice({
  name: "salesRep",
  initialState,
  reducers: {
    updateSalesReps(state, action) {
      state.salesReps = action.payload;
    },
    resetSalesRep(state, action) {
      state.isSubmitting = false;
      state.success = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      //get
      .addCase(getSalesReps.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getSalesReps.fulfilled, (state, action) => {
        state.isLoading = false;
        state.salesReps = action.payload.data;
        state.recordsFiltered = action.payload.recordsFiltered;
        state.recordsTotal = action.payload.recordsTotal;
      })
      .addCase(getSalesReps.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      //create
      .addCase(createSalesRep.pending, (state) => {
        state.isSubmitting = true;
        state.error = null;
        state.success = null;
      })
      .addCase(createSalesRep.fulfilled, (state, action) => {
        state.isSubmitting = false;
        state.error = null;
        const salesRep = action.payload.data.salesRep;
        state.salesReps.unshift(salesRep);
        state.success = action.payload;
      })
      .addCase(createSalesRep.rejected, (state, action) => {
        state.isSubmitting = false;
        state.success = null;
        state.error = action.payload;
      })

      //update
      .addCase(editSalesRep.pending, (state) => {
        state.isSubmitting = true;
        state.error = null;
        state.success = null;
      })
      .addCase(editSalesRep.fulfilled, (state, action) => {
        state.isSubmitting = false;
        state.error = null;
        const salesRep = action.payload.data.salesRep;
        state.salesReps = state.salesReps.map((item) => {
          if (item.rep_id === salesRep.rep_id) {
            return salesRep;
          }
          return item;
        });
        state.success = action.payload;
      })
      .addCase(editSalesRep.rejected, (state, action) => {
        state.isSubmitting = false;
        state.success = null;
        state.error = action.payload;
      })

      //delete
      .addCase(deleteSalesRep.pending, (state) => {
        state.isSubmitting = true;
        state.error = null;
        state.success = null;
      })
      .addCase(deleteSalesRep.fulfilled, (state, action) => {
        state.isSubmitting = false;
        state.error = null;
        const rep_id = action.payload.data.rep_id;
        state.salesReps = state.salesReps.filter(
          (salesRep) => salesRep.rep_id !== rep_id
        );
        state.success = action.payload;
      })
      .addCase(deleteSalesRep.rejected, (state, action) => {
        state.isSubmitting = false;
        state.success = null;
        state.error = action.payload;
      });
  },
});

export const { updateSalesReps, resetSalesRep } = salesRepSlice.actions;
export default salesRepSlice.reducer;
