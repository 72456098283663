import { Navigate, Routes, Route, Outlet } from "react-router-dom";
import { hasPrivilege } from "../../Helper";

import Error404 from "../errors/dashboard/Error404";
import POList from "./po/POList/POList";
import SupplierList from "./supplier/SupplierList";
import PONew from "./po/PONew";
import POEdit from "./po/POEdit";
import GRNList from "./grn/GRNList/GRNList";
import GRNNew from "./grn/GRNNew";
import GRNEdit from "./grn/GRNEdit";

const Purchases = () => {
  if (false) {
    return <Navigate to="/dashboard" />;
  }
  return (
    <>
      <Outlet />
      <Routes>
        {hasPrivilege("Purchases", 300) && <Route index element={<POList />} />}
        {/* New supplier handle by main menu */}
        {hasPrivilege("Suppliers", 600) && (
          <Route path="suppliers" element={<SupplierList />} />
        )}
        {hasPrivilege("Purchases", 301) && (
          <Route path="po-new" element={<PONew />} />
        )}
        {hasPrivilege("Purchases", 302) && (
          <Route path="po-edit/:id" element={<POEdit />} />
        )}
        {hasPrivilege("Purchases", 306) && (
          <Route path="grn-list" element={<GRNList />} />
        )}
        {hasPrivilege("Purchases", 307) && (
          <Route path="grn-new" element={<GRNNew />} />
        )}
        {hasPrivilege("Purchases", 308) && (
          <Route path="grn-edit/:id" element={<GRNEdit />} />
        )}
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
};

export default Purchases;
