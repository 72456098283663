import { useState } from "react";
import { XCircleFill } from "react-bootstrap-icons";

import SelectMaterialItem from "./SelectMaterialItem";
import SelectMaterialUnit from "./SelectMaterialUnit";

const MaterialRow = (props) => {
  const { row, deleteRow } = props;
  const [item, setItem] = useState();
  const [qty, setQty] = useState(row.qty);

  //select item
  const selectItemHandler = (item) => {
    setItem(item);
  };
  //close handler
  const rowDeleteHandler = () => {
    deleteRow(row);
  };

  const qtyChangeHandler = (e) => {
    const { value } = e.target;
    setQty(value);
  };

  return (
    <tr>
      <td></td>
      <td>
        <SelectMaterialItem selected={row.item} callback={selectItemHandler} />
      </td>
      <td>
        <input
          type="number"
          className="form-control"
          id="material_qty"
          name="material_qty[]"
          min="0"
          step="1"
          value={qty}
          onChange={qtyChangeHandler}
        />
      </td>
      <td>
        <SelectMaterialUnit unitId={item?.unit_id} selected={row.unit} />
      </td>
      <td>
        <div
          className="side-button"
          onClick={rowDeleteHandler}
          title="Delete Row"
        >
          <XCircleFill />
        </div>
      </td>
    </tr>
  );
};

export default MaterialRow;
