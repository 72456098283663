import { useState, useRef } from "react";
import { Upload } from "react-bootstrap-icons";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import axios from "../../api/axios";
import { useSelector, useDispatch } from "react-redux";
import { hidePrimaryModel } from "../../redux/features/modals/PrimaryModalSlice";
import { getUserRoleList } from "../../redux/features/users/UserRoleListSlice";
import { autoNotify } from "../../redux/features/notify/NotifySlice";
import { debounce } from "../../Helper";

const UserEdit = (props) => {
  const { edit_user, edit_callback } = props;
  const dispatch = useDispatch();
  const newUserForm = useRef(null);
  const [imageURI, setImageURI] = useState(
    process.env.REACT_APP_API_URL + edit_user.user_image
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { token, user } = useSelector((store) => store.auth);

  const [formData, setFormData] = useState({
    user_id: edit_user.user_id,
    user_role_id: edit_user.role_id,
    user_role_name: edit_user.role_name,
    user_title: edit_user.user_title,
    user_first_name: edit_user.user_first_name,
    user_last_name: edit_user.user_last_name,
    user_address_street: edit_user.user_address_street,
    user_address_city: edit_user.user_address_city,
    user_address_country: edit_user.user_address_country,
    user_address_postcode: edit_user.user_address_postcode,
    username: edit_user.username,
    user_email: edit_user.user_email,
    user_phone: edit_user.user_phone,
    user_mobile: edit_user.user_mobile,
  });

  //this will handle form change
  const handleFormChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevFormData) => {
      switch (type) {
        case "checkbox":
          return {
            ...prevFormData,
            [name]: checked,
          };
        default:
          return {
            ...prevFormData,
            [name]: value,
          };
      }
    });
  };

  // this will handle image change
  const readImageURI = (e) => {
    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader();
      reader.onload = function (ev) {
        setImageURI(ev.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  /*select options */
  const select_style = {
    control: (base) => ({
      ...base,
      height: 31,
      minHeight: 31,
      borderRadius: 3,
      border: "1px solid #d0d6db",
      boxShadow: "none",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "31px",
      padding: "0 6px",
    }),
    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "31px",
    }),
  };
  // this will load user Roles
  const loadUserRolesOptions = debounce(async (inputValue, callback) => {
    const response = await dispatch(
      getUserRoleList({ search: inputValue })
    ).unwrap();
    const options = response.data.map((role) => {
      return {
        label: role.role_name,
        value: role.role_id,
      };
    });
    callback(options);
  }, 500);

  // this will handle  user role on change
  const handleUserRoleChange = (e) => {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        user_role_id: e.value,
        user_role_name: e.label,
      };
    });
  };

  // this will load country
  const countryOptions = [
    { value: "Sri Lanka", label: "Sri Lanka" },
    { value: "India", label: "India" },
  ];

  const handleCountryChange = (e) => {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        user_address_country: e.value,
      };
    });
  };

  //this will submit edit user form
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const fd = new FormData(newUserForm.current);
      fd.append("updated_by", user.user_id);
      fd.append("user_id", edit_user.user_id);

      const resp = await axios.post("users/update-user", fd, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      autoNotify(dispatch, resp);
      setIsSubmitting(false);
      edit_callback(resp?.data.user);
      dispatch(hidePrimaryModel());
    } catch (error) {
      autoNotify(dispatch, error.response);
      setIsSubmitting(false);
    }
  };

  const handleFormClose = () => {
    dispatch(hidePrimaryModel());
  };

  return (
    <form
      action=""
      id="create-new-user"
      method="post"
      onSubmit={handleFormSubmit}
      ref={newUserForm}
    >
      <div className="row">
        <div className="col-6">
          <div className="form-row">
            <div className="form-group col-md-6 mb-1">
              <label htmlFor="user_first_name">First Name</label>
              <input
                type="text"
                className="form-control"
                id="user_first_name"
                name="user_first_name"
                value={formData.user_first_name}
                onChange={handleFormChange}
              />
            </div>
            <div className="form-group col-md-6  mb-1">
              <label htmlFor="user_last_name">Last Name</label>
              <input
                type="text"
                className="form-control"
                id="user_last_name"
                name="user_last_name"
                value={formData.user_last_name}
                onChange={handleFormChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-8 mb-1">
              <label htmlFor="user_title">Title</label>
              <input
                type="text"
                className="form-control"
                id="user_title"
                name="user_title"
                value={formData.user_title}
                onChange={handleFormChange}
              />
            </div>
          </div>
          <div className="form-group mb-1">
            <label htmlFor="user_address_street">Address Street</label>
            <input
              type="text"
              className="form-control"
              id="user_address_street"
              name="user_address_street"
              value={formData.user_address_street}
              onChange={handleFormChange}
            />
          </div>
          <div className="form-row">
            <div className="form-group col-md-6 mb-1">
              <label htmlFor="user_address_city">City</label>
              <input
                type="text"
                className="form-control"
                id="user_address_city"
                name="user_address_city"
                value={formData.user_address_city}
                onChange={handleFormChange}
              />
            </div>
            <div className="form-group col-md-4 mb-1">
              <label htmlFor="user_address_country">Country</label>
              <Select
                options={countryOptions}
                styles={select_style}
                name="user_address_country"
                id="user_address_country"
                value={{
                  value: formData.user_address_country,
                  label: formData.user_address_country,
                }}
                onChange={handleCountryChange}
              />
            </div>
            <div className="form-group col-md-2 mb-1">
              <label htmlFor="user_address_postcode">Zip</label>
              <input
                type="text"
                className="form-control"
                id="user_address_postcode"
                name="user_address_postcode"
                value={formData.user_address_postcode}
                onChange={handleFormChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6 mb-1">
              <label htmlFor="user_phone">Phone</label>
              <input
                type="text"
                className="form-control"
                id="user_phone"
                name="user_phone"
                value={formData.user_phone}
                onChange={handleFormChange}
              />
            </div>
            <div className="form-group col-md-6 mb-1">
              <label htmlFor="user_mobile">Mobile</label>
              <input
                type="text"
                className="form-control"
                id="user_mobile"
                name="user_mobile"
                value={formData.user_mobile}
                onChange={handleFormChange}
              />
            </div>
          </div>
          <div className="form-group mb-1">
            <label htmlFor="user_role_id">User Role</label>
            <AsyncSelect
              cacheOptions
              loadOptions={loadUserRolesOptions}
              defaultOptions
              styles={select_style}
              id="user_role_id"
              name="user_role_id"
              value={{
                value: formData.user_role_id,
                label: formData.user_role_name,
              }}
              onChange={handleUserRoleChange}
            />
          </div>
        </div>
        <div className="col-6">
          <div className="upload-image-preview mr-5 mb-2">
            <img
              id="user-img-new"
              className="img-thumbnail "
              src={imageURI ? imageURI : "/img/user.png"}
              alt="your"
            />
          </div>
          <div className="form-group">
            <div className="upload-image-file">
              <label htmlFor="user_image" className="">
                <Upload /> Upload Image
              </label>
              <input
                accept="image/*"
                type="file"
                className="form-control-file"
                id="user_image"
                name="user_image"
                onChange={readImageURI}
              />
            </div>
          </div>
          <hr />
          <div className="form-group mb-1">
            <label htmlFor="username">Username</label>
            <input
              type="text"
              className="form-control"
              id="username"
              name="username"
              value={formData.username}
              onChange={handleFormChange}
            />
          </div>
          <div className="form-group mb-1">
            <label htmlFor="user_email">Email address</label>
            <input
              type="email"
              className="form-control"
              id="user_email"
              placeholder="name@example.com"
              name="user_email"
              value={formData.user_email}
              onChange={handleFormChange}
            />
          </div>
          <div className="form-row">
            <div className="form-group col-md-6 mb-1">
              <label htmlFor="open-balance">Password</label>
              <input
                type="password"
                className="form-control"
                id="password"
                name="password"
              />
            </div>
            <div className="form-group col-md-6  mb-1">
              <label htmlFor="password_conform">Password Conform</label>
              <input
                type="password"
                className="form-control"
                id="password_conform"
                name="password_conform"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer mt-3">
        <button
          type="button"
          className="btn btn-secondary b-button"
          data-dismiss="modal"
          onClick={handleFormClose}
        >
          Close
        </button>
        <button
          type="submit"
          id="user-save-btn"
          className="btn btn-primary b-button"
          disabled={isSubmitting}
        >
          Save User
        </button>
      </div>
    </form>
  );
};

export default UserEdit;
