import httpClient from "../../../../api/httpClient";

class SalesRepService {
  //get customers
  async getSalesReps(token, params) {
    const urlSegment = `/${params.branch_id}/${params.start}/${params.length}/${params.order}/q=${params.search}`;
    return await httpClient.get(token, "customers/sales-reps" + urlSegment);
  }
  //create customer
  async createSalesRep(token, data) {
    return await httpClient.post(token, "customers/sales-rep", data);
  }
  //update customer
  async updateSalesRep(token, data) {
    return await httpClient.put(token, "customers/sales-rep", data);
  }
  //delete customer
  async deleteSalesRep(token, id, user_id) {
    return await httpClient.delete(
      token,
      `customers/sales-rep/${user_id}/${id}`
    );
  }
}

export default new SalesRepService();
