import "react-datepicker/dist/react-datepicker.css";

import { useState, useRef } from "react";
import Select from "react-select";
import axios from "../../../api/axios";
import { useSelector, useDispatch } from "react-redux";
import { hidePrimaryModel } from "../../../redux/features/modals/PrimaryModalSlice";
import { autoNotify } from "../../../redux/features/notify/NotifySlice";

const SupplierEdit = (props) => {
  const { edit_supplier, edit_callback } = props;
  const dispatch = useDispatch();
  const editSupplierForm = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { token, user } = useSelector((store) => store.auth);

  const [supplier, setSupplier] = useState(edit_supplier);
  //this will handle form change
  const handleFormChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSupplier((prevFormData) => {
      switch (type) {
        case "checkbox":
          return {
            ...prevFormData,
            [name]: checked,
          };
        default:
          return {
            ...prevFormData,
            [name]: value,
          };
      }
    });
  };

  const handleFormSelect = (e, name) => {
    setSupplier((prevSupplier) => {
      return {
        ...prevSupplier,
        [name]: e.value,
      };
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const fd = new FormData(editSupplierForm.current);
      fd.append("created_by", user.user_id);
      fd.append("supp_id", supplier.supp_id);
      const resp = await axios.post("purchase/edit-supplier", fd, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      autoNotify(dispatch, resp);
      setIsSubmitting(false);
      edit_callback(resp?.data.supplier);
      dispatch(hidePrimaryModel());
    } catch (error) {
      autoNotify(dispatch, error.response);
      setIsSubmitting(false);
    }
  };
  const handleFormClose = () => {
    dispatch(hidePrimaryModel());
  };
  /*select options */
  const select_style = {
    control: (base) => ({
      ...base,
      height: 31,
      minHeight: 31,
      borderRadius: 3,
      border: "1px solid #d0d6db",
      boxShadow: "none",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "31px",
      padding: "0 6px",
    }),
    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "31px",
    }),
  };
  const countryOptions = [
    { value: "Sri Lanka", label: "Sri Lanka" },
    { value: "India", label: "India" },
    { value: "Australia", label: "Australia" },
    { value: "Bangladesh", label: "Bangladesh" },
    { value: "China", label: "China" },
    { value: "Denmark", label: "Denmark" },
    { value: "France", label: "France" },
    { value: "Japan", label: "Japan" },
    { value: "United Kingdom", label: "United Kingdom" },
    { value: "United States of America", label: "United States of America" },
    { value: "United Arab Emirates", label: "United Arab Emirates" },
  ];

  return (
    <form
      id="create-new-supplier"
      action=""
      method="post"
      onSubmit={handleFormSubmit}
      ref={editSupplierForm}
    >
      <div className="row">
        <div className="col-6">
          <div className="form-row">
            <div className="form-group col-md-6 mb-1">
              <label htmlFor="supp_first_name">First Name</label>
              <input
                type="text"
                className="form-control"
                id="supp_first_name"
                name="supp_first_name"
                value={supplier.supp_first_name}
                onChange={handleFormChange}
              />
            </div>
            <div className="form-group col-md-6  mb-1">
              <label htmlFor="supp_last_name">Last Name</label>
              <input
                type="text"
                className="form-control"
                id="supp_last_name"
                name="supp_last_name"
                value={supplier.supp_last_name}
                onChange={handleFormChange}
              />
            </div>
          </div>
          <div className="form-group mb-1">
            <label htmlFor="supp_display_name">Display Name *</label>
            <input
              type="text"
              className="form-control"
              id="supp_display_name"
              name="supp_display_name"
              value={supplier.supp_display_name}
              onChange={handleFormChange}
            />
          </div>
          <div className="form-row">
            <div className="form-group col-md-4 mb-1">
              <label htmlFor="supp_title">Title</label>
              <input
                type="text"
                className="form-control"
                id="supp_title"
                name="supp_title"
                value={supplier.supp_title}
                onChange={handleFormChange}
              />
            </div>
            <div className="form-group col-md-8  mb-1">
              <label htmlFor="supp_company">Company</label>
              <input
                type="text"
                className="form-control"
                id="supp_company"
                name="supp_company"
                value={supplier.supp_company}
                onChange={handleFormChange}
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="supp_address_street">Address Street</label>
            <input
              type="text"
              className="form-control"
              id="supp_address_street"
              name="supp_address_street"
              value={supplier.supp_address_street}
              onChange={handleFormChange}
            />
          </div>
          <div className="form-row">
            <div className="form-group col-md-6 mb-1">
              <label htmlFor="supp_address_city">City</label>
              <input
                type="text"
                className="form-control"
                id="supp_address_city"
                name="supp_address_city"
                value={supplier.supp_address_city}
                onChange={handleFormChange}
              />
            </div>
            <div className="form-group col-md-4 mb-1">
              <label htmlFor="supp_address_country">Country</label>
              <Select
                options={countryOptions}
                styles={select_style}
                name="supp_address_country"
                id="supp_address_country"
                value={{
                  value: supplier.supp_address_country,
                  label: supplier.supp_address_country,
                }}
                onChange={(e) => handleFormSelect(e, "supp_address_country")}
              />
            </div>
            <div className="form-group col-md-2 mb-1">
              <label htmlFor="supp_address_postcode">Zip</label>
              <input
                type="text"
                className="form-control"
                id="supp_address_postcode"
                name="supp_address_postcode"
                value={supplier.supp_address_postcode}
                onChange={handleFormChange}
              />
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="form-group mb-1">
            <label htmlFor="supp_email">Email address</label>
            <input
              type="email"
              className="form-control"
              id="supp_email"
              placeholder="name@example.com"
              name="supp_email"
              value={supplier.supp_email}
              onChange={handleFormChange}
            />
          </div>
          <div className="form-row">
            <div className="form-group col-md-4 mb-1">
              <label htmlFor="supp_phone">Phone</label>
              <input
                type="text"
                className="form-control"
                id="supp_phone"
                name="supp_phone"
                value={supplier.supp_phone}
                onChange={handleFormChange}
              />
            </div>
            <div className="form-group col-md-4 mb-1">
              <label htmlFor="supp_mobile">Mobile</label>
              <input
                type="text"
                className="form-control"
                id="supp_mobile"
                name="supp_mobile"
                value={supplier.supp_mobile}
                onChange={handleFormChange}
              />
            </div>
            <div className="form-group col-md-4 mb-1">
              <label htmlFor="supp_fax">Fax</label>
              <input
                type="text"
                className="form-control"
                id="supp_fax"
                name="supp_fax"
                value={supplier.supp_fax}
                onChange={handleFormChange}
              />
            </div>
          </div>
          <div className="form-group mb-1">
            <label htmlFor="supp_website">Website</label>
            <input
              type="text"
              className="form-control"
              id="supp_website"
              name="supp_website"
              value={supplier.supp_website}
              onChange={handleFormChange}
            />
          </div>
          <div className="form-group mb-1">
            <label htmlFor="supp_account_no">Account no</label>
            <input
              type="text"
              className="form-control"
              id="supp_account_no"
              name="supp_account_no"
              value={supplier.supp_account_no}
              onChange={handleFormChange}
            />
          </div>
          <div className="form-group mb-1">
            <label htmlFor="supp_notes">Notes</label>
            <textarea
              className="form-control"
              id="supp_notes"
              rows="2"
              name="supp_notes"
            ></textarea>
          </div>
          <div className="mt-3">
            <button
              id="supp-create-btn"
              className="btn btn-primary float-right save-button ml-2"
              type="submit"
              disabled={isSubmitting}
            >
              <i className="fas fa-save"></i>
              Save Supplier
            </button>
            <button
              id="supp-close-btn"
              type="button"
              className="btn btn-secondary float-right "
              data-dismiss="modal"
              onClick={handleFormClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default SupplierEdit;
