import "./SupplierList.scss";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import DataTable from "react-data-table-component";
import SearchBar from "../../../components/searchbar/SearchBar";
import { debounce } from "../../../Helper";
import TextLoader from "../../../components/loaders/Loader";
import {
  hideRightPanel,
  showRightPanel,
} from "../../../redux/features/rightpanel/RightPanelSlice";
import RightSidePanel from "../../../components/panels/right/RightSidePanel";
import {
  getSupplierList,
  updateSupplierList,
} from "../../../redux/features/suppliers/SupplierListSlice";

import SupplierListRight from "./SupplierListRight";

const columns = [
  {
    name: "Supplier",
    selector: (row) => row.supp_display_name,
    sortable: true,
    cell: (row) => {
      return (
        <div className="tbl-supplier-col" data-tag="allowRowEvents">
          <h6 data-tag="allowRowEvents">{row.supp_display_name}</h6>
          {row.supp_address_city}
        </div>
      );
    },
  },
  {
    name: "Email",
    selector: (row) => row.supp_email,
    sortable: true,
  },
  {
    name: "Telephone",
    selector: (row) => row.supp_mobile + " " + row.supp_phone,
    sortable: true,
  },
  {
    name: "Balance",
    selector: (row) => row.supp_ledger_balance,
    sortable: true,
  },
];
const conditionalRowStyles = [
  {
    when: (row) => row.isSelected === true,
    style: {
      backgroundColor: "#e3f2fd",
      userSelect: "none",
    },
  },
];

const SupplierList = () => {
  const dispatch = useDispatch();
  const { supplierList, totalRows, isLoading } = useSelector(
    (store) => store.supplier_list
  );
  const { isPanelShow } = useSelector((store) => store.right_panel);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  /* set supplier list */
  const handleOnSearch = debounce((e) => {
    setSearch(e.target.value);
    dispatch(getSupplierList({ search: e.target.value }));
  }, 600);

  const handlePageChange = (page) => {
    const start = (page - 1) * perPage;
    dispatch(
      getSupplierList({ start: start, length: perPage, search: search })
    );
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    const start = (page - 1) * newPerPage;
    dispatch(
      getSupplierList({ start: start, length: newPerPage, search: search })
    );
    setPerPage(newPerPage);
    setCurrentPage(page);
  };

  const handleOnSelect = (row) => {
    const updatedList = supplierList.map((supplier) => {
      //unselect all
      if (row.supp_id !== supplier.supp_id) {
        return { ...supplier, isSelected: false };
      }
      //remove already selected one
      if (supplier?.isSelected === true) {
        dispatch(hideRightPanel());
        return { ...supplier, isSelected: false };
      }
      //selected current
      dispatch(
        showRightPanel({ content: <SupplierListRight supplier={supplier} /> })
      );
      return { ...supplier, isSelected: true };
    });
    dispatch(updateSupplierList(updatedList));
  };

  // on mount
  useEffect(() => {
    dispatch(hideRightPanel());
    dispatch(getSupplierList({ search: "" }));
  }, [dispatch]);

  return (
    <div
      className={
        isPanelShow
          ? "container supplier-list show-right-side-panel"
          : "container supplier-list"
      }
    >
      <div className="action-table-wrapper">
        <div className="action-table-title">
          <h3>Supplier List</h3>
        </div>
        <div className="row">
          <div className="col-md-4 ">
            <SearchBar
              searchHandler={handleOnSearch}
              placeholder="Search suppliers here..."
            />
          </div>
        </div>

        <div className="action-table">
          <DataTable
            dense
            columns={columns}
            data={supplierList}
            progressPending={isLoading}
            progressComponent={<TextLoader />}
            highlightOnHover
            onRowClicked={handleOnSelect}
            pagination
            paginationServer
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10, 20, 50, 100, 200]}
            paginationTotalRows={totalRows}
            paginationDefaultPage={currentPage}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            conditionalRowStyles={conditionalRowStyles}
            className="list-table"
          />
        </div>
      </div>
      <RightSidePanel />
    </div>
  );
};

export default SupplierList;
