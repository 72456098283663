import { useEffect } from "react";
import { store } from "./redux/store";
import moment from "moment";
import { number, floor } from "mathjs";

/* common helper functions */

//this will map objet like array
export const objectMap = (obj, fn) =>
  Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]));

//this will debounce the function
export const debounce = (func, delay) => {
  let timer;
  return (...arg) => {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      func(...arg);
    }, delay);
  };
};

/**
 * This function will check user has permission
 * @param { string } module_name
 * @param { integer } privilege_id
 * @returns { boolean }
 */
export const hasPrivilege = (module_name, privilege_id) => {
  const state = store.getState();
  const privileges = state?.auth?.privileges;
  if (Object.hasOwn(privileges, module_name)) {
    const privilege = privileges[module_name];
    //check have privilege id
    if (privilege_id) {
      if (Object.hasOwn(privilege, privilege_id)) {
        return true;
      }
      return false;
    }
    return true;
  }
  return false;
};

/* 
this will auto resize textArea 
  @textAreaRef : HTMLTextAreaElement -use ref
  @value : string - scroll height
*/
export const useAutoSizeTextArea = (textAreaRef, value) => {
  useEffect(() => {
    if (textAreaRef) {
      // We need to reset the height momentarily to get the correct scrollHeight for the textarea
      textAreaRef.style.height = "0px";
      const scrollHeight = textAreaRef.scrollHeight;

      // We then set the height directly, outside of the render loop
      // Trying to set this with state or a ref will product an incorrect value.
      textAreaRef.style.height = scrollHeight + "px";
    }
  }, [textAreaRef, value]);
};

/*Formats */
//date
export const formatDate = (date) => {
  const newDate = Date.parse(date);
  return moment(newDate).format("DD-MM-YYYY");
  //return moment(newDate).format("MMM Do YYYY");
};

//currency
export const formatCurrency = (amount, decimal = 2) => {
  //const usFormat = amount.toLocaleString('en-US');
  //const indianFormat = amount.toLocaleString('en-IN');
  if (isNaN(amount) || amount === null) {
    let n = 0;
    return n.toFixed(2);
  }
  let float = parseFloat(amount);
  const formattedNumber = float.toLocaleString("en-US", {
    minimumFractionDigits: decimal,
    maximumFractionDigits: decimal,
  });
  return formattedNumber;
};

//format Decimal numbers
export const formatDecimal = (n, p = 2) => {
  if (p === 2) {
    n = floor(n * 100) / 100;
    n = number(n).toFixed(2);
    return n;
  }
  n = floor(n * 10000) / 10000;
  n = number(n).toFixed(4);
  return n;
};
