import React, { useState } from "react";
import { Upload } from "react-bootstrap-icons";
import DatePicker from "react-datepicker";
import Moment from "moment";

import SelectUnit from "../../../components/form/select/SelectUnit";
import SelectBrand from "../../../components/form/select/SelectBrand";
import SelectCategory from "../../../components/form/select/SelectCategory";
import SelectTaxable from "../../../components/form/select/SelectTaxable";

const FormGeneral = (props) => {
  //locale state
  const [form, setFrom] = useState({
    item_name: "",
    item_sku: "",
    barcode: "",
    category_id: "",
    brand_id: "",
    unit_id: "",
    item_image: "/img/product.png",
    item_description: "",
    item_reorder_point: "0",
    transaction_quantity: "0",
    batch_made_date: "",
    batch_expiry_date: "",
    sales_tax_id: "1",
  });
  //event
  //this will handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFrom({ ...form, [name]: value });
  };

  // this will handle image change
  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader();
      reader.onload = function (ev) {
        setFrom({ ...form, item_image: ev.target.result });
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // this will handle date input change
  const handleDateChange = (date, name) => {
    if (date) {
      let formatted_date = Moment(date).format("YYYY-MM-DD");
      setFrom({ ...form, [name]: formatted_date });
    }
  };

  return (
    <div className="row">
      <div className="col-6">
        <div className="form-group mb-1">
          <label htmlFor="item_name">Product Name</label>
          <input
            type="text"
            className="form-control"
            name="item_name"
            id="item_name"
            value={form.item_name}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-row">
          <div className="form-group col-md-8 mb-1">
            <label htmlFor="sku-code">SKU</label>
            <input
              type="text"
              className="form-control"
              id="item_sku"
              placeholder="KD12548"
              name="item_sku"
              value={form.item_sku}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group col-md-4 mb-1">
            <label htmlFor="unit_id">Pack Size</label>
            <SelectUnit id="unit_id" />
          </div>
        </div>
        <div className="form-group mb-1">
          <label htmlFor="barcode">MPN/Barcode</label>
          <input
            type="text"
            className="form-control"
            id="barcode"
            name="barcode"
            value={form.barcode}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-row">
          <div className="form-group col-md-6 mb-1">
            <label htmlFor="brand_id">Brand</label>
            <SelectBrand id="brand_id" />
          </div>
          <div className="form-group col-md-6 mb-1">
            <label htmlFor="category_id">Category</label>
            <SelectCategory id="category_id" />
          </div>
        </div>
        <div className="form-group mb-1">
          <label htmlFor="item_description">Product Description</label>
          <input
            type="text"
            className="form-control"
            id="item_description"
            name="item_description"
            value={form.item_description}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-row">
          <div className="form-group col-md-5 mb-1">
            <label htmlFor="sales_tax_id">Sale Tax</label>
            <SelectTaxable selected={form.sales_tax_id} id="sales_tax_id" />
          </div>
        </div>
      </div>
      <div className="col-6">
        <div className="upload-image-preview mr-5 mb-2">
          <img
            id="user-img-new"
            className="img-thumbnail "
            src={form.item_image ? form.item_image : "/img/product.png"}
            alt="product"
          />
        </div>
        <div className="form-group">
          <div className="upload-image-file">
            <label htmlFor="item_image">
              <Upload /> Upload Image
            </label>
            <input
              accept="image/*"
              type="file"
              className="form-control-file"
              id="item_image"
              name="item_image"
              onChange={handleImageChange}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-5 mb-1">
            <label htmlFor="transaction_quantity">Opening Stock</label>
            <input
              type="number"
              className="form-control"
              id="transaction_quantity"
              name="transaction_quantity"
              value={form.transaction_quantity}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group col-md-5 mb-1">
            <label htmlFor="item_reorder_point">Reorder Point</label>
            <input
              type="number"
              className="form-control"
              id="item_reorder_point"
              name="item_reorder_point"
              value={form.item_reorder_point}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-5 mb-1">
            <label htmlFor="batch_made_date">Made Date</label>
            <DatePicker
              className="form-control"
              dateFormat="yyyy-MM-dd"
              id="batch_made_date"
              autoComplete="off"
              selected={Date.parse(form.batch_made_date)}
              onChange={(date) => handleDateChange(date, "batch_made_date")}
              name="batch_made_date"
            />
          </div>
          <div className="form-group col-md-5 mb-1">
            <label htmlFor="batch_expiry_date">Expiry Date</label>
            <DatePicker
              className="form-control"
              dateFormat="yyyy-MM-dd"
              id="batch_expiry_date"
              autoComplete="off"
              selected={Date.parse(form.batch_expiry_date)}
              onChange={(date) => handleDateChange(date, "batch_expiry_date")}
              name="batch_expiry_date"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default React.memo(FormGeneral);
