import React from "react";
import { useDispatch } from "react-redux";
import AsyncCreatableSelect from "react-select/async-creatable";
import { debounce } from "../../../Helper";
import { BoxSeam, Box2Fill } from "react-bootstrap-icons";

import { getInventoryItemList } from "../../../redux/features/inventory/InventoryItemSlice";
import StockItem from "../../../containers/inventory/stock-item/StockItem";

import { showPrimaryModel } from "../../../redux/features/modals/PrimaryModalSlice";
/*select options */
const select_style = {
  control: (base) => ({
    ...base,
    height: 31,
    minHeight: 31,
    borderRadius: 3,
    border: "none",
    boxShadow: "none",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: "31px",
    padding: "0 6px",
  }),
  input: (provided, state) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "31px",
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: "300px",
  }),
  menu: (provided) => ({
    ...provided,
    width: "max-content",
    minWidth: "100%",
  }),
};

const SelectItemSales = (props) => {
  const { selected, callback } = props;
  const dispatch = useDispatch();
  //Events
  const loadItemOptions = debounce(async (inputValue, callback) => {
    const response = await dispatch(
      getInventoryItemList({
        search: inputValue,
      })
    ).unwrap();
    const options = response.data.map((item) => {
      return {
        ...item,
        value: item.batch_id,
        label: item.item_sku,
      };
    });
    callback(options);
  }, 500);
  //
  const handleOnCreateOption = (inputValue) => {
    const itemCallback = (item) => {
      const update = { ...item, value: item.batch_id, label: item.item_sku };
      callback(update);
    };
    const modelData = {
      title: "Add Inventory Item",
      titleIcon: <Box2Fill />,
      content: <StockItem action={"new"} callback={itemCallback} />,
    };
    dispatch(showPrimaryModel(modelData));
  };

  const formatItemSelectOption = (option, { context }) => {
    if (option?.__isNew__) {
      return (
        <div className="drop-item">
          <div className="drop-item-col new">
            <BoxSeam /> {option.label}
          </div>
        </div>
      );
    }
    if (context === "menu") {
      let item_type = option.item_type ? option.item_type.substring(0, 3) : "";
      const label = (
        <div className="drop-item">
          <div className="drop-item-col sku">{option.label}</div>
          <div className="drop-item-col name">{option.item_name}</div>
          <div className="drop-item-col type">{item_type}</div>
          <div className="drop-item-col price">{option.batch_sale_price}</div>
          <div className="drop-item-col qty">
            Qty {option.batch_quantity_on_hand}
          </div>
        </div>
      );
      return label;
    }
    return option.label + " - " + option.item_name;
  };

  return (
    <AsyncCreatableSelect
      cacheOptions
      defaultOptions
      loadOptions={loadItemOptions}
      styles={select_style}
      onChange={callback}
      onCreateOption={handleOnCreateOption}
      value={selected}
      formatOptionLabel={formatItemSelectOption}
      formatCreateLabel={(userInput) => `Add "${userInput}" as new item`}
      placeholder="Select Item..."
    />
  );
};
export default React.memo(SelectItemSales);
