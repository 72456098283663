import { memo, useState, useEffect } from "react";
import { PencilFill, Trash2Fill, PersonFill } from "react-bootstrap-icons";
import { Scrollbars } from "react-custom-scrollbars-2";
import { hasPrivilege } from "../../../../Helper";
import { useDispatch } from "react-redux";

import { showPrimaryModel } from "../../../../redux/features/modals/PrimaryModalSlice";
import { showOptionModel } from "../../../../redux/features/modals/OptionModalSlide";

import CustomerEdit from "../CustomerEdit";
import CustomerDelete from "../CustomerDelete";

const ListRight = (props) => {
  const { data, hideRightPanel } = props;
  const dispatch = useDispatch();
  const [customer, setCustomer] = useState({});

  //set information

  let cust_full_address = customer?.cust_address_street
    ? customer?.cust_address_street + ", "
    : "";
  cust_full_address += customer?.cust_address_city
    ? customer?.cust_address_city + ", "
    : "";
  cust_full_address += customer?.cust_address_country
    ? customer?.cust_address_country + ","
    : "";
  cust_full_address += customer?.cust_address_postcode
    ? customer?.cust_address_postcode
    : "";

  const infoList = [
    {
      name: "Company",
      value: customer?.cust_company,
    },
    {
      name: "Address",
      value: cust_full_address,
    },
    {
      name: "Telephone",
      value: customer?.cust_phone,
    },
    {
      name: "Email",
      value: customer?.cust_email,
    },
    {
      name: "Credit Limit",
      value: customer?.cust_credit_limit,
    },
    {
      name: "Ledger Balance",
      value: customer?.cust_ledger_balance,
    },
  ];

  const info_list_elements = infoList.map((field) => {
    return (
      <div key={field.name} className="row no-gutters info-field">
        <div className="col-sm-3 name">
          <p>{field.name}</p>
        </div>
        <div className="col-sm-8 value">
          <p>: {field.value}</p>
        </div>
      </div>
    );
  });
  // set customer transaction
  const setCustomerTransactions = () => {
    return <p>No Transactions yet.</p>;
  };

  //handler for edit
  const editCallback = (customer) => {
    setCustomer(customer);
  };
  const handleEdit = () => {
    const modelData = {
      title: "Edit customer",
      titleIcon: <PersonFill />,
      content: <CustomerEdit customer={customer} callback={editCallback} />,
    };
    dispatch(showPrimaryModel(modelData));
  };

  //handler for delete
  const deleteCallback = (id) => {
    hideRightPanel();
  };
  const handleDelete = () => {
    const modelData = {
      title: "Delete customer",
      titleIcon: <PersonFill />,
      content: <CustomerDelete customer={customer} callback={deleteCallback} />,
    };
    dispatch(showOptionModel(modelData));
  };

  //set customer on mount
  useEffect(() => {
    setCustomer(data);
  }, [data]);

  return (
    <div className="right-content">
      <div className="header">
        <div className="header-img">
          <img src="/img/icons/cust-lg.png" alt="side header" />
        </div>
        <div className="header-text">
          <h4>{customer?.cust_display_name}</h4>
          <p>{customer?.cust_company}</p>
        </div>
      </div>
      <div className="info-section">
        <h6>Customer Information</h6>

        {customer?.cust_first_name ? (
          <div className="contact-person">
            <p>Contact Person</p>
            <div className="row no-gutters">
              <div className="col-sm-3">
                <p>Name</p>
              </div>
              <div className="col-sm-8">
                <p>
                  : {customer?.cust_first_name} {customer?.cust_last_name}
                </p>
              </div>
            </div>
            <div className="row no-gutters">
              <div className="col-sm-3">
                <p>Mobile</p>
              </div>
              <div className="col-sm-8">
                <p>: {customer?.cust_mobile}</p>
              </div>
            </div>
          </div>
        ) : null}

        {info_list_elements}
      </div>
      <h6>Customer Transactions</h6>
      <Scrollbars style={{ width: "100%", height: "100%" }}>
        <div className="trans-content">{setCustomerTransactions()}</div>
      </Scrollbars>
      <div className="action-section">
        {hasPrivilege("Customers", 502) && (
          <button onClick={handleEdit} className="btn btn-primary b-button">
            <PencilFill /> Edit Customer
          </button>
        )}
        {hasPrivilege("Customers", 503) && (
          <button onClick={handleDelete} className="btn btn-dark b-button">
            <Trash2Fill /> Delete
          </button>
        )}
      </div>
    </div>
  );
};

export default memo(ListRight);
