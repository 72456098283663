import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PencilFill, TrashFill, TagsFill } from "react-bootstrap-icons";

import { getCustomerCategories } from "../../../../redux/features/customers/customerCategorySlice";
import { showOptionModel } from "../../../../redux/features/modals/OptionModalSlide";
import ActionList from "../../../../components/layouts/ActionList";
import ListRight from "./ListRight";
import CategoryEdit from "../CategoryEdit";
import CategoryDelete from "../CategoryDelete";

const CategoryList = () => {
  const dispatch = useDispatch();
  const { customerCategories, recordsTotal, isLoading } = useSelector(
    (store) => store.customerCategory
  );

  //event
  const customersCategoriesCallback = useCallback(
    (option) => {
      dispatch(getCustomerCategories(option));
    },
    [dispatch]
  );

  //actions
  const categoryEdit = (row) => {
    const modelData = {
      title: "Edit Category",
      titleIcon: <TagsFill />,
      content: <CategoryEdit category={row} callback={(category) => {}} />,
    };
    dispatch(showOptionModel(modelData));
  };

  const categoryDelete = (row) => {
    const modelData = {
      title: "Delete Category",
      titleIcon: <TrashFill />,
      content: <CategoryDelete category={row} callback={(category) => {}} />,
    };
    dispatch(showOptionModel(modelData));
  };

  const columns = [
    {
      name: "Category",
      selector: (row) => row.cat_name,
      sortable: true,
      width: "30%",
    },
    {
      name: "Description",
      selector: (row) => row.cat_description,
      sortable: true,
    },
    {
      name: "",
      selector: (row) => row.cat_id,
      sortable: true,
      cell: (row) => {
        return (
          <div className="table-action-control">
            <button
              type="button"
              id="po-save-btn"
              className="btn row-btn"
              onClick={() => categoryEdit(row)}
            >
              <PencilFill />
            </button>
            <button
              type="button"
              id="po-save-btn"
              className="btn row-btn"
              onClick={() => categoryDelete(row)}
            >
              <TrashFill />
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <ActionList
      listName={"Customer Categories"}
      getListCallback={customersCategoriesCallback}
      columns={columns}
      listData={customerCategories}
      listTotal={recordsTotal}
      isLoading={isLoading}
      sideContent={(selected) => {
        return <ListRight />;
      }}
    />
  );
};

export default CategoryList;
