import React, { useState, useRef } from "react";
import { PlusSquareDotted } from "react-bootstrap-icons";

import MaterialRow from "./MaterialRow";

const FormMaterials = (props) => {
  let tableRowCount = useRef(1);
  //locale state
  const [form, setFrom] = useState({
    batch_cost_price: "0",
    batch_sale_price: "0",
  });
  const [materials, setMaterials] = useState([
    {
      rowID: tableRowCount.current,
      item: null,
      qty: 0,
      unit: null,
    },
  ]);
  //event
  //this will handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFrom({ ...form, [name]: value });
  };

  //this will add new table row
  const addNewRowHandler = (e) => {
    e.preventDefault();
    tableRowCount.current++;
    setMaterials((prevMaterial) => {
      return [
        ...prevMaterial,
        {
          rowID: tableRowCount.current,
          item: null,
          qty: 0,
          unit: null,
        },
      ];
    });
  };
  //this will delete table row
  const deleteRowHandler = (deletedRow) => {
    const newArray = materials.filter((row) => {
      return row.rowID !== deletedRow.rowID;
    });
    setMaterials(newArray);
  };

  return (
    <div className="row">
      <div className="col-9">
        <h6>Add item as materials</h6>
        <div className="material-table-wrapper">
          <table className="table table-sm table-hover material-table">
            <thead>
              <tr>
                <th></th>
                <th className="tbl-item-col">Item</th>
                <th className="tbl-qty-col">Qty</th>
                <th className="tbl-unit-col">Unit</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {materials.map((row) => {
                return (
                  <MaterialRow
                    key={row.rowID}
                    row={row}
                    deleteRow={deleteRowHandler}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
        <button className="btn" onClick={addNewRowHandler}>
          Add Row <PlusSquareDotted />
        </button>
      </div>
      <div className="col-3">
        <div className="form-group mb-1">
          <label htmlFor="batch_cost_price">Cost Price</label>
          <input
            type="number"
            className="form-control"
            id="batch_cost_price"
            name="batch_cost_price"
            value={form.batch_cost_price}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group mb-1">
          <label htmlFor="batch_sale_price">Selling Price</label>
          <input
            type="number"
            className="form-control"
            id="batch_sale_price"
            name="batch_sale_price"
            value={form.batch_sale_price}
            onChange={handleInputChange}
          />
        </div>
      </div>
    </div>
  );
};
export default FormMaterials;
