import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import AsyncCreatableSelect from "react-select/async-creatable";
import { PlusSquareDotted, PersonPlusFill } from "react-bootstrap-icons";

import { debounce } from "../../../../Helper";
import CustomerNew from "../../../../containers/sales/customer/CustomerNew";

import { showPrimaryModel } from "../../../../redux/features/modals/PrimaryModalSlice";
import { getCustomers } from "../../../../redux/features/customers/customerSlice";

/*select options */
const select_style = {
  control: (base) => ({
    ...base,
    height: 31,
    minHeight: 31,
    borderRadius: 3,
    border: "1px solid #d0d6db",
    boxShadow: "none",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: "31px",
    padding: "0 6px",
  }),
  input: (provided, state) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "31px",
  }),
  menuList: (provided) => ({
    ...provided,
  }),
  menu: (provided) => ({
    ...provided,
    width: "max-content",
    minWidth: "100%",
  }),
};

const formatItemSelectOption = (option, { context }) => {
  if (option?.__isNew__) {
    return (
      <>
        <PlusSquareDotted /> {option.label}
      </>
    );
  }
  return option.label;
};

const SelectCustomer = (props) => {
  const { selected, callback } = props;

  const dispatch = useDispatch();
  //local stats
  const [options, setOptions] = useState();
  const [customer, setCustomer] = useState(selected);
  const [isLoading, setIsLoading] = useState(false);
  //events
  const loadListOptions = debounce(async (inputValue, callback) => {
    let options = await dispatch(
      getCustomers({
        start: 0,
        length: 10,
        order: "desc",
        search: inputValue,
      })
    ).unwrap();
    options = options.data.map((customer) => {
      return {
        ...customer,
        label: customer.cust_display_name,
        value: customer.cust_id,
      };
    });
    callback(options);
  }, 500);

  const handleOnChange = (option) => {
    setCustomer(option);
    if (callback) {
      callback(option);
    }
  };

  const handleOnCreate = (inputValue) => {
    const new_customer = {
      cust_id: null,
      cust_display_name: inputValue,
    };
    const modelData = {
      title: "Add New Customer",
      titleIcon: <PersonPlusFill />,
      content: (
        <CustomerNew
          customer={new_customer}
          callback={(customer) => {
            const option = {
              ...customer,
              label: customer.cust_display_name,
              value: customer.cust_id,
            };
            setOptions([...options, option]);
            if (callback) {
              callback(option);
            }
          }}
        />
      ),
    };
    dispatch(showPrimaryModel(modelData));
  };

  //effect
  useEffect(() => {
    const unitData = async () => {
      setIsLoading(true);
      const list = await dispatch(
        getCustomers({
          start: 0,
          length: 10,
          order: "desc",
          search: "",
        })
      ).unwrap();
      const options = list.data.map((customer) => {
        return {
          ...customer,
          label: customer.cust_display_name,
          value: customer.cust_id,
        };
      });
      setOptions(options);
      setIsLoading(false);
    };
    unitData();
  }, [dispatch]);

  useEffect(() => {
    if (selected) {
      setCustomer(selected);
    }
  }, [selected]);

  return (
    <AsyncCreatableSelect
      cacheOptions
      defaultOptions={options}
      loadOptions={loadListOptions}
      styles={select_style}
      onChange={handleOnChange}
      onCreateOption={handleOnCreate}
      value={customer}
      formatOptionLabel={formatItemSelectOption}
      formatCreateLabel={(userInput) => `Add "${userInput}" as new customer`}
      placeholder="Search..."
      isLoading={isLoading}
      name="customer_id"
    />
  );
};
export default React.memo(SelectCustomer);
