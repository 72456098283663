import "./Dashboard.scss";

import { Outlet } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useSelector } from "react-redux";
import Notifier from "./Notifier";

import MainMenu from "../../components/navigation/mainmenu/MainMenu";

const Dashboard = () => {
  const { notifications } = useSelector((store) => store.notifier);
  return (
    <div className="dashboard">
      <MainMenu />
      <Scrollbars style={{ width: "100%", height: "100%" }}>
        <Outlet />
        <div className="notifier-wrapper">
          {notifications.map((notify) => {
            return <Notifier key={notify.id} notify={notify} />;
          })}
        </div>
      </Scrollbars>
    </div>
  );
};

export default Dashboard;
