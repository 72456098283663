import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../api/axios";

const initialState = {
  saleId: "",
  saleNo: "",
  saleDate: "",
  saleStatus: "Draft",
  customer: null,
  billingAddress: "",
  salesRep: null,
  salesRepName: "",
  saleOrder: null,
  orderNo: "",
  orderDate: null,
  tableRowCounter: 0,
  tableRows: [],
  subTotal: 0,
  discount: "",
  discountValue: 0,
  tax: {
    tax_id: "1",
    tax_name: "None",
    tax_rate: "0",
    label: "None",
    value: "1",
  },
  taxValue: 0,
  saleTotal: 0,
  reference: "",
  note: "",
  terms: "",
  paymentTerm: {
    term_id: 0,
    term_name: "Due Upon Receipt",
    term_days: 0,
    label: "Due Upon Receipt",
    value: 0,
  },
  paymentDue: new Date(),
  isLoading: true,
};

//get PO Number
export const getSaleNumber = createAsyncThunk(
  "sales/SaleNumber",
  async (data, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const token = auth.token;
      const branch = auth.branch;

      const searchParams = new URLSearchParams();
      searchParams.append("branch_id", branch.branch_id);
      searchParams.toString();

      const response = await axios.get(
        "customers/new-sale-no?" + searchParams.toString(),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const InvoiceSlice = createSlice({
  name: "sale_invoice",
  initialState,
  reducers: {
    setNewSale: () => initialState,
    setEditSale: (state, action) => {
      const invoice = action.payload;
      state.saleId = invoice.sale_id;
      state.saleNo = invoice.sale_no;
      state.saleDate = invoice.sale_date;
      state.saleStatus = invoice.invoice_status;
      state.customer = invoice.customer?.cust_id
        ? {
            ...invoice.customer,
            label: invoice.customer.cust_display_name,
            value: invoice.customer.cust_id,
          }
        : null;
      state.billingAddress = invoice.customer_address;
      state.salesRep = invoice.sales_rep?.rep_id
        ? {
            ...invoice.sales_rep,
            label: invoice.sales_rep.rep_name,
            value: invoice.sales_rep.rep_id,
          }
        : null;
      state.salesRepName = invoice.sales_rep.rep_name;

      state.saleOrder = invoice.sale_order?.so_id
        ? {
            ...invoice.sale_order,
            label: invoice.sale_order.so_no,
            value: invoice.sale_order.so_id,
          }
        : null;

      state.tableRows = invoice.sale_item_rows.map((sale_row) => {
        state.tableRowCounter++;
        return {
          rowID: state.tableRowCounter,
          item: {
            ...sale_row.sale_item,
            value: sale_row.sale_item.batch_id,
            label: sale_row.sale_item.item_sku,
          },
          itemDes: sale_row.sale_item_description,
          quantity: sale_row.sale_item_qty,
          unit: sale_row.sale_item_unit_id,
          rate: sale_row.sale_item_rate,
          amount: sale_row.sale_item_amount,
        };
      });
      state.discount = invoice.sale_discount;
      if (invoice.sale_tax !== null) {
        state.tax = {
          ...invoice.sale_tax,
          value: invoice.sale_tax.tax_id,
          label: invoice.sale_tax.tax_name,
        };
      } else {
        state.tax = initialState.tax;
      }
      state.reference = invoice.sale_reference;
      if (invoice.payment_term !== null) {
        state.paymentTerm = {
          ...invoice.payment_term,
          value: invoice.payment_term.term_id,
          label: invoice.payment_term.term_name,
        };
      } else {
        state.paymentTerm = initialState.paymentTerm;
      }
      const date = new Date();
      const days = parseInt(state.paymentTerm?.term_days);
      date.setDate(date.getDate() + days);
      state.paymentDue = date;

      state.note = invoice.customer_note;
      state.terms = invoice.sale_terms;
      state.orderNo = invoice.so_no;
      state.orderDate = invoice.so_date;
    },
    setSaleStatus: (state, action) => {
      state.saleStatus = action.payload;
    },
    setSaleNumber: (state, action) => {
      state.saleNo = action.payload;
    },
    setSaleDate: (state, action) => {
      state.saleDate = action.payload;
    },
    setSaleCustomer: (state, action) => {
      const customer = action.payload;
      let company = customer?.cust_company
        ? customer.cust_company
        : customer?.cust_display_name;

      let address = customer?.cust_first_name
        ? "Attn: " +
          customer?.cust_first_name +
          " " +
          customer?.cust_last_name +
          ",\n"
        : "";
      address += company ? company + ",\n" : "";
      address += customer?.cust_address_street
        ? customer.cust_address_street + ",\n"
        : "";
      address += customer?.cust_address_city
        ? customer.cust_address_city + ",\n"
        : "";
      address += customer?.cust_address_country
        ? customer.cust_address_country + ". "
        : "";
      address += customer?.cust_address_postcode
        ? customer.cust_address_postcode
        : "";
      address += customer?.cust_phone ? "\nTel : " + customer.cust_phone : "";
      state.customer = customer;
      state.billingAddress = address;
    },
    setSaleBillingAddress: (state, action) => {
      state.billingAddress = action.payload;
    },
    setSalesRep: (state, action) => {
      const sale_rep = action.payload;
      state.salesRep = sale_rep;
      state.salesRepName = sale_rep?.rep_name;
    },
    setSalesRepName: (state, action) => {
      state.salesRepName = action.payload;
    },
    setSaleOrder: (state, action) => {
      const so = action.payload;
      state.saleOrder = so;

      if (so) {
        state.billingAddress = so.customer_address;
        state.tableRows = so.so_item_rows.map((so_row) => {
          state.tableRowCounter++;
          return {
            rowID: state.tableRowCounter,
            item: {
              ...so_row.so_item,
              value: so_row.so_item.batch_id,
              label: so_row.so_item.item_sku,
            },
            itemDes: so_row.so_item_description,
            quantity: so_row.so_item_qty,
            rate: so_row.so_item_rate,
            amount: so_row.so_item_amount,
          };
        });
        state.discount = so.so_discount;
        if (so.so_tax !== null) {
          state.tax = {
            ...so.so_tax,
            value: so.so_tax.tax_id,
            label: so.so_tax.tax_name,
          };
        }
        state.reference = so.so_reference;

        state.note = so.customer_note;
        state.terms = so.so_terms;
        state.orderNo = so.so_no;
        state.orderDate = so.so_date;
      }
    },
    newTableRow: (state, action) => {
      state.tableRowCounter++;
      state.tableRows.push({
        rowID: state.tableRowCounter,
        item: null,
        itemDes: "",
        quantity: 0,
        unit: null,
        rate: 0,
        amount: 0,
      });
    },
    updateTableRow: (state, action) => {
      let editedRow = action.payload;
      const newArray = state.tableRows.map((row) => {
        if (row.rowID === editedRow.rowID) {
          return editedRow;
        }
        return row;
      });
      state.tableRows = newArray;
    },
    deleteTableRow: (state, action) => {
      let deletedRow = action.payload;
      const newArray = state.tableRows.filter((row) => {
        return row.rowID !== deletedRow.rowID;
      });
      state.tableRows = newArray;
    },
    setSubTotal: (state, action) => {
      state.subTotal = action.payload;
    },
    setDiscount: (state, action) => {
      state.discount = action.payload;
    },
    setDiscountValue: (state, action) => {
      state.discountValue = action.payload;
    },
    setTax: (state, action) => {
      state.tax = action.payload;
    },
    setTaxValue: (state, action) => {
      state.taxValue = action.payload;
    },
    setSaleTotal: (state, action) => {
      state.saleTotal = action.payload;
    },
    setOrderNo: (state, action) => {
      state.orderNo = action.payload;
    },
    setOrderDate: (state, action) => {
      state.orderDate = action.payload;
    },
    setReference: (state, action) => {
      state.reference = action.payload;
    },
    setNote: (state, action) => {
      state.note = action.payload;
    },
    setTerms: (state, action) => {
      state.terms = action.payload;
    },
    setPaymentTerms: (state, action) => {
      state.paymentTerm = action.payload;
      const date = new Date();
      const days = parseInt(state.paymentTerm?.term_days);
      date.setDate(date.getDate() + days);
      state.paymentDue = date;
    },
    setPaymentDue: (state, action) => {
      state.paymentDue = action.payload;
    },
  },
  extraReducers: (builder) => {
    //
    builder.addCase(getSaleNumber.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSaleNumber.fulfilled, (state, action) => {
      state.isLoading = false;
      state.saleNo = action.payload.new_sale_no;
    });
    builder.addCase(getSaleNumber.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const {
  setNewSale,
  setEditSale,
  setSaleNumber,
  setSaleStatus,
  setSaleDate,
  setSaleCustomer,
  setSaleBillingAddress,
  setSalesRep,
  setSalesRepName,
  setSaleOrder,
  newTableRow,
  updateTableRow,
  deleteTableRow,
  setSubTotal,
  setDiscount,
  setDiscountValue,
  setTax,
  setTaxValue,
  setSaleTotal,
  setOrderNo,
  setOrderDate,
  setReference,
  setNote,
  setTerms,
  setPaymentTerms,
  setPaymentDue,
} = InvoiceSlice.actions;
export default InvoiceSlice.reducer;
