import { PersonFill } from "react-bootstrap-icons";
import { Building } from "react-bootstrap-icons";

import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  userLogin,
  getPrivileges,
} from "../../../redux/features/auth/AuthSlice";

const SignInForm = () => {
  const { token, branches, isLoading } = useSelector((store) => store.auth);
  const loginForm = useRef(null);

  const [formData, setFormDta] = useState({
    user_id: "",
    password: "",
  });
  const { user_id, password } = formData;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormDta({ ...formData, [name]: value });
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(loginForm.current);
    dispatch(userLogin(data));
  };

  const handleBranchSubmit = async (token, branch) => {
    const response = await dispatch(getPrivileges({ token, branch })).unwrap();
    if (response.user) {
      navigate("/dashboard");
    } else {
      navigate("/");
    }
  };

  if (branches?.length > 0) {
    const branches_list = branches.map((branch) => {
      //disable bizenica web branch
      const disabled = branch.branch_id === "2022652" ? true : false;
      return (
        <button
          type="button"
          className="btn btn-outline-secondary btn-block choice"
          onClick={() => handleBranchSubmit(token, branch)}
          key={branch.branch_id}
          disabled={disabled || isLoading}
        >
          <Building size={20} />
          <div className="choice-text">
            <span>
              {branch.branch_name}
              {disabled ? " Coming soon" : ""}
            </span>
            <span>{branch.com_name}</span>
          </div>
        </button>
      );
    });
    return (
      <div className="login-form">
        <h4 className="title">Which one</h4>
        <div className="choice-wrapper">
          <label htmlFor="company" className="mb-3">
            <strong>Your Companies</strong>
          </label>
          {branches_list}
        </div>
      </div>
    );
  } else {
    return (
      <div className="login-form">
        <h4 className="title">Sign in</h4>
        <form ref={loginForm} id="user-login-form" onSubmit={handleFormSubmit}>
          <div className="form-group">
            <label htmlFor="user_id">
              <strong>User ID</strong>
            </label>
            <input
              type="text"
              className="form-control"
              name="user_id"
              id="user_id"
              onChange={handleFormChange}
              value={user_id}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">
              <strong>Password</strong>
            </label>
            <input
              type="password"
              className="form-control"
              name="password"
              id="password"
              onChange={handleFormChange}
              value={password}
            />
          </div>
          <div className="form-group form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="remember"
              name="remember"
            />
            <label className="form-check-label" htmlFor="remember">
              Remember
            </label>
          </div>
          <div className="row">
            <div className="col-12">
              <button
                id="user-login-btn"
                type="submit"
                className="btn btn-primary"
                disabled={isLoading}
              >
                <PersonFill /> Sign in
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
};

export default SignInForm;
