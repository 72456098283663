import { memo, useState } from "react";
import DataTable from "react-data-table-component";
import { debounce } from "../../../../Helper";
import SearchBar from "../SearchBar";
import Loader from "../../../Loader";

const conditionalRowStyles = [
  {
    when: (row) => row.isSelected === true,
    style: {
      backgroundColor: "#e3f2fd",
      userSelect: "none",
    },
  },
];

const ListTable = (props) => {
  const { listName, getListCallback, columns, listData, listTotal, isLoading } =
    props;

  //locale
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  //events
  const handleOnSearch = debounce((e) => {
    setSearch(e.target.value);
    getListCallback({ search: e.target.value });
  }, 600);

  const handlePageChange = (page) => {
    const start = (page - 1) * perPage;
    getListCallback({ start: start, length: perPage, search: search });
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    const start = (page - 1) * newPerPage;
    getListCallback({ start: start, length: newPerPage, search: search });
    setPerPage(newPerPage);
    setCurrentPage(page);
  };

  return (
    <>
      <div className="row">
        <div className="col-md-4 ">
          <SearchBar
            searchHandler={handleOnSearch}
            placeholder={"Search " + listName + " here..."}
          />
        </div>
      </div>

      <div className="action-table">
        <DataTable
          dense
          columns={columns}
          data={listData}
          progressPending={isLoading}
          progressComponent={<Loader />}
          highlightOnHover
          pagination
          paginationServer
          paginationPerPage={perPage}
          paginationRowsPerPageOptions={[10, 20, 50, 100, 200]}
          paginationTotalRows={listTotal}
          paginationDefaultPage={currentPage}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          conditionalRowStyles={conditionalRowStyles}
          className="list-table"
        />
      </div>
    </>
  );
};

export default memo(ListTable);
