import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Invoice from "../../../components/workplace/invoice/Invoice";

import {
  setEditSale,
  setNewSale,
} from "../../../redux/features/sales/InvoiceSlice";

const InvoiceEdit = () => {
  const params = useParams();
  const sale_id = params.id;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { invoiceList } = useSelector((store) => store.sale_invoice_list);

  const invoice = invoiceList.find((sale) => {
    return sale.sale_id === sale_id;
  });

  /* this will dispatch edit Invoice */
  useEffect(() => {
    if (invoice?.invoice_status === "Draft") {
      dispatch(setEditSale(invoice));
    } else {
      navigate("/dashboard/sales/invoices");
    }
    // call when unmount
    return () => {
      dispatch(setNewSale());
    };
  }, [dispatch, invoice, navigate]);

  return <Invoice action="Edit" />;
};
export default InvoiceEdit;
