import "./UserRoleNew.scss";
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "../../api/axios";
import { Scrollbars } from "react-custom-scrollbars-2";
import { hidePrimaryModel } from "../../redux/features/modals/PrimaryModalSlice";
import { autoNotify } from "../../redux/features/notify/NotifySlice";
import CheckPermission from "../../components/form/checkbox/CheckPermission";

const getPrivilegesList = async (token, branch, user_role) => {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append("com_id", branch.com_id);
    searchParams.append("role_id", user_role.role_id);
    searchParams.toString();
    const response = await axios.get(
      "users/privileges-list?" + searchParams.toString(),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return [];
  }
};

const UserRoleEdit = (props) => {
  const { user_role, callback } = props;
  const dispatch = useDispatch();
  const editRoleForm = useRef(null);
  const { token, branch, user } = useSelector((store) => store.auth);
  const [privileges, setPrivileges] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formData, setFormData] = useState({
    userRole: user_role.role_name,
    roleDescription: user_role.role_description,
    privileges: {},
  });

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const fd = new FormData(editRoleForm.current);
      fd.append("role_id", user_role.role_id);
      fd.append("created_by", user.user_id);
      fd.append("com_id", branch.com_id);
      fd.append("branch_id", branch.branch_id);
      const resp = await axios.post("users/edit-role", fd, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      autoNotify(dispatch, resp);

      setIsSubmitting(false);
      callback(resp?.data.role);
      dispatch(hidePrimaryModel());
    } catch (error) {
      autoNotify(dispatch, error.response);
      setIsSubmitting(false);
    }
  };

  const handleFormClose = () => {
    dispatch(hidePrimaryModel());
  };

  // on mount
  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const privilege_obj = await getPrivilegesList(token, branch, user_role);
      setPrivileges(privilege_obj);
      setIsLoading(false);
    };
    getData();
  }, [branch, token, user_role]);

  return (
    <div>
      <form
        ref={editRoleForm}
        id="create-new-role"
        action=""
        method="post"
        onSubmit={handleFormSubmit}
      >
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="role_name">Role Name</label>
              <input
                type="text"
                className="form-control"
                id="role_name"
                name="role_name"
                placeholder="Enter User Role Name"
                value={formData.userRole}
                onChange={(e) => {
                  setFormData((prevFormData) => {
                    return {
                      ...prevFormData,
                      userRole: e.target.value,
                    };
                  });
                }}
              />
            </div>
            <div className="form-group">
              <label htmlFor="role_description">Role Description</label>
              <input
                type="text"
                className="form-control"
                id="role_description"
                name="role_description"
                placeholder="Enter Description"
                value={formData.roleDescription}
                onChange={(e) => {
                  setFormData((prevFormData) => {
                    return {
                      ...prevFormData,
                      roleDescription: e.target.value,
                    };
                  });
                }}
              />
            </div>
          </div>
          <div className="col-md-6">
            <h6>Privileges</h6>
            <Scrollbars
              style={{ width: "100%", height: 300 }}
              className="privileges-content"
            >
              {isLoading !== true ? (
                Object.keys(privileges).map((key) => {
                  let permissions_list = privileges[key].map((permission) => {
                    return (
                      <li key={permission.privi_id}>
                        <CheckPermission permission={permission} />
                      </li>
                    );
                  });
                  return (
                    <div key={key} className="permission-section">
                      <h6 className="permission-title">{key}</h6>
                      <ul className="permission-list">{permissions_list}</ul>
                    </div>
                  );
                })
              ) : (
                <h6 className="permission-title">Loading...</h6>
              )}
            </Scrollbars>
          </div>
        </div>
        <div className="modal-footer mt-3">
          <button
            type="button"
            className="btn btn-secondary b-button"
            data-dismiss="modal"
            onClick={handleFormClose}
          >
            Close
          </button>
          <button
            type="submit"
            id="user-save-btn"
            className="btn btn-primary b-button"
            disabled={isSubmitting}
          >
            Save Role
          </button>
        </div>
      </form>
    </div>
  );
};

export default UserRoleEdit;
