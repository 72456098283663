import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SaleOrder from "../../../components/workplace/so/SaleOrder";
import {
  setEditSO,
  setNewSO,
} from "../../../redux/features/sales/SaleOrderSlice";

const SOEdit = () => {
  const params = useParams();
  const so_id = params.id;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { saleOrderList } = useSelector((store) => store.sale_order_list);

  const saleOrder = saleOrderList.find((so) => {
    return so.so_id === so_id;
  });

  /* this will dispatch edit PO */
  useEffect(() => {
    if (saleOrder && saleOrder.so_status === "Draft") {
      dispatch(setEditSO(saleOrder));
    } else {
      navigate("/dashboard/sales");
    }
    // call when unmount
    return () => {
      dispatch(setNewSO());
    };
  }, [dispatch, navigate, saleOrder]);

  return <SaleOrder action="Edit" />;
};
export default SOEdit;
