import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import AsyncSelect from "react-select/async";
import { debounce } from "../../../../Helper";
import axios from "../../../../api/axios";

/*select options */
const select_style = {
  control: (base) => ({
    ...base,
    height: 31,
    minHeight: 31,
    borderRadius: 3,
    border: "1px solid #d0d6db",
    boxShadow: "none",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: "31px",
    padding: "0 6px",
  }),
  input: (provided, state) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "31px",
  }),
  menuList: (provided) => ({
    ...provided,
  }),
  menu: (provided) => ({
    ...provided,
    width: "max-content",
    minWidth: "100%",
  }),
};

const getSaleOrderList = async (
  token,
  branch,
  start = 0,
  length = 10,
  order = "desc",
  search = "",
  cust_id = "0",
  so_status = "Confirmed"
) => {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append("branch_id", branch.branch_id);
    searchParams.append("start", start);
    searchParams.append("length", length);
    searchParams.append("order", order);
    searchParams.append("search", search);
    searchParams.append("cust_id", cust_id);
    searchParams.append("so_status", so_status);
    searchParams.toString();
    const response = await axios.get(
      "customers/so-list?" + searchParams.toString(),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    response.data.data = response.data.data.map((so) => {
      return {
        ...so,
        label: so.so_no,
        value: so.so_id,
      };
    });
    return response.data;
  } catch (error) {
    return [];
  }
};

const SelectSO = (props) => {
  const { value, customer, callback } = props;

  const { token, branch } = useSelector((store) => store.auth);
  //local stats
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  //events
  const loadListOptions = debounce(async (inputValue, callback) => {
    if (customer) {
      const options = await getSaleOrderList(
        token,
        branch,
        0,
        10,
        "desc",
        inputValue,
        customer?.cust_id,
        "Confirmed"
      );
      callback(options.data);
    }
  }, 500);

  const handleOnChange = (saleOrder) => {
    if (callback) {
      callback(saleOrder);
    }
  };

  //effect
  useEffect(() => {
    const unitData = async () => {
      setIsLoading(true);
      const list = await getSaleOrderList(
        token,
        branch,
        0,
        10,
        "desc",
        "",
        customer?.cust_id,
        "Confirmed"
      );
      setDefaultOptions(list.data);
      setIsLoading(false);
    };

    if (customer) {
      unitData();
    }
  }, [token, branch, customer]);

  return (
    <AsyncSelect
      cacheOptions
      defaultOptions={defaultOptions}
      loadOptions={loadListOptions}
      styles={select_style}
      onChange={handleOnChange}
      value={value}
      placeholder="Select.."
      isLoading={isLoading}
      isClearable={true}
      name="sale_order_id"
    />
  );
};
export default React.memo(SelectSO);
