import "./InvoiceList.scss";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import DataTable from "react-data-table-component";
import SearchBar from "../../../../components/searchbar/SearchBar";
import { debounce } from "../../../../Helper";
import TextLoader from "../../../../components/loaders/Loader";
import {
  hideRightPanel,
  showRightPanel,
} from "../../../../redux/features/rightpanel/RightPanelSlice";
import RightSidePanel from "../../../../components/panels/right/RightSidePanel";
import {
  getInvoiceDeletedList,
  updateInvoiceList,
} from "../../../../redux/features/sales/InvoiceListSlice";

import InvoiceListRight from "./InvoiceListRight";

/* set supplier list */
const columns = [
  {
    name: "Date",
    selector: (row) => row.sale_date,
    sortable: true,
    width: "10%",
  },
  {
    name: "Deleted At",
    selector: (row) => row.deleted_at,
    sortable: true,
    width: "15%",
  },
  {
    name: "Invoice#",
    selector: (row) => row.sale_no,
    sortable: true,
    cell: (row) => {
      return (
        <div className="tbl-so-no-col" data-tag="allowRowEvents">
          <h6 data-tag="allowRowEvents">{row.sale_no}</h6>
        </div>
      );
    },
    width: "15%",
  },
  {
    name: "Customer",
    selector: (row) => row?.customer.cust_id,
    sortable: true,
    cell: (row) => {
      return (
        <div className="tbl-customer-col" data-tag="allowRowEvents">
          <p data-tag="allowRowEvents">{row.customer?.cust_display_name}</p>
          {row.customer?.cust_address_street}, {row.customer?.cust_address_city}
          , {row.customer?.cust_address_country}
        </div>
      );
    },
  },
  {
    name: "Status",
    selector: (row) => row.invoice_status,
    sortable: true,
  },
];
const conditionalRowStyles = [
  {
    when: (row) => row.isSelected === true,
    style: {
      backgroundColor: "#e3f2fd",
      userSelect: "none",
    },
  },
];

const InvoiceDeletedList = () => {
  const dispatch = useDispatch();
  const { invoiceList, totalRows, isLoading } = useSelector(
    (store) => store.sale_invoice_list
  );
  const { isPanelShow } = useSelector((store) => store.right_panel);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  /*Events */
  const handleOnSearch = debounce((e) => {
    setSearch(e.target.value);
    dispatch(getInvoiceDeletedList({ search: e.target.value }));
  }, 600);

  const handlePageChange = (page) => {
    const start = (page - 1) * perPage;
    dispatch(
      getInvoiceDeletedList({ start: start, length: perPage, search: search })
    );
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    const start = (page - 1) * newPerPage;
    dispatch(
      getInvoiceDeletedList({
        start: start,
        length: newPerPage,
        search: search,
      })
    );
    setPerPage(newPerPage);
    setCurrentPage(page);
  };

  const handleOnSelect = (row) => {
    const updatedList = invoiceList.map((invoice) => {
      //unselect all
      if (row.sale_id !== invoice.sale_id) {
        return { ...invoice, isSelected: false };
      }
      //remove already selected one
      if (invoice?.isSelected === true) {
        dispatch(hideRightPanel());
        return { ...invoice, isSelected: false };
      }
      //selected current
      dispatch(
        showRightPanel({ content: <InvoiceListRight invoice={invoice} /> })
      );
      return { ...invoice, isSelected: true };
    });
    dispatch(updateInvoiceList(updatedList));
  };

  // on mount
  useEffect(() => {
    dispatch(hideRightPanel());
    dispatch(getInvoiceDeletedList({ search: "" }));
  }, [dispatch]);

  return (
    <div
      className={
        isPanelShow
          ? "container invoice-list show-right-side-panel"
          : "container invoice-list"
      }
    >
      <div className="action-table-wrapper">
        <div className="action-table-title">
          <h3>Invoice Deleted List</h3>
        </div>
        <div className="row">
          <div className="col-md-4 ">
            <SearchBar
              searchHandler={handleOnSearch}
              placeholder="Search Invoices here..."
            />
          </div>
        </div>

        <div className="action-table">
          <DataTable
            dense
            columns={columns}
            data={invoiceList}
            progressPending={isLoading}
            progressComponent={<TextLoader />}
            highlightOnHover
            onRowClicked={handleOnSelect}
            pagination
            paginationServer
            paginationPerPage={perPage}
            paginationRowsPerPageOptions={[10, 20, 50, 100, 200]}
            paginationTotalRows={totalRows}
            paginationDefaultPage={currentPage}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            conditionalRowStyles={conditionalRowStyles}
            className="list-table"
          />
        </div>
      </div>
      <RightSidePanel />
    </div>
  );
};
export default InvoiceDeletedList;
