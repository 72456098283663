import { memo, useState } from "react";
import SelectCountry from "./SelectCountry";

const FormBasic = (props) => {
  const { customer, changeCallback } = props;
  //local
  const [form, setForm] = useState(customer);

  //events
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
    changeCallback(name, value);
  };

  const handleSelectChange = (name, value) => {
    setForm({ ...form, [name]: value });
    changeCallback(name, value);
  };

  return (
    <div className="row">
      <div className="col-6">
        <div className="form-group mb-1">
          <label htmlFor="cust_display_name">Display Name *</label>
          <input
            type="text"
            className="form-control"
            id="cust_display_name"
            name="cust_display_name"
            value={form.cust_display_name}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-group mb-1 mt-3">
          <label htmlFor="cust_address_street">Address Street</label>
          <input
            type="text"
            className="form-control"
            id="cust_address_street"
            name="cust_address_street"
            value={form.cust_address_street}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-row">
          <div className="form-group col-md-6 mb-1">
            <label htmlFor="cust_address_city">City</label>
            <input
              type="text"
              className="form-control"
              id="cust_address_city"
              name="cust_address_city"
              value={form.cust_address_city}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group col-md-4 mb-1">
            <label htmlFor="cust_address_country">Country</label>
            <SelectCountry
              selectName={"cust_address_country"}
              onChange={handleSelectChange}
              value={form.cust_address_country}
            />
          </div>
          <div className="form-group col-md-2 mb-1">
            <label htmlFor="cust_address_postcode">Zip</label>
            <input
              type="text"
              className="form-control"
              id="cust_address_postcode"
              name="cust_address_postcode"
              value={form.cust_address_postcode}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>
      <div className="col-6">
        <div className="form-row">
          <div className="form-group col-md-10 mb-1">
            <label htmlFor="cust_phone">Phone</label>
            <input
              type="text"
              className="form-control"
              id="cust_phone"
              name="cust_phone"
              value={form.cust_phone}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-10 mb-1">
            <label htmlFor="cust_email">Email address</label>
            <input
              type="email"
              className="form-control"
              id="cust_email"
              placeholder="name@example.com"
              name="cust_email"
              value={form.cust_email}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(FormBasic);
