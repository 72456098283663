import "./Error404.scss";
import { useSelector } from "react-redux";
const Error404 = () => {
  const { user } = useSelector((store) => store.auth);
  return (
    <div className="container dashboard-error">
      <div className="row no-gutters">
        <div className="col-md-8">
          <div className="module-wrapper main-section shadow-sm">
            <h5 className="title">Error 404 :(</h5>
            <p>
              <strong>Sorry {user.username},</strong> I have nothing to do with
              your requested URL. Please contact your admin.
            </p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="module-wrapper side-section shadow-sm">
            <h6 className="title">News</h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error404;
