const ListRight = () => {
  return (
    <div>
      <h5 className="display-4 title">Customer Category</h5>
      <p>
        Customer is the one who uses the products and services and judges the
        quality of those products and services. Hence it’s important for an
        organization to retain customers or make new customers and flourish
        business.
      </p>
      <p>
        To manage customers, organizations should follow some sort of approaches
        like segmentation or division of customers into groups because each
        customer has to be considered valuable and profitable
      </p>
    </div>
  );
};

export default ListRight;
