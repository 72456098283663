import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars-2";
import { Dropdown } from "react-bootstrap";
import {
  PencilFill,
  CheckLg,
  Trash2Fill,
  FileTextFill,
  CashCoin,
} from "react-bootstrap-icons";
import { hasPrivilege } from "../../../../Helper";

import { showOptionModel } from "../../../../redux/features/modals/OptionModalSlide";
import { showPrimaryModel } from "../../../../redux/features/modals/PrimaryModalSlice";
import { hideRightPanel } from "../../../../redux/features/rightpanel/RightPanelSlice";
import { showPrintPreview } from "../../../../redux/features/modals/PrintPreviewSlice";
import { updateInvoiceList } from "../../../../redux/features/sales/InvoiceListSlice";

//import SOConfirm from "../SOConfirm";
import InvoiceDelete from "../InvoiceDelete";
import InvoiceProcess from "../InvoiceProcess";
import ReceivePayment from "../../payment/ReceivePayment";

const InvoiceListRight = (props) => {
  const [invoice, setInvoice] = useState(props.invoice);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { invoiceList } = useSelector((store) => store.sale_invoice_list);

  //set info
  const infoList = [
    {
      name: "Invoice Date",
      value: invoice.sale_date,
    },
    {
      name: "Sales Rep",
      value: invoice.sales_rep_name,
    },
    {
      name: "Order",
      value: invoice.sale_order?.so_no,
    },
  ];
  const info_list_elements = infoList.map((field) => {
    return (
      <div key={field.name} className="row no-gutters info-field">
        <div className="col-sm-5 name">
          <p>{field.name}</p>
        </div>
        <div className="col-sm-7 value">
          <p>: {field.value}</p>
        </div>
      </div>
    );
  });

  //action

  //handle view
  const handleInvoiceView = () => {
    const previewData = {
      title: "Sale Invoice",
      format: "SaleInvoice",
      data: invoice,
      printable: hasPrivilege("Sales", 209),
    };
    dispatch(showPrintPreview(previewData));
  };

  //handle SO Confirm
  const confirmCallback = (updated_invoice) => {
    const updatedData = invoiceList.map((list_invoice) => {
      if (updated_invoice.sale_id === list_invoice.sale_id) {
        return updated_invoice;
      }
      return list_invoice;
    });
    dispatch(updateInvoiceList(updatedData));
    setInvoice(updated_invoice);
  };
  const handleInvoiceProcess = () => {
    const modelData = {
      title: "Process Invoice",
      titleIcon: <FileTextFill />,
      content: (
        <InvoiceProcess invoice={invoice} confirm_callback={confirmCallback} />
      ),
    };
    dispatch(showOptionModel(modelData));
  };

  //handle edit
  const handleSaleEdit = () => {
    navigate("/dashboard/sales/invoice-edit/" + invoice.sale_id);
  };

  //handle Payments
  const paymentCallback = (updated_invoice) => {
    const updatedData = invoiceList.map((list_invoice) => {
      if (updated_invoice.sale_id === list_invoice.sale_id) {
        return updated_invoice;
      }
      return list_invoice;
    });
    dispatch(updateInvoiceList(updatedData));
    setInvoice(updated_invoice);
  };
  const handlePayments = () => {
    const modelData = {
      title: "Receive Payments - Invoice# " + invoice.sale_no,
      titleIcon: <CashCoin />,
      content: (
        <ReceivePayment invoice={invoice} payment_callback={paymentCallback} />
      ),
    };
    dispatch(showPrimaryModel(modelData));
  };

  //handler for delete
  const deleteCallback = (invoice_id) => {
    const updatedData = invoiceList.filter(
      (list_invoice) => invoice_id !== list_invoice.sale_id
    );
    dispatch(updateInvoiceList(updatedData));
    dispatch(hideRightPanel());
  };
  const handleDelete = () => {
    const modelData = {
      title: "Delete Sale Invoice",
      titleIcon: <FileTextFill />,
      content: (
        <InvoiceDelete invoice={invoice} delete_callback={deleteCallback} />
      ),
    };
    dispatch(showOptionModel(modelData));
  };

  //set props
  useEffect(() => {
    setInvoice(props.invoice);
  }, [props.invoice]);

  //locale ui component
  const PaymentBadge = () => {
    let style = { float: "right" };
    switch (invoice?.payment_status) {
      case "Unpaid":
        style = { float: "right", backgroundColor: "#ffd2bb" };
        break;
      case "Partial Payment":
        style = { float: "right", backgroundColor: "#bbffdf" };
        break;
      case "Paid":
        style = { float: "right", backgroundColor: "#c6ffbb" };
        break;
      default:
        style = { float: "right" };
    }
    return (
      <div className="badge-status" style={style}>
        {invoice?.payment_status}
      </div>
    );
  };

  return (
    <div className="right-content">
      <div className="header">
        <div className="header-img">
          <img src="/img/icons/invoice-lg.png" alt="side header" />
        </div>
        <div className="header-text">
          <h4>Invoice</h4>
          <p>Invoice# {invoice.sale_no}</p>
        </div>
      </div>

      <Scrollbars style={{ width: "100%", height: "100%" }}>
        <div className="info-section">
          <h6>Invoice Information</h6>
          <div className="row mb-2">
            <div className="col-sm-6">{info_list_elements}</div>
            <div className="col-sm-6">
              <table style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <td className="align-middle">Status</td>
                    <td className="text-right">
                      <div className="badge-status" style={{ float: "right" }}>
                        {invoice?.invoice_status}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="align-middle">Payment</td>
                    <td className="text-right">
                      <PaymentBadge />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <h6>Billing Address</h6>
              <p className="m-0">{invoice.customer_address}</p>
            </div>
            <div className="col-sm-6">
              <h6>Delivery Address</h6>
              <p className="m-0"></p>
            </div>
          </div>
        </div>

        <div className="trans-content">
          <h6>Invoice Items</h6>
          <table className="table table-sm table-hover">
            <thead>
              <tr>
                <th>Item</th>
                <th>Qty</th>
                <th style={{ textAlign: "right" }}>Rate</th>
                <th style={{ textAlign: "right" }}>Amount</th>
              </tr>
            </thead>
            <tbody>
              {invoice.sale_item_rows.map((row) => {
                return (
                  <tr key={row?.row_id}>
                    <td>
                      <p style={{ fontWeight: 600 }}>
                        {row.sale_item?.item_name}
                      </p>
                      <p>{row.sale_item_description}</p>
                    </td>
                    <td>{row.sale_item_qty}</td>
                    <td style={{ textAlign: "right" }}>{row.sale_item_rate}</td>
                    <td style={{ textAlign: "right" }}>
                      {row.sale_item_amount}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Scrollbars>
      <div className="action-section">
        <div className="action-control">
          {hasPrivilege("Sales", 206) && (
            <button
              className="btn btn-dark b-button"
              onClick={handleInvoiceView}
            >
              View
            </button>
          )}
          {hasPrivilege("Sales", 210) && invoice.invoice_status === "Draft" ? (
            <button
              className="btn btn-primary b-button"
              onClick={handleInvoiceProcess}
            >
              <CheckLg /> Process
            </button>
          ) : null}
          {hasPrivilege("Sales", 211) ? (
            <button
              className="btn btn-primary b-button"
              onClick={handlePayments}
            >
              <CashCoin /> Payments
            </button>
          ) : null}

          <Dropdown>
            <Dropdown.Toggle
              variant="secondary"
              id="more-action"
              className="b-button"
            >
              More
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ margin: 0 }}>
              {hasPrivilege("Sales", 208) &&
              invoice.invoice_status === "Draft" ? (
                <Dropdown.Item eventKey="2" onClick={handleSaleEdit}>
                  <PencilFill /> Edit Invoice
                </Dropdown.Item>
              ) : null}
              {hasPrivilege("Sales", 211) && (
                <Dropdown.Item eventKey="3" onClick={handleDelete}>
                  <Trash2Fill /> Delete
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};
export default InvoiceListRight;
