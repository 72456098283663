import "./ReceivePayment.scss";
import { useState, useCallback } from "react";
import Moment from "moment";
import DatePicker from "react-datepicker";
import { useSelector, useDispatch } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars-2";
import axios from "../../../api/axios";
import SelectPayMethod from "../../../components/form/select/SelectPayMethod";
import SelectAssetAccount from "../../../components/form/select/SelectAssetAccount";
import { hidePrimaryModel } from "../../../redux/features/modals/PrimaryModalSlice";
import { autoNotify } from "../../../redux/features/notify/NotifySlice";
import PayHistoryList from "./PayHistoryList";

/**
 * This will return next due date
 * @returns
 */
const setNextDueDate = () => {
  const date = new Date();
  date.setDate(date.getDate() + 30);
  return Moment(date).format("YYYY-MM-DD");
};

const ReceivePayment = (props) => {
  const { payment_callback } = props;
  const dispatch = useDispatch();
  //Global
  const { token, user } = useSelector((store) => store.auth);
  //local
  const [payMethod, setPayMethod] = useState(null);
  const [payAccount, setPayAccount] = useState(null);
  const [invoice, setInvoice] = useState(props.invoice);
  const [payMade, setPayMade] = useState(invoice.sale_payment);
  const [balance, setBalance] = useState(invoice.sale_balance);

  const [isDue, setIsDue] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [donePayment, setDonePayment] = useState();

  const [payment, setPayment] = useState({
    pay_id: null,
    sale_id: invoice.sale_id,
    paid_date: Moment(new Date()).format("YYYY-MM-DD"),
    paid_amount: 0,
    payment_method_id: null,
    pay_account_id: null,
    payment_due: setNextDueDate(),
    payment_note: "",
    payment_ref: "",
    created_by: user?.user_id,
  });

  /**
   * This will calculate the invoice balance
   * @param {float} paid
   * @returns {float} balance
   */
  const calculateBalance = (paid) => {
    let invoice_total = parseFloat(invoice.sale_amount);
    let balance = invoice_total - parseFloat(paid);
    return balance;
  };

  //events
  const receiveOnChange = (e) => {
    const { value } = e.target;
    let receiveAmount = parseFloat(value) || 0;
    let totalReceive = receiveAmount + parseFloat(invoice.sale_payment);
    setPayMade(totalReceive);
    let balance = calculateBalance(totalReceive);
    setBalance(balance);
    balance > 0 ? setIsDue(true) : setIsDue(false);
    //
    setPayment((prevData) => {
      return {
        ...prevData,
        paid_amount: value,
      };
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPayment((prevData) => {
      return { ...prevData, [name]: value };
    });
  };

  const handleDateChange = (date, name) => {
    if (date) {
      let formatted_date = Moment(date).format("YYYY-MM-DD");
      setPayment((prevData) => {
        return { ...prevData, [name]: formatted_date };
      });
    }
  };

  const payMethodHandler = useCallback((option) => {
    setPayMethod(option);
    setPayment((prevData) => {
      return {
        ...prevData,
        payment_method_id: option?.method_id,
      };
    });
  }, []);

  const payAccountHandler = useCallback((option) => {
    setPayAccount(option);
    setPayment((prevData) => {
      return {
        ...prevData,
        pay_account_id: option?.acc_id,
      };
    });
  }, []);

  //reset ui for new payment
  const newPayment = () => {
    setPayment((prevData) => {
      return {
        ...prevData,
        paid_date: Moment(new Date()).format("YYYY-MM-DD"),
        paid_amount: 0,
        payment_due: setNextDueDate(),
        payment_note: "",
        payment_ref: "",
      };
    });
  };

  //action
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    //update invoice
    let updated_invoice = {
      ...invoice,
      sale_payment: payMade,
      sale_balance: balance,
      payment_status: balance > 0 ? "Partial Payment" : "Paid",
    };
    let post_data = {
      payment: payment,
      invoice: updated_invoice,
    };
    try {
      const resp = await axios.post("customers/receive-payment", post_data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      autoNotify(dispatch, resp);
      setIsSubmitting(false);
      //update history
      if (resp.data?.payment) {
        setDonePayment(resp.data.payment);
        setInvoice(updated_invoice);
        payment_callback(updated_invoice);
      }
      //set form to new
      newPayment();
    } catch (error) {
      autoNotify(dispatch, error.response);
      setIsSubmitting(false);
    }
  };

  const handleFormClose = () => {
    dispatch(hidePrimaryModel());
  };

  return (
    <div className="receive-payment">
      <div className="row">
        <div className="col-6">
          <div className="invoice-info">
            <table>
              <tbody>
                <tr>
                  <td className="invoice-info-label">Invoice Amount</td>
                  <td className="invoice-info-label"> : </td>
                  <td className="invoice-info-value">{invoice?.sale_amount}</td>
                </tr>
                <tr>
                  <td className="invoice-info-label">Payments Made</td>
                  <td className="invoice-info-label"> : </td>
                  <td className="invoice-info-value">
                    {parseFloat(payMade).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td className="invoice-info-label">Balance Due</td>
                  <td className="invoice-info-label"> : </td>
                  <td className="invoice-info-value">
                    {parseFloat(balance).toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <h6>Payment History</h6>
          <Scrollbars style={{ width: "100%", height: "230px" }}>
            <PayHistoryList invoice={invoice} payment={donePayment} />
          </Scrollbars>
        </div>
        <div className="col-6">
          <h6>New Payment Transaction</h6>
          <form
            method="post"
            className="receive-payment-form"
            onSubmit={handleFormSubmit}
          >
            <div className="form-row">
              <div className="form-group col-md-6 mb-1">
                <label htmlFor="paid_date">Payment Date</label>
                <DatePicker
                  className="form-control"
                  dateFormat="yyyy-MM-dd"
                  id="paid_date"
                  autoComplete="off"
                  selected={Date.parse(payment.paid_date)}
                  onChange={(date) => {
                    handleDateChange(date, "paid_date");
                  }}
                  name="paid_date"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6 mb-1">
                <label htmlFor="warehouse_id">Payment Method</label>
                <SelectPayMethod
                  method_name="Cash"
                  selected={payMethod}
                  callback={payMethodHandler}
                />
              </div>
              <div className="form-group col-md-6 mb-1">
                <label htmlFor="warehouse_id">Deposit To</label>
                <SelectAssetAccount
                  account_no="10003"
                  selected={payAccount}
                  callback={payAccountHandler}
                />
              </div>
            </div>
            <div className="cal-wrapper">
              <div className="form-group row">
                <label
                  className="col-sm-6 col-form-label"
                  htmlFor="paid_amount"
                >
                  Received Amount
                </label>
                <div className="col-sm-6">
                  <input
                    type="number"
                    className="form-control"
                    id="paid_amount"
                    name="paid_amount"
                    value={payment.paid_amount}
                    onChange={receiveOnChange}
                    min="0"
                  />
                </div>
              </div>
              {isDue && (
                <div className="form-group row">
                  <label
                    className="col-sm-6 col-form-label"
                    htmlFor="payment_due"
                  >
                    Next Due Date
                  </label>
                  <div className="col-sm-6">
                    <DatePicker
                      className="form-control"
                      dateFormat="yyyy-MM-dd"
                      id="payment_due"
                      autoComplete="off"
                      selected={Date.parse(payment.payment_due)}
                      onChange={(date) => {
                        handleDateChange(date, "payment_due");
                      }}
                      name="payment_due"
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="form-row">
              <div className="form-group col-md-6 mb-1">
                <label htmlFor="payment_ref">Reference #</label>
                <input
                  type="text"
                  className="form-control"
                  id="payment_ref"
                  name="payment_ref"
                  value={payment.payment_ref}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group col-md-6 mb-1">
                <label htmlFor="payment_note">Notes</label>
                <textarea
                  className="form-control"
                  id="terms"
                  rows="3"
                  name="payment_note"
                  value={payment.payment_note}
                  onChange={handleInputChange}
                ></textarea>
              </div>
            </div>
          </form>
        </div>
        <div className="col-12 mt-3">
          <button
            id="supp-create-btn"
            className="btn btn-primary float-right save-button ml-2"
            type="submit"
            disabled={isSubmitting}
            onClick={handleFormSubmit}
          >
            Save Payment
          </button>
          <button
            id="supp-close-btn"
            type="button"
            className="btn btn-secondary float-right "
            data-dismiss="modal"
            onClick={handleFormClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReceivePayment;
