import "./Notify.scss";
import { InfoCircleFill } from "react-bootstrap-icons";
import { ExclamationDiamondFill } from "react-bootstrap-icons";
import { CheckCircleFill } from "react-bootstrap-icons";
import { X } from "react-bootstrap-icons";
import { useSelector, useDispatch } from "react-redux";
import { hideNotify } from "../../../redux/features/notify/NotifySlice";

const getIcon = (type) => {
  switch (type) {
    case "error":
      return <ExclamationDiamondFill className="notify-icon error" />;
    case "success":
      return <CheckCircleFill className="notify-icon success" />;
    default:
      return <InfoCircleFill className="notify-icon info" />;
  }
};

const Notify = () => {
  const { type, title, messages } = useSelector((store) => store.notify);

  let message_list = null;
  message_list = messages.map((item, i) => <li key={i}>{item}</li>);
  const dispatch = useDispatch();

  return (
    <div className="notify-wrapper">
      <div className="notify-header">
        {getIcon(type)}
        <h4 className="notify-title">{title}</h4>
        <button className="close-btn" onClick={() => dispatch(hideNotify())}>
          <X />
        </button>
      </div>
      <div className="notify-content">
        <ul>{message_list}</ul>
      </div>
    </div>
  );
};

export default Notify;
