import "./style.scss";
import { memo } from "react";
import { XLg } from "react-bootstrap-icons";

const RightSidePanel = (props) => {
  const { hideSidePanel, content } = props;

  return (
    <div className="right-side-panel">
      <div className="close-button">
        <button type="button" className="close" onClick={hideSidePanel}>
          <XLg />
        </button>
      </div>
      {content}
    </div>
  );
};

export default memo(RightSidePanel);
