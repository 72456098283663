import { Link } from "react-router-dom";
import classNames from "classnames";
import { hasPrivilege } from "../../../../Helper";

const HomeTab = (props) => {
  return (
    <div className="row no-gutters">
      <div className="col-md-auto ">
        <div className="toolbar-section">
          <div className="row no-gutters">
            <div className="col-auto">
              <Link className="btn toolbar-button" to="/dashboard">
                <img src="/img/icons/home.png" alt="dashboard" />
                <span className="text">Dashboard</span>
              </Link>
            </div>
            <div className="col-auto"></div>
          </div>
        </div>
      </div>
      <div className="col-md-auto">
        <div className="toolbar-section">
          <div className="row no-gutters">
            <div className="col-auto">
              <Link
                className={classNames("btn toolbar-button", {
                  disabled: !hasPrivilege("Sales", 207),
                })}
                to="/dashboard/sales/invoice-new"
              >
                <img src="/img/icons/invoice.png" alt="invoice" />
                <span className="text">New Invoice</span>
              </Link>
            </div>
            <div className="col-auto">
              <Link
                className={classNames("btn toolbar-button-inline", {
                  disabled: !hasPrivilege("Sales", 212),
                })}
                to="/dashboard/sales/new-invoice"
              >
                <img src="/img/icons/receive-cash.png" alt="receive cash" />
                <span className="text">Receive Payment </span>
              </Link>
              <Link
                className="btn toolbar-button-inline disabled"
                to="/customers/sale-recept"
              >
                <img src="/img/icons/receipt.png" alt="sale recept" />
                <span className="text"> Sale Receipt </span>
              </Link>
            </div>
            <div className="col-auto">
              <Link
                className={classNames("btn toolbar-button-inline", {
                  disabled: !hasPrivilege("Sales", 201),
                })}
                to="/dashboard/sales/so-new"
              >
                <img src="/img/icons/quotation.png" alt="new quotation" />
                <span className="text">New Quotation </span>
              </Link>
              <Link
                className="btn toolbar-button-inline disabled"
                to="/customers/refund"
              >
                <img src="/img/icons/refund.png" alt="refund" />
                <span className="text"> Refund </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-auto">
        <div className="toolbar-section">
          <div className="row no-gutters">
            <div className="col-auto">
              <Link
                className={classNames("btn toolbar-button-inline", {
                  disabled: !hasPrivilege("Purchases", 301),
                })}
                to="/dashboard/purchases/po-new"
              >
                <img src="/img/icons/purchase-order.png" alt="new purchase" />
                <span className="text">New Purchase Order </span>
              </Link>
              <Link
                className={classNames("btn toolbar-button-inline", {
                  disabled: !hasPrivilege("Purchases", 307),
                })}
                to="//dashboard/receive-inventory"
              >
                <img src="/img/icons/receive-inventory.png" alt="sale recept" />
                <span className="text"> Receive Inventory </span>
              </Link>
            </div>
            <div className="col-auto">
              <Link
                className={classNames("btn toolbar-button-inline", {
                  disabled: !hasPrivilege("Purchases", 313),
                })}
                to="/dashboard/new-purchase"
              >
                <img src="/img/icons/quotation.png" alt="new purchase" />
                <span className="text">Enter New Bill </span>
              </Link>
              <Link
                className={classNames("btn toolbar-button-inline", {
                  disabled: !hasPrivilege("Purchases", 317),
                })}
                to="/dashboard/pay-bill"
              >
                <img src="/img/icons/pay-bill.png" alt="pay bill" />
                <span className="text"> Pay Bill </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-auto ">
        <div className="toolbar-section">
          <div className="row no-gutters">
            <div className="col-auto">
              <Link
                className={classNames("btn toolbar-button", {
                  disabled: !hasPrivilege("Inventory", 401),
                })}
                to="/dashboard/inventory/add-inventory"
              >
                <img src="/img/icons/new-product.png" alt="products" />
                <span className="text">Add Inventory</span>
              </Link>
            </div>
            <div className="col-auto">
              <Link
                className={classNames("btn toolbar-button-inline", {
                  disabled: !hasPrivilege("Inventory", 407),
                })}
                to="/dashboard/inventory/add-service"
              >
                <img src="/img/icons/service.png" alt="add service" />
                <span className="text"> Add Service </span>
              </Link>
              <Link
                className={classNames("btn toolbar-button-inline", {
                  disabled: !hasPrivilege("Inventory", 410),
                })}
                to="/dashboard/inventory/add-stock"
              >
                <img
                  src="/img/icons/inventory-adjustments.png"
                  alt="add new stock"
                />
                <span className="text"> Add New Stock </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeTab;
