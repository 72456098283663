import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import CustomerService from "../../services/customers/CustomerService";

const initialState = {
  customers: [],
  recordsFiltered: 0,
  recordsTotal: 0,
  isLoading: false,
  isSubmitting: false,
  error: null,
  success: null,
};

export const getCustomers = createAsyncThunk(
  "customer/getCustomers",
  async ({ start = 0, length = 10, order = "desc", search = "" }, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const params = {
        branch_id: auth.branch.branch_id,
        start: start,
        length: length,
        order: order,
        search: search,
      };
      const response = await CustomerService.getCustomers(auth.token, params);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const createCustomer = createAsyncThunk(
  "customer/createCustomer",
  async (customer, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const { token, branch, user } = auth;
      const data = {
        ...customer,
        branch_id: branch.branch_id,
        created_by: user.user_id,
      };
      return await CustomerService.createCustomer(token, data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const editCustomer = createAsyncThunk(
  "customer/editCustomer",
  async (customer, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const { token, branch, user } = auth;
      const data = {
        ...customer,
        branch_id: branch.branch_id,
        created_by: user.user_id,
      };
      return await CustomerService.updateCustomer(token, data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteCustomer = createAsyncThunk(
  "customer/deleteCustomer",
  async (id, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const { token, user } = auth;
      return await CustomerService.deleteCustomer(token, id, user.user_id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    updateCustomers(state, action) {
      state.customers = action.payload;
    },
    resetCustomer(state, action) {
      state.isSubmitting = false;
      state.success = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      //get
      .addCase(getCustomers.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getCustomers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.customers = action.payload.data;
        state.recordsFiltered = action.payload.recordsFiltered;
        state.recordsTotal = action.payload.recordsTotal;
      })
      .addCase(getCustomers.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })

      //create
      .addCase(createCustomer.pending, (state) => {
        state.isSubmitting = true;
        state.error = null;
        state.success = null;
      })
      .addCase(createCustomer.fulfilled, (state, action) => {
        state.isSubmitting = false;
        state.error = null;
        const customer = action.payload.data.customer;
        state.customers.unshift(customer);
        state.success = action.payload;
      })
      .addCase(createCustomer.rejected, (state, action) => {
        state.isSubmitting = false;
        state.success = null;
        state.error = action.payload;
      })

      //update
      .addCase(editCustomer.pending, (state) => {
        state.isSubmitting = true;
        state.error = null;
        state.success = null;
      })
      .addCase(editCustomer.fulfilled, (state, action) => {
        state.isSubmitting = false;
        state.error = null;
        const customer = action.payload.data.customer;
        state.customers = state.customers.map((item) => {
          if (item.cust_id === customer.cust_id) {
            return customer;
          }
          return item;
        });
        state.success = action.payload;
      })
      .addCase(editCustomer.rejected, (state, action) => {
        state.isSubmitting = false;
        state.success = null;
        state.error = action.payload;
      })

      //delete
      .addCase(deleteCustomer.pending, (state) => {
        state.isSubmitting = true;
        state.error = null;
        state.success = null;
      })
      .addCase(deleteCustomer.fulfilled, (state, action) => {
        state.isSubmitting = false;
        state.error = null;
        const cust_id = action.payload.data.cust_id;
        state.customers = state.customers.filter(
          (customer) => customer.cust_id !== cust_id
        );
        state.success = action.payload;
      })
      .addCase(deleteCustomer.rejected, (state, action) => {
        state.isSubmitting = false;
        state.success = null;
        state.error = action.payload;
      });
  },
});

export const { updateCustomers, resetCustomer } = customerSlice.actions;
export default customerSlice.reducer;
