import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../api/axios";

const initialState = {
  poId: "",
  poNo: "",
  poDate: "",
  poStatus: "Draft",
  poExpectedDeliveryDate: "",
  supplier: null,
  supplierAddress: "",
  deliveryType: "warehouse",
  warehouse: null,
  customer: null,
  deliveryAddress: "",
  tableRowCounter: 0,
  tableRows: [],
  subTotal: 0,
  discount: "",
  discountValue: 0,
  tax: {
    tax_id: "1",
    tax_name: "None",
    tax_rate: "0",
    label: "None",
    value: "1",
  },
  taxValue: 0,
  poTotal: 0,
  reference: "",
  note: "",
  terms: "",
  isLoading: true,
};

//get PO Number
export const getPONumber = createAsyncThunk(
  "purchases/PONumber",
  async (data, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const token = auth.token;
      const branch = auth.branch;

      const searchParams = new URLSearchParams();
      searchParams.append("branch_id", branch.branch_id);
      searchParams.toString();

      const response = await axios.get(
        "purchase/po-no?" + searchParams.toString(),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const PurchaseOrderSlice = createSlice({
  name: "purchase_order",
  initialState,
  reducers: {
    setNewPO: () => initialState,
    setEditPO: (state, action) => {
      const po = action.payload;
      state.poId = po.po_id;
      state.poNo = po.po_no;
      state.poDate = po.po_date;
      state.poStatus = po.po_status;
      state.poExpectedDeliveryDate = po.ex_delivery_date;
      state.supplier = {
        ...po.supplier,
        label: po.supplier.supp_display_name,
        value: po.supplier.supp_id,
      };
      state.supplierAddress = po.supplier_address;
      state.deliveryType = po.deliver_location_type;
      if (state.deliveryType === "warehouse") {
        state.warehouse = {
          ...po.deliver_location,
          label: po.deliver_location.ware_name,
          value: po.deliver_location.ware_id,
        };
      } else {
        state.customer = {
          ...po.deliver_location,
          label: po.deliver_location.cust_display_name,
          value: po.deliver_location.cust_id,
        };
      }
      state.deliveryAddress = po.deliver_address;
      state.tableRows = po.po_item_rows.map((po_row) => {
        state.tableRowCounter++;
        return {
          rowID: po_row.row_id,
          item: {
            ...po_row.po_item,
            value: po_row.po_item.batch_id,
            label: po_row.po_item.item_sku,
          },
          itemDes: po_row.po_item_description,
          quantity: po_row.po_item_qty,
          rate: po_row.po_item_rate,
          amount: po_row.po_item_amount,
        };
      });
      state.discount = po.po_discount;
      if (po.po_tax !== null) {
        state.tax = {
          ...po.po_tax,
          value: po.po_tax.tax_id,
          label: po.po_tax.tax_name,
        };
      } else {
        state.tax = initialState.tax;
      }
      state.reference = po.po_reference;
      state.note = po.supplier_note;
      state.terms = po.po_terms;
    },
    setPOStatus: (state, action) => {
      state.poStatus = action.payload;
    },
    setPONumber: (state, action) => {
      state.poNo = action.payload;
    },
    setPODate: (state, action) => {
      state.poDate = action.payload;
    },
    setExpectedDeliveryDate: (state, action) => {
      state.poExpectedDeliveryDate = action.payload;
    },
    setPOSupplier: (state, action) => {
      const supplier = action.payload;
      let address = supplier.supp_first_name
        ? "Attn: " +
          supplier.supp_first_name +
          " " +
          supplier.supp_last_name +
          ",\n"
        : "";
      address += supplier.supp_company ? supplier.supp_company + ",\n" : "";
      address += supplier.supp_address_street
        ? supplier.supp_address_street + ",\n"
        : "";
      address += supplier.supp_address_city
        ? supplier.supp_address_city + ", "
        : "";
      address += supplier.supp_address_country
        ? supplier.supp_address_country + ". "
        : "";
      address += supplier.supp_address_postcode
        ? supplier.supp_address_postcode
        : "";

      state.supplier = supplier;
      state.supplierAddress = address;
    },
    setPODeliveryType: (state, action) => {
      state.deliveryType = action.payload;
    },
    setPODeliveryTo: (state, action) => {
      const delivery_obj = action.payload;

      if (state.deliveryType === "warehouse") {
        let address = delivery_obj.ware_name + ",\n";
        address += delivery_obj.ware_address_street
          ? delivery_obj.ware_address_street + ",\n"
          : "";
        address += delivery_obj.ware_address_city
          ? delivery_obj.ware_address_city + ",\n"
          : "";
        address += delivery_obj.ware_address_counrty
          ? delivery_obj.ware_address_counrty + ". "
          : "";
        address += delivery_obj.ware_address_postcode
          ? delivery_obj.ware_address_postcode
          : "";
        state.warehouse = delivery_obj;
        state.deliveryAddress = address;
      } else {
        let address = delivery_obj.cust_first_name
          ? "Attn: " +
            delivery_obj.cust_first_name +
            " " +
            delivery_obj.cust_last_name +
            ",\n"
          : "";
        address += delivery_obj.cust_company
          ? delivery_obj.cust_company + ",\n"
          : "";
        address += delivery_obj.cust_address_street
          ? delivery_obj.cust_address_street + ",\n"
          : "";
        address += delivery_obj.cust_address_city
          ? delivery_obj.cust_address_city + ", "
          : "";
        address += delivery_obj.cust_address_country
          ? delivery_obj.cust_address_country + ". "
          : "";
        address += delivery_obj.cust_address_postcode
          ? delivery_obj.cust_address_postcode
          : "";

        state.customer = delivery_obj;
        state.deliveryAddress = address;
      }
    },
    setPOSupplierAddress: (state, action) => {
      state.supplierAddress = action.payload;
    },
    setPODeliveryAddress: (state, action) => {
      state.deliveryAddress = action.payload;
    },
    newTableRow: (state, action) => {
      state.tableRowCounter++;
      state.tableRows.push({
        rowID: state.tableRowCounter,
        item: null,
        itemDes: "",
        quantity: 0,
        rate: 0,
        amount: 0,
      });
    },
    updateTableRow: (state, action) => {
      let editedRow = action.payload;
      const newArray = state.tableRows.map((row) => {
        if (row.rowID === editedRow.rowID) {
          return editedRow;
        }
        return row;
      });
      state.tableRows = newArray;
    },
    deleteTableRow: (state, action) => {
      let deletedRow = action.payload;
      const newArray = state.tableRows.filter((row) => {
        return row.rowID !== deletedRow.rowID;
      });
      state.tableRows = newArray;
    },
    setSubTotal: (state, action) => {
      state.subTotal = action.payload;
    },
    setDiscount: (state, action) => {
      state.discount = action.payload;
    },
    setDiscountValue: (state, action) => {
      state.discountValue = action.payload;
    },
    setPOTax: (state, action) => {
      state.tax = action.payload;
    },
    setPOTaxValue: (state, action) => {
      state.taxValue = action.payload;
    },
    setPOTotal: (state, action) => {
      state.poTotal = action.payload;
    },
    setReference: (state, action) => {
      state.reference = action.payload;
    },
    setNote: (state, action) => {
      state.note = action.payload;
    },
    setTerms: (state, action) => {
      state.terms = action.payload;
    },
  },
  extraReducers: (builder) => {
    //
    builder.addCase(getPONumber.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPONumber.fulfilled, (state, action) => {
      state.isLoading = false;
      state.poNo = action.payload.new_po_no;
    });
    builder.addCase(getPONumber.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const {
  setNewPO,
  setEditPO,
  setPONumber,
  setPOStatus,
  setPODate,
  setExpectedDeliveryDate,
  setPOSupplier,
  setPODeliveryType,
  setPODeliveryTo,
  setPOSupplierAddress,
  setPODeliveryAddress,
  newTableRow,
  updateTableRow,
  deleteTableRow,
  setSubTotal,
  setDiscount,
  setDiscountValue,
  setPOTax,
  setPOTaxValue,
  setPOTotal,
  setReference,
  setNote,
  setTerms,
} = PurchaseOrderSlice.actions;
export default PurchaseOrderSlice.reducer;
