import { Link } from "react-router-dom";
import classNames from "classnames";
import { hasPrivilege } from "../../../../Helper";

const AccountsTab = (props) => {
  return (
    <div className="row no-gutters">
      <div className="col-md-auto ">
        <div className="toolbar-section">
          <div className="row no-gutters">
            <div className="col-auto">
              <Link
                className={classNames("btn toolbar-button", {
                  disabled: !hasPrivilege("Accounts", 800),
                })}
                to="/dashboard/accounts"
              >
                <img src="/img/icons/accounts-list.png" alt="Journal Report" />
                <span className="text">Journal Report</span>
              </Link>
            </div>
            <div className="col-auto"></div>
          </div>
        </div>
      </div>
      <div className="col-md-auto">
        <div className="toolbar-section">
          <div className="row no-gutters">
            <div className="col-auto">
              <Link
                className="btn toolbar-button"
                to="/dashboard/accounts/journal"
              >
                <img src="/img/icons/journal.png" alt="invoice" />
                <span className="text">New Invoice</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountsTab;
