import { Link } from "react-router-dom";
import classNames from "classnames";
import { hasPrivilege } from "../../../../Helper";

const PurchasesTab = (props) => {
  return (
    <div className="row no-gutters">
      <div className="col-md-auto">
        <div className="toolbar-section">
          <div className="row no-gutters">
            <div className="col-auto">
              <Link
                className={classNames("btn toolbar-button", {
                  disabled: !hasPrivilege("Purchases", 300),
                })}
                to="/dashboard/purchases"
                title="Purchase Orders"
              >
                <img src="/img/icons/purchase-orders.png" alt="Purchases" />
                <span className="text">PO List</span>
              </Link>
            </div>
            <div className="col-auto">
              <Link
                className={classNames("btn toolbar-button-inline", {
                  disabled: !hasPrivilege("Purchases", 301),
                })}
                to="/dashboard/purchases/po-new"
                title="Create Purchase Order"
              >
                <img src="/img/icons/purchase-order.png" alt="new purchase" />
                <span className="text">Create PO </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-auto">
        <div className="toolbar-section">
          <div className="row no-gutters">
            <div className="col-auto">
              <Link
                className={classNames("btn toolbar-button", {
                  disabled: !hasPrivilege("Purchases", 306),
                })}
                to="/dashboard/purchases/grn-list"
                title="Goods Received Notes"
              >
                <img src="/img/icons/grn.png" alt="bill list" />
                <span className="text">GRN List</span>
              </Link>
            </div>
            <div className="col-auto">
              <Link
                className={classNames("btn toolbar-button-inline", {
                  disabled: !hasPrivilege("Purchases", 307),
                })}
                to="/dashboard/purchases/grn-new"
                title="Create Goods Received Note"
              >
                <img src="/img/icons/new-grn.png" alt="new purchase" />
                <span className="text">Create GRN </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-auto">
        <div className="toolbar-section">
          <div className="row no-gutters">
            <div className="col-auto">
              <Link
                className={classNames("btn toolbar-button", {
                  disabled: !hasPrivilege("Purchases", 312),
                })}
                to="/dashboard/purchases/bill-list"
              >
                <img src="/img/icons/purchase-orders.png" alt="bill list" />
                <span className="text">Bill List</span>
              </Link>
            </div>
            <div className="col-auto">
              <Link
                className={classNames("btn toolbar-button-inline", {
                  disabled: !hasPrivilege("Purchases", 313),
                })}
                to="/dashboard/new-purchase"
              >
                <img src="/img/icons/quotation.png" alt="new purchase" />
                <span className="text">Enter New Bill </span>
              </Link>
              <Link
                className={classNames("btn toolbar-button-inline", {
                  disabled: !hasPrivilege("Purchases", 317),
                })}
                to="/dashboard/pay-bill"
              >
                <img src="/img/icons/pay-bill.png" alt="pay bill" />
                <span className="text"> Pay Bill </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchasesTab;
