import "./SaleOrder.scss";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";

import SaleOrderCtrl from "./SaleOrderCtrl";
import WorkplaceTable from "./WorkplaceTable";
import SelectPaymentTerms from "../../form/select/SelectPaymentTerms";

import {
  setSONumber,
  setSODate,
  setSOSaleRepName,
  setExpectedDeliveryDate,
  setSOBillingAddress,
  setReference,
  setNote,
  setTerms,
  setPaymentTerms,
  setPaymentDue,
} from "../../../redux/features/sales/SaleOrderSlice";

const SaleOrder = (prop) => {
  const dispatch = useDispatch();
  const action = prop.action;

  const {
    soNo,
    soDate,
    saleRepName,
    soExpectedDeliveryDate,
    billingAddress,
    reference,
    note,
    terms,
    paymentTerm,
    paymentDue,
  } = useSelector((store) => store.sale_order);

  /*change handlers */
  const soNoOnChange = (e) => {
    const { value } = e.target;
    dispatch(setSONumber(value));
  };

  const soDateOnChange = (date) => {
    //console.log(Date.parse("2022-10-30"));
    dispatch(setSODate(date));
  };

  const soSaleRepNameOnChange = (e) => {
    const { value } = e.target;
    dispatch(setSOSaleRepName(value));
  };

  const soExpectedDeliveryDateOnChange = (date) => {
    dispatch(setExpectedDeliveryDate(date));
  };

  const billingAddressOnChange = (e) => {
    const { value } = e.target;
    dispatch(setSOBillingAddress(value));
  };

  const referenceOnChange = (e) => {
    const { value } = e.target;
    dispatch(setReference(value));
  };

  const noteOnChange = (e) => {
    const { value } = e.target;
    dispatch(setNote(value));
  };

  const termsOnChange = (e) => {
    const { value } = e.target;
    dispatch(setTerms(value));
  };

  const paymentDueOnChange = (date) => {
    dispatch(setPaymentDue(date));
  };

  const paymentTermChangeHandler = useCallback(
    (option) => {
      dispatch(setPaymentTerms(option));
    },
    [dispatch]
  );

  return (
    <div className="new-purchase">
      <div className="doc-editor">
        <div className="doc-editor-workplace">
          <div className="workplace-header">
            <div className="row no-gutters">
              <div className="col-md-5">
                <div className="form-row">
                  <div className="form-group col-md-6  mb-0">
                    <label htmlFor="so-no">Sale Order#</label>
                    <input
                      type="text"
                      className="form-control"
                      name="so-no"
                      id="so-no"
                      value={soNo}
                      onChange={soNoOnChange}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-5 mb-0">
                    <label htmlFor="so-date">Date</label>
                    <DatePicker
                      className="form-control"
                      dateFormat="yyyy-MM-dd"
                      id="so-date"
                      name="so-date"
                      autoComplete="off"
                      selected={Date.parse(soDate)}
                      onChange={soDateOnChange}
                    />
                  </div>
                  <div className="form-group col-md-5 mb-1">
                    <label htmlFor="so-date-expect">Sales Rep</label>
                    <input
                      type="text"
                      className="form-control"
                      name="sale-rep"
                      id="sale-rep"
                      value={saleRepName}
                      onChange={soSaleRepNameOnChange}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="row no-gutters">
                  <div className="col-md-5 pl-2"></div>
                  <div className="col-md-7 pl-2">
                    <div className="form-group">
                      <label htmlFor="billing_address">Billing Address</label>
                      <textarea
                        className="form-control"
                        id="billing_address"
                        rows="5"
                        name="billing_address"
                        value={billingAddress}
                        onChange={billingAddressOnChange}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <WorkplaceTable />
          <div className="workplace-footer">
            <div className="row mb-1">
              <div className="col-md-5">
                <div className="form-row">
                  <div className="form-group col-md-7 ">
                    <label htmlFor="reference">Reference#</label>
                    <input
                      type="text"
                      className="form-control"
                      name="reference"
                      id="reference"
                      value={reference}
                      onChange={referenceOnChange}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="form-row">
                  <div className="form-group col-md-4">
                    <label htmlFor="so-date-expect">Expected Date</label>
                    <DatePicker
                      className="form-control"
                      dateFormat="yyyy-MM-dd"
                      id="so-date-expect"
                      name="so-date-expect"
                      autoComplete="off"
                      selected={Date.parse(soExpectedDeliveryDate)}
                      onChange={soExpectedDeliveryDateOnChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-5">
                <div className="form-row">
                  <div className="form-group col-md-7 mb-1">
                    <label htmlFor="payment-term">Payment Terms</label>
                    <SelectPaymentTerms
                      selected={paymentTerm}
                      callback={paymentTermChangeHandler}
                    />
                  </div>
                  <div className="form-group col-md-5 mb-1">
                    <label htmlFor="payment-due">Payment Due</label>
                    <DatePicker
                      className="form-control"
                      dateFormat="yyyy-MM-dd"
                      id="payment-due"
                      name="payment-due"
                      autoComplete="off"
                      selected={Date.parse(paymentDue)}
                      onChange={paymentDueOnChange}
                    />
                  </div>
                </div>
                <div className="form-group mt-1">
                  <label htmlFor="po-note">Note</label>
                  <textarea
                    className="form-control"
                    id="po-note"
                    rows="3"
                    name="po-note"
                    value={note}
                    onChange={noteOnChange}
                  ></textarea>
                </div>
              </div>
              <div className="col-md-7">
                <div className="form-group">
                  <label htmlFor="so-terms">Terms & Conditions</label>
                  <textarea
                    className="form-control"
                    id="so-terms"
                    rows="6"
                    name="so-terms"
                    value={terms}
                    onChange={termsOnChange}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="doc-editor-control-wrapper">
          <div className="doc-editor-control">
            <SaleOrderCtrl action={action} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaleOrder;
