import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import AsyncCreatableSelect from "react-select/async-creatable";
import { debounce } from "../../../Helper";
import { PlusSquareDotted, BoundingBox } from "react-bootstrap-icons";
import axios from "../../../api/axios";
import { showOptionModel } from "../../../redux/features/modals/OptionModalSlide";
import UnitEdit from "../../../containers/inventory/unit/UnitEdit";

/*select options */
const select_style = {
  control: (base) => ({
    ...base,
    height: 31,
    minHeight: 31,
    borderRadius: 3,
    border: "1px solid #d0d6db",
    boxShadow: "none",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: "31px",
    padding: "0 6px",
  }),
  input: (provided, state) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "31px",
  }),
  menuList: (provided) => ({
    ...provided,
  }),
  menu: (provided) => ({
    ...provided,
    width: "max-content",
    minWidth: "100%",
  }),
};

const formatItemSelectOption = (option, { context }) => {
  if (option?.__isNew__) {
    return (
      <>
        <PlusSquareDotted /> {option.label}
      </>
    );
  }
  return option.label;
};

const getUnitList = async (
  token,
  branch,
  start = 0,
  length = 10,
  order = "desc",
  search = ""
) => {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append("com_id", branch.com_id);
    searchParams.append("start", start);
    searchParams.append("length", length);
    searchParams.append("order", order);
    searchParams.append("search", search);
    searchParams.toString();
    const response = await axios.get(
      "inventory/units?" + searchParams.toString(),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    response.data.data = response.data.data.map((unit) => {
      return {
        ...unit,
        label: unit.unit,
        value: unit.unit_id,
      };
    });
    return response.data;
  } catch (error) {
    return [];
  }
};

const SelectUnit = (props) => {
  const { selected, callback } = props;
  const { token, branch } = useSelector((store) => store.auth);
  const dispatch = useDispatch();
  //local stats
  const [options, setOptions] = useState();
  const [unit, setUnit] = useState();
  const [isLoading, setIsLoading] = useState(false);
  //events
  const loadListOptions = debounce(async (inputValue, callback) => {
    const options = await getUnitList(token, branch, 0, 10, "desc", inputValue);
    callback(options.data);
  }, 500);

  const handleOnCreate = (inputValue) => {
    const new_unit = {
      unit_id: null,
      unit: inputValue,
      measur_id: null,
      pack_size: 1,
    };
    const modelData = {
      title: "Add New Unit",
      titleIcon: <BoundingBox />,
      content: (
        <UnitEdit
          unit={new_unit}
          edit_callback={(unit) => {
            const option = { ...unit, label: unit.unit, value: unit.unit_id };
            setUnit(option);
            setOptions([...options, option]);
            if (callback) {
              callback(option);
            }
          }}
        />
      ),
    };
    dispatch(showOptionModel(modelData));
  };

  const handleOnChange = (option) => {
    setUnit(option);
    if (callback) {
      callback(option);
    }
  };

  //effect
  useEffect(() => {
    const unitData = async () => {
      setIsLoading(true);
      const list = await getUnitList(token, branch, 0, 10, "desc", "");
      setOptions(list.data);
      setIsLoading(false);
    };
    unitData();
  }, [token, branch]);

  useEffect(() => {
    if (selected) {
      setUnit(selected);
    }
  }, [selected]);

  return (
    <AsyncCreatableSelect
      cacheOptions
      defaultOptions={options}
      loadOptions={loadListOptions}
      styles={select_style}
      onChange={handleOnChange}
      onCreateOption={handleOnCreate}
      value={unit}
      formatOptionLabel={formatItemSelectOption}
      formatCreateLabel={(userInput) => `Add "${userInput}"`}
      placeholder=""
      isLoading={isLoading}
      name="unit_id"
    />
  );
};
export default React.memo(SelectUnit);
