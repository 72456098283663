import "./GRN.scss";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import DatePicker from "react-datepicker";

import GRNCtrl from "./GRNCtrl";
import WorkplaceTable from "./WorkplaceTable";

import {
  setGRNNumber,
  setGRNDate,
  setInvoiceNo,
  setGRNSupplier,
  setGRNDeliveryAddress,
  setReference,
  setNote,
  setTerms,
} from "../../../redux/features/purchases/GRNSlice";

const GRN = (prop) => {
  const dispatch = useDispatch();
  const action = prop.action;

  const {
    grnNo,
    grnDate,
    invoiceNo,
    supplierAddress,
    deliveryAddress,
    reference,
    note,
    terms,
  } = useSelector((store) => store.goods_received_note, shallowEqual);

  /*change handlers */
  const grnNoOnChange = (e) => {
    const { value } = e.target;
    dispatch(setGRNNumber(value));
  };

  const grnDateOnChange = (date) => {
    //console.log(Date.parse("2022-10-30"));
    dispatch(setGRNDate(date));
  };

  const invoiceNoOnChange = (e) => {
    const { value } = e.target;
    dispatch(setInvoiceNo(value));
  };

  const supplierAddressOnChange = (e) => {
    const { value } = e.target;
    dispatch(setGRNSupplier(value));
  };

  const deliveryAddressOnChange = (e) => {
    const { value } = e.target;
    dispatch(setGRNDeliveryAddress(value));
  };

  const referenceOnChange = (e) => {
    const { value } = e.target;
    dispatch(setReference(value));
  };

  const noteOnChange = (e) => {
    const { value } = e.target;
    dispatch(setNote(value));
  };

  const termsOnChange = (e) => {
    const { value } = e.target;
    dispatch(setTerms(value));
  };

  return (
    <div className="new-grn">
      <div className="doc-editor">
        <div className="doc-editor-workplace">
          <div className="workplace-header">
            <div className="row no-gutters">
              <div className="col-md-5">
                <div className="form-row">
                  <div className="form-group col-md-6  mb-0">
                    <label htmlFor="grn_no">GRN#</label>
                    <input
                      type="text"
                      className="form-control"
                      name="grn_no"
                      id="grn_no"
                      value={grnNo}
                      onChange={grnNoOnChange}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-5 mb-0">
                    <label htmlFor="po-date">Date</label>
                    <DatePicker
                      className="form-control"
                      dateFormat="yyyy-MM-dd"
                      id="grn_date"
                      name="grn_date"
                      autoComplete="off"
                      selected={Date.parse(grnDate)}
                      onChange={grnDateOnChange}
                    />
                  </div>
                  <div className="form-group col-md-5 mb-1">
                    <label htmlFor="po-date-expect">Invoice#</label>
                    <input
                      type="text"
                      className="form-control"
                      name="invoice_no"
                      id="invoice_no"
                      value={invoiceNo}
                      onChange={invoiceNoOnChange}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="row no-gutters">
                  <div className="col-md-6 pl-2">
                    <div className="form-group">
                      <label htmlFor="supplier_address">Supplier Address</label>
                      <textarea
                        className="form-control"
                        id="supplier_address"
                        rows="4"
                        name="supplier_address"
                        value={supplierAddress}
                        onChange={supplierAddressOnChange}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-md-6 pl-2">
                    <div className="form-group">
                      <label htmlFor="deliver_address">Delivery Address</label>
                      <textarea
                        className="form-control"
                        id="deliver_address"
                        rows="4"
                        name="deliver_address"
                        value={deliveryAddress}
                        onChange={deliveryAddressOnChange}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <WorkplaceTable />
          <div className="workplace-footer">
            <div className="row">
              <div className="col-md-5">
                <div className="form-row">
                  <div className="form-group col-md-6  mb-0">
                    <label htmlFor="grn_reference">Reference#</label>
                    <input
                      type="text"
                      className="form-control"
                      name="grn_reference"
                      id="grn_reference"
                      value={reference}
                      onChange={referenceOnChange}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="supplier_note">Notes</label>
                  <textarea
                    className="form-control"
                    id="supplier_note"
                    rows="3"
                    name="supplier_note"
                    value={note}
                    onChange={noteOnChange}
                  ></textarea>
                </div>
              </div>
              <div className="col-md-7 pt-3">
                <div className="form-group">
                  <label htmlFor="grn_terms">Terms & Conditions</label>
                  <textarea
                    className="form-control"
                    id="grn_terms"
                    rows="5"
                    name="grn_terms"
                    value={terms}
                    onChange={termsOnChange}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="doc-editor-control-wrapper">
          <div className="doc-editor-control">
            <GRNCtrl action={action} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GRN;
