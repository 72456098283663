import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AsyncSelect from "react-select/async";
import { debounce } from "../../../Helper";
import axios from "../../../api/axios";
import { autoNotify } from "../../../redux/features/notify/NotifySlice";
import { hideOptionModel } from "../../../redux/features/modals/OptionModalSlide";

const select_style = {
  control: (base) => ({
    ...base,
    height: 31,
    minHeight: 31,
    borderRadius: 3,
    border: "1px solid #d0d6db",
    boxShadow: "none",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: "31px",
    padding: "0 6px",
  }),
  input: (provided, state) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "31px",
  }),
};

//api request
const getMeasurement = async (token, branch, search) => {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append("com_id", branch.com_id);
    searchParams.append("start", 0);
    searchParams.append("length", 15);
    searchParams.append("order", "desc");
    searchParams.append("search", search);
    searchParams.toString();
    const response = await axios.get(
      "inventory/measurements?" + searchParams.toString(),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const options = response.data.data.map((measure) => {
      return {
        ...measure,
        label: measure.measurement,
        value: measure.measur_id,
      };
    });
    return options;
  } catch (error) {
    return [];
  }
};

const Unit = (props) => {
  const dispatch = useDispatch();
  const { edit_unit, edit_callback } = props;
  const { token, branch, user } = useSelector((store) => store.auth);
  //local stats
  const [measurement, setMeasurement] = useState();
  const [unit, setUnit] = useState({
    com_id: branch.com_id,
    unit_id: null,
    unit: "",
    measur_id: null,
    pack_size: 1,
    created_by: user.user_id,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  //events
  const inputsOnChange = (e) => {
    const { name, value } = e.target;
    setUnit((prevUnit) => {
      return { ...prevUnit, [name]: value };
    });
  };

  //action
  const loadMeasurement = debounce(async (inputValue, callback) => {
    const options = await getMeasurement(token, branch, inputValue);
    callback(options);
  }, 500);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const resp = await axios.post("inventory/unit", unit, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      autoNotify(dispatch, resp);
      setIsSubmitting(false);
      if (edit_callback) {
        edit_callback(resp.data.unit);
      }
      dispatch(hideOptionModel());
    } catch (error) {
      autoNotify(dispatch, error.response);
      setIsSubmitting(false);
    }
  };

  const handleFormClose = () => {
    dispatch(hideOptionModel());
  };

  //effect
  useEffect(() => {
    if (edit_unit) {
      setUnit((prevUnit) => {
        return {
          ...prevUnit,
          unit_id: edit_unit.unit_id,
          unit: edit_unit.unit,
          measur_id: edit_unit.measur_id,
          pack_size: edit_unit.pack_size,
        };
      });
      if (edit_unit?.measurement) {
        const measure_option = {
          ...edit_unit.measurement,
          label: edit_unit.measurement?.measurement,
          value: edit_unit.measurement?.measur_id,
        };
        setMeasurement(measure_option);
      }
    }
  }, [edit_unit]);

  useEffect(() => {
    setUnit((prevUnit) => {
      return { ...prevUnit, measur_id: measurement?.measur_id };
    });
  }, [measurement]);

  return (
    <form>
      <div className="form-row">
        <div className="form-group col-md-8 mb-1">
          <label htmlFor="unit">Pack Name</label>
          <input
            type="text"
            className="form-control"
            id="unit"
            name="unit"
            value={unit.unit}
            onChange={inputsOnChange}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-2 mb-1">
          <label htmlFor="pack_size">Pack Size</label>
          <input
            type="text"
            className="form-control"
            id="pack_size"
            name="pack_size"
            value={unit.pack_size}
            onChange={inputsOnChange}
          />
        </div>
        <div className="form-group col-md-1 pt-3 text-center">
          <h6 className="mb-0 mt-3">x</h6>
        </div>
        <div className="form-group col-md-4 mb-1">
          <label htmlFor="product-location">Measurement</label>
          <AsyncSelect
            cacheOptions
            loadOptions={loadMeasurement}
            defaultOptions
            styles={select_style}
            id="measur_id"
            name="measur_id"
            value={measurement}
            onChange={(option) => setMeasurement(option)}
          />
        </div>
      </div>
      <div className="mt-5">
        <button
          id="supp-create-btn"
          className="btn btn-primary float-right save-button ml-2"
          type="submit"
          disabled={isSubmitting}
          onClick={handleFormSubmit}
        >
          Save
        </button>
        <button
          id="supp-close-btn"
          type="button"
          className="btn btn-secondary float-right "
          data-dismiss="modal"
          onClick={handleFormClose}
        >
          Close
        </button>
      </div>
    </form>
  );
};
export default Unit;
