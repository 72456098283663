import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PurchaseOrder from "../../../components/workplace/po/PurchaseOrder";
import {
  setEditPO,
  setNewPO,
} from "../../../redux/features/purchases/PurchaseOrderSlice";

const POEdit = () => {
  const params = useParams();
  const po_id = params.id;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { purchaseOrderList } = useSelector(
    (store) => store.purchase_order_list
  );

  const purchaseOrder = purchaseOrderList.find((po) => {
    return po.po_id === po_id;
  });

  /* this will dispatch edit PO */
  useEffect(() => {
    if (purchaseOrder && purchaseOrder.po_status === "Draft") {
      dispatch(setEditPO(purchaseOrder));
    } else {
      navigate("/dashboard/purchases");
    }
    // call when unmount
    return () => {
      dispatch(setNewPO());
    };
  }, [dispatch, navigate, purchaseOrder]);

  return <PurchaseOrder action="Edit" />;
};
export default POEdit;
