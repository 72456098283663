import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { hasPrivilege } from "./Helper";

import UserLogin from "./containers/user/UserLogin";
import Dashboard from "./containers/dashboard/Dashboard";
import Error404 from "./containers/errors/Error404";

import Home from "./containers/dashboard/Home";
import DashboardError404 from "./containers/errors/dashboard/Error404";
import User from "./containers/user/User";
import Purchases from "./containers/purchases/Purchases";
import Company from "./containers/company/Company";

import SOList from "./containers/sales/so/SOList/SOList";
import SONew from "./containers/sales/so/SONew";
import SOEdit from "./containers/sales/so/SOEdit";
import InvoiceNew from "./containers/sales/invoice/InvoiceNew";
import InvoiceList from "./containers/sales/invoice/InvoiceList/InvoiceList";
import InvoiceDeletedList from "./containers/sales/invoice/InvoiceDeletedList";
import InvoiceEdit from "./containers/sales/invoice/InvoiceEdit";

import CustomerList from "./containers/sales/customer/CustomerList";
import CustomerCategoryList from "./containers/sales/customer/CategoryList";

import ProductList from "./containers/inventory/product-list/ProductList";
import UnitList from "./containers/inventory/unit/UnitList";
import InventoryCategoryList from "./containers/inventory/category/CategoryList";
import BrandList from "./containers/inventory/brand/BrandList";

const Router = () => {
  const { user } = useSelector((store) => store.auth);

  //Sales Routs
  const CustomerRouts = (
    <>
      {hasPrivilege("Customers", 500) && (
        <Route index element={<CustomerList />} />
      )}
      {hasPrivilege("Customers", 500) && (
        <Route path="list" element={<CustomerList />} />
      )}
      {/* New category handle by main menu */}
      {hasPrivilege("Customers", 501) && (
        <Route path="categories" element={<CustomerCategoryList />} />
      )}
      {/* New customer handle by main menu */}
    </>
  );

  const SalesRouts = (
    <>
      {hasPrivilege("Sales", 200) && <Route index element={<SOList />} />}

      <Route path="customer">{CustomerRouts}</Route>

      {hasPrivilege("Sales", 201) && (
        <Route path="so-new" element={<SONew />} />
      )}
      {hasPrivilege("Sales", 202) && (
        <Route path="so-edit/:id" element={<SOEdit />} />
      )}
      {hasPrivilege("Sales", 206) && (
        <Route path="invoices" element={<InvoiceList />} />
      )}
      {hasPrivilege("Sales", 207) && (
        <Route path="invoice-new" element={<InvoiceNew />} />
      )}
      {hasPrivilege("Sales", 208) && (
        <Route path="invoice-edit/:id" element={<InvoiceEdit />} />
      )}
      {hasPrivilege("Sales", 206) && (
        <Route path="invoices-deleted" element={<InvoiceDeletedList />} />
      )}
    </>
  );

  //Inventory routes
  const InventoryRouts = (
    <>
      {hasPrivilege("Inventory", 400) && (
        <Route index element={<ProductList />} />
      )}
      {hasPrivilege("Inventory", 401) && (
        <Route path="units" element={<UnitList />} />
      )}
      {/* New Unit handle by main menu */}
      {hasPrivilege("Inventory", 401) && (
        <Route path="categories" element={<InventoryCategoryList />} />
      )}
      {/* New category handle by main menu */}
      {hasPrivilege("Inventory", 401) && (
        <Route path="brands" element={<BrandList />} />
      )}
      {/* New brand handle by main menu */}
    </>
  );

  return (
    <Routes>
      <Route path="/" element={<UserLogin />} />

      {user?.user_id ? (
        <Route path="/dashboard" element={<Dashboard />}>
          <Route index element={<Home />} />

          <Route path="users/*" element={<User />} />

          <Route path="sales">{SalesRouts}</Route>

          <Route path="purchases/*" element={<Purchases />} />

          <Route path="inventory/">{InventoryRouts}</Route>

          <Route path="company/*" element={<Company />} />
          <Route path="*" element={<DashboardError404 />} />
        </Route>
      ) : null}

      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};

export default Router;
