import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  title: "",
  titleIcon: "",
  content: "",
  isPrimaryModal: false,
};

const PrimaryModalSlice = createSlice({
  name: "primary_modal",
  initialState,
  reducers: {
    showPrimaryModel: (state, action) => {
      state.isPrimaryModal = true;
      state.title = action.payload.title;
      state.titleIcon = action.payload.titleIcon;
      state.content = action.payload.content;
    },
    hidePrimaryModel: (state, action) => {
      state.isPrimaryModal = false;
    },
  },
});

export const { showPrimaryModel, hidePrimaryModel } = PrimaryModalSlice.actions;
export default PrimaryModalSlice.reducer;
