import "./MainMenu.scss";
import { memo } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { hasPrivilege } from "../../../Helper";
import { setCurrentTab } from "../../../redux/features/dashboard/MainMenuSlice";

import UserToolbar from "./user_toolbar/UserToolbar";
import HomeTab from "./tabs/HomeTab";
import SalesTab from "./tabs/SalesTab";
import PurchasesTab from "./tabs/PurchasesTab";
import InventoryTab from "./tabs/InventoryTab";
import CustomersTab from "./tabs/CustomersTab";
import SuppliersTab from "./tabs/SuppliersTab";
import UsersTab from "./tabs/UsersTab";
import AccountsTab from "./tabs/AccountsTab";
import CompanyTab from "./tabs/CompanyTab";

const MainMenu = () => {
  const dispatch = useDispatch();
  const { currentTab } = useSelector((store) => store.main_menu);
  return (
    <div className="main-menu">
      <div className="menu-bar">
        <div className="tab-bar">
          <Tabs
            id="controlled-meu-tabs"
            activeKey={currentTab}
            onSelect={(currentTab) => dispatch(setCurrentTab(currentTab))}
            transition={true}
          >
            <Tab eventKey="home" title="Home">
              <HomeTab />
            </Tab>

            {hasPrivilege("Sales", null) && (
              <Tab eventKey="sales" title="Sales">
                <SalesTab />
              </Tab>
            )}

            {hasPrivilege("Purchases", null) && (
              <Tab eventKey="purchases" title="Purchases">
                <PurchasesTab />
              </Tab>
            )}

            {hasPrivilege("Inventory", null) && (
              <Tab eventKey="inventory" title="Inventory">
                <InventoryTab />
              </Tab>
            )}

            {hasPrivilege("Customers", null) && (
              <Tab eventKey="customers" title="Customers">
                <CustomersTab />
              </Tab>
            )}

            {hasPrivilege("Suppliers", null) && (
              <Tab eventKey="suppliers" title="Suppliers">
                <SuppliersTab />
              </Tab>
            )}

            {hasPrivilege("Users", null) && (
              <Tab eventKey="users" title="Users">
                <UsersTab />
              </Tab>
            )}

            {hasPrivilege("Accounts", null) && (
              <Tab eventKey="accounts" title="Accounts">
                <AccountsTab />
              </Tab>
            )}

            {hasPrivilege("Company", null) && (
              <Tab eventKey="company" title="Company">
                <CompanyTab />
              </Tab>
            )}
          </Tabs>
        </div>
        <UserToolbar />
      </div>
    </div>
  );
};

export default memo(MainMenu);
