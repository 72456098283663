import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars-2";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  PencilFill,
  CheckLg,
  Trash2Fill,
  Box2Fill,
} from "react-bootstrap-icons";
import { hasPrivilege } from "../../../../Helper";

import GRNReceive from "../GRNReceive";
import { showOptionModel } from "../../../../redux/features/modals/OptionModalSlide";
import { showPrintPreview } from "../../../../redux/features/modals/PrintPreviewSlice";
import { updateGRNList } from "../../../../redux/features/purchases/GRNListSlice";

const GRNListRight = (props) => {
  const [grn, setGRN] = useState(props.grn);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { grnList } = useSelector((store) => store.goods_received_note_list);

  //set info
  const infoList = [
    {
      name: "GRN Date",
      value: grn.grn_date,
    },
    {
      name: "Invoice#",
      value: grn.invoice_no,
    },
  ];
  const info_list_elements = infoList.map((field) => {
    return (
      <div key={field.name} className="row no-gutters info-field">
        <div className="col-sm-4 name">
          <p>{field.name} : </p>
        </div>
        <div className="col-sm-8 value">
          <p>{field.value}</p>
        </div>
      </div>
    );
  });

  //action

  //handle view
  const handlePOView = () => {
    const previewData = {
      title: "Good Received Note",
      format: "GoodReceivedNote",
      data: grn,
    };
    dispatch(showPrintPreview(previewData));
  };

  //handle PO close
  /*
  const closeCallback = (updated_po) => {
    const updatedData = grnList.map((list_po) => {
      if (updated_po.po_id === list_po.po_id) {
        return updated_po;
      }
      return list_po;
    });
    dispatch(updatePOList(updatedData));
    //setPurchaseOrder(updated_po);
  };*/

  const handlePOClose = () => {
    /*
    const modelData = {
      title: "Close Purchase Order",
      titleIcon: <FileTextFill />,
      content: <POClose po={purchaseOrder} close_callback={closeCallback} />,
    };
    dispatch(showOptionModel(modelData));*/
  };

  //handle grn Received
  const receiveCallback = (updated_grn) => {
    const updatedData = grnList.map((list_grn) => {
      if (updated_grn.grn_id === list_grn.grn_id) {
        return updated_grn;
      }
      return list_grn;
    });
    dispatch(updateGRNList(updatedData));
    setGRN(updated_grn);
  };
  const handleGRNReceive = () => {
    const modelData = {
      title: "Receive Goods",
      titleIcon: <Box2Fill />,
      content: <GRNReceive grn={grn} receive_callback={receiveCallback} />,
    };
    dispatch(showOptionModel(modelData));
  };

  //handle edit
  const handleGrnEdit = () => {
    navigate("/dashboard/purchases/grn-edit/" + grn.grn_id);
  };

  //handler for delete

  const handleDelete = () => {
    /*
    const modelData = {
      title: "Delete Purchase Order",
      titleIcon: <FileTextFill />,
      content: <PODelete po={purchaseOrder} delete_callback={deleteCallback} />,
    };
    dispatch(showOptionModel(modelData));*/
  };

  //set props
  useEffect(() => {
    setGRN(props.grn);
  }, [props.grn]);

  return (
    <div className="right-content">
      <div className="header">
        <div className="header-img">
          <img src="/img/icons/invoice-lg.png" alt="side header" />
        </div>
        <div className="header-text">
          <h4>Good Received Note</h4>
          <p>GRN# {grn.grn_no}</p>
        </div>
      </div>

      <Scrollbars style={{ width: "100%", height: "100%" }}>
        <div className="info-section">
          <h6>PO Information</h6>
          <div className="row mb-2">
            <div className="col-sm-6">{info_list_elements}</div>
            <div className="col-sm-6">
              <div className="d-flex">
                <div className="p-1">Status</div>
                <div className="badge-status">{grn.grn_status}</div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <h6>Supplier Address</h6>
              <p className="m-0">{grn.supplier_address}</p>
            </div>
            <div className="col-sm-6">
              <h6>Delivery Address</h6>
              <p className="m-0">{grn.deliver_address}</p>
            </div>
          </div>
        </div>

        <div className="trans-content">
          <h6>GRN Items</h6>
          <table className="table table-sm table-hover">
            <thead>
              <tr>
                <th>Item</th>
                <th>Qty</th>
                <th style={{ textAlign: "right" }}>Rate</th>
                <th style={{ textAlign: "right" }}>Amount</th>
              </tr>
            </thead>
            <tbody>
              {grn.grn_item_rows.map((row) => {
                return (
                  <tr key={row.row_id}>
                    <td>
                      <p style={{ fontWeight: 600 }}>
                        {row.grn_item.item_name}
                      </p>
                      <p>{row.grn_item_description}</p>
                    </td>
                    <td>{row.grn_item_qty}</td>
                    <td style={{ textAlign: "right" }}>{row.grn_item_rate}</td>
                    <td style={{ textAlign: "right" }}>
                      {row.grn_item_amount}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Scrollbars>
      <div className="action-section">
        <div className="action-control">
          {hasPrivilege("Purchases", 307) && (
            <button className="btn btn-dark b-button" onClick={handlePOView}>
              View
            </button>
          )}
          {hasPrivilege("Purchases", 310) && grn.grn_status === "Draft" ? (
            <button
              className="btn btn-success b-button"
              onClick={handleGRNReceive}
            >
              <CheckLg />
              Received
            </button>
          ) : null}
          {hasPrivilege("Purchases", 310) && grn.grn_status === "Open" ? (
            <button className="btn btn-light b-button" onClick={handlePOClose}>
              Close PO
            </button>
          ) : null}

          <Dropdown>
            <Dropdown.Toggle
              variant="secondary"
              id="more-action"
              className="b-button"
            >
              More
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ margin: 0 }}>
              {hasPrivilege("Purchases", 308) && grn.grn_status === "Draft" ? (
                <Dropdown.Item eventKey="2" onClick={handleGrnEdit}>
                  <PencilFill /> Edit GRN
                </Dropdown.Item>
              ) : null}

              {hasPrivilege("Purchases", 308) && (
                <Dropdown.Item eventKey="3" onClick={handleDelete}>
                  <Trash2Fill /> Delete
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};
export default GRNListRight;
