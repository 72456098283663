import "./UnitList.scss";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import { debounce } from "../../../Helper";
import SearchBar from "../../../components/searchbar/SearchBar";
import TextLoader from "../../../components/loaders/Loader";
import axios from "../../../api/axios";

import { PencilFill, TrashFill, BoundingBox } from "react-bootstrap-icons";
import { showOptionModel } from "../../../redux/features/modals/OptionModalSlide";
import UnitEdit from "./UnitEdit";

//api request
const getUnitList = async (
  token,
  branch,
  start = 0,
  length = 10,
  order = "desc",
  search = ""
) => {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append("com_id", branch.com_id);
    searchParams.append("start", start);
    searchParams.append("length", length);
    searchParams.append("order", order);
    searchParams.append("search", search);
    searchParams.toString();
    const response = await axios.get(
      "inventory/units?" + searchParams.toString(),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    response.data.data = response.data.data.map((unit) => {
      return {
        ...unit,
        label: unit.unit,
        value: unit.unit_id,
      };
    });
    return response.data;
  } catch (error) {
    return [];
  }
};

const UnitList = () => {
  const dispatch = useDispatch();
  const { token, branch } = useSelector((store) => store.auth);
  //local stats
  const [search, setSearch] = useState("");
  const [recordsTotal, setRecordsTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [unitList, setUnitList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const columns = [
    {
      name: "Unit",
      selector: (row) => row.unit,
      sortable: true,
    },
    {
      name: "Pack Size",
      selector: (row) => row.pack_size,
      sortable: true,
    },
    {
      name: "Type",
      selector: (row) => row.measurement?.type,
      sortable: true,
    },
    {
      name: "Measurement",
      selector: (row) => row.measurement?.measurement,
      sortable: true,
    },
    {
      name: "",
      selector: (row) => row.unit_id,
      sortable: true,
      cell: (row) => {
        return (
          <div className="table-action-control">
            <button
              type="button"
              id="po-save-btn"
              className="btn row-btn"
              onClick={() => unitEdit(row)}
            >
              <PencilFill />
            </button>
            <button type="button" id="po-save-btn" className="btn row-btn">
              <TrashFill />
            </button>
          </div>
        );
      },
    },
  ];

  //events
  const handleOnSearch = debounce(async (e) => {
    setSearch(e.target.value);
  }, 600);

  const handlePageChange = async (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
  };

  //actions
  const unitEdit = (row) => {
    const modelData = {
      title: "Edit Unit",
      titleIcon: <BoundingBox />,
      content: (
        <UnitEdit
          unit={row}
          edit_callback={(edited_unit) => {
            const updatedData = unitList.map((list_unit) => {
              if (edited_unit.unit_id === list_unit.unit_id) {
                return edited_unit;
              }
              return list_unit;
            });
            setUnitList(updatedData);
          }}
        />
      ),
    };
    dispatch(showOptionModel(modelData));
  };

  //effect
  useEffect(() => {
    const loadUnitList = async () => {
      setIsLoading(true);
      const start = (currentPage - 1) * perPage;
      const list_data = await getUnitList(
        token,
        branch,
        start,
        perPage,
        "desc",
        search
      );
      setRecordsTotal(list_data.recordsTotal);
      setUnitList(list_data.data);
      setIsLoading(false);
    };
    loadUnitList();
  }, [token, branch, search, currentPage, perPage]);

  return (
    <div className="container unit-list">
      <div className="row no-gutters">
        <div className="col-md-8">
          <div className="module-wrapper shadow-sm">
            <div className="main-section ">
              <div className="action-table-title">
                <h3>Units</h3>
              </div>
              <div className="row">
                <div className="col-md-4 ">
                  <SearchBar
                    searchHandler={handleOnSearch}
                    placeholder="Search Unit here..."
                  />
                </div>
              </div>
              <div className="action-table">
                <DataTable
                  dense
                  columns={columns}
                  data={unitList}
                  progressPending={isLoading}
                  progressComponent={<TextLoader />}
                  highlightOnHover
                  pagination
                  paginationServer
                  paginationPerPage={perPage}
                  paginationRowsPerPageOptions={[10, 20, 50, 100, 200]}
                  paginationTotalRows={recordsTotal}
                  paginationDefaultPage={currentPage}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  className="list-table"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="module-wrapper shadow-sm">
            <div className="side-section">
              <h5 className="display-4 title">Unit Conversion</h5>
              <p>
                Unit Conversion lets you convert an item’s base unit to another
                unit while creating transactions. Once you configure a default
                unit for sales and purchase transactions, System will
                automatically convert the base unit of an item to the default
                unit that you’ve configured.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnitList;
