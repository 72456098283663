import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SaleOrder from "../../../components/workplace/so/SaleOrder";

import {
  getSONumber,
  setSODate,
  newTableRow,
} from "../../../redux/features/sales/SaleOrderSlice";

const SONew = () => {
  const dispatch = useDispatch();
  const { soNo, soDate, tableRowCounter } = useSelector(
    (store) => store.sale_order
  );

  // set New PO data
  const isNewSoNo = soNo === "";
  const isNewSoDate = soDate === "";
  const isZeroRow = tableRowCounter === 0;
  useEffect(() => {
    if (isNewSoNo) dispatch(getSONumber());
    if (isNewSoDate) dispatch(setSODate(new Date()));
    if (isZeroRow) dispatch(newTableRow());
  }, [isNewSoNo, isNewSoDate, isZeroRow, dispatch]);

  return <SaleOrder action="New" />;
};

export default SONew;
