import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideOptionModel } from "../../../redux/features/modals/OptionModalSlide";
import axios from "../../../api/axios";
import { autoNotify } from "../../../redux/features/notify/NotifySlice";

const SupplierDelete = (props) => {
  const { supplier, delete_callback } = props;
  const dispatch = useDispatch();
  const [isDeleting, setIsDeleting] = useState(false);
  const { token, user } = useSelector((store) => store.auth);

  const handleOnDelete = async (e) => {
    e.preventDefault();
    setIsDeleting(true);
    try {
      const fd = new FormData();
      fd.append("supp_id", supplier.supp_id);
      fd.append("deleted_by", user.user_id);

      const resp = await axios.post("purchase/delete-supplier", fd, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      autoNotify(dispatch, resp);
      setIsDeleting(false);
      delete_callback(resp.data?.supp_id);
      dispatch(hideOptionModel());
    } catch (error) {
      autoNotify(dispatch, error.response);
      setIsDeleting(false);
    }
  };

  const handleOnClose = () => {
    dispatch(hideOptionModel());
  };
  return (
    <div>
      <h5>You are going to remove supplier from your company.</h5>
      <p>
        <strong>{supplier.supp_display_name}</strong> no longer available in
        supplier list. Please Conform your action.
      </p>
      <div className="modal-footer mt-3">
        <button
          type="button"
          className="btn btn-secondary b-button"
          data-dismiss="modal"
          onClick={handleOnClose}
        >
          Dismiss
        </button>
        <button
          type="submit"
          id="user-delete-btn"
          className="btn btn-primary b-button"
          onClick={handleOnDelete}
          disabled={isDeleting}
        >
          Delete Supplier
        </button>
      </div>
    </div>
  );
};

export default SupplierDelete;
