import "./style.scss";
import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../../../api/axios";
import Moment from "moment";
import { Dropdown } from "react-bootstrap";
import { PlusSquareDotted, Save } from "react-bootstrap-icons";

import SelectSupplier from "../../../form/select/SelectSupplier";
import SelectWarehouse from "../../../form/select/SelectWarehouse";
import SelectCustomer from "./SelectCustomer";
import {
  setPOSupplier,
  setPODeliveryType,
  setPODeliveryTo,
  setNewPO,
} from "../../../../redux/features/purchases/PurchaseOrderSlice";
import { autoNotify } from "../../../../redux/features/notify/NotifySlice";
import { showPrintPreview } from "../../../../redux/features/modals/PrintPreviewSlice";

const PurchaseOrderCtrl = (prop) => {
  const dispatch = useDispatch();
  const { token, branch, user } = useSelector((store) => store.auth);
  const action = prop.action;
  const {
    poId,
    poNo,
    poDate,
    poStatus,
    poExpectedDeliveryDate,
    supplier,
    supplierAddress,
    deliveryType,
    deliveryAddress,
    warehouse,
    customer,
    discount,
    discountValue,
    tax,
    taxValue,
    subTotal,
    poTotal,
    tableRows,
    reference,
    note,
    terms,
  } = useSelector((store) => store.purchase_order);

  const [totalItems, setTotalItems] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const supplierCallback = useCallback(
    (selectedOption) => {
      dispatch(setPOSupplier(selectedOption));
    },
    [dispatch]
  );

  /* delivery to handler*/
  const deliveryToChange = (e) => {
    const { value } = e.target;
    if (value === "customer") {
      dispatch(setPODeliveryType("customer"));
    } else {
      dispatch(setPODeliveryType("warehouse"));
    }
  };

  const deliverySelectCallback = useCallback(
    (newValue, actionMeta) => {
      dispatch(setPODeliveryTo(newValue));
    },
    [dispatch]
  );

  //set total items
  useEffect(() => {
    let totalQty = 0;
    for (let i = 0; i < tableRows.length; i++) {
      const element = tableRows[i];
      totalQty = totalQty + parseInt(element.quantity);
    }
    setTotalItems(totalQty);
  }, [tableRows]);

  /*Controls */
  //Save PO
  const savePO = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      let data = getCurrentPO();
      const resp = await axios.post("purchase/po-save", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setIsSubmitting(false);
      autoNotify(dispatch, resp);
      if (action === "New") {
        dispatch(setNewPO());
      }
    } catch (error) {
      setIsSubmitting(false);
      autoNotify(dispatch, error?.response ? error.response : error.message);
    }
  };

  //Sve PO with confirm
  const saveAndConfirm = async () => {
    setIsSubmitting(true);
    try {
      let data = getCurrentPO();
      data.po_status = "Open";

      const resp = await axios.post("purchase/po-save", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setIsSubmitting(false);
      autoNotify(dispatch, resp);
      if (action === "New") {
        dispatch(setNewPO());
      }
    } catch (error) {
      setIsSubmitting(false);
      autoNotify(dispatch, error?.response ? error.response : error.message);
    }
  };

  /* PO print */
  const poOnPrintHandler = () => {
    const previewData = {
      title: "Purchase Order",
      format: "PurchaseOrder",
      data: getCurrentPO(),
    };
    dispatch(showPrintPreview(previewData));
  };

  //new PO
  const newPOHandler = () => {
    dispatch(setNewPO());
  };

  // this will return current po
  const getCurrentPO = () => {
    let deliver_location_id =
      deliveryType === "warehouse" ? warehouse?.ware_id : customer?.cust_id;

    let table_row_items = tableRows.filter((row) => {
      return row.item ? true : false;
    });
    let row_id = 0;
    let po_item_rows = table_row_items.map((row) => {
      row_id++;
      const table_row = {
        row_id: row_id,
        batch_id: row.item.batch_id,
        po_item: row.item,
        po_item_description: row.itemDes,
        po_item_qty: row.quantity,
        po_item_rate: row.rate,
        po_item_amount: row.amount,
      };
      return table_row;
    });

    let po = {
      branch_id: branch.branch_id,
      po_id: poId,
      po_date: Moment(poDate).format("YYYY-MM-DD"),
      po_no: poNo,
      po_status: poStatus,
      supplier_id: supplier?.supp_id,
      supplier_address: supplierAddress,
      deliver_location_type: deliveryType,
      deliver_location_id: deliver_location_id,
      deliver_address: deliveryAddress,
      total_items: totalItems,
      po_discount: discount,
      po_discount_val: discountValue,
      po_tax_id: tax?.tax_id,
      po_tax_total: taxValue,
      po_sub_total: subTotal,
      po_total: poTotal,
      ex_delivery_date: Moment(poExpectedDeliveryDate).format("YYYY-MM-DD"),
      supplier_note: note,
      po_reference: reference,
      po_terms: terms,
      po_item_rows: po_item_rows,
      created_by: user.user_id,
    };
    return po;
  };

  return (
    <div className="control-content">
      <h5 className="display-4 title">{action} Purchase Order</h5>
      <hr className="mt-0" />
      <div className="form-row">
        <div className="form-group col-md-9 mb-2">
          <label htmlFor="supplier-select">Select Supplier</label>
          <SelectSupplier selected={supplier} callback={supplierCallback} />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-12 mb-1">
          <label htmlFor="deliver-select">Delivery To</label>
        </div>
        <div className="form-group col-md-12 mb-1">
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="deliveryTo"
              id="red_warehouse"
              value="warehouse"
              checked={deliveryType === "warehouse"}
              onChange={deliveryToChange}
            />
            <label className="form-check-label" htmlFor="red_warehouse">
              Warehouse
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="deliveryTo"
              id="red_customer"
              value="customer"
              checked={deliveryType === "customer"}
              onChange={deliveryToChange}
            />
            <label className="form-check-label" htmlFor="red_customer">
              Customer
            </label>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-9 mb-2 delivery-to-wrapper">
          {deliveryType === "warehouse" ? (
            <SelectWarehouse
              selected={warehouse}
              callback={deliverySelectCallback}
            />
          ) : null}
          {deliveryType === "customer" ? (
            <SelectCustomer
              selected={customer}
              callback={deliverySelectCallback}
            />
          ) : null}
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-12 mb-1">
          <label htmlFor="sale-tax">Total Items</label>
          <h3 id="po-item-count-lbl">{totalItems}</h3>
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-12 mb-1">
          <label htmlFor="sale-tax">Total Amount</label>
          <h3 id="po-item-total-lbl">{parseFloat(poTotal).toFixed(2)}</h3>
        </div>
      </div>
      <div className="mt-5 action-control">
        <button
          type="button"
          id="po-save-btn"
          className="btn btn-success b-button"
          onClick={savePO}
          disabled={isSubmitting}
        >
          Save
        </button>
        <button
          id="po-save-send-btn"
          className="btn btn-dark b-button"
          type="button"
          onClick={poOnPrintHandler}
        >
          Preview
        </button>
        <Dropdown>
          <Dropdown.Toggle
            variant="secondary"
            id="more-action"
            className="b-button"
          >
            More
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ margin: 0 }}>
            {action === "New" ? (
              <Dropdown.Item eventKey="2" onClick={newPOHandler}>
                <PlusSquareDotted /> New Purchase Order
              </Dropdown.Item>
            ) : (
              ""
            )}
            <Dropdown.Item
              eventKey="3"
              onClick={(e) => {
                saveAndConfirm();
              }}
              disabled={isSubmitting}
            >
              <Save /> Save & Confirm
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default PurchaseOrderCtrl;
