import { Link } from "react-router-dom";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { hasPrivilege } from "../../../../Helper";
import { showPrimaryModel } from "../../../../redux/features/modals/PrimaryModalSlice";
import { PersonPlusFill } from "react-bootstrap-icons";
import { PersonWorkspace } from "react-bootstrap-icons";
import UserNew from "../../../../containers/user/UserNew";
import UserRoleNew from "../../../../containers/user/UserRoleNew";

const UsersTab = (props) => {
  const dispatch = useDispatch();

  // carate new user
  const handleNewUser = () => {
    const modelData = {
      title: "Add New User",
      titleIcon: <PersonPlusFill />,
      content: <UserNew />,
    };
    dispatch(showPrimaryModel(modelData));
  };

  //crate new user role
  const handleNewRole = () => {
    const modelData = {
      title: "Add New User Role",
      titleIcon: <PersonWorkspace />,
      content: <UserRoleNew />,
    };
    dispatch(showPrimaryModel(modelData));
  };

  return (
    <div className="row no-gutters">
      <div className="col-md-auto ">
        <div className="toolbar-section">
          <div className="row no-gutters">
            <div className="col-auto">
              <Link
                className={classNames("btn toolbar-button", {
                  disabled: !hasPrivilege("Users", 700),
                })}
                to="/dashboard/users"
              >
                <img src="/img/icons/users.png" alt="employees" />
                <span className="text">Users</span>
              </Link>
            </div>
            <div className="col-auto">
              <button
                className={classNames("btn toolbar-button", {
                  disabled: !hasPrivilege("Users", 701),
                })}
                onClick={hasPrivilege("Users", 701) && handleNewUser}
              >
                <img src="/img/icons/add-user.png" alt="New User" />
                <span className="text">New User</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-auto">
        <div className="toolbar-section">
          <div className="row no-gutters">
            <div className="col-auto">
              <Link
                className={classNames("btn toolbar-button", {
                  disabled: !hasPrivilege("Users", 704),
                })}
                to="/dashboard/users/user-roles"
              >
                <img src="/img/icons/roles-list-lg.png" alt="User Roles" />
                <span className="text">User Roles</span>
              </Link>
            </div>

            <div className="col-auto">
              <button
                className={classNames("btn toolbar-button-inline", {
                  disabled: !hasPrivilege("Users", 704),
                })}
                onClick={hasPrivilege("Users", 704) && handleNewRole}
              >
                <img src="/img/icons/user-role-sm.png" alt="New Use role" />
                <span className="text">Create New Role</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-auto">
        <div className="toolbar-section">
          <div className="row no-gutters">
            <div className="col-auto">
              <Link
                className={classNames("btn toolbar-button-inline", {
                  disabled: !hasPrivilege("Users", 704),
                })}
                to="/dashboard/users/privileges"
              >
                <img src="/img/icons/privileges.png" alt="privileges" />
                <span className="text">Privileges </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersTab;
