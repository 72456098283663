import { useState, useEffect, memo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import AsyncCreatableSelect from "react-select/async-creatable";
import { PlusSquareDotted, BoundingBox } from "react-bootstrap-icons";
import { debounce } from "../../../../Helper";
import { getCustomerCategories } from "../../../../redux/features/customers/customerCategorySlice";
import { showOptionModel } from "../../../../redux/features/modals/OptionModalSlide";
import CategoryNew from "../CategoryNew";

/*select options */
const select_style = {
  control: (base) => ({
    ...base,
    height: 31,
    minHeight: 31,
    borderRadius: 3,
    border: "1px solid #d0d6db",
    boxShadow: "none",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: "31px",
    padding: "0 6px",
  }),
  input: (provided, state) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "31px",
  }),
  menuList: (provided) => ({
    ...provided,
  }),
  menu: (provided) => ({
    ...provided,
    width: "max-content",
    minWidth: "100%",
  }),
};

const formatItemSelectOption = (option, { context }) => {
  if (option?.__isNew__) {
    return (
      <>
        <PlusSquareDotted /> {option.label}
      </>
    );
  }
  return option.label;
};

const SelectCategory = (props) => {
  const { name, onChange, value } = props;
  const { customerCategories, isLoading } = useSelector(
    (store) => store.customerCategory
  );
  const dispatch = useDispatch();
  //local stats
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [category, setCategory] = useState(value);

  //events
  /* this will search items */
  const loadListOptions = debounce((inputValue, callback) => {
    dispatch(
      getCustomerCategories({
        start: 0,
        length: 10,
        order: "desc",
        search: inputValue,
      })
    );
    const options = customerCategories.map((category) => {
      return {
        ...category,
        label: category.cat_name,
        value: category.cat_id,
      };
    });
    callback(options);
  }, 600);

  /* this will create new items */
  const categoryCreateCallback = useCallback(
    (category) => {
      const option = {
        ...category,
        label: category.cat_name,
        value: category.cat_id,
      };
      setCategory(option);
      setDefaultOptions([...defaultOptions, option]);
      if (onChange) {
        onChange(name, option);
      }
    },
    [defaultOptions, onChange, name]
  );

  const handleOnCreate = (inputValue) => {
    const new_cat = {
      cat_id: null,
      cat_name: inputValue,
      cat_description: "",
    };
    const modelData = {
      title: "Add New Category",
      titleIcon: <BoundingBox />,
      content: (
        <CategoryNew category={new_cat} callback={categoryCreateCallback} />
      ),
    };
    dispatch(showOptionModel(modelData));
  };

  const handleOnChange = (option) => {
    setCategory(option);
    if (onChange) {
      onChange(name, option);
    }
  };

  //effect
  /* this will load default items */
  useEffect(() => {
    const optionData = async () => {
      const list = await dispatch(
        getCustomerCategories({
          start: 0,
          length: 25,
          order: "desc",
          search: "",
        })
      ).unwrap();
      const options = list.data.map((category) => {
        return {
          ...category,
          label: category.cat_name,
          value: category.cat_id,
        };
      });
      setDefaultOptions(options);
    };
    optionData();
  }, [dispatch]);

  return (
    <AsyncCreatableSelect
      cacheOptions
      defaultOptions={defaultOptions}
      loadOptions={loadListOptions}
      styles={select_style}
      onChange={handleOnChange}
      onCreateOption={handleOnCreate}
      value={category}
      formatOptionLabel={formatItemSelectOption}
      formatCreateLabel={(userInput) => `Add "${userInput}"`}
      placeholder="Select Customer Category"
      isLoading={isLoading}
      name={name}
      inputId={name}
    />
  );
};
export default memo(SelectCategory);
