import { memo } from "react";
import Select from "react-select";

const select_style = {
  control: (base) => ({
    ...base,
    height: 31,
    minHeight: 31,
    borderRadius: 3,
    border: "1px solid #d0d6db",
    boxShadow: "none",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: "31px",
    padding: "0 6px",
  }),
  input: (provided, state) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "31px",
  }),
};
const types = [
  { value: 1, label: "Stock written off" },
  { value: 2, label: "Stock on fire" },
  { value: 3, label: "Stolen goods" },
  { value: 4, label: "Damaged goods" },
  { value: 5, label: "Stocktaking result" },
  { value: 6, label: "Inventory Revaluation" },
];

const SelectReason = (props) => {
  const { name, value, callback } = props;
  return (
    <Select
      options={types}
      styles={select_style}
      name={name}
      inputId={name}
      onChange={(type) => {
        if (callback) {
          callback(name, type);
        }
      }}
      value={value}
    />
  );
};
export default memo(SelectReason);
