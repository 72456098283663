import "./UserRoleList.scss";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import DataTable from "react-data-table-component";
import TextLoader from "../../components/loaders/Loader";
import { debounce } from "../../Helper";
import SearchBar from "../../components/searchbar/SearchBar";
import {
  getUserRoleList,
  updateData,
  setPerPage,
  setCurrentPage,
} from "../../redux/features/users/UserRoleListSlice";
import RightSidePanel from "../../components/panels/right/RightSidePanel";
import {
  showRightPanel,
  hideRightPanel,
} from "../../redux/features/rightpanel/RightPanelSlice";
import UserRoleListRight from "./UserRoleListRight";

const columns = [
  {
    name: "Role Name",
    selector: (row) => row.role_name,
    sortable: true,
    cell: (row) => {
      return (
        <div className="tbl-role-name">
          <div className="tbl-role-img">
            <img
              src="/img/icons/roles-list-lg.png"
              alt="user-role"
              data-tag="allowRowEvents"
            />
          </div>
          <div data-tag="allowRowEvents">
            <h6 data-tag="allowRowEvents">{row.role_name}</h6>
          </div>
        </div>
      );
    },
  },
  {
    name: "Description",
    selector: (row) => row.role_description,
    sortable: true,
  },
  {
    name: "User Accounts",
    selector: (row) => row.user_accounts,
    sortable: true,
  },
];

const conditionalRowStyles = [
  {
    when: (row) => row.isSelected === true,
    style: {
      backgroundColor: "#e3f2fd",
      userSelect: "none",
    },
  },
];

const UserRoleList = () => {
  const dispatch = useDispatch();
  const { data, recordsTotal, isLoading, currentPage } = useSelector(
    (store) => store.user_role_list
  );
  const { isPanelShow } = useSelector((store) => store.right_panel);
  const [search, setSearch] = useState("");

  // on mount
  useEffect(() => {
    dispatch(hideRightPanel());
    dispatch(getUserRoleList({ search: "" }));
  }, [dispatch]);

  //handle table
  const handleOnSearch = debounce((e) => {
    dispatch(getUserRoleList({ search: e.target.value }));
    setSearch(e.target.value);
  }, 600);

  const handlePageChange = (page) => {
    dispatch(setCurrentPage(page));
    dispatch(getUserRoleList({ search: search }));
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    dispatch(setPerPage(newPerPage));
    dispatch(getUserRoleList({ search: search }));
  };

  const handleOnSelect = (row) => {
    const updatedData = data.map((role) => {
      //unselect all
      if (row.role_id !== role.role_id) {
        return { ...role, isSelected: false };
      }
      //remove already selected one
      if (role?.isSelected === true) {
        dispatch(hideRightPanel());
        return { ...role, isSelected: false };
      }
      //selected current
      dispatch(showRightPanel({ content: <UserRoleListRight role={role} /> }));

      return { ...role, isSelected: true };
    });

    dispatch(updateData(updatedData));
  };

  //set side panel
  return (
    <div
      className={
        isPanelShow
          ? "container user-role-list show-right-side-panel"
          : "container user-role-list"
      }
    >
      <div className="action-table-wrapper">
        <div className="action-table-title">
          <h3>User Role List</h3>
        </div>
        <div className="row">
          <div className="col-md-4 ">
            <SearchBar
              searchHandler={handleOnSearch}
              placeholder="Search user role here..."
            />
          </div>
        </div>

        <div className="action-table">
          <DataTable
            dense
            columns={columns}
            data={data}
            progressPending={isLoading}
            progressComponent={<TextLoader />}
            highlightOnHover
            onRowClicked={handleOnSelect}
            pagination
            paginationServer
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10, 20, 50, 100, 200]}
            paginationTotalRows={recordsTotal}
            paginationDefaultPage={currentPage}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            conditionalRowStyles={conditionalRowStyles}
          />
        </div>
      </div>
      <RightSidePanel />
    </div>
  );
};

export default UserRoleList;
