import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideOptionModel } from "../../../../redux/features/modals/OptionModalSlide";
import {
  createCustomerCategory,
  resetCustomerCategory,
} from "../../../../redux/features/customers/customerCategorySlice";
import { addNotify } from "../../../../redux/features/dashboard/notifierSlice";

const CategoryNew = (props) => {
  const { category: init, callback } = props;
  const { isSubmitting, success, error } = useSelector(
    (store) => store.customerCategory
  );
  const dispatch = useDispatch();

  //state
  const [category, setCategory] = useState(init);
  //events
  const inputsOnChange = (e) => {
    const { name, value } = e.target;
    setCategory((prevCategory) => {
      return { ...prevCategory, [name]: value };
    });
  };

  //action
  const handleFormSubmit = (e) => {
    e.preventDefault();
    dispatch(createCustomerCategory(category));
  };
  const handleFormClose = () => {
    dispatch(hideOptionModel());
  };

  //effect
  //show notification
  useEffect(() => {
    if (success) {
      dispatch(addNotify({ type: "SUCCESS", content: success }));
      callback(success.data.category);
      dispatch(resetCustomerCategory());
      dispatch(hideOptionModel());
    }
    if (error) {
      dispatch(addNotify({ type: "ERROR", content: error }));
      dispatch(resetCustomerCategory());
    }
  }, [dispatch, success, error, callback]);

  return (
    <form>
      <div className="form-row">
        <div className="form-group col-md-6 mb-1">
          <label htmlFor="cat_name">Category Name</label>
          <input
            type="text"
            className="form-control"
            id="cat_name"
            name="cat_name"
            value={category.cat_name}
            onChange={inputsOnChange}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-9 mb-1">
          <label htmlFor="cat_description">Category Description</label>
          <input
            type="text"
            className="form-control"
            id="cat_description"
            name="cat_description"
            value={category.cat_description}
            onChange={inputsOnChange}
          />
        </div>
      </div>
      <div className="mt-3">
        <button
          id="supp-create-btn"
          className="btn btn-primary float-right save-button ml-2"
          type="submit"
          disabled={isSubmitting}
          onClick={handleFormSubmit}
        >
          Save
        </button>
        <button
          id="supp-close-btn"
          type="button"
          className="btn btn-secondary float-right "
          data-dismiss="modal"
          onClick={handleFormClose}
        >
          Close
        </button>
      </div>
    </form>
  );
};
export default CategoryNew;
