import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import CustomerCategoryService from "../../services/customers/CustomerCategoryService";

const initialState = {
  customerCategories: [],
  recordsFiltered: 0,
  recordsTotal: 0,
  isLoading: false,
  isSubmitting: false,
  error: null,
  success: null,
};

export const getCustomerCategories = createAsyncThunk(
  "customer/getCustomerCategories",
  async ({ start = 0, length = 10, order = "desc", search = "" }, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const params = {
        branch_id: auth.branch.branch_id,
        start: start,
        length: length,
        order: order,
        search: search,
      };
      const response = await CustomerCategoryService.getCustomerCategories(
        auth.token,
        params
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createCustomerCategory = createAsyncThunk(
  "customer/createCustomerCategory",
  async (category, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const { token, branch, user } = auth;
      const data = {
        ...category,
        branch_id: branch.branch_id,
        created_by: user.user_id,
      };
      return await CustomerCategoryService.createCustomerCategory(token, data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const editCustomerCategory = createAsyncThunk(
  "customer/editCustomerCategory",
  async (category, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const { token, branch, user } = auth;
      const data = {
        ...category,
        branch_id: branch.branch_id,
        updated_by: user.user_id,
      };
      return await CustomerCategoryService.updateCustomerCategory(token, data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteCustomerCategory = createAsyncThunk(
  "customer/deleteCustomerCategory",
  async (id, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const { token, user } = auth;
      return await CustomerCategoryService.deleteCustomerCategory(
        token,
        id,
        user.user_id
      );
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const customerCategorySlice = createSlice({
  name: "customerCategory",
  initialState,
  reducers: {
    resetCustomerCategory(state, action) {
      state.isSubmitting = false;
      state.success = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      //get
      .addCase(getCustomerCategories.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getCustomerCategories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.customerCategories = action.payload.data;
        state.recordsFiltered = action.payload.recordsFiltered;
        state.recordsTotal = action.payload.recordsTotal;
      })
      .addCase(getCustomerCategories.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      //create
      .addCase(createCustomerCategory.pending, (state) => {
        state.isSubmitting = true;
        state.error = null;
        state.success = null;
      })
      .addCase(createCustomerCategory.fulfilled, (state, action) => {
        state.isSubmitting = false;
        state.error = null;
        const category = action.payload.data.category;
        state.customerCategories.push(category);
        state.success = action.payload;
      })
      .addCase(createCustomerCategory.rejected, (state, action) => {
        state.isSubmitting = false;
        state.success = null;
        state.error = action.payload;
      })

      //update
      .addCase(editCustomerCategory.pending, (state) => {
        state.isSubmitting = true;
        state.error = null;
        state.success = null;
      })
      .addCase(editCustomerCategory.fulfilled, (state, action) => {
        state.isSubmitting = false;
        state.error = null;
        const category = action.payload.data.category;
        state.customerCategories = state.customerCategories.map((item) => {
          if (item.cat_id === category.cat_id) {
            return category;
          }
          return item;
        });
        state.success = action.payload;
      })
      .addCase(editCustomerCategory.rejected, (state, action) => {
        state.isSubmitting = false;
        state.success = null;
        state.error = action.payload;
      })

      //delete
      .addCase(deleteCustomerCategory.pending, (state) => {
        state.isSubmitting = true;
        state.error = null;
        state.success = null;
      })
      .addCase(deleteCustomerCategory.fulfilled, (state, action) => {
        state.isSubmitting = false;
        state.error = null;
        const cat_id = action.payload.data.cat_id;
        state.customerCategories = state.customerCategories.filter(
          (category) => category.cat_id !== cat_id
        );
        state.success = action.payload;
      })
      .addCase(deleteCustomerCategory.rejected, (state, action) => {
        state.isSubmitting = false;
        state.success = null;
        state.error = action.payload;
      });
  },
});

export const { resetCustomerCategory } = customerCategorySlice.actions;
export default customerCategorySlice.reducer;
