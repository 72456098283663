import httpClient from "../../../../api/httpClient";

class CustomerCategoryService {
  //get customers cat
  async getCustomerCategories(token, params) {
    const urlSegment = `/${params.branch_id}/${params.start}/${params.length}/${params.order}/q=${params.search}`;
    return await httpClient.get(token, "customers/categories" + urlSegment);
  }
  //create customer cat
  async createCustomerCategory(token, data) {
    return await httpClient.post(token, "customers/category", data);
  }
  //update customer cat
  async updateCustomerCategory(token, data) {
    return await httpClient.put(token, "customers/category", data);
  }
  //delete customer cat
  async deleteCustomerCategory(token, id, user_id) {
    return await httpClient.delete(
      token,
      `customers/category/${user_id}/${id}`
    );
  }
}
export default new CustomerCategoryService();
