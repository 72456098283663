import { useState, useRef } from "react";
import { Upload } from "react-bootstrap-icons";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import axios from "../../api/axios";
import { useSelector, useDispatch } from "react-redux";
import { hidePrimaryModel } from "../../redux/features/modals/PrimaryModalSlice";
import { getUserRoleList } from "../../redux/features/users/UserRoleListSlice";
import { autoNotify } from "../../redux/features/notify/NotifySlice";
import { getUserList } from "../../redux/features/users/UserListSlice";
import { debounce } from "../../Helper";

const UserNew = () => {
  const dispatch = useDispatch();
  const newUserForm = useRef(null);
  const [imageURI, setImageURI] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { token, branch, user } = useSelector((store) => store.auth);

  const readImageURI = (e) => {
    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader();
      reader.onload = function (ev) {
        setImageURI(ev.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const fd = new FormData(newUserForm.current);
      fd.append("created_by", user.user_id);
      fd.append("com_id", branch.com_id);
      fd.append("branch_id", branch.branch_id);
      const resp = await axios.post("users/new-user", fd, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      autoNotify(dispatch, resp);
      setIsSubmitting(false);
      dispatch(getUserList({ search: "" }));
      dispatch(hidePrimaryModel());
    } catch (error) {
      autoNotify(dispatch, error.response);
      setIsSubmitting(false);
    }
  };

  const handleFormClose = () => {
    dispatch(hidePrimaryModel());
  };

  /*select options */
  const select_style = {
    control: (base) => ({
      ...base,
      height: 31,
      minHeight: 31,
      borderRadius: 3,
      border: "1px solid #d0d6db",
      boxShadow: "none",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "31px",
      padding: "0 6px",
    }),
    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "31px",
    }),
  };

  const loadUserRolesOptions = debounce(async (inputValue, callback) => {
    const response = await dispatch(
      getUserRoleList({ search: inputValue })
    ).unwrap();
    const options = response.data.map((role) => {
      return {
        label: role.role_name,
        value: role.role_id,
      };
    });
    callback(options);
  }, 500);

  const countryOptions = [
    { value: "Sri Lanka", label: "Sri Lanka" },
    { value: "India", label: "India" },
  ];

  return (
    <form
      action=""
      id="create-new-user"
      method="post"
      onSubmit={handleFormSubmit}
      ref={newUserForm}
    >
      <div className="row">
        <div className="col-6">
          <div className="form-row">
            <div className="form-group col-md-6 mb-1">
              <label htmlFor="user_first_name">First Name</label>
              <input
                type="text"
                className="form-control"
                id="user_first_name"
                name="user_first_name"
              />
            </div>
            <div className="form-group col-md-6  mb-1">
              <label htmlFor="user_last_name">Last Name</label>
              <input
                type="text"
                className="form-control"
                id="user_last_name"
                name="user_last_name"
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-8 mb-1">
              <label htmlFor="user_title">Title</label>
              <input
                type="text"
                className="form-control"
                id="user_title"
                name="user_title"
              />
            </div>
          </div>
          <div className="form-group mb-1">
            <label htmlFor="user_address_street">Address Street</label>
            <input
              type="text"
              className="form-control"
              id="user_address_street"
              name="user_address_street"
            />
          </div>
          <div className="form-row">
            <div className="form-group col-md-6 mb-1">
              <label htmlFor="user_address_city">City</label>
              <input
                type="text"
                className="form-control"
                id="user_address_city"
                name="user_address_city"
              />
            </div>
            <div className="form-group col-md-4 mb-1">
              <label htmlFor="user_address_country">Country</label>
              <Select
                options={countryOptions}
                styles={select_style}
                name="user_address_country"
                id="user_address_country"
              />
            </div>
            <div className="form-group col-md-2 mb-1">
              <label htmlFor="user_address_postcode">Zip</label>
              <input
                type="text"
                className="form-control"
                id="user_address_postcode"
                name="user_address_postcode"
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6 mb-1">
              <label htmlFor="user_phone">Phone</label>
              <input
                type="text"
                className="form-control"
                id="user_phone"
                name="user_phone"
              />
            </div>
            <div className="form-group col-md-6 mb-1">
              <label htmlFor="user_mobile">Mobile</label>
              <input
                type="text"
                className="form-control"
                id="user_mobile"
                name="user_mobile"
              />
            </div>
          </div>
          <div className="form-group mb-1">
            <label htmlFor="user_role_id">User Role</label>
            <AsyncSelect
              cacheOptions
              loadOptions={loadUserRolesOptions}
              defaultOptions
              styles={select_style}
              id="user_role_id"
              name="user_role_id"
            />
          </div>
        </div>
        <div className="col-6">
          <div className="upload-image-preview mr-5 mb-2">
            <img
              id="user-img-new"
              className="img-thumbnail "
              src={imageURI ? imageURI : "/img/user.png"}
              alt="your"
            />
          </div>
          <div className="form-group">
            <div className="upload-image-file">
              <label htmlFor="user_image" className="">
                <Upload /> Upload Image
              </label>
              <input
                accept="image/*"
                type="file"
                className="form-control-file"
                id="user_image"
                name="user_image"
                onChange={readImageURI}
              />
            </div>
          </div>
          <hr />
          <div className="form-group mb-1">
            <label htmlFor="username">Username</label>
            <input
              type="text"
              className="form-control"
              id="username"
              name="username"
            />
          </div>
          <div className="form-group mb-1">
            <label htmlFor="user_email">Email address</label>
            <input
              type="email"
              className="form-control"
              id="user_email"
              placeholder="name@example.com"
              name="user_email"
            />
          </div>
          <div className="form-row">
            <div className="form-group col-md-6 mb-1">
              <label htmlFor="open-balance">Password</label>
              <input
                type="password"
                className="form-control"
                id="password"
                name="password"
              />
            </div>
            <div className="form-group col-md-6  mb-1">
              <label htmlFor="password_conform">Password Conform</label>
              <input
                type="password"
                className="form-control"
                id="password_conform"
                name="password_conform"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer mt-3">
        <button
          type="button"
          className="btn btn-secondary b-button"
          data-dismiss="modal"
          onClick={handleFormClose}
        >
          Close
        </button>
        <button
          type="submit"
          id="user-save-btn"
          className="btn btn-primary b-button"
          disabled={isSubmitting}
        >
          Create User
        </button>
      </div>
    </form>
  );
};

export default UserNew;
