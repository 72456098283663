import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Scrollbars } from "react-custom-scrollbars-2";
import { hasPrivilege } from "../../../../Helper";
import { showPrintPreview } from "../../../../redux/features/modals/PrintPreviewSlice";

const InvoiceListRight = (props) => {
  const [invoice, setInvoice] = useState(props.invoice);

  const dispatch = useDispatch();
  //set info
  const infoList = [
    {
      name: "Invoice Date",
      value: invoice.sale_date,
    },
    {
      name: "Sales Rep",
      value: invoice.sales_rep_name,
    },
    {
      name: "Order",
      value: invoice.sale_order?.so_no,
    },
  ];
  const info_list_elements = infoList.map((field) => {
    return (
      <div key={field.name} className="row no-gutters info-field">
        <div className="col-sm-5 name">
          <p>{field.name}</p>
        </div>
        <div className="col-sm-7 value">
          <p>: {field.value}</p>
        </div>
      </div>
    );
  });

  //action

  //handle view
  const handleInvoiceView = () => {
    const previewData = {
      title: "Sale Invoice",
      format: "SaleInvoice",
      data: invoice,
      printable: hasPrivilege("Sales", 209),
    };
    dispatch(showPrintPreview(previewData));
  };

  //set props
  useEffect(() => {
    setInvoice(props.invoice);
  }, [props.invoice]);

  //locale ui component
  const PaymentBadge = () => {
    let style = { float: "right" };
    switch (invoice?.payment_status) {
      case "Unpaid":
        style = { float: "right", backgroundColor: "#ffd2bb" };
        break;
      case "Partial Payment":
        style = { float: "right", backgroundColor: "#bbffdf" };
        break;
      case "Paid":
        style = { float: "right", backgroundColor: "#c6ffbb" };
        break;
      default:
        style = { float: "right" };
    }
    return (
      <div className="badge-status" style={style}>
        {invoice?.payment_status}
      </div>
    );
  };

  return (
    <div className="right-content">
      <div className="header">
        <div className="header-img">
          <img src="/img/icons/invoice-lg-delete.png" alt="side header" />
        </div>
        <div className="header-text">
          <h4>Deleted Invoice</h4>
          <p>Invoice# {invoice.sale_no}</p>
        </div>
      </div>

      <Scrollbars style={{ width: "100%", height: "100%" }}>
        <div className="info-section">
          <h6>Invoice Information</h6>
          <div className="row mb-2">
            <div className="col-sm-6">{info_list_elements}</div>
            <div className="col-sm-6">
              <table style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <td className="align-middle">Status</td>
                    <td className="text-right">
                      <div className="badge-status" style={{ float: "right" }}>
                        {invoice?.invoice_status}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="align-middle">Payment</td>
                    <td className="text-right">
                      <PaymentBadge />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <h6>Billing Address</h6>
              <p className="m-0">{invoice.customer_address}</p>
            </div>
            <div className="col-sm-6">
              <h6>Delivery Address</h6>
              <p className="m-0"></p>
            </div>
          </div>
        </div>

        <div className="trans-content">
          <h6>Invoice Items</h6>
          <table className="table table-sm table-hover">
            <thead>
              <tr>
                <th>Item</th>
                <th>Qty</th>
                <th style={{ textAlign: "right" }}>Rate</th>
                <th style={{ textAlign: "right" }}>Amount</th>
              </tr>
            </thead>
            <tbody>
              {invoice.sale_item_rows.map((row) => {
                return (
                  <tr key={row?.row_id}>
                    <td>
                      <p style={{ fontWeight: 600 }}>
                        {row.sale_item?.item_name}
                      </p>
                      <p>{row.sale_item_description}</p>
                    </td>
                    <td>{row.sale_item_qty}</td>
                    <td style={{ textAlign: "right" }}>{row.sale_item_rate}</td>
                    <td style={{ textAlign: "right" }}>
                      {row.sale_item_amount}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Scrollbars>
      <div className="action-section">
        <div className="action-control">
          {hasPrivilege("Sales", 206) && (
            <button
              className="btn btn-dark b-button"
              onClick={handleInvoiceView}
            >
              View
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
export default InvoiceListRight;
