import "./PrintPreview.scss";
import { useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { hidePrintPreview } from "../../redux/features/modals/PrintPreviewSlice";
import {
  Modal,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
} from "react-bootstrap";
import { X } from "react-bootstrap-icons";
import { PrinterFill } from "react-bootstrap-icons";
import { useReactToPrint } from "react-to-print";

import DefaultFormat from "./formats/DefaultFormat";
import POPrintFormat from "./formats/POPrintFormat";
import GRNPrintFormat from "./formats/GRNPrintFormat";
import SOPrintFormat from "./formats/SOPrintFormat";
import InvoicePrintFormat from "./formats/InvoicePrintFormat";

const PrintPreview = () => {
  const dispatch = useDispatch();
  const contentRef = useRef(null);
  const { isPrintPreview, title, format, data, printable } = useSelector(
    (store) => store.print_preview
  );

  const content = (format, ref) => {
    switch (format) {
      case "PurchaseOrder":
        return <POPrintFormat ref={ref} po={data} />;
      case "GoodReceivedNote":
        return <GRNPrintFormat ref={ref} grn={data} />;
      case "SaleOrder":
        return <SOPrintFormat ref={ref} so={data} />;
      case "SaleInvoice":
        return <InvoicePrintFormat ref={ref} invoice={data} />;
      default:
        return <DefaultFormat />;
    }
  };

  const handleClose = () => dispatch(hidePrintPreview());
  const handlePrint = useReactToPrint({
    content: () => contentRef.current,
  });

  return (
    <Modal
      show={isPrintPreview}
      onHide={handleClose}
      backdrop={false}
      keyboard={false}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      animation={false}
      ria-labelledby="staticBackdropLabel"
      dialogClassName="modal-dialog-scrollable"
      contentClassName="shadow"
      className="print-preview"
    >
      <ModalHeader>
        <ModalTitle>
          <PrinterFill /> Print Preview for - {title}
        </ModalTitle>
        <button
          type="button"
          className="close text-white"
          aria-label="Close"
          onClick={handleClose}
        >
          <span aria-hidden="true">
            <X />
          </span>
        </button>
      </ModalHeader>
      <ModalBody>
        <div className="print-content-wrapper">
          {content(format, contentRef)}
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          className="btn btn-secondary"
          data-dismiss="modal"
          onClick={handleClose}
        >
          Cancel
        </button>
        {printable && (
          <button
            type="button"
            className="btn btn-primary"
            onClick={handlePrint}
          >
            Print
          </button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default PrintPreview;
