import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideOptionModel } from "../../../redux/features/modals/OptionModalSlide";
import axios from "../../../api/axios";
import { autoNotify } from "../../../redux/features/notify/NotifySlice";

const InvoiceProcess = (props) => {
  const { invoice, confirm_callback } = props;
  const dispatch = useDispatch();
  const [isOpening, setIsOpening] = useState(false);
  const { token, branch, user } = useSelector((store) => store.auth);

  const handleOnProcess = async (e) => {
    e.preventDefault();
    setIsOpening(true);
    try {
      let data = {
        ...invoice,
        com_id: branch.com_id,
        invoice_status: "Invoiced",
        updated_by: user.user_id,
      };
      const resp = await axios.post("customers/process-invoice", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      autoNotify(dispatch, resp);
      setIsOpening(false);
      confirm_callback(resp.data?.sale_invoice);
      dispatch(hideOptionModel());
    } catch (error) {
      autoNotify(dispatch, error.response);
      setIsOpening(false);
    }
  };

  const handleOnClose = () => {
    dispatch(hideOptionModel());
  };
  return (
    <div>
      <h5>This will process all inventory and accounting transactions.</h5>
      <p>
        <strong>{invoice.sale_no}</strong> no longer able to edit. Please
        conform your action.
      </p>
      <div className="modal-footer mt-3">
        <button
          type="button"
          className="btn btn-secondary b-button"
          data-dismiss="modal"
          onClick={handleOnClose}
        >
          Dismiss
        </button>
        <button
          type="submit"
          id="user-delete-btn"
          className="btn btn-primary b-button"
          onClick={handleOnProcess}
          disabled={isOpening}
        >
          Process Invoice
        </button>
      </div>
    </div>
  );
};

export default InvoiceProcess;
