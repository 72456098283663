import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideOptionModel } from "../../../redux/features/modals/OptionModalSlide";
import axios from "../../../api/axios";
import { autoNotify } from "../../../redux/features/notify/NotifySlice";

const SOConfirm = (props) => {
  const { so, confirm_callback } = props;
  const dispatch = useDispatch();
  const [isOpening, setIsOpening] = useState(false);
  const { token, user } = useSelector((store) => store.auth);

  const handleOnOpen = async (e) => {
    e.preventDefault();
    setIsOpening(true);
    try {
      const fd = new FormData();
      fd.append("so_id", so.so_id);
      fd.append("so_status", "Confirmed");
      fd.append("updated_by", user.user_id);

      const resp = await axios.post("customers/update-so", fd, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      autoNotify(dispatch, resp);
      setIsOpening(false);
      confirm_callback(resp.data?.so);
      dispatch(hideOptionModel());
    } catch (error) {
      autoNotify(dispatch, error.response);
      setIsOpening(false);
    }
  };

  const handleOnClose = () => {
    dispatch(hideOptionModel());
  };
  return (
    <div>
      <h5>This will confirmed sale order from the customer.</h5>
      <p>
        <strong>{so.so_no}</strong> no longer able to edit. Please conform your
        action.
      </p>
      <div className="modal-footer mt-3">
        <button
          type="button"
          className="btn btn-secondary b-button"
          data-dismiss="modal"
          onClick={handleOnClose}
        >
          Dismiss
        </button>
        <button
          type="submit"
          id="user-delete-btn"
          className="btn btn-primary b-button"
          onClick={handleOnOpen}
          disabled={isOpening}
        >
          Confirm SO
        </button>
      </div>
    </div>
  );
};

export default SOConfirm;
