import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../api/axios";

const initialState = {
  invoiceList: [],
  totalRows: 0,
  isLoading: true,
};

// this will get active invoice list
export const getInvoiceList = createAsyncThunk(
  "sales/SaleInvoiceList",
  async ({ start = 0, length = 10, order = "desc", search = "" }, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const token = auth.token;
      const branch = auth.branch;

      const searchParams = new URLSearchParams();
      searchParams.append("branch_id", branch.branch_id);
      searchParams.append("start", start);
      searchParams.append("length", length);
      searchParams.append("order", order);
      searchParams.append("search", search.trim());
      searchParams.toString();
      const response = await axios.get(
        "customers/invoices?" + searchParams.toString(),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// this will get deleted invoice list
export const getInvoiceDeletedList = createAsyncThunk(
  "sales/SaleInvoiceDeletedList",
  async ({ start = 0, length = 10, order = "desc", search = "" }, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const token = auth.token;
      const branch = auth.branch;

      const searchParams = new URLSearchParams();
      searchParams.append("branch_id", branch.branch_id);
      searchParams.append("start", start);
      searchParams.append("length", length);
      searchParams.append("order", order);
      searchParams.append("search", search.trim());
      searchParams.toString();
      const response = await axios.get(
        "customers/invoices-deleted?" + searchParams.toString(),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const InvoiceListSlice = createSlice({
  name: "sale_invoice_list",
  initialState,
  reducers: {
    updateInvoiceList: (state, action) => {
      state.invoiceList = action.payload;
    },
  },
  extraReducers: {
    [getInvoiceList.pending]: (state) => {
      state.isLoading = true;
    },
    [getInvoiceList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.invoiceList = action.payload.data;
      state.totalRows = action.payload.recordsTotal;
    },
    [getInvoiceList.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getInvoiceDeletedList.pending]: (state) => {
      state.isLoading = true;
    },
    [getInvoiceDeletedList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.invoiceList = action.payload.data;
      state.totalRows = action.payload.recordsTotal;
    },
    [getInvoiceDeletedList.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
});

export const { updateInvoiceList } = InvoiceListSlice.actions;
export default InvoiceListSlice.reducer;
