import { useState, useEffect, forwardRef } from "react";
import classNames from "classnames";
import { formatDate, formatCurrency } from "../../../Helper";

const pageStyle = `
@media all {
  .print-container {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
  }
  .page{
    background-color: #ffffff;
    width:210mm;
    height:297mm;
    padding: 1px;
    overflow: hidden;
    position: relative;
    border-radius: 5px;
    transform-origin: top left;
    -webkit-transform: scale(0.75);
    -moz-transform: scale(0.75);
    -ms-transform: scale(0.75);
    -o-transform: scale(0.75);
    transform: scale(0.75);
    margin-bottom: -250px;
  }
}

@page { 
  size: 210mm 297mm; 
  margin: 0;
}

@media print {
  html, body {
    width: 210mm;
    height: 297mm;
  }
  .page{
    display: block;
    margin: 0px;
    border: initial;
    border-radius: initial;
    width:210mm;
    height:297mm;
    page-break-after: always;
    transform-origin: top left;
    -webkit-transform: scale(1.25);
    -moz-transform: scale(1.25);
    -ms-transform: scale(1.25);
    -o-transform: scale(1.25);
    transform: scale(1.25);
  }
}
`;
const printStyle = `
  .content {
    position: relative;
  }
  .print-item {
    position: absolute;
    border: 1px dashed #9b9b9b;
    word-break: break-word;
    white-space: pre-wrap;
  }
  .print-row{
    display: flex;
    flex-direction: row;
  }
  .print-table{
    border-collapse: collapse;
    width: 100%;
  }
  .not-print{
    visibility:visible;
    opacity: 0.6;
  }
  @media print {
    .print-item {
      border: none;
    }
    .not-print{
      visibility:hidden;
    }
  }
`;
const GRNPrintFormat = forwardRef((props, ref) => {
  const { grn } = props;
  const [setting, setSetting] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const printText = (setting, text) => {
    return (
      <div
        className={classNames("print-item", {
          "not-print": !setting?.print,
        })}
        style={setting?.style}
      >
        {text}
      </div>
    );
  };

  const printImage = (setting, src) => {
    return (
      <img
        src={src}
        alt="print"
        className={classNames("print-item", {
          "not-print": !setting?.print,
        })}
        style={setting?.style}
      />
    );
  };

  const printRow = (setting, label, text) => {
    return (
      <div className="print-item print-row" style={setting?.row}>
        <div
          className={classNames({ "not-print": !setting?.labelPrint })}
          style={setting?.labelStyle}
        >
          {label}
        </div>
        <div
          className={classNames({ "not-print": !setting?.textPrint })}
          style={setting?.textStyle}
        >
          {text}
        </div>
      </div>
    );
  };

  const printCol = (setting, label, text) => {
    return (
      <div className="print-item" style={setting?.col}>
        <div
          className={classNames({ "not-print": !setting?.labelPrint })}
          style={setting?.labelStyle}
        >
          {label}
        </div>
        <div
          className={classNames({ "not-print": !setting?.textPrint })}
          style={setting?.textStyle}
        >
          {text}
        </div>
      </div>
    );
  };

  /*get setting */
  useEffect(() => {
    setIsLoading(true);
    fetch("/setting/print/GRNLayout.json")
      .then((res) => res.json())
      .then((data) => {
        setIsLoading(false);
        setSetting(data);
      });
  }, []);

  return (
    <>
      <style type="text/css">
        {pageStyle}
        {printStyle}
      </style>
      {isLoading ? (
        <div className="page">
          <h4>Wait for your settings..</h4>
        </div>
      ) : (
        <div ref={ref} className="print-container">
          <div className="page">
            <div className="content" style={setting?.page}>
              {/*document title */}
              {printText(setting?.docTitle, "Good Received Note")}
              {/* date info */}
              {printRow(setting?.docDate, "Date", formatDate(grn.grn_date))}
              {printRow(setting?.grnNo, "GRN No", grn.grn_no)}
              {printRow(setting?.invoiceNO, "Invoice No", grn.invoice_no)}
              {/* company header */}
              {printImage(setting?.comLogo, "/img/kayak-print.png")}
              {printText(setting?.comName, "Kayak Surgi Pharma (Pvt) Ltd")}
              {printText(
                setting?.comAddress,
                `490/1B, Lionel Michel Mawatha, Eriyawetiya, Kiribathgoda, Kelaniya, Sri Lanka.`
              )}
              {printText(
                setting?.comTel,
                `Tel : +94 11-50 22 832, +94 11-29 42 952`
              )}
              {printText(
                setting?.comEmail,
                `Email : kayakpharma@sltnet.lk, info@kayakpharma.lk`
              )}
              {printText(setting?.comWeb, `Web : www.kayakpharma.lk`)}
              {/* supplier address */}
              {printCol(
                setting?.supplierAddress,
                "Vendor",
                grn.supplier_address
              )}
              {/* Delivery address */}
              {printCol(
                setting?.deliveryAddress,
                "Delivery To",
                grn.deliver_address
              )}
              {/* item table */}
              <div className="print-item" style={setting?.itemTable.wrapper}>
                <div style={{ position: "relative" }}>
                  <div style={setting?.itemTable.table}>
                    <table className="print-table">
                      <thead
                        className={classNames({
                          "not-print": !setting?.itemTable.tHead.print,
                        })}
                      >
                        <tr>
                          <th style={setting?.itemTable.tHead.code}>
                            Item Code
                          </th>
                          <th style={setting?.itemTable.tHead.desc}>
                            Description
                          </th>
                          <th style={setting?.itemTable.tHead.unit}>
                            Pack Size
                          </th>
                          <th style={setting?.itemTable.tHead.qty}>Qty</th>
                          <th style={setting?.itemTable.tHead.rate}>Price</th>
                          <th style={setting?.itemTable.tHead.amount}>
                            Amount
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {grn.grn_item_rows.map((row) => {
                          return (
                            <tr key={row.row_id}>
                              <td style={setting?.itemTable.tBody.code}>
                                {row?.grn_item.item_sku}
                              </td>
                              <td style={setting?.itemTable.tBody.desc}>
                                <b>{row?.grn_item.item_name}</b>
                                {`\n`}
                                {row?.grn_item_description}
                              </td>
                              <td style={setting?.itemTable.tBody.unit}>
                                {row?.grn_item.unit}
                              </td>
                              <td style={setting?.itemTable.tBody.qty}>
                                {row?.grn_item_qty}
                              </td>
                              <td style={setting?.itemTable.tBody.rate}>
                                {formatCurrency(row?.grn_item_rate)}
                              </td>
                              <td style={setting?.itemTable.tBody.amount}>
                                {formatCurrency(row?.grn_item_amount)}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div style={{ position: "relative" }}>
                  {printRow(
                    setting?.poSubTotal,
                    "Subtotal",
                    formatCurrency(grn.grn_sub_total)
                  )}
                  {printRow(
                    setting?.poDiscount,
                    "Discount",
                    formatCurrency(grn.grn_discount_val)
                  )}
                  {printRow(
                    setting?.poTax,
                    "VAT 15%",
                    formatCurrency(grn.grn_tax_total)
                  )}
                  {printRow(
                    setting?.poTotal,
                    "Total",
                    formatCurrency(grn.grn_total)
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
});

export default GRNPrintFormat;
