import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../api/axios";

const initialState = {
  warehouseList: [],
  isLoading: true,
};

// this will get user role
export const getWarehouseList = createAsyncThunk(
  "warehouse/warehouseList",
  async ({ start = 0, length = 20, order = "desc", search = "" }, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const token = auth.token;
      const branch = auth.branch;

      const searchParams = new URLSearchParams();
      searchParams.append("com_id", branch.com_id);
      searchParams.append("start", start);
      searchParams.append("length", length);
      searchParams.append("order", order);
      searchParams.append("search", search.trim());
      searchParams.toString();
      const response = await axios.get(
        "company/warehouses?" + searchParams.toString(),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const WarehouseSlice = createSlice({
  name: "warehouse",
  initialState,
  reducers: {
    updateWarehouseList: (state, action) => {
      state.warehouseList = action.payload;
    },
  },
  extraReducers: {
    [getWarehouseList.pending]: (state) => {
      state.isLoading = true;
    },
    [getWarehouseList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.warehouseList = action.payload.data;
    },
    [getWarehouseList.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
});

export const { updateWarehouseList } = WarehouseSlice.actions;
export default WarehouseSlice.reducer;
