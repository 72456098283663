import httpClient from "../../../../api/httpClient";

class CustomerService {
  //get customers
  async getCustomers(token, params) {
    const urlSegment = `/${params.branch_id}/${params.start}/${params.length}/${params.order}/q=${params.search}`;
    return await httpClient.get(token, "customers/customers" + urlSegment);
  }
  //create customer
  async createCustomer(token, data) {
    return await httpClient.post(token, "customers/customer", data);
  }
  //update customer
  async updateCustomer(token, data) {
    return await httpClient.put(token, "customers/customer", data);
  }
  //delete customer
  async deleteCustomer(token, id, user_id) {
    return await httpClient.delete(
      token,
      `customers/customer/${user_id}/${id}`
    );
  }
}

export default new CustomerService();
