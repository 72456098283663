import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import AsyncSelect from "react-select/async";
import { debounce } from "../../../Helper";
import axios from "../../../api/axios";

/*select options */
const select_style = {
  control: (base) => ({
    ...base,
    height: 31,
    minHeight: 31,
    borderRadius: 3,
    border: "1px solid #d0d6db",
    boxShadow: "none",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: "31px",
    padding: "0 6px",
  }),
  input: (provided, state) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "31px",
  }),
  menuList: (provided) => ({
    ...provided,
  }),
  menu: (provided) => ({
    ...provided,
    width: "max-content",
    minWidth: "100%",
  }),
};

const getISaleTaxList = async (
  token,
  branch,
  start = 0,
  length = 10,
  order = "desc",
  search = ""
) => {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append("com_id", branch.com_id);
    searchParams.append("start", start);
    searchParams.append("length", length);
    searchParams.append("order", order);
    searchParams.append("search", search);
    searchParams.toString();
    const response = await axios.get(
      "company/sale-taxes?" + searchParams.toString(),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    response.data.data = response.data.data.map((tax) => {
      return {
        ...tax,
        label: tax.tax_name,
        value: tax.tax_id,
      };
    });
    return response.data;
  } catch (error) {
    return [];
  }
};

const SelectTax = (props) => {
  const { selected, callback } = props;
  const { token, branch } = useSelector((store) => store.auth);
  //local stats
  const [options, setOptions] = useState();
  const [isLoading, setIsLoading] = useState(false);
  //events
  const loadListOptions = debounce(async (inputValue, callback) => {
    const options = await getISaleTaxList(
      token,
      branch,
      0,
      10,
      "desc",
      inputValue
    );
    callback(options.data);
  }, 500);

  const handleOnChange = (option) => {
    if (callback) {
      callback(option);
    }
  };

  //effect
  useEffect(() => {
    const unitData = async () => {
      setIsLoading(true);
      const list = await getISaleTaxList(token, branch, 0, 10, "desc", "");
      setOptions(list.data);
      setIsLoading(false);
    };
    unitData();
  }, [token, branch]);

  return (
    <AsyncSelect
      cacheOptions
      defaultOptions={options}
      loadOptions={loadListOptions}
      styles={select_style}
      onChange={handleOnChange}
      value={selected}
      placeholder=""
      isLoading={isLoading}
      name="select_sales_tax"
    />
  );
};
export default React.memo(SelectTax);
