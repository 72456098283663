import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteCustomerCategory,
  resetCustomerCategory,
} from "../../../../redux/features/customers/customerCategorySlice";
import { hideOptionModel } from "../../../../redux/features/modals/OptionModalSlide";
import { addNotify } from "../../../../redux/features/dashboard/notifierSlice";

const CategoryDelete = (props) => {
  const { category, callback } = props;
  const { isSubmitting, success, error } = useSelector(
    (store) => store.customerCategory
  );
  const dispatch = useDispatch();
  //action
  const handleFormSubmit = (e) => {
    e.preventDefault();
    dispatch(deleteCustomerCategory(category.cat_id));
  };
  const handleOnClose = () => {
    dispatch(hideOptionModel());
  };

  //effect
  //show notification
  useEffect(() => {
    if (success) {
      dispatch(addNotify({ type: "SUCCESS", content: success }));
      callback(success.data.cat_id);
      dispatch(resetCustomerCategory());
      dispatch(hideOptionModel());
    }
    if (error) {
      dispatch(addNotify({ type: "ERROR", content: error }));
      dispatch(resetCustomerCategory());
    }
  }, [dispatch, success, error, callback]);

  return (
    <div>
      <h5>You are going to delete customer category.</h5>
      <p>
        <strong>"{category.cat_name}"</strong> no longer available. Please
        Conform your action.
      </p>
      <div className="modal-footer mt-3">
        <button
          type="button"
          className="btn btn-secondary b-button"
          data-dismiss="modal"
          onClick={handleOnClose}
        >
          Dismiss
        </button>
        <button
          type="submit"
          id="user-delete-btn"
          className="btn btn-primary b-button"
          onClick={handleFormSubmit}
          disabled={isSubmitting}
        >
          Delete
        </button>
      </div>
    </div>
  );
};
export default CategoryDelete;
