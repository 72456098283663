import { useState, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars-2";
import DatePicker from "react-datepicker";
import moment from "moment";

import SelectAdjustmentType from "./SelectAdjustmentType";
import SelectItem from "./SelectItem";
import AdjustmentQuantity from "./AdjustmentQuantity";
import AdjustmentValue from "./AdjustmentValue";
import SelectExpensesAccount from "./SelectExpensesAccount";
import SelectReason from "./SelectReason";

import axios from "../../../api/axios";
import { autoNotify } from "../../../redux/features/notify/NotifySlice";
import { hidePrimaryModel } from "../../../redux/features/modals/PrimaryModalSlice";

const init = {
  adjustment_id: null,
  adjustment_type: null,
  inventory_item: null,
  adjustment_date: new Date(),
  adjustment_account: null,
  adjustment_reason: null,
  adjustment_description: "",
};
const StockAdjustment = () => {
  const dispatch = useDispatch();
  const { token, branch, user } = useSelector((store) => store.auth);
  //locale
  const [adjustment, setAdjustment] = useState(init);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const quantityAdjustments = useRef();
  const valueAdjustment = useRef();

  //events
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAdjustment((adjustment) => ({ ...adjustment, [name]: value }));
  };

  const handleDateChange = (name, value) => {
    setAdjustment((adjustment) => ({ ...adjustment, [name]: value }));
  };

  const handleSelectChange = useCallback((name, value) => {
    setAdjustment((adjustment) => ({ ...adjustment, [name]: value }));
  }, []);

  const qtyChangeCallback = useCallback((qtyAdjustments) => {
    quantityAdjustments.current = qtyAdjustments;
  }, []);

  const valueChangeCallback = useCallback((valueAdjustments) => {
    valueAdjustment.current = valueAdjustments;
  }, []);

  //actions
  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    try {
      const data = {
        ...adjustment,
        quantity_adjustments: quantityAdjustments.current,
        value_adjustment: valueAdjustment.current,
        com_id: branch?.com_id,
        branch_id: branch?.branch_id,
        created_by: user?.user_id,
      };
      const resp = await axios.post("inventory/add-stock-adjustment", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      autoNotify(dispatch, resp);
      setIsSubmitting(false);
      dispatch(hidePrimaryModel());
    } catch (error) {
      autoNotify(dispatch, error.response);
      setIsSubmitting(false);
    }
  };

  const handleFormClose = () => {
    dispatch(hidePrimaryModel());
  };

  return (
    <>
      <div className="row">
        <div className="col-6">
          <div className="form-row">
            <div className="form-group col-md-8 mb-1">
              <label htmlFor="adjustment_type">Select Adjustment Type</label>
              <SelectAdjustmentType
                name={"adjustment_type"}
                value={adjustment.adjustment_type}
                callback={handleSelectChange}
              />
            </div>
          </div>
        </div>
        <div className="col-6"></div>
      </div>
      <div className="row">
        <div className="col-6">
          <div className="form-row">
            <div className="form-group col-md-12 mb-3">
              <label htmlFor="inventory_item">Select Item</label>
              <SelectItem
                name={"inventory_item"}
                value={adjustment.inventory_item}
                callback={handleSelectChange}
              />
            </div>
          </div>
          <Scrollbars style={{ width: "100%", height: "200px" }}>
            {adjustment.inventory_item &&
            (adjustment.adjustment_type?.value === 1 ||
              adjustment.adjustment_type?.value === 2) ? (
              <AdjustmentQuantity
                item={adjustment.inventory_item}
                type={adjustment.adjustment_type}
                callback={qtyChangeCallback}
              />
            ) : null}
            {adjustment.inventory_item &&
            adjustment.adjustment_type?.value === 3 ? (
              <AdjustmentValue
                item={adjustment.inventory_item}
                type={adjustment.adjustment_type}
                callback={valueChangeCallback}
              />
            ) : null}
          </Scrollbars>
        </div>
        <div className="col-6">
          <div className="form-row">
            <div className="form-group col-md-6  mb-1">
              <label htmlFor="adjustment_date">Date</label>
              <DatePicker
                className="form-control"
                dateFormat="yyyy-MM-dd"
                id="adjustment_date"
                name="adjustment_date"
                autoComplete="off"
                selected={Date.parse(adjustment.adjustment_date)}
                onChange={(date) => {
                  let newDate = moment(Date.parse(date)).format("YYYY-MM-DD");
                  handleDateChange("adjustment_date", newDate);
                }}
              />
            </div>
            <div className="form-group col-md-6 mb-1">
              <label htmlFor="adjustment_account">Expenses Account</label>
              <SelectExpensesAccount
                account_no="60008"
                name={"adjustment_account"}
                value={adjustment.adjustment_account}
                callback={handleSelectChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-12 mb-3">
              <label htmlFor="adjustment_reason">Select Reason</label>
              <SelectReason
                name={"adjustment_reason"}
                value={adjustment.adjustment_reason}
                callback={handleSelectChange}
              />
            </div>
          </div>
          <div className="form-group mb-1">
            <label htmlFor="adjustment_description">Description</label>
            <textarea
              className="form-control"
              id="adjustment_description"
              rows="4"
              name="adjustment_description"
              value={adjustment.adjustment_description}
              onChange={handleInputChange}
            ></textarea>
          </div>
        </div>
      </div>
      <div className="modal-footer mt-1">
        <button
          type="button"
          className="btn btn-secondary b-button"
          data-dismiss="modal"
          onClick={handleFormClose}
        >
          Close
        </button>
        <button
          type="submit"
          id="user-save-btn"
          className="btn btn-primary b-button"
          disabled={isSubmitting}
          onClick={handleSubmit}
        >
          Add Adjustment
        </button>
      </div>
    </>
  );
};
export default StockAdjustment;
