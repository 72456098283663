import { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import Moment from "moment";
import SelectItemInventory from "../../../components/form/select/SelectItemInventory";
import SelectWarehouse from "../../../components/form/select/SelectWarehouse";
import SelectBatch from "./SelectBatch";
import { BoxFill } from "react-bootstrap-icons";
import axios from "../../../api/axios";

import { hidePrimaryModel } from "../../../redux/features/modals/PrimaryModalSlice";
import { autoNotify } from "../../../redux/features/notify/NotifySlice";

const AddStock = (props) => {
  const { callback } = props;
  const dispatch = useDispatch();
  //Global
  const { token, branch, user } = useSelector((store) => store.auth);
  //locale state
  const [isNewBatch, setIsNewBatch] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [available, setAvailable] = useState(0);
  const [unit, setUnit] = useState();

  const [form, setForm] = useState({
    com_id: branch?.com_id,
    branch_id: branch?.branch_id,
    batch_id: "",
    inventory_item_id: "",
    unit_id: "",
    item_type: "",
    item_sku: "",
    warehouse_id: 0,
    warehouse: 0,
    lot_number: "",
    batch_location: "",
    batch_cost_price: 0,
    batch_sale_price: 0,
    batch_made_date: "",
    batch_expiry_date: "",
    barcode: "",
    batch_quantity_on_hand: 0,
    created_by: user?.user_id,
  });

  //event
  const itemSelectHandler = useCallback((option) => {
    setForm((prevData) => {
      return {
        ...prevData,
        inventory_item_id: option?.item_id,
        unit_id: option?.unit_id,
        item_type: option?.item_type,
        item_sku: option?.item_sku,
      };
    });
    setUnit(option.unit);
  }, []);

  const batchSelectHandler = useCallback((option) => {
    if (option?.batch_id) {
      setIsNewBatch(false);
      // set batch data
      setForm((prevData) => {
        return {
          ...prevData,
          batch_id: option?.batch_id,
          batch_location: option?.batch_location ? option?.batch_location : "",
          barcode: option?.barcode ? option?.barcode : "",
          warehouse_id: option?.warehouse_id ? option?.warehouse_id : "",
          warehouse: option?.warehouse
            ? {
                ...option.warehouse,
                label: option.warehouse.ware_name,
                value: option.warehouse.ware_id,
              }
            : 0,
          lot_number: option?.lot_number ? option?.lot_number : "",
          batch_sale_price: option?.batch_sale_price
            ? option?.batch_sale_price
            : "",
          batch_cost_price: option?.batch_cost_price
            ? option?.batch_cost_price
            : "",
          batch_made_date: option?.batch_made_date
            ? option?.batch_made_date
            : "",
          batch_expiry_date: option?.batch_expiry_date
            ? option?.batch_expiry_date
            : "",
        };
      });
      setAvailable(option?.batch_quantity_on_hand);
    } else {
      //new batch
      setForm((prevData) => {
        return { ...prevData, batch_id: "" };
      });
      setAvailable(0);
    }
  }, []);

  const warehouseHandler = useCallback((option) => {
    setForm((prevData) => {
      return { ...prevData, warehouse_id: option?.value };
    });
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm((prevData) => {
      return { ...prevData, [name]: value };
    });
  };

  const handleDateChange = (date, name) => {
    if (date) {
      let formatted_date = Moment(date).format("YYYY-MM-DD");
      setForm((prevData) => {
        return { ...prevData, [name]: formatted_date };
      });
    }
  };

  //actions
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    try {
      const resp = await axios.post("inventory/add-batch-stock", form, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      autoNotify(dispatch, resp);
      setIsSubmitting(false);
      if (callback) {
        callback(resp.data.batch_with_item);
      }
      dispatch(hidePrimaryModel());
    } catch (error) {
      autoNotify(dispatch, error.response);
      setIsSubmitting(false);
    }
  };

  const handleFormClose = () => {
    dispatch(hidePrimaryModel());
  };

  return (
    <form id="add-new -stock" method="post" onSubmit={handleFormSubmit}>
      <div className="row">
        <div className="col-6">
          <div className="form-group mb-1">
            <label htmlFor="user_title">Select Item</label>
            <SelectItemInventory callback={itemSelectHandler} />
          </div>
          <div className="form-row">
            <div className="form-group col-md-8 mb-1">
              <label htmlFor="user_title">Select Item Batch</label>
              <SelectBatch
                item_id={form.inventory_item_id}
                isNewBatch={isNewBatch}
                callback={batchSelectHandler}
              />
            </div>
          </div>
          <div className="form-group mb-1">
            <label htmlFor="batch_location">Rack Location</label>
            <input
              type="text"
              className="form-control"
              id="batch_location"
              name="batch_location"
              value={form.batch_location}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group mb-1">
            <label htmlFor="barcode">MPN/Barcode</label>
            <input
              type="text"
              className="form-control"
              id="barcode"
              name="barcode"
              value={form.barcode}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-row">
            <div className="form-group col-md-9 mb-1">
              <label htmlFor="user_title">Warehouse</label>
              <SelectWarehouse
                selected={form.warehouse}
                callback={warehouseHandler}
              />
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="form-row">
            <div className="form-group col-md-8 mb-1">
              <label htmlFor="lot_number">Lot Number</label>
              <input
                type="text"
                className="form-control"
                id="lot_number"
                name="lot_number"
                value={form.lot_number}
                onChange={(e) => {
                  setIsNewBatch(true);
                  handleInputChange(e);
                }}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-5 mb-1">
              <label htmlFor="batch_sale_price">Selling Price</label>
              <input
                type="number"
                className="form-control"
                id="batch_sale_price"
                name="batch_sale_price"
                value={form.batch_sale_price}
                onChange={(e) => {
                  setIsNewBatch(true);
                  handleInputChange(e);
                }}
              />
            </div>
            <div className="form-group col-md-5 mb-1">
              <label htmlFor="batch_cost_price">Cost Price</label>
              <input
                type="number"
                className="form-control"
                id="batch_cost_price"
                name="batch_cost_price"
                value={form.batch_cost_price}
                onChange={(e) => {
                  setIsNewBatch(true);
                  handleInputChange(e);
                }}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-5 mb-1">
              <label htmlFor="batch_made_date">Made Date</label>
              <DatePicker
                className="form-control"
                dateFormat="yyyy-MM-dd"
                id="batch_made_date"
                autoComplete="off"
                selected={Date.parse(form.batch_made_date)}
                onChange={(date) => {
                  setIsNewBatch(true);
                  handleDateChange(date, "batch_made_date");
                }}
                name="batch_made_date"
              />
            </div>
            <div className="form-group col-md-5 mb-1">
              <label htmlFor="batch_expiry_date">Expiry Date</label>
              <DatePicker
                className="form-control"
                dateFormat="yyyy-MM-dd"
                id="batch_expiry_date"
                autoComplete="off"
                selected={Date.parse(form.batch_expiry_date)}
                onChange={(date) => {
                  setIsNewBatch(true);
                  handleDateChange(date, "batch_expiry_date");
                }}
                name="batch_expiry_date"
              />
            </div>
          </div>
          <hr />
          <div className="form-row">
            <div className="form-group col-md-5 mb-1">
              <label htmlFor="batch_quantity_on_hand">
                <BoxFill /> Add Stock Quantity
              </label>
              <input
                type="number"
                className="form-control"
                id="batch_quantity_on_hand"
                name="batch_quantity_on_hand"
                value={form.batch_quantity_on_hand}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group col-md-5 mb-1">
              <label htmlFor="available">Available Qty</label>
              <h5>
                {available} {unit}
              </h5>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer mt-3">
        <button
          type="button"
          className="btn btn-secondary b-button"
          data-dismiss="modal"
          onClick={handleFormClose}
        >
          Close
        </button>
        <button
          type="submit"
          id="user-save-btn"
          className="btn btn-primary b-button"
          disabled={isSubmitting}
        >
          Add Stock
        </button>
      </div>
    </form>
  );
};

export default AddStock;
