import { memo, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "../../../api/axios";

const getBatchList = async (
  token,
  branch,
  start = 0,
  length = 10,
  order = "desc",
  search = "",
  itemId = ""
) => {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append("com_id", branch.com_id);
    searchParams.append("item_id", itemId);
    searchParams.append("start", start);
    searchParams.append("length", length);
    searchParams.append("order", order);
    searchParams.append("search", search);
    searchParams.toString();
    const response = await axios.get(
      "inventory/batch-list?" + searchParams.toString(),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    response.data.data = response.data.data.map((batch) => {
      return {
        ...batch,
        label: "Batch - " + batch.batch_id,
        value: batch.batch_id,
      };
    });
    return response.data;
  } catch (error) {
    return [];
  }
};

const AdjustmentQuantity = (props) => {
  const { item, callback } = props;
  //global
  const { token, branch } = useSelector((store) => store.auth);
  //local stats
  const [adjustments, setAdjustments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  //event
  /* this will update the adjustments array */
  const onHandQtyChange = (adjust) => {
    const updated = adjustments.map((adjustment) => {
      if (adjustment.batch_id === adjust.batch_id) {
        return adjust;
      }
      return adjustment;
    });
    setAdjustments(updated);
    callback(updated);
  };

  //effect
  useEffect(() => {
    const batchData = async () => {
      setIsLoading(true);
      const list = await getBatchList(
        token,
        branch,
        0,
        50,
        "desc",
        "",
        item?.item_id
      );

      const adjustmentList = list.data.map((batch) => {
        return {
          batch_id: batch?.batch_id,
          label: batch?.label,
          lot_number: batch?.lot_number,
          batch_cost_price: batch?.batch_cost_price,
          batch_quantity_on_hand: batch?.batch_quantity_on_hand,
          batch_quantity_updated: batch?.batch_quantity_on_hand,
          quantity_adjusted: 0,
          value_adjusted: 0,
        };
      });
      setAdjustments(adjustmentList);
      setIsLoading(false);
    };
    batchData();
  }, [branch, item, token]);

  return (
    <>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <table className="table table-sm table-hover">
          <thead>
            <tr>
              <th>Batches</th>
              <th style={{ width: "23%" }}>Available</th>
              <th style={{ width: "26%" }}>On Hand</th>
            </tr>
          </thead>
          <tbody>
            {adjustments.map((adjust, i) => {
              return (
                <tr key={i}>
                  <td className="align-middle">
                    {adjust?.label}{" "}
                    <p style={{ color: "#9ba0a7", marginBottom: "0px" }}>
                      {adjust?.lot_number
                        ? "lot ( " + adjust.lot_number + " )"
                        : ""}
                    </p>
                  </td>
                  <td className="align-middle">
                    {adjust?.batch_quantity_on_hand}
                  </td>
                  <td className="align-middle">
                    <input
                      type="number"
                      className="form-control"
                      value={adjust?.batch_quantity_updated}
                      onChange={(e) => {
                        //set value
                        const value = e.target.value
                          ? parseInt(e.target.value)
                          : 0;
                        //set adjusted qty
                        const qty_adjusted =
                          value - adjust?.batch_quantity_on_hand;
                        //set adjusted value
                        const value_adjusted =
                          adjust?.batch_cost_price * qty_adjusted;

                        const newAdjust = {
                          ...adjust,
                          batch_quantity_updated: value,
                          quantity_adjusted: qty_adjusted,
                          value_adjusted: value_adjusted,
                        };
                        onHandQtyChange(newAdjust);
                      }}
                      title={"Adjusted :" + adjust?.quantity_adjusted}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </>
  );
};

export default memo(AdjustmentQuantity);
