import "./SearchBar.scss";
import { Search } from "react-bootstrap-icons";

const SearchBar = (props) => {
  return (
    <div className="form-group default-search-bar">
      <span className="form-control-feedback">
        <Search />
      </span>
      <input
        type="text"
        className="form-control"
        defaultValue=""
        onChange={props.searchHandler}
        placeholder={props.placeholder}
      />
    </div>
  );
};

export default SearchBar;
