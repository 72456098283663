import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Invoice from "../../../components/workplace/invoice/Invoice";

import {
  getSaleNumber,
  setSaleDate,
  newTableRow,
} from "../../../redux/features/sales/InvoiceSlice";

const InvoiceNew = () => {
  const dispatch = useDispatch();
  const { saleNo, saleDate, tableRowCounter } = useSelector(
    (store) => store.sale_invoice
  );

  // set New PO data
  const isNewSaleNo = saleNo === "";
  const isZeroRow = tableRowCounter === 0;
  const isNewSaleDate = saleDate === "";

  useEffect(() => {
    if (isNewSaleNo) dispatch(getSaleNumber());
    if (isNewSaleDate) dispatch(setSaleDate(new Date()));
    if (isZeroRow) dispatch(newTableRow());
  }, [isNewSaleNo, dispatch, isZeroRow, isNewSaleDate]);

  return <Invoice action="New" />;
};

export default InvoiceNew;
