import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../api/axios";

const initialState = {
  data: [],
  recordsTotal: 0,
  recordsFiltered: 0,
  currentPage: 1,
  perPage: 10,
  isLoading: true,
};

// this will get user role
export const getUserRoleList = createAsyncThunk(
  "users/userRoleList",
  async ({ search }, thunkAPI) => {
    try {
      const { auth, user_role_list } = thunkAPI.getState();
      const token = auth.token;
      const branch = auth.branch;
      // const numberOfPages = Math.ceil(state.recordsTotal / state.perPage);
      const start = (user_role_list.currentPage - 1) * user_role_list.perPage;

      const searchParams = new URLSearchParams();
      searchParams.append("com_id", branch.com_id);
      searchParams.append("start", start);
      searchParams.append("length", user_role_list.perPage);
      searchParams.append("order", "desc");
      searchParams.append("search", search.trim());
      searchParams.toString();
      const response = await axios.get(
        "users/role-list?" + searchParams.toString(),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const UserRoleListSlice = createSlice({
  name: "user_role_list",
  initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setPerPage: (state, action) => {
      state.perPage = action.payload;
    },
    updateData: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers: {
    [getUserRoleList.pending]: (state) => {
      state.isLoading = true;
    },
    [getUserRoleList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.recordsTotal = action.payload.recordsTotal;
      state.recordsFiltered = action.payload.recordsFiltered;
      state.data = action.payload.data;
    },
    [getUserRoleList.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
});

export const { setCurrentPage, setPerPage, updateData } =
  UserRoleListSlice.actions;
export default UserRoleListSlice.reducer;
