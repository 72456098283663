import { memo, useState } from "react";
import SelectCategory from "./SelectCategory";
import DatePicker from "react-datepicker";
import moment from "moment";

const FormAdvanced = (props) => {
  const { customer, changeCallback } = props;
  //local
  const [form, setForm] = useState(customer);

  //events
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
    changeCallback(name, value);
  };

  const handleSelectChange = (name, value) => {
    setForm({ ...form, [name]: value });
    changeCallback(name, value);
  };

  const handleDateChange = (name, value) => {
    setForm({ ...form, [name]: value });
    changeCallback(name, value);
  };

  return (
    <div className="row">
      <div className="col-6">
        <div className="form-group mb-3">
          <label htmlFor="cust_company">Company</label>
          <input
            type="text"
            className="form-control"
            id="cust_company"
            name="cust_company"
            value={form.cust_company}
            onChange={handleInputChange}
          />
        </div>
        <div className="contact-person-wrapper">
          <p>Contact Person</p>
          <div className="form-row">
            <div className="form-group col-md-6 mb-1">
              <label htmlFor="cust_first_name">First Name</label>
              <input
                type="text"
                className="form-control"
                id="cust_first_name"
                name="cust_first_name"
                value={form.cust_first_name}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group col-md-6  mb-1">
              <label htmlFor="cust_last_name">Last Name</label>
              <input
                type="text"
                className="form-control"
                id="cust_last_name"
                name="cust_last_name"
                value={form.cust_last_name}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-8 mb-1">
              <label htmlFor="cust_mobile">Mobile</label>
              <input
                type="text"
                className="form-control"
                id="cust_mobile"
                name="cust_mobile"
                value={form.cust_mobile}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>
        <div className="form-group mb-1">
          <label htmlFor="cust_notes">Notes</label>
          <textarea
            className="form-control"
            id="cust_notes"
            rows="2"
            name="cust_notes"
            value={form.cust_notes}
            onChange={handleInputChange}
          ></textarea>
        </div>
      </div>
      <div className="col-6">
        <div className="form-group mb-3">
          <label htmlFor="category">Customer Category</label>
          <SelectCategory
            name={"category"}
            onChange={handleSelectChange}
            value={form.category}
          />
        </div>
        <div className="form-row">
          <div className="form-group col-md-6 mb-1">
            <label htmlFor="cust_ledger_balance">Opening balance</label>
            <input
              type="number"
              className="form-control"
              id="cust_ledger_balance"
              name="cust_ledger_balance"
              value={form.cust_ledger_balance}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group col-md-6  mb-1">
            <label htmlFor="cust_balance_opendate">as of</label>
            <DatePicker
              className="form-control"
              dateFormat="yyyy-MM-dd"
              id="cust_balance_opendate"
              name="cust_balance_opendate"
              autoComplete="off"
              selected={Date.parse(form.cust_balance_opendate)}
              onChange={(date) => {
                let newDate = moment(Date.parse(date)).format("YYYY-MM-DD");
                handleDateChange("cust_balance_opendate", newDate);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(FormAdvanced);
