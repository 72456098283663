import { Navigate, Routes, Route, Outlet } from "react-router-dom";

import Error404 from "../errors/dashboard/Error404";
import ComSetting from "./com/ComSetting";

const Company = () => {
  if (false) {
    return <Navigate to="/dashboard" />;
  }
  return (
    <>
      <Outlet />
      <Routes>
        <Route index element={<ComSetting />} />
        {/* New Company handle by main menu */}
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
};

export default Company;
