import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import AsyncSelect from "react-select/async";
import axios from "../../../api/axios";
import { debounce } from "../../../Helper";

/*select options */
const select_style = {
  control: (base) => ({
    ...base,
    height: 31,
    minHeight: 31,
    borderRadius: 3,
    border: "1px solid #d0d6db",
    boxShadow: "none",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: "31px",
    padding: "0 6px",
  }),
  input: (provided, state) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "31px",
  }),
  menuList: (provided) => ({
    ...provided,
  }),
  menu: (provided) => ({
    ...provided,
    width: "max-content",
    minWidth: "100%",
  }),
};

const getPOList = async (
  token,
  branch,
  start = 0,
  length = 10,
  order = "desc",
  search = "",
  supp_id = 0,
  po_status = "Open"
) => {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append("branch_id", branch.branch_id);
    searchParams.append("supp_id", branch.branch_id);
    searchParams.append("start", start);
    searchParams.append("length", length);
    searchParams.append("order", order);
    searchParams.append("search", search);
    searchParams.append("supp_id", supp_id);
    searchParams.append("po_status", po_status);
    searchParams.toString();
    const response = await axios.get(
      "purchase/po-list?" + searchParams.toString(),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    response.data.data = response.data.data.map((po) => {
      return {
        ...po,
        label: po.po_no,
        value: po.po_id,
      };
    });
    return response.data;
  } catch (error) {
    return [];
  }
};

const SelectPO = (props) => {
  const { selected, supplier, callback } = props;
  const { token, branch } = useSelector((store) => store.auth);
  //local stats
  const [options, setOptions] = useState();
  const [isLoading, setIsLoading] = useState(false);
  //events
  const loadListOptions = debounce(async (inputValue, callback) => {
    const options = await getPOList(
      token,
      branch,
      0,
      10,
      "desc",
      inputValue,
      supplier.supp_id,
      "Open"
    );
    callback(options.data);
  }, 500);

  const handleOnChange = (option) => {
    if (callback) {
      callback(option);
    }
  };

  //effect
  useEffect(() => {
    const unitData = async () => {
      setIsLoading(true);
      const list = await getPOList(
        token,
        branch,
        0,
        10,
        "desc",
        "",
        supplier.supp_id,
        "Open"
      );
      setOptions(list.data);
      setIsLoading(false);
    };
    if (supplier) {
      unitData();
    }
  }, [token, branch, supplier]);

  return (
    <AsyncSelect
      cacheOptions
      defaultOptions={options}
      loadOptions={loadListOptions}
      styles={select_style}
      onChange={handleOnChange}
      value={selected}
      placeholder="Select.."
      isLoading={isLoading}
      isClearable={true}
      name="purchase_order_id"
    />
  );
};
export default React.memo(SelectPO);
