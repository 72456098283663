import "./Invoice.scss";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";

import InvoiceCtrl from "./InvoiceCtrl";
import WorkplaceTable from "./WorkplaceTable";
import SelectPaymentTerms from "../../form/select/SelectPaymentTerms";

import {
  setSaleNumber,
  setSaleDate,
  setSalesRepName,
  setSaleBillingAddress,
  setOrderNo,
  setOrderDate,
  setReference,
  setNote,
  setTerms,
  setPaymentTerms,
  setPaymentDue,
} from "../../../redux/features/sales/InvoiceSlice";

const Invoice = (prop) => {
  const dispatch = useDispatch();
  const action = prop.action;

  const {
    saleNo,
    saleDate,
    salesRepName,
    billingAddress,
    orderNo,
    orderDate,
    reference,
    note,
    terms,
    paymentTerm,
    paymentDue,
  } = useSelector((store) => store.sale_invoice);

  /*change handlers */
  const saleNoOnChange = (e) => {
    const { value } = e.target;
    dispatch(setSaleNumber(value));
  };

  const saleDateOnChange = (date) => {
    //console.log(Date.parse("2022-10-30"));
    dispatch(setSaleDate(date));
  };

  const salesRepNameOnChange = (e) => {
    const { value } = e.target;
    dispatch(setSalesRepName(value));
  };

  const billingAddressOnChange = (e) => {
    const { value } = e.target;
    dispatch(setSaleBillingAddress(value));
  };

  const orderNoOnChange = (e) => {
    const { value } = e.target;
    dispatch(setOrderNo(value));
  };

  const orderDateOnChange = (date) => {
    dispatch(setOrderDate(date));
  };

  const referenceOnChange = (e) => {
    const { value } = e.target;
    dispatch(setReference(value));
  };

  const noteOnChange = (e) => {
    const { value } = e.target;
    dispatch(setNote(value));
  };

  const termsOnChange = (e) => {
    const { value } = e.target;
    dispatch(setTerms(value));
  };

  const paymentDueOnChange = (date) => {
    dispatch(setPaymentDue(date));
  };

  const paymentTermChangeHandler = useCallback(
    (option) => {
      dispatch(setPaymentTerms(option));
    },
    [dispatch]
  );

  return (
    <div className="new-purchase">
      <div className="doc-editor">
        <div className="doc-editor-workplace">
          <div className="workplace-header">
            <div className="row no-gutters">
              <div className="col-md-5">
                <div className="form-row">
                  <div className="form-group col-md-6  mb-0">
                    <label htmlFor="sale_no">Invoice#</label>
                    <input
                      type="text"
                      className="form-control"
                      name="sale_no"
                      id="sale_no"
                      value={saleNo}
                      onChange={saleNoOnChange}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-5 mb-0">
                    <label htmlFor="sale_date">Invoice Date</label>
                    <DatePicker
                      className="form-control"
                      dateFormat="yyyy-MM-dd"
                      id="sale_date"
                      name="sale_date"
                      autoComplete="off"
                      selected={Date.parse(saleDate)}
                      onChange={saleDateOnChange}
                    />
                  </div>
                  <div className="form-group col-md-5 mb-1">
                    <label htmlFor="sales_rep">Sales Rep</label>
                    <input
                      type="text"
                      className="form-control"
                      name="sales_rep"
                      id="sales_rep"
                      value={salesRepName}
                      onChange={salesRepNameOnChange}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="row no-gutters">
                  <div className="col-md-5 pl-2"></div>
                  <div className="col-md-7 pl-2">
                    <div className="form-group">
                      <label htmlFor="billing_address">Billing Address</label>
                      <textarea
                        className="form-control"
                        id="billing_address"
                        rows="5"
                        name="billing_address"
                        value={billingAddress}
                        onChange={billingAddressOnChange}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <WorkplaceTable />
          <div className="workplace-footer">
            <div className="row mb-1">
              <div className="col-md-5">
                <div className="form-row">
                  <div className="form-group col-md-7 mb-1">
                    <label htmlFor="order-no">Order#</label>
                    <input
                      type="text"
                      className="form-control"
                      name="order-no"
                      id="order-no"
                      value={orderNo}
                      onChange={orderNoOnChange}
                    />
                  </div>
                  <div className="form-group col-md-5 mb-1">
                    <label htmlFor="order-date">Order Date</label>
                    <DatePicker
                      className="form-control"
                      dateFormat="yyyy-MM-dd"
                      id="order-date"
                      name="order-date"
                      autoComplete="off"
                      selected={Date.parse(orderDate)}
                      onChange={orderDateOnChange}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="form-row">
                  <div className="form-group col-md-7 ">
                    <label htmlFor="reference">Reference#</label>
                    <input
                      type="text"
                      className="form-control"
                      name="reference"
                      id="reference"
                      value={reference}
                      onChange={referenceOnChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-5">
                <div className="form-row">
                  <div className="form-group col-md-7 mb-1">
                    <label htmlFor="payment-term">Payment Terms</label>
                    <SelectPaymentTerms
                      selected={paymentTerm}
                      callback={paymentTermChangeHandler}
                    />
                  </div>
                  <div className="form-group col-md-5 mb-1">
                    <label htmlFor="payment-due">Payment Due</label>
                    <DatePicker
                      className="form-control"
                      dateFormat="yyyy-MM-dd"
                      id="payment-due"
                      name="payment-due"
                      autoComplete="off"
                      selected={Date.parse(paymentDue)}
                      onChange={paymentDueOnChange}
                    />
                  </div>
                </div>
                <div className="form-group mt-1">
                  <label htmlFor="note">Note</label>
                  <textarea
                    className="form-control"
                    id="note"
                    rows="3"
                    name="note"
                    value={note}
                    onChange={noteOnChange}
                  ></textarea>
                </div>
              </div>
              <div className="col-md-7">
                <div className="form-group">
                  <label htmlFor="terms">Terms & Conditions</label>
                  <textarea
                    className="form-control"
                    id="terms"
                    rows="6"
                    name="terms"
                    value={terms}
                    onChange={termsOnChange}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="doc-editor-control-wrapper">
          <div className="doc-editor-control">
            <InvoiceCtrl action={action} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
