import { configureStore } from "@reduxjs/toolkit";
//import storage from "redux-persist/lib/storage";
import storage from "redux-persist-indexeddb-storage";
import { persistReducer, persistStore } from "redux-persist";
import { combineReducers } from "@reduxjs/toolkit";
import thunk from "redux-thunk";

import AuthSlice from "./features/auth/AuthSlice";
import NotifySlice from "./features/notify/NotifySlice";
import notifierSlice from "./features/dashboard/notifierSlice";
import RightPanelSlice from "./features/rightpanel/RightPanelSlice";
import MainMenuSlice from "./features/dashboard/MainMenuSlice";
import PrimaryModalSlice from "./features/modals/PrimaryModalSlice";
import OptionModalSlide from "./features/modals/OptionModalSlide";
import PrintPreviewSlice from "./features/modals/PrintPreviewSlice";

import UserListSlice from "./features/users/UserListSlice";
import UserRoleListSlice from "./features/users/UserRoleListSlice";

import WarehouseSlice from "./features/warehouses/WarehouseSlice";
import InventoryItemSlice from "./features/inventory/InventoryItemSlice";

import SupplierListSlice from "./features/suppliers/SupplierListSlice";
import PurchaseOrderSlice from "./features/purchases/PurchaseOrderSlice";
import PurchaseOrderListSlice from "./features/purchases/PurchaseOrderListSlice";
import GRNSlice from "./features/purchases/GRNSlice";
import GRNListSlice from "./features/purchases/GRNListSlice";
import ProductListSlice from "./features/inventory/ProductListSlice";

import customerSlice from "./features/customers/customerSlice";
import customerCategorySlice from "./features/customers/customerCategorySlice";
import salesRepSlice from "./features/sales/salesRepSlice";

import SaleOrderSlice from "./features/sales/SaleOrderSlice";
import SaleOrderListSlice from "./features/sales/SaleOrderListSlice";
import InvoiceSlice from "./features/sales/InvoiceSlice";
import InvoiceListSlice from "./features/sales/InvoiceListSlice";

const persistConfig = {
  key: "root",
  version: 1,
  storage: storage("bizenicaDB"),
  whitelist: [
    "auth",
    "main_menu",
    "purchase_order",
    "goods_received_note",
    "sale_order",
    "sale_invoice",
  ],
};

const combinedReducer = combineReducers({
  auth: AuthSlice,
  notify: NotifySlice,
  notifier: notifierSlice,
  main_menu: MainMenuSlice,
  primary_modal: PrimaryModalSlice,
  option_modal: OptionModalSlide,
  print_preview: PrintPreviewSlice,
  right_panel: RightPanelSlice,
  user_list: UserListSlice,
  user_role_list: UserRoleListSlice,
  supplier_list: SupplierListSlice,
  warehouse: WarehouseSlice,
  inventory_item: InventoryItemSlice,
  purchase_order: PurchaseOrderSlice,
  purchase_order_list: PurchaseOrderListSlice,
  goods_received_note: GRNSlice,
  goods_received_note_list: GRNListSlice,
  inventory_product_list: ProductListSlice,
  customer: customerSlice,
  customerCategory: customerCategorySlice,
  salesRep: salesRepSlice,
  sale_order: SaleOrderSlice,
  sale_order_list: SaleOrderListSlice,
  sale_invoice: InvoiceSlice,
  sale_invoice_list: InvoiceListSlice,
});

const rootReducer = (state, action) => {
  if (action.type === "auth/userLogout") {
    state = undefined;
  }
  return combinedReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);
