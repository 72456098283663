import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideOptionModel } from "../../../redux/features/modals/OptionModalSlide";
import axios from "../../../api/axios";
import { autoNotify } from "../../../redux/features/notify/NotifySlice";

const InvoiceDelete = (props) => {
  const { invoice, delete_callback } = props;
  const dispatch = useDispatch();
  const [isDeleting, setIsDeleting] = useState(false);
  const { token, user } = useSelector((store) => store.auth);

  const handleOnDelete = async (e) => {
    e.preventDefault();
    setIsDeleting(true);
    try {
      const data = { ...invoice, deleted_by: user.user_id };
      const resp = await axios.post("customers/delete-invoice", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      autoNotify(dispatch, resp);
      setIsDeleting(false);
      delete_callback(resp.data?.invoice_id);
      dispatch(hideOptionModel());
    } catch (error) {
      autoNotify(dispatch, error.response);
      setIsDeleting(false);
    }
  };

  const handleOnClose = () => {
    dispatch(hideOptionModel());
  };
  return (
    <div>
      <h5>Are you sure you want to delete this invoice ?</h5>
      <p>
        <strong>{invoice.sale_no}</strong> no longer available in invoice list.
      </p>
      <p>
        This will remove related accounts, inventory and payments transactions
        if available.
      </p>
      <p>Please conform your action.</p>
      <div className="modal-footer mt-3">
        <button
          type="button"
          className="btn btn-secondary b-button"
          data-dismiss="modal"
          onClick={handleOnClose}
        >
          Dismiss
        </button>
        <button
          type="submit"
          id="user-delete-btn"
          className="btn btn-danger b-button"
          onClick={handleOnDelete}
          disabled={isDeleting}
        >
          Delete Invoice
        </button>
      </div>
    </div>
  );
};

export default InvoiceDelete;
