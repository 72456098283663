import "./BrandList.scss";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import { debounce } from "../../../Helper";
import SearchBar from "../../../components/searchbar/SearchBar";
import TextLoader from "../../../components/loaders/Loader";
import axios from "../../../api/axios";
import { PencilFill, TrashFill, TagsFill } from "react-bootstrap-icons";

import { showOptionModel } from "../../../redux/features/modals/OptionModalSlide";
import BrandEdit from "./BrandEdit";

//api request
const getBrandList = async (
  token,
  branch,
  start = 0,
  length = 10,
  order = "desc",
  search = ""
) => {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append("com_id", branch.com_id);
    searchParams.append("start", start);
    searchParams.append("length", length);
    searchParams.append("order", order);
    searchParams.append("search", search);
    searchParams.toString();
    const response = await axios.get(
      "inventory/brands?" + searchParams.toString(),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return [];
  }
};

const BrandList = () => {
  const dispatch = useDispatch();
  const { token, branch } = useSelector((store) => store.auth);
  //local stats
  const [search, setSearch] = useState("");
  const [recordsTotal, setRecordsTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [brandList, setBrandList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const columns = [
    {
      name: "Brand",
      selector: (row) => row.brand,
      sortable: true,
      width: "30%",
    },
    {
      name: "Manufacture",
      selector: (row) => row.manufacturer,
      sortable: true,
    },
    {
      name: "Website",
      selector: (row) => row.website,
      sortable: true,
    },
    {
      name: "Made By",
      selector: (row) => row.country_of_origin,
      sortable: true,
    },
    {
      name: "",
      selector: (row) => row.brand_id,
      sortable: true,
      cell: (row) => {
        return (
          <div className="table-action-control">
            <button
              type="button"
              id="po-save-btn"
              className="btn row-btn"
              onClick={() => unitEdit(row)}
            >
              <PencilFill />
            </button>
            <button type="button" id="po-save-btn" className="btn row-btn">
              <TrashFill />
            </button>
          </div>
        );
      },
    },
  ];

  //events
  const handleOnSearch = debounce(async (e) => {
    setSearch(e.target.value);
  }, 600);

  const handlePageChange = async (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
  };

  //actions
  const unitEdit = (row) => {
    const modelData = {
      title: "Edit Category",
      titleIcon: <TagsFill />,
      content: (
        <BrandEdit
          brand={row}
          edit_callback={(edited_brand) => {
            const updatedData = brandList.map((list_brand) => {
              if (edited_brand.brand_id === list_brand.brand_id) {
                return edited_brand;
              }
              return list_brand;
            });
            setBrandList(updatedData);
          }}
        />
      ),
    };
    dispatch(showOptionModel(modelData));
  };

  //effect
  useEffect(() => {
    const loadUnitList = async () => {
      setIsLoading(true);
      const start = (currentPage - 1) * perPage;
      const list_data = await getBrandList(
        token,
        branch,
        start,
        perPage,
        "desc",
        search
      );
      setRecordsTotal(list_data.recordsTotal);
      setBrandList(list_data.data);
      setIsLoading(false);
    };
    loadUnitList();
  }, [token, branch, search, currentPage, perPage]);

  return (
    <div className="container brand-list">
      <div className="row no-gutters">
        <div className="col-md-8">
          <div className="module-wrapper shadow-sm">
            <div className="main-section ">
              <div className="action-table-title">
                <h3>Brands</h3>
              </div>
              <div className="row">
                <div className="col-md-4 ">
                  <SearchBar
                    searchHandler={handleOnSearch}
                    placeholder="Search Brands here..."
                  />
                </div>
              </div>
              <div className="action-table">
                <DataTable
                  dense
                  columns={columns}
                  data={brandList}
                  progressPending={isLoading}
                  progressComponent={<TextLoader />}
                  highlightOnHover
                  pagination
                  paginationServer
                  paginationPerPage={perPage}
                  paginationRowsPerPageOptions={[10, 20, 50, 100, 200]}
                  paginationTotalRows={recordsTotal}
                  paginationDefaultPage={currentPage}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  className="list-table"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="module-wrapper shadow-sm">
            <div className="side-section">
              <h5 className="display-4 title">Product Brand</h5>
              <p>
                A brand is the image and personality of a product or service
                that a business provides. A product’s features, such as logos or
                slogans, make it unique and different.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandList;
