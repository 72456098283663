import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideOptionModel } from "../../../../redux/features/modals/OptionModalSlide";
import {
  createSalesRep,
  resetSalesRep,
} from "../../../../redux/features/sales/salesRepSlice";
import { addNotify } from "../../../../redux/features/dashboard/notifierSlice";

const init = {
  branch_id: null,
  rep_id: null,
  rep_name: "",
  rep_description: "",
  created_by: "",
};

const SalesRepNew = (props) => {
  const { newInput, callback } = props;
  const dispatch = useDispatch();
  const { isSubmitting, success, error } = useSelector(
    (store) => store.salesRep
  );

  //local stats
  const [rep, setRep] = useState(
    newInput ? { ...init, rep_name: newInput } : init
  );

  //events
  const inputsOnChange = (e) => {
    const { name, value } = e.target;
    setRep((rep) => {
      return { ...rep, [name]: value };
    });
  };

  //action
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    dispatch(createSalesRep(rep));
  };

  const handleFormClose = () => {
    dispatch(hideOptionModel());
  };

  //effect
  //show notification
  useEffect(() => {
    if (success) {
      dispatch(addNotify({ type: "SUCCESS", content: success }));
      callback(success.data.salesRep);
      dispatch(resetSalesRep());
      dispatch(hideOptionModel());
    }
    if (error) {
      dispatch(addNotify({ type: "ERROR", content: error }));
      dispatch(resetSalesRep());
    }
  }, [dispatch, success, error, callback]);

  return (
    <form>
      <div className="form-row">
        <div className="form-group col-md-6 mb-1">
          <label htmlFor="rep_name">Sales Rep Name</label>
          <input
            type="text"
            className="form-control"
            id="rep_name"
            name="rep_name"
            value={rep.rep_name}
            onChange={inputsOnChange}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-9 mb-1">
          <label htmlFor="rep_description">Sales Rep Description</label>
          <input
            type="text"
            className="form-control"
            id="rep_description"
            name="rep_description"
            value={rep.rep_description}
            onChange={inputsOnChange}
          />
        </div>
      </div>
      <div className="mt-3">
        <button
          id="supp-create-btn"
          className="btn btn-primary float-right save-button ml-2"
          type="submit"
          disabled={isSubmitting}
          onClick={handleFormSubmit}
        >
          Save
        </button>
        <button
          id="supp-close-btn"
          type="button"
          className="btn btn-secondary float-right "
          data-dismiss="modal"
          onClick={handleFormClose}
        >
          Close
        </button>
      </div>
    </form>
  );
};

export default SalesRepNew;
