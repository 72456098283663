import React, { useState } from "react";
import SelectVendor from "../../../../components/form/select/SelectVendor";
import SelectAssetAccount from "../../../../components/form/select/SelectAssetAccount";
import SelectIncomeAccount from "../../../../components/form/select/SelectIncomeAccount";

const FormAdditionalEdit = (props) => {
  const { item } = props;
  //locale state
  const [form, setFrom] = useState({
    item_width: item?.item_width ? item.item_width : "",
    item_height: item?.item_height ? item.item_height : "",
    item_depth: item?.item_depth ? item.item_depth : "",
    pref_supplier: item?.pref_supplier
      ? {
          ...item.pref_supplier,
          label: item.pref_supplier.supp_display_name,
          value: item.pref_supplier.supp_id,
        }
      : null,
    account_income: item?.account_income
      ? {
          ...item.account_income,
          label: item.account_income.account_name,
          value: item.account_income.acc_id,
        }
      : null,
    account_expense: item?.account_expense
      ? {
          ...item.account_expense,
          label: item.account_expense.account_name,
          value: item.account_expense.acc_id,
        }
      : null,
  });
  //event
  //this will handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFrom({ ...form, [name]: value });
  };

  return (
    <div className="row">
      <div className="col-6">
        <div className="form-row">
          <div className="form-group col-md-12 mb-1">
            <label>Product Dimensions</label>
          </div>
          <div className="form-group col-md-4 mb-1">
            <input
              type="text"
              className="form-control"
              id="item_width"
              name="item_width"
              placeholder="Width"
              value={form.item_width}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group col-md-4 mb-1">
            <input
              type="text"
              className="form-control"
              id="item_height"
              name="item_height"
              placeholder="Height"
              value={form.item_height}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group col-md-4 mb-1">
            <input
              type="text"
              className="form-control"
              id="item_depth"
              name="item_depth"
              placeholder="Depth"
              value={form.item_depth}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-10 mb-1">
            <label htmlFor="pref_supplier_id">Preferred Vendor</label>
            <SelectVendor selected={form.pref_supplier} id="pref_supplier_id" />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6 mb-1">
            <label htmlFor="expense_account_id">Inventory Asset Account</label>
            <SelectAssetAccount
              selected={form.account_expense}
              id="expense_account_id"
            />
          </div>
          <div className="form-group col-md-6 mb-1">
            <label htmlFor="income_account_id">Income Account</label>
            <SelectIncomeAccount
              selected={form.account_income}
              id="income_account_id"
            />
          </div>
        </div>
      </div>
      <div className="col-6"></div>
    </div>
  );
};
export default FormAdditionalEdit;
