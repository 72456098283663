import { useCallback } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { hasPrivilege } from "../../../../Helper";
import { useDispatch } from "react-redux";
import { PersonPlusFill } from "react-bootstrap-icons";

import { showPrimaryModel } from "../../../../redux/features/modals/PrimaryModalSlice";
import { getSupplierList } from "../../../../redux/features/suppliers/SupplierListSlice";

import SupplierNew from "../../../../containers/purchases/supplier/SupplierNew";

const SuppliersTab = (props) => {
  const dispatch = useDispatch();

  // carate new supplier
  const supplierCallback = useCallback(
    (supplier) => {
      dispatch(getSupplierList({ search: "" }));
    },
    [dispatch]
  );
  const handleNewSupplier = () => {
    const modelData = {
      title: "Add New Supplier",
      titleIcon: <PersonPlusFill />,
      content: <SupplierNew callback={supplierCallback} />,
    };
    dispatch(showPrimaryModel(modelData));
  };

  return (
    <div className="row no-gutters">
      <div className="col-md-auto ">
        <div className="toolbar-section">
          <div className="row no-gutters">
            <div className="col-auto">
              <Link
                className={classNames("btn toolbar-button", {
                  disabled: !hasPrivilege("Suppliers", 600),
                })}
                to="/dashboard/purchases/suppliers"
              >
                <img src="/img/icons/suppliers.png" alt="suppliers" />
                <span className="text">Suppliers</span>
              </Link>
            </div>
            <div className="col-auto">
              <button
                className={classNames("btn toolbar-button", {
                  disabled: !hasPrivilege("Suppliers", 601),
                })}
                onClick={hasPrivilege("Suppliers", 601) && handleNewSupplier}
              >
                <img src="/img/icons/supplier.png" alt="New Supplier" />
                <span className="text">Add Supplier</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuppliersTab;
