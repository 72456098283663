import { useRef, useState, useCallback, useEffect } from "react";
import { XCircleFill, CardText } from "react-bootstrap-icons";
import SelectItemSales from "../../form/select/SelectItemSales";
import { useAutoSizeTextArea, formatDecimal } from "../../../Helper";
import { number, isNaN, multiply } from "mathjs";

const TableRow = (props) => {
  const { new_row, update_callback, delete_callback } = props;
  //locale state
  const [row, setRow] = useState(new_row);
  //set auto resize
  const itemDescriptionRef = useRef(null);
  useAutoSizeTextArea(itemDescriptionRef.current, row.itemDes);

  /* Functions */
  // calculate row amount
  const calculateAmount = (qty, rate) => {
    if (qty && rate) {
      if (isNaN(qty) || isNaN(rate)) {
        return 0;
      }
      const quantity = number(qty);
      const price = number(rate);
      return multiply(quantity, price);
    }
    return 0;
  };

  /*Events */
  const setItemHandler = useCallback((item) => {
    const amount = calculateAmount(1, item.batch_sale_price);
    setRow((prevState) => ({
      ...prevState,
      item: item,
      itemDes: item.item_description,
      quantity: 1,
      rate: item.batch_sale_price,
      amount: amount,
    }));
  }, []);

  //close handler
  const rowDeleteHandler = () => {
    delete_callback(row);
  };

  //extra details handler
  const addExtraDetails = (item) => {
    let details = "\n";
    details += item.lot_number
      ? "Lot No\t\t\t : " + item.lot_number + "\n"
      : "";
    details += item.brand ? "Brand\t\t\t : " + item?.brand.brand + "\n" : "";
    details += item.brand?.manufacturer
      ? "Manufacture\t\t : " + item?.brand.manufacturer + "\n"
      : "";
    details += item.brand?.country_of_origin
      ? "Country of Origin\t : " + item.brand?.country_of_origin + "\n"
      : "";
    details += item.batch_made_date
      ? "Manufacture Date\t : " + item.batch_made_date + "\n"
      : "";
    details += item.batch_expiry_date
      ? "Expiration Date\t : " + item.batch_expiry_date + "\n"
      : "";

    details += item.item_width
      ? "Size\t\t\t\t : " +
        item.item_width +
        " x " +
        item.item_height +
        " x " +
        item.item_depth +
        "\n"
      : "";

    setRow((prevState) => {
      let item_description = prevState.itemDes;
      item_description += details;
      return {
        ...prevState,
        itemDes: item_description,
      };
    });
  };

  const descriptionChangeHandler = (e) => {
    const { value } = e.target;
    setRow((prevState) => ({
      ...prevState,
      itemDes: value,
    }));
  };

  const descriptionTabHandler = (e) => {
    const { value } = e.target;
    if (e.key === "Tab") {
      e.preventDefault();
      const cursorPosition = e.target.selectionStart;
      const cursorEndPosition = e.target.selectionEnd;
      const tab = "\t";
      e.target.value =
        value.substring(0, cursorPosition) +
        tab +
        value.substring(cursorEndPosition);
      // if you modify the value programmatically, the cursor is moved
      // to the end of the value, we need to reset it to the correct
      // position again
      e.target.selectionStart = cursorPosition + 1;
      e.target.selectionEnd = cursorPosition + 1;
    }
  };

  //quantity  handler
  const quantityChangeHandler = (e) => {
    const { value } = e.target;
    const amount = calculateAmount(value, row.rate);
    setRow((prevState) => ({
      ...prevState,
      quantity: value,
      amount: amount,
    }));
  };

  //rate handler
  const rateChangeHandler = (e) => {
    const { value } = e.target;
    const amount = calculateAmount(row.quantity, value);
    setRow((prevState) => ({
      ...prevState,
      rate: value,
      amount: amount,
    }));
  };

  //Effects
  useEffect(() => {
    update_callback(row);
  }, [row, update_callback]);

  return (
    <tr>
      <td className="row-first"></td>
      <td className="row-item">
        <SelectItemSales selected={row.item} callback={setItemHandler} />
        <textarea
          className="form-control"
          value={row.itemDes}
          onChange={descriptionChangeHandler}
          ref={itemDescriptionRef}
          onKeyDown={descriptionTabHandler}
        ></textarea>
      </td>
      <td className="row-qty">
        <input
          type="number"
          className="form-control"
          onChange={quantityChangeHandler}
          value={row.quantity}
          min="0"
          step="1"
        />
        {row.item?.unit && <p className="meta-unit">Unit : {row.item?.unit}</p>}
        {row.item?.batch_quantity_on_hand && (
          <p className="meta-qty">
            On Hand : {row.item?.batch_quantity_on_hand}
          </p>
        )}
      </td>
      <td className="row-rate">
        <input
          type="number"
          className="form-control"
          onChange={rateChangeHandler}
          value={number(row.rate)}
          min="0"
          step="0.25"
        />
      </td>
      <td className="row-amount">
        <input
          type="text"
          className="form-control"
          disabled
          value={formatDecimal(row.amount, 4)}
        />
      </td>
      <td className="row-last">
        <div
          className="side-button"
          onClick={rowDeleteHandler}
          title="Delete Row"
        >
          <XCircleFill />
        </div>
        {row.item ? (
          <div
            className="side-button"
            onClick={() => addExtraDetails(row.item)}
            title="Add Extra Details"
          >
            <CardText />
          </div>
        ) : (
          ""
        )}
      </td>
    </tr>
  );
};

export default TableRow;
