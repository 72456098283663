import "./WorkplaceTable.scss";
import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PlusSquareDotted } from "react-bootstrap-icons";

import TableRow from "./TableRow";
import {
  newTableRow,
  updateTableRow,
  deleteTableRow,
  setSubTotal,
  setDiscount,
  setDiscountValue,
  setTax,
  setTaxValue,
  setSaleTotal,
} from "../../../redux/features/sales/InvoiceSlice";
import SelectTax from "../../form/select/SelectTax";
import { formatDecimal } from "../../../Helper";
import { number, add, divide, subtract, chain } from "mathjs";

const WorkplaceTable = () => {
  const dispatch = useDispatch();
  //glob
  const {
    tableRows,
    subTotal,
    discount,
    discountValue,
    tax,
    taxValue,
    saleTotal,
  } = useSelector((store) => store.sale_invoice);

  /* Functions */

  // calculate discount val
  const calculateDiscountValue = (discount, subTotal, dispatch) => {
    if (discount) {
      if (isNaN(discount)) {
        if (discount.match(/\d+[%]/)) {
          let percentage = discount.match(/\d+/);
          let discount_val = (percentage / 100) * subTotal;
          dispatch(setDiscountValue(discount_val));
        } else {
          dispatch(setDiscountValue(0));
        }
      } else {
        dispatch(setDiscountValue(discount));
      }
    } else {
      dispatch(setDiscountValue(0));
    }
  };

  // calculate Tax val
  const calculateTotal = (discountValue, tableRows, tax, dispatch) => {
    //remove empty rows
    const item_rows = tableRows.filter((row) => {
      return row.item ? true : false;
    });
    const item_row_count = item_rows.length;
    let sub_total = 0;
    let taxable_amount = 0;
    let vat_total = 0;
    let net_total = 0;

    // calculate
    for (var i = 0; i < item_row_count; i++) {
      //sub total
      const item_amount = number(item_rows[i].amount);
      sub_total = add(sub_total, item_amount);

      //distribute  discount on items
      let item_discount = divide(discountValue, item_row_count);

      //check only taxable
      if (item_rows[i].item.sales_tax_id !== "1") {
        let item_taxable = subtract(item_amount, item_discount);
        taxable_amount = add(taxable_amount, item_taxable);
      }
    }

    //check not minus
    if (taxable_amount > 0) {
      let vatRate = number(tax.tax_rate);
      vat_total = chain(taxable_amount).multiply(vatRate).divide(100).done();
    }

    net_total = chain(sub_total).subtract(discountValue).add(vat_total).done();

    dispatch(setSubTotal(sub_total));
    dispatch(setTaxValue(vat_total));
    dispatch(setSaleTotal(net_total));
  };

  /* EVent handler */
  // table rows handlers
  const addNewRowHandler = () => dispatch(newTableRow());

  const updateRowHandler = useCallback(
    (row) => dispatch(updateTableRow(row)),
    [dispatch]
  );

  const deleteRowHandler = useCallback(
    (row) => dispatch(deleteTableRow(row)),
    [dispatch]
  );

  // discount on change
  const discountOnChange = (e) => {
    const { value } = e.target;
    dispatch(setDiscount(value));
  };

  // tax on change handler
  const taxSelectOnChange = useCallback(
    (tax) => dispatch(setTax(tax)),
    [dispatch]
  );

  // calculate discount value on discount and subtotal change
  useEffect(() => {
    calculateDiscountValue(discount, subTotal, dispatch);
  }, [discount, subTotal, dispatch]);

  // calculate Tax value on discount, subtotal, tax change
  useEffect(() => {
    calculateTotal(discountValue, tableRows, tax, dispatch);
  }, [discountValue, tableRows, tax, dispatch]);

  return (
    <div className="workplace-table">
      <h6 className="sub-title"> Items Details</h6>
      <table className="table ">
        <thead>
          <tr>
            <th scope="col" className="col-first"></th>
            <th scope="col" className="col-item">
              Item
            </th>
            <th scope="col" className="col-qty">
              Quantity
            </th>
            <th scope="col" className="col-rate">
              Rate
            </th>
            <th scope="col" className="col-amount">
              Amount
            </th>
            <th scope="col" className="col-last"></th>
          </tr>
        </thead>
        <tbody>
          {tableRows.map((row) => {
            return (
              <TableRow
                key={row.rowID}
                new_row={row}
                update_callback={updateRowHandler}
                delete_callback={deleteRowHandler}
              />
            );
          })}
        </tbody>
      </table>
      <div className="table-footer">
        <div className="row ">
          <div className="col-md-6">
            <button className="btn" onClick={addNewRowHandler}>
              Add Row <PlusSquareDotted />
            </button>
          </div>
          <div className="col-md-6">
            <div className="total-wrapper">
              <div className="row  no-gutters sub-total-row">
                <div className="col-md-6">
                  <label htmlFor="sub-total-val">Sub Total</label>
                </div>
                <div className="col-md-6 sub-value">
                  <p id="sub-total-val" title={subTotal}>
                    {formatDecimal(subTotal, 4)}
                  </p>
                </div>
              </div>
              <div className="row  no-gutters discount-row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label
                      className="col-sm-4 col-form-label"
                      htmlFor="discount"
                    >
                      Discount
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="discount"
                        name="discount"
                        value={discount}
                        onChange={discountOnChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 sub-value">
                  <p id="sub-discount-val" title={discountValue}>
                    {formatDecimal(discountValue, 4)}
                  </p>
                </div>
              </div>
              <div className="row  no-gutters tax-row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-sm-4 col-form-label" htmlFor="tax">
                      Tax
                    </label>
                    <div className="col-sm-8">
                      <SelectTax selected={tax} callback={taxSelectOnChange} />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 sub-value">
                  <p id="sub-discount-val" title={taxValue}>
                    {formatDecimal(taxValue, 4)}
                  </p>
                </div>
              </div>
              <div className="row  no-gutters total-row">
                <div className="col-md-6">
                  <label htmlFor="total-val">Total</label>
                </div>
                <div className="col-md-6 sub-value">
                  <p id="total-val" title={saleTotal}>
                    {formatDecimal(saleTotal, 4)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkplaceTable;
