import "./PrimaryModal.scss";
import { useSelector, useDispatch } from "react-redux";
import { Modal, ModalHeader, ModalTitle, ModalBody } from "react-bootstrap";
import { hidePrimaryModel } from "../../redux/features/modals/PrimaryModalSlice";
import { XLg } from "react-bootstrap-icons";

const PrimaryModal = () => {
  const { isPrimaryModal, title, titleIcon, content } = useSelector(
    (store) => store.primary_modal
  );
  const dispatch = useDispatch();

  const handleClose = () => dispatch(hidePrimaryModel());
  return (
    <Modal
      show={isPrimaryModal}
      onHide={handleClose}
      backdrop={false}
      keyboard={false}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      animation={false}
      ria-labelledby="staticBackdropLabel"
      dialogClassName=""
      contentClassName="shadow"
      className="primary_model"
    >
      <ModalHeader>
        <ModalTitle>
          {titleIcon} {title}
        </ModalTitle>
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={handleClose}
        >
          <span aria-hidden="true">
            <XLg />
          </span>
        </button>
      </ModalHeader>
      <ModalBody>{content}</ModalBody>
    </Modal>
  );
};

export default PrimaryModal;
