import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import GRN from "../../../components/workplace/grn/GRN";

import {
  getGRNNumber,
  setGRNDate,
} from "../../../redux/features/purchases/GRNSlice";

const GRNNew = () => {
  const dispatch = useDispatch();

  const { grnNo, grnDate } = useSelector((store) => store.goods_received_note);

  const isNewGRNNo = grnNo === "";
  const isNewDate = grnDate === "";

  useEffect(() => {
    if (isNewGRNNo) dispatch(getGRNNumber());
    if (isNewDate) dispatch(setGRNDate(new Date()));
  }, [isNewGRNNo, dispatch, isNewDate]);

  return <GRN action="New" />;
};
export default GRNNew;
