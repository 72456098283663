import "./style.scss";
import { useState, useCallback, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import FormBasic from "./FormBasic";
import FormAdvanced from "./FormAdvanced";

import { hidePrimaryModel } from "../../../../redux/features/modals/PrimaryModalSlice";
import {
  editCustomer,
  resetCustomer,
} from "../../../../redux/features/customers/customerSlice";
import { addNotify } from "../../../../redux/features/dashboard/notifierSlice";

const CustomerEdit = (props) => {
  const { customer: init, callback } = props;
  const dispatch = useDispatch();
  const { isSubmitting, success, error } = useSelector(
    (store) => store.customer
  );
  //locale states
  const [tab, setTab] = useState("basic");
  const customer = useRef(init);

  //events
  const changeCallback = useCallback((name, value) => {
    const current = customer.current;
    customer.current = { ...current, [name]: value };
  }, []);

  //action
  const handleFormSubmit = (e) => {
    e.preventDefault();
    dispatch(editCustomer(customer.current));
  };
  const handleFormClose = () => {
    dispatch(hidePrimaryModel());
  };

  //effect
  //show notification
  useEffect(() => {
    if (success) {
      dispatch(addNotify({ type: "SUCCESS", content: success }));
      if (callback) {
        callback(success.data.customer);
      }
      dispatch(resetCustomer());
    }
    if (error) {
      dispatch(addNotify({ type: "ERROR", content: error }));
      dispatch(resetCustomer());
    }
  }, [dispatch, success, error, callback]);

  return (
    <div className="edit-customer">
      <Tabs id="new-customer-tabs" activeKey={tab} onSelect={(t) => setTab(t)}>
        <Tab eventKey="basic" title="Basic Details">
          <FormBasic
            customer={customer.current}
            changeCallback={changeCallback}
          />
        </Tab>
        <Tab eventKey="advanced" title="Advanced Details">
          <FormAdvanced
            customer={customer.current}
            changeCallback={changeCallback}
          />
        </Tab>
      </Tabs>
      <div className="m-1">
        <button
          className="btn btn-primary float-right save-button ml-2"
          type="submit"
          disabled={isSubmitting}
          onClick={handleFormSubmit}
        >
          Save
        </button>
        <button
          type="button"
          className="btn btn-secondary float-right "
          data-dismiss="modal"
          onClick={handleFormClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default CustomerEdit;
