import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../api/axios";

const initialState = {
  grnId: "",
  grnNo: "",
  grnDate: "",
  invoiceNo: "",
  grnStatus: "Draft",
  supplier: null,
  supplierAddress: "",
  deliveryType: "warehouse",
  warehouse: null,
  deliveryAddress: "",
  purchaseOrders: [],
  tableRowCounter: 0,
  tableRows: [],
  subTotal: 0,
  discount: "",
  discountValue: 0,
  tax: {
    tax_id: "1",
    tax_name: "None",
    tax_rate: "0",
    label: "None",
    value: "1",
  },
  taxValue: 0,
  isTaxesLoading: true,
  grnTotal: 0,
  reference: "",
  note: "",
  terms: "",
  isLoading: true,
};

//get PO Number
export const getGRNNumber = createAsyncThunk(
  "purchases/GRNNumber",
  async (data, thunkAPI) => {
    try {
      const { auth } = thunkAPI.getState();
      const token = auth.token;
      const branch = auth.branch;

      const searchParams = new URLSearchParams();
      searchParams.append("branch_id", branch.branch_id);
      searchParams.toString();

      const response = await axios.get(
        "purchase/grn-no?" + searchParams.toString(),
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const GRNSlice = createSlice({
  name: "goods_received_note",
  initialState,
  reducers: {
    setNewGRN: () => initialState,
    setEditGRN: (state, action) => {
      const grn = action.payload;
      if (grn) {
        state.grnId = grn.grn_id;
        state.grnNo = grn.grn_no ? grn.grn_no : "";
        state.grnDate = grn.grn_date ? grn.grn_date : "";
        state.invoiceNo = grn.invoice_no ? grn.invoice_no : "";
        state.grnStatus = grn.grn_status;
        state.supplier = {
          ...grn.supplier,
          label: grn.supplier.supp_display_name,
          value: grn.supplier.supp_id,
        };
        state.supplierAddress = grn.supplier_address;
        state.deliveryType = grn.deliver_location_type;
        if (grn.deliver_location_type === "warehouse") {
          state.warehouse = {
            ...grn.deliver_location,
            label: grn.deliver_location.ware_name,
            value: grn.deliver_location.ware_id,
          };
        }
        state.deliveryAddress = grn.deliver_address;
        state.purchaseOrders = grn.po_list.map((po) => {
          return {
            ...po,
            value: po.po_id,
            label: po.po_no,
          };
        });
        state.tableRows = grn.grn_item_rows.map((grn_row) => {
          state.tableRowCounter++;
          return {
            rowID: state.tableRowCounter,
            item: {
              ...grn_row.grn_item,
              value: grn_row.grn_item.batch_id,
              label: grn_row.grn_item.item_sku,
            },
            itemDes: grn_row.grn_item_description,
            quantity: grn_row.grn_item_qty,
            rate: grn_row.grn_item_rate,
            amount: grn_row.grn_item_amount,
          };
        });
        state.discount = grn.grn_discount;
        if (grn.grn_tax !== null) {
          state.tax = {
            ...grn.grn_tax,
            value: grn.grn_tax.tax_id,
            label: grn.grn_tax.tax_name,
          };
        } else {
          state.tax = initialState.tax;
        }
        state.reference = grn.grn_reference;
        state.note = grn.supplier_note;
        state.terms = grn.grn_terms;
      }
    },
    setGrnPO: (state, action) => {
      const po = action.payload;
      state.purchaseOrders = [po];
      if (po) {
        if (po.deliver_location_type === "warehouse") {
          const warehouse = po.deliver_location;
          let address = warehouse.ware_name + ",\n";
          address += warehouse.ware_address_street
            ? warehouse.ware_address_street + ",\n"
            : "";
          address += warehouse.ware_address_city
            ? warehouse.ware_address_city + ",\n"
            : "";
          address += warehouse.ware_address_counrty
            ? warehouse.ware_address_counrty + ". "
            : "";
          address += warehouse.ware_address_postcode
            ? warehouse.ware_address_postcode
            : "";
          state.warehouse = {
            ...warehouse,
            label: warehouse.ware_name,
            value: warehouse.ware_id,
          };
          state.deliveryAddress = address;
        }
        state.tableRows = po.po_item_rows.map((po_row) => {
          state.tableRowCounter++;
          return {
            rowID: state.tableRowCounter,
            item: {
              ...po_row.po_item,
              value: po_row.po_item.batch_id,
              label: po_row.po_item.item_sku,
            },
            itemDes: po_row.po_item_description,
            quantity: po_row.po_item_qty,
            rate: po_row.po_item_rate,
            amount: po_row.po_item_amount,
          };
        });

        state.discount = po.po_discount;
        if (po.po_tax !== null) {
          state.tax = {
            ...po.po_tax,
            value: po.po_tax.tax_id,
            label: po.po_tax.tax_name,
          };
        } else {
          state.tax = initialState.tax;
        }
        state.reference = po.po_reference;
        state.note = po.supplier_note;
        state.terms = po.po_terms;
      }
    },
    setGRNStatus: (state, action) => {
      state.grnStatus = action.payload;
    },
    setGRNNumber: (state, action) => {
      state.grnNo = action.payload;
    },
    setGRNDate: (state, action) => {
      state.grnDate = action.payload;
    },
    setInvoiceNo: (state, action) => {
      state.invoiceNo = action.payload;
    },
    setGRNSupplier: (state, action) => {
      const supplier = action.payload;
      let address = supplier.supp_first_name
        ? "Attn: " +
          supplier.supp_first_name +
          " " +
          supplier.supp_last_name +
          ",\n"
        : "";
      address += supplier.supp_company ? supplier.supp_company + ",\n" : "";
      address += supplier.supp_address_street
        ? supplier.supp_address_street + ",\n"
        : "";
      address += supplier.supp_address_city
        ? supplier.supp_address_city + ", "
        : "";
      address += supplier.supp_address_country
        ? supplier.supp_address_country + ". "
        : "";
      address += supplier.supp_address_postcode
        ? supplier.supp_address_postcode
        : "";

      state.supplier = supplier;
      state.supplierAddress = address;
    },
    setGRNDeliveryAddress: (state, action) => {
      state.deliveryAddress = action.payload;
    },
    setGRNWarehouse: (state, action) => {
      const delivery_obj = action.payload;
      let address = delivery_obj.ware_name + ",\n";
      address += delivery_obj.ware_address_street
        ? delivery_obj.ware_address_street + ",\n"
        : "";
      address += delivery_obj.ware_address_city
        ? delivery_obj.ware_address_city + ",\n"
        : "";
      address += delivery_obj.ware_address_counrty
        ? delivery_obj.ware_address_counrty + ". "
        : "";
      address += delivery_obj.ware_address_postcode
        ? delivery_obj.ware_address_postcode
        : "";
      state.warehouse = delivery_obj;
      state.deliveryAddress = address;
    },
    newTableRow: (state, action) => {
      state.tableRowCounter++;
      state.tableRows.push({
        rowID: state.tableRowCounter,
        item: null,
        itemDes: "",
        quantity: 0,
        rate: 0,
        amount: 0,
      });
    },
    updateTableRow: (state, action) => {
      let editedRow = action.payload;
      const newArray = state.tableRows.map((row) => {
        if (row.rowID === editedRow.rowID) {
          return editedRow;
        }
        return row;
      });
      state.tableRows = newArray;
    },
    deleteTableRow: (state, action) => {
      let deletedRow = action.payload;
      const newArray = state.tableRows.filter((row) => {
        return row.rowID !== deletedRow.rowID;
      });
      state.tableRows = newArray;
    },
    setSubTotal: (state, action) => {
      state.subTotal = action.payload;
    },
    setDiscount: (state, action) => {
      state.discount = action.payload;
    },
    setDiscountValue: (state, action) => {
      state.discountValue = action.payload;
    },
    setTax: (state, action) => {
      state.tax = action.payload;
    },
    setTaxValue: (state, action) => {
      state.taxValue = action.payload;
    },
    setTotal: (state, action) => {
      state.grnTotal = action.payload;
    },
    setReference: (state, action) => {
      state.reference = action.payload;
    },
    setNote: (state, action) => {
      state.note = action.payload;
    },
    setTerms: (state, action) => {
      state.terms = action.payload;
    },
  },
  extraReducers: (builder) => {
    //grn number
    builder.addCase(getGRNNumber.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getGRNNumber.fulfilled, (state, action) => {
      state.isLoading = false;
      state.grnNo = action.payload.new_grn_no;
    });
    builder.addCase(getGRNNumber.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const {
  setNewGRN,
  setEditGRN,
  setGrnPO,
  setGRNStatus,
  setGRNNumber,
  setGRNDate,
  setInvoiceNo,
  setGRNSupplier,
  setGRNDeliveryAddress,
  setGRNWarehouse,
  newTableRow,
  updateTableRow,
  deleteTableRow,
  setSubTotal,
  setDiscount,
  setDiscountValue,
  setTax,
  setTaxValue,
  setTotal,
  setReference,
  setNote,
  setTerms,
} = GRNSlice.actions;
export default GRNSlice.reducer;
