import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import { hasPrivilege } from "../../../../Helper";

import { Dropdown } from "react-bootstrap";
import {
  Box2Fill,
  BoundingBox,
  Rulers,
  TagsFill,
  BadgeTmFill,
} from "react-bootstrap-icons";

import StockItem from "../../../../containers/inventory/stock-item/StockItem";
import ComplexItem from "../../../../containers/inventory/complex-item/ComplexItem";
import UnitNew from "../../../../containers/inventory/unit/UnitNew";
import MeasurementNew from "../../../../containers/inventory/unit/MeasurementNew";
import CategoryNew from "../../../../containers/inventory/category/CategoryNew";
import BrandNew from "../../../../containers/inventory/brand/BrandNew";
import AddStock from "../../../../containers/inventory/add-stock/AddStock";
import StockAdjustment from "../../../../containers/inventory/StockAdjustment";

import { showPrimaryModel } from "../../../../redux/features/modals/PrimaryModalSlice";
import { showOptionModel } from "../../../../redux/features/modals/OptionModalSlide";
import { getProductList } from "../../../../redux/features/inventory/ProductListSlice";

const InventoryTab = (props) => {
  const dispatch = useDispatch();

  // events
  const itemAddCallback = (item) => {
    dispatch(getProductList({ search: "" }));
  };
  // carate new stock item
  const handleNewStockItem = () => {
    const modelData = {
      title: "Add Inventory Item",
      titleIcon: <Box2Fill />,
      content: <StockItem action={"new"} callback={itemAddCallback} />,
    };
    dispatch(showPrimaryModel(modelData));
  };
  // carate new complex item
  const handleNewComplexItem = () => {
    const modelData = {
      title: "Add Complex Inventory Item",
      titleIcon: <Box2Fill />,
      content: <ComplexItem callback={itemAddCallback} />,
    };
    dispatch(showPrimaryModel(modelData));
  };
  //create new unit
  const handleNewUnit = () => {
    const modelData = {
      title: "Add New Unit",
      titleIcon: <BoundingBox />,
      content: <UnitNew />,
    };
    dispatch(showOptionModel(modelData));
  };
  //create new Measurement
  const handleNewMeasurement = () => {
    const modelData = {
      title: "New Measurement",
      titleIcon: <Rulers />,
      content: <MeasurementNew />,
    };
    dispatch(showOptionModel(modelData));
  };

  //create new category
  const handleNewCategory = () => {
    const modelData = {
      title: "New Category",
      titleIcon: <TagsFill />,
      content: <CategoryNew />,
    };
    dispatch(showOptionModel(modelData));
  };

  //create new Brand
  const handleNewBrand = () => {
    const modelData = {
      title: "New Brand",
      titleIcon: <BadgeTmFill />,
      content: <BrandNew />,
    };
    dispatch(showOptionModel(modelData));
  };

  //add new Stock
  const handleAddStock = () => {
    const modelData = {
      title: "Add New Stock",
      titleIcon: <Box2Fill />,
      content: <AddStock callback={itemAddCallback} />,
    };
    dispatch(showPrimaryModel(modelData));
  };

  //Stock Adjustment
  const handleStockAdjustment = () => {
    const modelData = {
      title: "Stock Adjustment",
      titleIcon: <Box2Fill />,
      content: <StockAdjustment />,
    };
    dispatch(showPrimaryModel(modelData));
  };

  return (
    <div className="row no-gutters">
      <div className="col-md-auto ">
        <div className="toolbar-section">
          <div className="row no-gutters">
            <div className="col-auto">
              <Link
                className={classNames("btn toolbar-button", {
                  disabled: !hasPrivilege("Inventory", 400),
                })}
                to="/dashboard/inventory"
              >
                <img src="/img/icons/products.png" alt="products" />
                <span className="text">Products</span>
              </Link>
            </div>
            <div className="col-auto">
              <button
                className={classNames("btn toolbar-button-inline", {
                  disabled: !hasPrivilege("Inventory", 401),
                })}
                onClick={hasPrivilege("Inventory", 401) && handleNewStockItem}
              >
                <img src="/img/icons/new-product-sm.png" alt="New Inventory" />
                <span className="text"> Add Inventory </span>
              </button>

              <button
                className={classNames("btn toolbar-button-inline", {
                  disabled: !hasPrivilege("Inventory", 401),
                })}
                onClick={hasPrivilege("Inventory", 401) && handleNewComplexItem}
              >
                <img src="/img/icons/non-inventory.png" alt="complex item" />
                <span className="text"> Complex Item </span>
              </button>
            </div>
            <div className="col-auto">
              <Link
                className={classNames("btn toolbar-button-inline", {
                  disabled: !hasPrivilege("Inventory", 407),
                })}
                to="/dashboard/inventory/add-service"
              >
                <img src="/img/icons/service.png" alt="add service" />
                <span className="text"> Add Service </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-auto ">
        <div className="toolbar-section">
          <div className="row no-gutters">
            <div className="col-auto">
              <button
                className={classNames("btn toolbar-button-inline", {
                  disabled: !hasPrivilege("Inventory", 411),
                })}
                onClick={
                  hasPrivilege("Inventory", 411) && handleStockAdjustment
                }
              >
                <img
                  src="/img/icons/inventory-adjustments.png"
                  alt="adjustments"
                />
                <span className="text"> Adjustments </span>
              </button>
              <button
                className={classNames("btn toolbar-button-inline", {
                  disabled: !hasPrivilege("Inventory", 410),
                })}
                onClick={hasPrivilege("Inventory", 410) && handleAddStock}
              >
                <img
                  src="/img/icons/inventory-adjustments.png"
                  alt="add new stock"
                />
                <span className="text"> Add New Stock </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-auto ">
        <div className="toolbar-section">
          <div className="row no-gutters">
            <div className="col-auto">
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  className="toolbar-button-drop"
                >
                  <img src="/img/icons/categorize.png" alt="Categories" />
                  <span className="text"> Categories </span>
                </Dropdown.Toggle>

                <Dropdown.Menu style={{ margin: 0 }}>
                  <Link
                    className={classNames("btn toolbar-button-drop-item", {
                      disabled: !hasPrivilege("Inventory", 401),
                    })}
                    to="/dashboard/inventory/categories"
                  >
                    <img src="/img/icons/categorize.png" alt="Category List" />
                    <span className="text"> Category List </span>
                  </Link>
                  <button
                    className={classNames("btn toolbar-button-drop-item", {
                      disabled: !hasPrivilege("Inventory", 401),
                    })}
                    onClick={
                      hasPrivilege("Inventory", 401) && handleNewCategory
                    }
                  >
                    <img src="/img/icons/add-tag.png" alt="New Category" />
                    <span className="text"> Add Category </span>
                  </button>
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  className="toolbar-button-drop"
                >
                  <img src="/img/icons/units.png" alt="Unit" />
                  <span className="text"> Units </span>
                </Dropdown.Toggle>

                <Dropdown.Menu style={{ margin: 0 }}>
                  <Link
                    className={classNames("btn toolbar-button-drop-item", {
                      disabled: !hasPrivilege("Inventory", 401),
                    })}
                    to="/dashboard/inventory/units"
                  >
                    <img src="/img/icons/units.png" alt="Unit List" />
                    <span className="text"> Unit List </span>
                  </Link>
                  <button
                    className={classNames("btn toolbar-button-drop-item", {
                      disabled: !hasPrivilege("Inventory", 401),
                    })}
                    onClick={hasPrivilege("Inventory", 401) && handleNewUnit}
                  >
                    <img src="/img/icons/size.png" alt="New unit" />
                    <span className="text"> Add Pack Size </span>
                  </button>
                  <button
                    className={classNames("btn toolbar-button-drop-item", {
                      disabled: !hasPrivilege("Inventory", 401),
                    })}
                    onClick={
                      hasPrivilege("Inventory", 401) && handleNewMeasurement
                    }
                  >
                    <img src="/img/icons/measur.png" alt="New unit" />
                    <span className="text"> Add Measurement </span>
                  </button>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-auto">
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  className="toolbar-button-drop"
                >
                  <img src="/img/icons/brands.png" alt="brands" />
                  <span className="text"> Brands </span>
                </Dropdown.Toggle>

                <Dropdown.Menu style={{ margin: 0 }}>
                  <Link
                    className={classNames("btn toolbar-button-drop-item", {
                      disabled: !hasPrivilege("Inventory", 401),
                    })}
                    to="/dashboard/inventory/brands"
                  >
                    <img src="/img/icons/brands.png" alt="Category List" />
                    <span className="text"> Brand List </span>
                  </Link>
                  <button
                    className={classNames("btn toolbar-button-drop-item", {
                      disabled: !hasPrivilege("Inventory", 401),
                    })}
                    onClick={hasPrivilege("Inventory", 401) && handleNewBrand}
                  >
                    <img src="/img/icons/trademark.png" alt="New brand" />
                    <span className="text"> Add Brand </span>
                  </button>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InventoryTab;
