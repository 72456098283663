import "./style.scss";
import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import PrimaryList from "../../../../components/layouts/PrimaryList";
import {
  getCustomers,
  updateCustomers,
} from "../../../../redux/features/customers/customerSlice";
import ListRight from "./ListRight";

/* set supplier list */
const columns = [
  {
    name: "Customer",
    selector: (row) => row?.cust_display_name,
    sortable: true,
    cell: (row) => {
      return (
        <div className="tbl-supplier-col" data-tag="allowRowEvents">
          <h6 data-tag="allowRowEvents">{row?.cust_display_name}</h6>
          {row?.cust_address_city}
        </div>
      );
    },
  },
  {
    name: "Category",
    selector: (row) => row?.category?.cat_name,
    sortable: true,
  },
  {
    name: "Email",
    selector: (row) => row?.cust_email,
    sortable: true,
  },
  {
    name: "Telephone",
    selector: (row) => {
      return row?.cust_phone ? row.cust_phone : "";
    },
    sortable: true,
  },
  {
    name: "Balance",
    selector: (row) => row?.cust_ledger_balance,
    sortable: true,
  },
];

const CustomerList = () => {
  const dispatch = useDispatch();

  const { customers, recordsTotal, isLoading } = useSelector(
    (store) => store.customer
  );
  //events
  const customersCallback = useCallback(
    (option) => {
      dispatch(getCustomers(option));
    },
    [dispatch]
  );

  const onSelectCallback = useCallback(
    (row) => {
      const updatedList = customers.map((customer) => {
        //unselect all
        if (row.cust_id !== customer.cust_id) {
          return { ...customer, isSelected: false };
        }
        //remove already selected one
        if (customer?.isSelected === true) {
          return { ...customer, isSelected: false };
        }
        return { ...customer, isSelected: true };
      });
      dispatch(updateCustomers(updatedList));
    },
    [customers, dispatch]
  );

  return (
    <PrimaryList
      listName={"Customer"}
      getListCallback={customersCallback}
      selectListCallback={onSelectCallback}
      columns={columns}
      listData={customers}
      listTotal={recordsTotal}
      isLoading={isLoading}
      sideContent={(selected, closeCallback) => {
        return <ListRight data={selected} hideRightPanel={closeCallback} />;
      }}
    />
  );
};

export default CustomerList;
