import "./UserToolbar.scss";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { userLogout } from "../../../../redux/features/auth/AuthSlice";
import { useNavigate } from "react-router-dom";
import { GearFill } from "react-bootstrap-icons";
import { QuestionCircleFill } from "react-bootstrap-icons";
import { DoorOpenFill } from "react-bootstrap-icons";

const UserToolbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, role } = useSelector((store) => store.auth);
  const [userDropdown, setUserDropdown] = useState(false);

  const toggleUserDropdown = () => {
    setUserDropdown(!userDropdown);
  };

  const handleLogOut = (e) => {
    e.preventDefault();

    dispatch(userLogout());
    navigate("/");
  };

  return (
    <div className="user-menu-toolbar">
      <div className="user-name-wrapper">Hi {user.username}</div>
      <div className="user-image-wrapper" onClick={toggleUserDropdown}>
        <img src={process.env.REACT_APP_API_URL + user.user_image} alt="user" />
      </div>

      {userDropdown && (
        <div className="user-toolbar-dropdown shadow-sm">
          <div className="profile-image">
            <img
              src={process.env.REACT_APP_API_URL + user.user_image}
              alt="user-profile"
            />
            <h1>{`${user.user_first_name} ${user.user_last_name}`}</h1>
            <p>{role.role_name}</p>
          </div>
          <a className="dropdown-item" href="users/my-account">
            <GearFill width="16" height="16" /> Manage My Account
          </a>
          <a className="dropdown-item" href="users/my-account">
            <QuestionCircleFill width="16" height="16" /> Help Center
          </a>
          <a
            className="dropdown-item"
            href="users/my-account"
            onClick={handleLogOut}
          >
            <DoorOpenFill width="16" height="16" /> Sign out
          </a>
        </div>
      )}
    </div>
  );
};

export default UserToolbar;
