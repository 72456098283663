import React, { useState } from "react";
import { Upload } from "react-bootstrap-icons";

import SelectUnit from "../../../../components/form/select/SelectUnit";
import SelectBrand from "../../../../components/form/select/SelectBrand";
import SelectCategory from "../../../../components/form/select/SelectCategory";
import SelectTaxable from "../../../../components/form/select/SelectTaxable";

const FormGeneralEdit = (props) => {
  const { item } = props;
  //locale state
  const [form, setFrom] = useState({
    item_name: item?.item_name ? item.item_name : "",
    item_sku: item?.item_sku ? item.item_sku : "",
    category: item?.category
      ? {
          ...item.category,
          label: item.category.cat_name,
          value: item.category.cat_id,
        }
      : null,
    brand: item?.brand
      ? {
          ...item.brand,
          label: item.brand.brand,
          value: item.brand.brand_id,
        }
      : null,
    unit: item?.unit
      ? {
          ...item.unit,
          label: item.unit.unit,
          value: item.unit.unit_id,
        }
      : null,
    item_image: item?.item_image
      ? process.env.REACT_APP_API_URL + item.item_image
      : "",
    item_description: item?.item_description ? item.item_description : "",
    sales_tax_id: item?.sales_tax_id ? item.sales_tax_id : "1",
  });
  //event
  //this will handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFrom({ ...form, [name]: value });
  };

  // this will handle image change
  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader();
      reader.onload = function (ev) {
        setFrom({ ...form, item_image: ev.target.result });
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <div className="row">
      <div className="col-6">
        <div className="form-group mb-1">
          <label htmlFor="item_name">Product Name</label>
          <input
            type="text"
            className="form-control"
            name="item_name"
            id="item_name"
            value={form.item_name}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-row">
          <div className="form-group col-md-8 mb-1">
            <label htmlFor="sku-code">SKU</label>
            <input
              type="text"
              className="form-control"
              id="item_sku"
              placeholder="KD12548"
              name="item_sku"
              value={form.item_sku}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group col-md-4 mb-1">
            <label htmlFor="unit_id">Pack Size</label>
            <SelectUnit selected={form.unit} id="unit_id" />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6 mb-1">
            <label htmlFor="brand_id">Brand</label>
            <SelectBrand selected={form.brand} id="brand_id" />
          </div>
          <div className="form-group col-md-6 mb-1">
            <label htmlFor="category_id">Category</label>
            <SelectCategory selected={form.category} id="category_id" />
          </div>
        </div>
        <div className="form-group mb-1">
          <label htmlFor="item_description">Product Description</label>
          <input
            type="text"
            className="form-control"
            id="item_description"
            name="item_description"
            value={form.item_description}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-row">
          <div className="form-group col-md-5 mb-1">
            <label htmlFor="sales_tax_id">Sale Tax</label>
            <SelectTaxable selected={form.sales_tax_id} id="sales_tax_id" />
          </div>
        </div>
      </div>
      <div className="col-6">
        <div className="upload-image-preview mr-5 mb-2">
          <img
            id="user-img-new"
            className="img-thumbnail"
            src={form.item_image ? form.item_image : "/img/product.png"}
            alt="product"
          />
        </div>
        <div className="form-group">
          <div className="upload-image-file">
            <label htmlFor="item_image">
              <Upload /> Upload Image
            </label>
            <input
              accept="image/*"
              type="file"
              className="form-control-file"
              id="item_image"
              name="item_image"
              onChange={handleImageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default React.memo(FormGeneralEdit);
