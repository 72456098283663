import React, { useState } from "react";

import SelectWarehouse from "../../../components/form/select/SelectWarehouse";
import SelectVendor from "../../../components/form/select/SelectVendor";
import SelectAssetAccount from "../../../components/form/select/SelectAssetAccount";
import SelectIncomeAccount from "../../../components/form/select/SelectIncomeAccount";

const FormAdditional = (props) => {
  //locale state
  const [form, setFrom] = useState({
    batch_location: "",
    item_width: "",
    item_height: "",
    item_depth: "",
    warehouse_id: "",
    pref_supplier_id: "",
    income_account_id: "",
    expense_account_id: "",
  });
  //event
  //this will handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFrom({ ...form, [name]: value });
  };

  return (
    <div className="row">
      <div className="col-6">
        <div className="form-group mb-1">
          <label htmlFor="batch_location">Location Of Item On Store</label>
          <input
            type="text"
            className="form-control"
            id="batch_location"
            name="batch_location"
            value={form.batch_location}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-row">
          <div className="form-group col-md-12 mb-1">
            <label>Product Dimensions</label>
          </div>
          <div className="form-group col-md-4 mb-1">
            <input
              type="text"
              className="form-control"
              id="item_width"
              name="item_width"
              placeholder="Width"
              value={form.item_width}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group col-md-4 mb-1">
            <input
              type="text"
              className="form-control"
              id="item_height"
              name="item_height"
              placeholder="Height"
              value={form.item_height}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group col-md-4 mb-1">
            <input
              type="text"
              className="form-control"
              id="item_depth"
              name="item_depth"
              placeholder="Depth"
              value={form.item_depth}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-10 mb-1">
            <label htmlFor="warehouse_id">Warehouse</label>
            <SelectWarehouse id="warehouse_id" selected={0} />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-10 mb-1">
            <label htmlFor="pref_supplier_id">Preferred Vendor</label>
            <SelectVendor id="pref_supplier_id" />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-6 mb-1">
            <label htmlFor="expense_account_id">Inventory Asset Account</label>
            <SelectAssetAccount account_no="10005" id="expense_account_id" />
          </div>
          <div className="form-group col-md-6 mb-1">
            <label htmlFor="income_account_id">Income Account</label>
            <SelectIncomeAccount account_no="40002" id="income_account_id" />
          </div>
        </div>
      </div>
      <div className="col-6"></div>
    </div>
  );
};
export default FormAdditional;
