import "./RightSidePanel.scss";
import { useSelector, useDispatch } from "react-redux";
import { XLg } from "react-bootstrap-icons";
import { hideRightPanel } from "../../../redux/features/rightpanel/RightPanelSlice";

const RightSidePanel = () => {
  const dispatch = useDispatch();
  const { content } = useSelector((store) => store.right_panel);
  const handleOnClose = () => {
    dispatch(hideRightPanel());
  };
  return (
    <div className="right-side-panel">
      <div className="close-button">
        <button type="button" className="close" onClick={handleOnClose}>
          <XLg />
        </button>
      </div>
      {content}
    </div>
  );
};

export default RightSidePanel;
