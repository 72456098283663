import { Link } from "react-router-dom";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { hasPrivilege } from "../../../../Helper";
import { Coin } from "react-bootstrap-icons";

import { showOptionModel } from "../../../../redux/features/modals/OptionModalSlide";

import PaymentTerm from "../../../../containers/company/terms/PaymentTerm";

const CompanyTab = (props) => {
  const dispatch = useDispatch();

  //create new category
  const handleNewPayTerm = () => {
    const modelData = {
      title: "New Payment Term",
      titleIcon: <Coin />,
      content: <PaymentTerm />,
    };
    dispatch(showOptionModel(modelData));
  };

  return (
    <div className="row no-gutters">
      <div className="col-md-auto ">
        <div className="toolbar-section">
          <div className="row no-gutters">
            <div className="col-auto">
              <Link
                className={classNames("btn toolbar-button", {
                  disabled: !hasPrivilege("Company", 901),
                })}
                to="/dashboard/company"
              >
                <img src="/img/icons/company.png" alt="company" />
                <span className="text">Company</span>
              </Link>
            </div>
            <div className="col-auto">
              <Link
                className={classNames("btn toolbar-button", {
                  disabled: !hasPrivilege("Company", 902),
                })}
                to="/dashboard/company/branches"
              >
                <img src="/img/icons/branch.png" alt="company" />
                <span className="text">Branches</span>
              </Link>
            </div>
            <div className="col-auto">
              <Link
                className={classNames("btn toolbar-button", {
                  disabled: !hasPrivilege("Company", 905),
                })}
                to="/dashboard/company/warehouses"
              >
                <img src="/img/icons/warehouse.png" alt="Warehouses" />
                <span className="text">Warehouses</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-auto">
        <div className="toolbar-section">
          <div className="row no-gutters">
            <div className="col-auto">
              <Link
                className={classNames("btn toolbar-button", {
                  disabled: !hasPrivilege("Company", 906),
                })}
                to="/dashboard/company/sales-taxes"
              >
                <img src="/img/icons/tax.png" alt="invoice" />
                <span className="text">Sales Taxes</span>
              </Link>
            </div>
            <div className="col-auto">
              <Link
                className={classNames("btn toolbar-button-inline", {
                  disabled: !hasPrivilege("Company", 906),
                })}
                to="/dashboard/company/new-sales-tax"
              >
                <img src="/img/icons/new-tax.png" alt="receive cash" />
                <span className="text"> Add Sale Tax </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-auto">
        <div className="toolbar-section">
          <div className="row no-gutters">
            <div className="col-auto">
              <Link
                className={classNames("btn toolbar-button", {
                  disabled: !hasPrivilege("Company", 907),
                })}
                to="/dashboard/company/payment-terms"
              >
                <img src="/img/icons/terms.png" alt="Terms" />
                <span className="text">Payment Terms</span>
              </Link>
            </div>
            <div className="col-auto">
              <button
                className={classNames("btn toolbar-button-inline", {
                  disabled: !hasPrivilege("Company", 907),
                })}
                onClick={hasPrivilege("Company", 907) && handleNewPayTerm}
              >
                <img src="/img/icons/new-terms.png" alt="receive cash" />
                <span className="text"> Add Payment Term </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-auto">
        <div className="toolbar-section">
          <div className="row no-gutters">
            <div className="col-auto">
              <Link
                className={classNames("btn toolbar-button-inline", {
                  disabled: !hasPrivilege("Company", 908),
                })}
                to="/dashboard/company/modules"
              >
                <img src="/img/icons/modules.png" alt="receive cash" />
                <span className="text"> Modules </span>
              </Link>
              <Link
                className={classNames("btn toolbar-button-inline", {
                  disabled: !hasPrivilege("Company", 908),
                })}
                to="/dashboard/company/setting"
              >
                <img src="/img/icons/setting.png" alt="Setting" />
                <span className="text"> Settings </span>
              </Link>
            </div>
            <div className="col-auto">
              <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  className="toolbar-button-drop"
                >
                  <img src="/img/icons/setting.png" alt="Setting" />
                  <span className="text"> Print Formats </span>
                </Dropdown.Toggle>

                <Dropdown.Menu style={{ margin: 0 }}>
                  <Link
                    className={classNames("btn toolbar-button-drop-item", {
                      disabled: !hasPrivilege("Company", 909),
                    })}
                    to="/dashboard/company/print-sale-order-format"
                  >
                    <img src="/img/icons/quotation.png" alt="Print format" />
                    <span className="text"> Print Sale Order Formats </span>
                  </Link>
                  <Link
                    className={classNames("btn toolbar-button-drop-item", {
                      disabled: !hasPrivilege("Company", 909),
                    })}
                    to="/dashboard/company/print-invoice-format"
                  >
                    <img src="/img/icons/quotation.png" alt="Print format" />
                    <span className="text"> Print Invoice Formats </span>
                  </Link>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyTab;
