import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";

import PurchaseOrderCtrl from "./PurchaseOrderCtrl";
import WorkplaceTable from "./WorkplaceTable";

import {
  setPONumber,
  setPODate,
  setExpectedDeliveryDate,
  setPOSupplierAddress,
  setPODeliveryAddress,
  setReference,
  setNote,
  setTerms,
} from "../../../redux/features/purchases/PurchaseOrderSlice";

const PurchaseOrder = (prop) => {
  const dispatch = useDispatch();
  const action = prop.action;

  const {
    poNo,
    poDate,
    poExpectedDeliveryDate,
    supplierAddress,
    deliveryAddress,
    reference,
    note,
    terms,
  } = useSelector((store) => store.purchase_order);

  /*change handlers */
  const poNoOnChange = (e) => {
    const { value } = e.target;
    dispatch(setPONumber(value));
  };

  const poDateOnChange = (date) => {
    //console.log(Date.parse("2022-10-30"));
    dispatch(setPODate(date));
  };

  const poExpectedDeliveryDateOnChange = (date) => {
    dispatch(setExpectedDeliveryDate(date));
  };

  const supplierAddressOnChange = (e) => {
    const { value } = e.target;
    dispatch(setPOSupplierAddress(value));
  };

  const deliveryAddressOnChange = (e) => {
    const { value } = e.target;
    dispatch(setPODeliveryAddress(value));
  };

  const referenceOnChange = (e) => {
    const { value } = e.target;
    dispatch(setReference(value));
  };

  const noteOnChange = (e) => {
    const { value } = e.target;
    dispatch(setNote(value));
  };

  const termsOnChange = (e) => {
    const { value } = e.target;
    dispatch(setTerms(value));
  };

  return (
    <div className="new-purchase">
      <div className="doc-editor">
        <div className="doc-editor-workplace">
          <div className="workplace-header">
            <div className="row no-gutters">
              <div className="col-md-5">
                <div className="form-row">
                  <div className="form-group col-md-6  mb-0">
                    <label htmlFor="po-no">Purchase Order#</label>
                    <input
                      type="text"
                      className="form-control"
                      name="po-no"
                      id="po-no"
                      value={poNo}
                      onChange={poNoOnChange}
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-5 mb-0">
                    <label htmlFor="po-date">Date</label>
                    <DatePicker
                      className="form-control"
                      dateFormat="yyyy-MM-dd"
                      id="po-date"
                      name="po-date"
                      autoComplete="off"
                      selected={Date.parse(poDate)}
                      onChange={poDateOnChange}
                    />
                  </div>
                  <div className="form-group col-md-5 mb-1">
                    <label htmlFor="po-date-expect">
                      Expected Delivery Date
                    </label>
                    <DatePicker
                      className="form-control"
                      dateFormat="yyyy-MM-dd"
                      id="po-date-expect"
                      name="po-date-expect"
                      autoComplete="off"
                      selected={Date.parse(poExpectedDeliveryDate)}
                      onChange={poExpectedDeliveryDateOnChange}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="row no-gutters">
                  <div className="col-md-6 pl-2">
                    <div className="form-group">
                      <label htmlFor="po-supplier-address">
                        Supplier Address
                      </label>
                      <textarea
                        className="form-control"
                        id="po-supplier-address"
                        rows="4"
                        name="po-supplier-address"
                        value={supplierAddress}
                        onChange={supplierAddressOnChange}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-md-6 pl-2">
                    <div className="form-group">
                      <label htmlFor="po-deliver-address">
                        Delivery Address
                      </label>
                      <textarea
                        className="form-control"
                        id="po-deliver-address"
                        rows="4"
                        name="po-deliver-address"
                        value={deliveryAddress}
                        onChange={deliveryAddressOnChange}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <WorkplaceTable />
          <div className="workplace-footer">
            <div className="row">
              <div className="col-md-5">
                <div className="form-row">
                  <div className="form-group col-md-6  mb-0">
                    <label htmlFor="po-reference">Reference#</label>
                    <input
                      type="text"
                      className="form-control"
                      name="po-reference"
                      id="po-reference"
                      value={reference}
                      onChange={referenceOnChange}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="po-note">Note for Supplier</label>
                  <textarea
                    className="form-control"
                    id="po-note"
                    rows="3"
                    name="po-note"
                    value={note}
                    onChange={noteOnChange}
                  ></textarea>
                </div>
              </div>
              <div className="col-md-7 pt-3">
                <div className="form-group">
                  <label htmlFor="po-terms">Terms & Conditions</label>
                  <textarea
                    className="form-control"
                    id="po-terms"
                    rows="5"
                    name="po-terms"
                    value={terms}
                    onChange={termsOnChange}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="doc-editor-control-wrapper">
          <div className="doc-editor-control">
            <PurchaseOrderCtrl action={action} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseOrder;
