import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars-2";
import { Dropdown } from "react-bootstrap";
import {
  PencilFill,
  CheckLg,
  Trash2Fill,
  FileTextFill,
} from "react-bootstrap-icons";
import { hasPrivilege } from "../../../../Helper";

import SOConfirm from "../SOConfirm";
import SODelete from "../SODelete";

import { showOptionModel } from "../../../../redux/features/modals/OptionModalSlide";
import { hideRightPanel } from "../../../../redux/features/rightpanel/RightPanelSlice";
import { showPrintPreview } from "../../../../redux/features/modals/PrintPreviewSlice";

import { updateSOList } from "../../../../redux/features/sales/SaleOrderListSlice";

const SOListRight = (props) => {
  //locale
  const [saleOrder, setSaleOrder] = useState(props.saleOrder);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { saleOrderList } = useSelector((store) => store.sale_order_list);

  //set info
  const infoList = [
    {
      name: "SO Date",
      value: saleOrder.so_date,
    },
    {
      name: "Expected",
      value: saleOrder.ex_shipment_date,
    },
    {
      name: "Sales Rep",
      value: saleOrder.sales_rep_name,
    },
  ];
  const info_list_elements = infoList.map((field) => {
    return (
      <div key={field.name} className="row no-gutters info-field">
        <div className="col-sm-4 name">
          <p>{field.name}</p>
        </div>
        <div className="col-sm-8 value">
          <p> : {field.value}</p>
        </div>
      </div>
    );
  });

  //action

  //handle view
  const handleSOView = () => {
    const previewData = {
      title: "Sale Order",
      format: "SaleOrder",
      data: saleOrder,
      printable: hasPrivilege("Sales", 203),
    };
    dispatch(showPrintPreview(previewData));
  };

  //handle SO Confirm
  const confirmCallback = (updated_so) => {
    const updatedData = saleOrderList.map((list_so) => {
      if (updated_so.so_id === list_so.so_id) {
        return updated_so;
      }
      return list_so;
    });
    dispatch(updateSOList(updatedData));
    setSaleOrder(updated_so);
  };
  const handleSOConfirm = () => {
    const modelData = {
      title: "Confirm Sale Order",
      titleIcon: <FileTextFill />,
      content: <SOConfirm so={saleOrder} confirm_callback={confirmCallback} />,
    };
    dispatch(showOptionModel(modelData));
  };

  //handle edit
  const handleSOEdit = () => {
    navigate("so-edit/" + saleOrder.so_id);
  };

  //handler for delete
  const deleteCallback = (so_id) => {
    const updatedData = saleOrderList.filter(
      (list_po) => so_id !== list_po.so_id
    );
    dispatch(updateSOList(updatedData));
    dispatch(hideRightPanel());
  };
  const handleDelete = () => {
    const modelData = {
      title: "Delete Sale Order",
      titleIcon: <FileTextFill />,
      content: <SODelete so={saleOrder} delete_callback={deleteCallback} />,
    };
    dispatch(showOptionModel(modelData));
  };

  //set props
  useEffect(() => {
    setSaleOrder(props.saleOrder);
  }, [props.saleOrder]);

  return (
    <div className="right-content">
      <div className="header">
        <div className="header-img">
          <img src="/img/icons/invoice-lg.png" alt="side header" />
        </div>
        <div className="header-text">
          <h4>Sale Order</h4>
          <p>SO# {saleOrder.so_no}</p>
        </div>
      </div>

      <Scrollbars style={{ width: "100%", height: "100%" }}>
        <div className="info-section">
          <h6>SO Information</h6>
          <div className="row mb-2">
            <div className="col-sm-6">{info_list_elements}</div>
            <div className="col-sm-6">
              <div className="d-flex">
                <div className="p-1">Status</div>
                <div className="badge-status">{saleOrder.so_status}</div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <h6>Billing Address</h6>
              <p className="m-0">{saleOrder.customer_address}</p>
            </div>
            <div className="col-sm-6">
              <h6>Delivery Address</h6>
              <p className="m-0"></p>
            </div>
          </div>
        </div>

        <div className="trans-content">
          <h6>SO Items</h6>
          <table className="table table-sm table-hover">
            <thead>
              <tr>
                <th>Item</th>
                <th>Qty</th>
                <th style={{ textAlign: "right" }}>Rate</th>
                <th style={{ textAlign: "right" }}>Amount</th>
              </tr>
            </thead>
            <tbody>
              {saleOrder.so_item_rows.map((row) => {
                return (
                  <tr key={row.row_id}>
                    <td>
                      <p style={{ fontWeight: 600 }}>{row.so_item.item_name}</p>
                      <p>{row.so_item_description}</p>
                    </td>
                    <td>{row.so_item_qty}</td>
                    <td style={{ textAlign: "right" }}>{row.so_item_rate}</td>
                    <td style={{ textAlign: "right" }}>{row.so_item_amount}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Scrollbars>
      <div className="action-section">
        <div className="action-control">
          {hasPrivilege("Sales", 200) && (
            <button className="btn btn-dark b-button" onClick={handleSOView}>
              View
            </button>
          )}

          {hasPrivilege("Sales", 204) && saleOrder.so_status === "Draft" ? (
            <button
              className="btn btn-primary b-button"
              onClick={handleSOConfirm}
            >
              <CheckLg />
              Confirm
            </button>
          ) : null}

          <Dropdown>
            <Dropdown.Toggle
              variant="secondary"
              id="more-action"
              className="b-button"
            >
              More
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ margin: 0 }}>
              {hasPrivilege("Sales", 202) && saleOrder.so_status === "Draft" ? (
                <Dropdown.Item eventKey="2" onClick={handleSOEdit}>
                  <PencilFill /> Edit Sale Order
                </Dropdown.Item>
              ) : null}
              {hasPrivilege("Sales", 205) && (
                <Dropdown.Item eventKey="3" onClick={handleDelete}>
                  <Trash2Fill /> Delete
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};
export default SOListRight;
