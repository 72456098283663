import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../../../api/axios";
import Moment from "moment";
import { Dropdown } from "react-bootstrap";
import { PlusSquareDotted, Save } from "react-bootstrap-icons";

import SelectCustomer from "./SelectCustomer";
import SelectSalesRep from "./SelectSalesRep";

import {
  setSOCustomer,
  setSOSaleRep,
  setNewSO,
} from "../../../../redux/features/sales/SaleOrderSlice";
import { autoNotify } from "../../../../redux/features/notify/NotifySlice";
import { showPrintPreview } from "../../../../redux/features/modals/PrintPreviewSlice";

const SaleOrderCtrl = (prop) => {
  const dispatch = useDispatch();
  const { token, branch, user } = useSelector((store) => store.auth);
  const action = prop.action;
  const {
    soId,
    soNo,
    soDate,
    soStatus,
    soExpectedDeliveryDate,
    customer,
    saleRep,
    saleRepName,
    billingAddress,
    discount,
    discountValue,
    tax,
    taxValue,
    subTotal,
    soTotal,
    tableRows,
    reference,
    note,
    terms,
    paymentTerm,
    paymentDue,
  } = useSelector((store) => store.sale_order);

  const [totalItems, setTotalItems] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  //Events
  const customerOnChangeHandler = useCallback(
    (name, option) => {
      dispatch(setSOCustomer(option));
    },
    [dispatch]
  );

  const saleRepOnChangeHandler = useCallback(
    (name, salesRep) => {
      dispatch(setSOSaleRep(salesRep));
    },
    [dispatch]
  );

  //set total items
  useEffect(() => {
    let totalQty = 0;
    for (let i = 0; i < tableRows.length; i++) {
      const element = tableRows[i];
      totalQty = totalQty + parseInt(element.quantity);
    }
    setTotalItems(totalQty);
  }, [tableRows]);

  //Actions
  //Save PO
  const saveSO = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      let data = getCurrentSO();
      const resp = await axios.post("customers/sale-order", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setIsSubmitting(false);
      autoNotify(dispatch, resp);
      if (action === "New") {
        dispatch(setNewSO());
      }
    } catch (error) {
      setIsSubmitting(false);
      autoNotify(dispatch, error?.response ? error.response : error.message);
    }
  };

  //Sve PO with confirm
  const saveAndConfirm = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      let data = getCurrentSO();
      data.so_status = "Confirmed";
      const resp = await axios.post("customers/sale-order", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setIsSubmitting(false);
      autoNotify(dispatch, resp);
      if (action === "New") {
        dispatch(setNewSO());
      }
    } catch (error) {
      setIsSubmitting(false);
      autoNotify(dispatch, error?.response ? error.response : error.message);
    }
  };

  /* PO print */
  const soPrintHandler = () => {
    const previewData = {
      title: "Sale Order",
      format: "SaleOrder",
      data: getCurrentSO(),
    };
    dispatch(showPrintPreview(previewData));
  };

  //new PO
  const newSOHandler = () => {
    dispatch(setNewSO());
  };

  // this will return current po
  const getCurrentSO = () => {
    //remove empty
    let table_row_items = tableRows.filter((row) => {
      return row.item ? true : false;
    });
    let row_id = 0;
    let so_item_rows = table_row_items.map((row) => {
      row_id++;
      const table_row = {
        row_id: row_id,
        batch_id: row.item.batch_id,
        so_item: row.item,
        so_item_description: row.itemDes,
        so_item_qty: row.quantity,
        so_item_rate: row.rate,
        so_item_amount: row.amount,
      };
      return table_row;
    });

    let so = {
      branch_id: branch.branch_id,
      so_id: soId,
      so_date: Moment(soDate).format("YYYY-MM-DD"),
      so_no: soNo,
      so_status: soStatus,
      customer_id: customer?.cust_id,
      customer_address: billingAddress,
      sales_rep_id: saleRep?.rep_id,
      sales_rep_name: saleRepName,
      total_items: totalItems,
      so_discount: discount,
      so_discount_val: discountValue,
      so_tax_id: tax?.tax_id,
      so_tax_total: taxValue,
      so_sub_total: subTotal,
      so_total: soTotal,
      ex_shipment_date: soExpectedDeliveryDate
        ? Moment(soExpectedDeliveryDate).format("YYYY-MM-DD")
        : "",
      payment_terms_id: paymentTerm?.term_id,
      payment_term: paymentTerm,
      payment_due_date: Moment(paymentDue).format("YYYY-MM-DD"),
      customer_note: note,
      so_reference: reference,
      so_terms: terms,
      so_item_rows: so_item_rows,
      created_by: user.user_id,
    };
    return so;
  };

  return (
    <div className="control-content">
      <h5 className="display-4 title">{action} Sale Order</h5>
      <hr className="mt-0" />
      <div className="form-row">
        <div className="form-group col-md-9 mb-2">
          <label htmlFor="customer_id">Select Customer</label>
          <SelectCustomer
            name={"customer_id"}
            value={customer}
            callback={customerOnChangeHandler}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-9 mb-2">
          <label htmlFor="salesRep">Select Sales Rep</label>
          <SelectSalesRep
            name={"salesRep"}
            value={saleRep}
            callback={saleRepOnChangeHandler}
          />
        </div>
      </div>

      <div className="form-row mt-5">
        <div className="form-group col-md-12 mb-1">
          <label htmlFor="sale-tax">Total Items</label>
          <h3 id="po-item-count-lbl">{totalItems}</h3>
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-12 mb-1">
          <label htmlFor="sale-tax">Total Amount</label>
          <h3 id="po-item-total-lbl">{parseFloat(soTotal).toFixed(2)}</h3>
        </div>
      </div>
      <div className="mt-5 action-control">
        <button
          type="button"
          id="po-save-btn"
          className="btn btn-success b-button"
          onClick={saveSO}
          disabled={isSubmitting}
        >
          Save
        </button>
        <button
          id="po-save-send-btn"
          className="btn btn-dark b-button"
          type="button"
          onClick={soPrintHandler}
        >
          Preview
        </button>
        <Dropdown>
          <Dropdown.Toggle
            variant="secondary"
            id="more-action"
            className="b-button"
          >
            More
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ margin: 0 }}>
            {action === "New" ? (
              <Dropdown.Item eventKey="2" onClick={newSOHandler}>
                <PlusSquareDotted /> New Sale Order
              </Dropdown.Item>
            ) : (
              ""
            )}
            <Dropdown.Item
              eventKey="3"
              onClick={(e) => {
                saveAndConfirm(e);
              }}
              disabled={isSubmitting}
            >
              <Save /> Save & Confirm
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default SaleOrderCtrl;
