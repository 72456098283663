import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  PencilFill,
  Trash2Fill,
  PersonWorkspace,
  UnlockFill,
} from "react-bootstrap-icons";
import { Scrollbars } from "react-custom-scrollbars-2";
import axios from "../../api/axios";
import { hasPrivilege } from "../../Helper";

import { showPrimaryModel } from "../../redux/features/modals/PrimaryModalSlice";
import { showOptionModel } from "../../redux/features/modals/OptionModalSlide";
import { hideRightPanel } from "../../redux/features/rightpanel/RightPanelSlice";
import UserRoleEdit from "./UserRoleEdit";
import UserRoleDelete from "./UserRoleDelete";
import { updateData } from "../../redux/features/users/UserRoleListSlice";

const UserRoleListRight = (props) => {
  const dispatch = useDispatch();
  const { role } = props;
  const user_role_list = useSelector((store) => store.user_role_list.data);
  const { token } = useSelector((store) => store.auth);
  const [userRole, setUserRole] = useState(role);
  const [privilegesList, setPrivilegesList] = useState([]);
  const [isPrivilegesLoading, setPrivilegesLoading] = useState(true);
  const isFirstRender = useRef(true);

  //set user info
  const infoList = [
    {
      name: "User Accounts",
      value: userRole.user_accounts,
    },
    {
      name: "Created at",
      value: userRole.created_at,
    },
  ];

  const info_list_elements = infoList.map((field) => {
    return (
      <div key={field.name} className="row no-gutters info-field">
        <div className="col-sm-3 name">
          <p>{field.name} : </p>
        </div>
        <div className="col-sm-8 value">
          <p>{field.value}</p>
        </div>
      </div>
    );
  });

  //handler for edit
  const editCallback = (edited_role) => {
    setUserRole(edited_role);
    const updatedData = user_role_list.map((list_role) => {
      if (edited_role.role_id === list_role.role_id) {
        return edited_role;
      }
      return list_role;
    });
    dispatch(updateData(updatedData));
  };
  const handleEdit = () => {
    const modelData = {
      title: "Edit User Role",
      titleIcon: <PersonWorkspace />,
      content: <UserRoleEdit user_role={userRole} callback={editCallback} />,
    };
    dispatch(showPrimaryModel(modelData));
  };

  //handler for delete
  const deleteCallback = (role_id) => {
    const updatedData = user_role_list.filter(
      (list_user_role) => role_id !== list_user_role.role_id
    );
    dispatch(updateData(updatedData));
    dispatch(hideRightPanel());
  };
  const handleDelete = () => {
    const modelData = {
      title: "Delete User Role",
      titleIcon: <PersonWorkspace />,
      content: (
        <UserRoleDelete delete_role={userRole} callback={deleteCallback} />
      ),
    };
    dispatch(showOptionModel(modelData));
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    setUserRole(role);
  }, [role]);

  //set privileges
  useEffect(() => {
    let ignore = false;

    const setRolePrivileges = async () => {
      try {
        setPrivilegesLoading(true);
        const searchParams = new URLSearchParams();
        searchParams.append("role_id", userRole.role_id);
        searchParams.toString();
        const response = await axios.get(
          "users/role-privileges?" + searchParams.toString(),
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!ignore) {
          let privileges_list = [];
          for (const [key, permissions] of Object.entries(response.data)) {
            let permissions_list = permissions.map((permission) => {
              return (
                <li key={permission.privi_id}>
                  <UnlockFill /> {permission.privi_name}
                </li>
              );
            });
            privileges_list.push(
              <div key={key}>
                <h6 className="trans-title">{key}</h6>
                <ul className="trans-list">{permissions_list}</ul>
              </div>
            );
          }
          setPrivilegesList(privileges_list);
          setPrivilegesLoading(false);
        }
      } catch (error) {
        setPrivilegesList([]);
        setPrivilegesLoading(false);
      }
    };
    setRolePrivileges();
    return () => {
      ignore = true;
    };
  }, [token, userRole]);

  //
  return (
    <div className="right-content">
      <div className="header">
        <div className="header-img">
          <img src="/img/icons/user-role-edit.png" alt="side header" />
        </div>
        <div className="header-text">
          <h4>{userRole.role_name}</h4>
          <p>{userRole.role_description}</p>
        </div>
      </div>
      <div className="info-section">
        <h6>Role Information</h6>
        {info_list_elements}
      </div>
      <h6>Role Privileges</h6>
      <Scrollbars style={{ width: "100%", height: "100%" }}>
        <div className="trans-content">
          {isPrivilegesLoading ? <p>Processing...</p> : privilegesList}
        </div>
      </Scrollbars>
      <div className="action-section">
        {hasPrivilege("Users", 705) && (
          <button onClick={handleEdit} className="btn btn-primary b-button">
            <PencilFill /> Edit Role
          </button>
        )}
        {hasPrivilege("Users", 706) && (
          <button onClick={handleDelete} className="btn btn-dark b-button">
            <Trash2Fill /> Delete
          </button>
        )}
      </div>
    </div>
  );
};

export default UserRoleListRight;
