import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../../api/axios";
import { autoNotify } from "../../../redux/features/notify/NotifySlice";
import { hideOptionModel } from "../../../redux/features/modals/OptionModalSlide";

const PaymentTerm = (props) => {
  const dispatch = useDispatch();
  const { edit_term, edit_callback } = props;
  const { token, branch, user } = useSelector((store) => store.auth);

  //local stats
  const [term, setTerm] = useState({
    com_id: branch.com_id,
    term_id: null,
    term_name: "",
    term_days: 7,
    created_by: user.user_id,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  //events
  const inputsOnChange = (e) => {
    const { name, value } = e.target;
    setTerm((prevCategory) => {
      return { ...prevCategory, [name]: value };
    });
  };

  //action
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const resp = await axios.post("company/payment-term", term, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      autoNotify(dispatch, resp);
      setIsSubmitting(false);
      if (edit_callback) {
        edit_callback(resp.data.term);
      }
      dispatch(hideOptionModel());
    } catch (error) {
      autoNotify(dispatch, error.response);
      setIsSubmitting(false);
    }
  };

  const handleFormClose = () => {
    dispatch(hideOptionModel());
  };

  //effect
  useEffect(() => {
    if (edit_term) {
      setTerm((prevTerm) => {
        return {
          ...prevTerm,
          term_id: edit_term.term_id,
          term_name: edit_term.term_name,
          term_days: edit_term.term_days,
        };
      });
    }
  }, [edit_term]);

  return (
    <form>
      <div className="form-row">
        <div className="form-group col-md-6 mb-1">
          <label htmlFor="term_name">Term Name</label>
          <input
            type="text"
            className="form-control"
            id="term_name"
            name="term_name"
            value={term.term_name}
            onChange={inputsOnChange}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-3 mb-1">
          <label htmlFor="term_days">Due in number of days</label>
          <input
            type="number"
            className="form-control"
            id="term_days"
            name="term_days"
            value={term.term_days}
            onChange={inputsOnChange}
          />
        </div>
      </div>
      <div className="mt-3">
        <button
          id="supp-create-btn"
          className="btn btn-primary float-right save-button ml-2"
          type="submit"
          disabled={isSubmitting}
          onClick={handleFormSubmit}
        >
          Save
        </button>
        <button
          id="supp-close-btn"
          type="button"
          className="btn btn-secondary float-right "
          data-dismiss="modal"
          onClick={handleFormClose}
        >
          Close
        </button>
      </div>
    </form>
  );
};

export default PaymentTerm;
