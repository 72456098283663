import "./BatchEdit.scss";
import { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import Moment from "moment";
import DatePicker from "react-datepicker";
import axios from "../../../api/axios";
import SelectWarehouse from "../../../components/form/select/SelectWarehouse";

import BatchChooser from "./BatchChooser";
import { hidePrimaryModel } from "../../../redux/features/modals/PrimaryModalSlice";
import { autoNotify } from "../../../redux/features/notify/NotifySlice";

const BatchEdit = (props) => {
  const { item, callback } = props;
  const dispatch = useDispatch();
  //Global
  const { token, branch, user } = useSelector((store) => store.auth);
  // local sates
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form, setForm] = useState({
    com_id: branch?.com_id,
    branch_id: branch?.branch_id,
    batch_id: "",
    warehouse_id: "",
    warehouse: null,
    lot_number: "",
    batch_location: "",
    batch_cost_price: 0,
    batch_sale_price: 0,
    batch_made_date: "",
    batch_expiry_date: "",
    barcode: "",
    updated_by: user?.user_id,
  });
  //events
  const batchSelectCallback = useCallback((batch) => {
    setForm((prevData) => {
      return {
        ...prevData,
        batch_id: batch?.batch_id ? batch.batch_id : "",
        warehouse_id: batch?.warehouse_id ? batch.warehouse_id : "",
        warehouse: batch?.warehouse
          ? {
              ...batch.warehouse,
              label: batch.warehouse.ware_name,
              value: batch.warehouse.ware_id,
            }
          : null,
        lot_number: batch?.lot_number ? batch.lot_number : "",
        batch_location: batch?.batch_location ? batch.batch_location : "",
        batch_cost_price: batch?.batch_cost_price ? batch.batch_cost_price : "",
        batch_sale_price: batch?.batch_sale_price ? batch.batch_sale_price : "",
        batch_made_date: batch?.batch_made_date ? batch.batch_made_date : "",
        batch_expiry_date: batch?.batch_expiry_date
          ? batch.batch_expiry_date
          : "",
        barcode: batch?.barcode ? batch.barcode : "",
      };
    });
  }, []);

  const warehouseHandler = useCallback((option) => {
    setForm((prevData) => {
      return {
        ...prevData,
        warehouse_id: option?.value,
        warehouse: option,
      };
    });
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm((prevData) => {
      return { ...prevData, [name]: value };
    });
  };

  const handleDateChange = (date, name) => {
    if (date) {
      let formatted_date = Moment(date).format("YYYY-MM-DD");
      setForm((prevData) => {
        return { ...prevData, [name]: formatted_date };
      });
    }
  };

  //actions
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const resp = await axios.post("inventory/batch-edit", form, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      autoNotify(dispatch, resp);
      setIsSubmitting(false);
      if (callback) {
        callback(resp.data.batch);
      }
      //dispatch(hidePrimaryModel());
    } catch (error) {
      autoNotify(dispatch, error.response);
      setIsSubmitting(false);
    }
  };
  const handleFormClose = () => {
    dispatch(hidePrimaryModel());
  };

  return (
    <div>
      <div className="row">
        <div className="col-5">
          <BatchChooser item={item} callback={batchSelectCallback} />
        </div>
        <div className="col-7">
          <p>Edit Item Batch</p>
          <form
            method="post"
            className="edit-batch"
            onSubmit={handleFormSubmit}
          >
            <div className="form-row">
              <div className="form-group col-md-8 mb-1">
                <label htmlFor="warehouse_id">Warehouse</label>
                <SelectWarehouse
                  id="warehouse_id"
                  selected={form.warehouse}
                  callback={warehouseHandler}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-5 mb-1">
                <label htmlFor="batch_location">Rack Location</label>
                <input
                  type="text"
                  className="form-control"
                  id="batch_location"
                  name="batch_location"
                  value={form.batch_location}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group col-md-5 mb-1">
                <label htmlFor="lot_number">Lot Number</label>
                <input
                  type="text"
                  className="form-control"
                  id="lot_number"
                  name="lot_number"
                  value={form.lot_number}
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-10 mb-1">
                <label htmlFor="barcode">MPN/Barcode</label>
                <input
                  type="text"
                  className="form-control"
                  id="barcode"
                  name="barcode"
                  value={form.barcode}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-5 mb-1">
                <label htmlFor="batch_sale_price">Selling Price</label>
                <input
                  type="number"
                  className="form-control"
                  id="batch_sale_price"
                  name="batch_sale_price"
                  value={form.batch_sale_price}
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                />
              </div>
              <div className="form-group col-md-5 mb-1">
                <label htmlFor="batch_cost_price">Cost Price</label>
                <input
                  type="number"
                  className="form-control"
                  id="batch_cost_price"
                  name="batch_cost_price"
                  value={form.batch_cost_price}
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-5 mb-1">
                <label htmlFor="batch_made_date">Made Date</label>
                <DatePicker
                  className="form-control"
                  dateFormat="yyyy-MM-dd"
                  id="batch_made_date"
                  autoComplete="off"
                  selected={Date.parse(form.batch_made_date)}
                  onChange={(date) => {
                    handleDateChange(date, "batch_made_date");
                  }}
                  name="batch_made_date"
                />
              </div>
              <div className="form-group col-md-5 mb-1">
                <label htmlFor="batch_expiry_date">Expiry Date</label>
                <DatePicker
                  className="form-control"
                  dateFormat="yyyy-MM-dd"
                  id="batch_expiry_date"
                  autoComplete="off"
                  selected={Date.parse(form.batch_expiry_date)}
                  onChange={(date) => {
                    handleDateChange(date, "batch_expiry_date");
                  }}
                  name="batch_expiry_date"
                />
              </div>
            </div>
            <div className="mt-5">
              <button
                id="supp-create-btn"
                className="btn btn-primary float-right save-button ml-2"
                type="submit"
                disabled={isSubmitting}
              >
                Save
              </button>
              <button
                id="supp-close-btn"
                type="button"
                className="btn btn-secondary float-right "
                data-dismiss="modal"
                onClick={handleFormClose}
              >
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BatchEdit;
