import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideOptionModel } from "../../../redux/features/modals/OptionModalSlide";
import axios from "../../../api/axios";
import { autoNotify } from "../../../redux/features/notify/NotifySlice";

const POConfirm = (props) => {
  const { po, confirm_callback } = props;
  const dispatch = useDispatch();
  const [isOpening, setIsOpening] = useState(false);
  const { token, user } = useSelector((store) => store.auth);

  const handleOnOpen = async (e) => {
    e.preventDefault();
    setIsOpening(true);
    try {
      const fd = new FormData();
      fd.append("po_id", po.po_id);
      fd.append("po_status", "Open");
      fd.append("updated_by", user.user_id);

      const resp = await axios.post("purchase/po-update", fd, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      autoNotify(dispatch, resp);
      setIsOpening(false);
      confirm_callback(resp.data?.po);
      dispatch(hideOptionModel());
    } catch (error) {
      autoNotify(dispatch, error.response);
      setIsOpening(false);
    }
  };

  const handleOnClose = () => {
    dispatch(hideOptionModel());
  };
  return (
    <div>
      <h5>This will open purchase order for the vendor.</h5>
      <p>
        <strong>{po.po_no}</strong> no longer able to edit. Please conform your
        action.
      </p>
      <div className="modal-footer mt-3">
        <button
          type="button"
          className="btn btn-secondary b-button"
          data-dismiss="modal"
          onClick={handleOnClose}
        >
          Dismiss
        </button>
        <button
          type="submit"
          id="user-delete-btn"
          className="btn btn-primary b-button"
          onClick={handleOnOpen}
          disabled={isOpening}
        >
          Open PO
        </button>
      </div>
    </div>
  );
};

export default POConfirm;
