import React, { useState, useEffect } from "react";
import axios from "../../../api/axios";
import { useSelector } from "react-redux";

const getBatchList = async (
  token,
  branch,
  start = 0,
  length = 10,
  order = "desc",
  search = "",
  itemId = ""
) => {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append("com_id", branch.com_id);
    searchParams.append("item_id", itemId);
    searchParams.append("start", start);
    searchParams.append("length", length);
    searchParams.append("order", order);
    searchParams.append("search", search);
    searchParams.toString();
    const response = await axios.get(
      "inventory/batch-list?" + searchParams.toString(),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    response.data.data = response.data.data.map((batch) => {
      return {
        ...batch,
        label: "Batch - " + batch.batch_id,
        value: batch.batch_id,
      };
    });
    return response.data;
  } catch (error) {
    return [];
  }
};

const BatchChooser = (props) => {
  const { item, callback } = props;
  //global
  const { token, branch } = useSelector((store) => store.auth);
  //locale
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedBatch, setSelectedBatch] = useState({});

  //events
  const onSelectHandler = (batch) => {
    setSelectedBatch(batch);
    if (callback) {
      callback(batch);
    }
  };

  //effect
  useEffect(() => {
    const batchOptions = async () => {
      setIsLoading(true);
      const list = await getBatchList(
        token,
        branch,
        0,
        10,
        "desc",
        "",
        item.item_id
      );
      setOptions(list.data);
      if (callback) {
        setSelectedBatch(list.data[0]);
        callback(list.data[0]);
      }
      setIsLoading(false);
    };
    if (item) {
      batchOptions();
    }
  }, [branch, callback, item, token]);

  return (
    <div>
      <p>Batches for {item.item_sku + " : " + item.item_name}</p>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <table className="table table-sm table-hover batch-chooser-table">
          <thead>
            <tr>
              <th>Choose Item batch</th>
            </tr>
          </thead>
          <tbody>
            {options.map((batch, i) => {
              return (
                <tr
                  key={i}
                  className={selectedBatch === batch ? "rowSelected" : ""}
                >
                  <td
                    style={{ padding: 10 }}
                    onClick={() => onSelectHandler(batch)}
                  >
                    Batch - {batch?.batch_id}{" "}
                    <span style={{ color: "#9ba0a7" }}>
                      {batch?.lot_number
                        ? "lot ( " + batch.lot_number + " )"
                        : ""}
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default React.memo(BatchChooser);
