import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { PlusSquareDotted } from "react-bootstrap-icons";
import axios from "../../../../api/axios";
import MaterialRow from "../MaterialRow";

/**
 * This will get the row materials from the api by item id
 * @param {String} token
 * @param {String} branch
 * @param {String} item_id
 * @returns
 */
const getMaterialList = async (token, branch, item_id) => {
  try {
    const searchParams = new URLSearchParams();
    searchParams.append("com_id", branch.com_id);
    searchParams.append("item_id", item_id);
    searchParams.toString();
    const response = await axios.get(
      "inventory/complex-item-materials?" + searchParams.toString(),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return [];
  }
};

const FormMaterialsEdit = (props) => {
  const { token, branch } = useSelector((store) => store.auth);
  //locale state
  const { item } = props;
  let tableRowCount = useRef(1);
  const [isLoading, setIsLoading] = useState(false);
  const [materials, setMaterials] = useState([
    {
      rowID: tableRowCount.current,
      item: null,
      qty: 0,
      unit: null,
    },
  ]);
  //event

  //this will add new table row
  const addNewRowHandler = (e) => {
    e.preventDefault();
    tableRowCount.current++;
    setMaterials((prevMaterial) => {
      return [
        ...prevMaterial,
        {
          rowID: tableRowCount.current,
          item: null,
          qty: 0,
          unit: null,
        },
      ];
    });
  };
  //this will delete table row
  const deleteRowHandler = (deletedRow) => {
    const newArray = materials.filter((row) => {
      return row.rowID !== deletedRow.rowID;
    });
    setMaterials(newArray);
  };

  //effect
  useEffect(() => {
    const unitData = async () => {
      setIsLoading(true);
      if (item) {
        const material_list = await getMaterialList(
          token,
          branch,
          item.item_id
        );
        let new_list = material_list.map((material, index) => {
          return {
            rowID: tableRowCount.current++,
            item: {
              ...material.material_item,
              label: material.material_item.item_sku,
              value: material.material_item.item_id,
            },
            qty: material.material_qty,
            unit: {
              ...material.material_unit,
              label: material.material_unit.unit,
              value: material.material_unit.unit_id,
            },
          };
        });
        setMaterials(new_list);
      }
      setIsLoading(false);
    };
    unitData();
  }, [token, branch, item]);

  return (
    <div className="row">
      <div className="col-9">
        <h6>Add item as materials</h6>
        {isLoading ? (
          <h6>Materials loading...</h6>
        ) : (
          <div className="material-table-wrapper">
            <table className="table table-sm table-hover material-table">
              <thead>
                <tr>
                  <th></th>
                  <th className="tbl-item-col">Item</th>
                  <th className="tbl-qty-col">Qty</th>
                  <th className="tbl-unit-col">Unit</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {materials.map((row) => {
                  return (
                    <MaterialRow
                      key={row.rowID}
                      row={row}
                      deleteRow={deleteRowHandler}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        <button className="btn" onClick={addNewRowHandler}>
          Add Row <PlusSquareDotted />
        </button>
      </div>
      <div className="col-3"></div>
    </div>
  );
};
export default FormMaterialsEdit;
