import "./style.scss";
import { useState, useCallback, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import FormBasic from "./FormBasic";
import FormAdvanced from "./FormAdvanced";
import {
  createCustomer,
  resetCustomer,
} from "../../../../redux/features/customers/customerSlice";
import { hidePrimaryModel } from "../../../../redux/features/modals/PrimaryModalSlice";
import { addNotify } from "../../../../redux/features/dashboard/notifierSlice";

let initCustomer = {
  cust_display_name: "",
  cust_address_street: "",
  cust_address_city: "",
  cust_address_country: "Sri Lanka",
  cust_address_postcode: "",
  cust_email: "",
  cust_phone: "",
  cust_company: "",
  cust_first_name: "",
  cust_last_name: "",
  cust_mobile: "",
  cust_notes: "",
  category: null,
  cust_balance_opendate: "",
  cust_ledger_balance: "",
};

const CustomerNew = (props) => {
  const { newInput, callback } = props;

  const dispatch = useDispatch();
  const { isSubmitting, success, error } = useSelector(
    (store) => store.customer
  );
  //locale states
  const [tab, setTab] = useState("basic");
  if (newInput) {
    initCustomer = { ...initCustomer, cust_display_name: newInput };
  }
  const customer = useRef(initCustomer);

  //events
  const changeCallback = useCallback((name, value) => {
    const current = customer.current;
    customer.current = { ...current, [name]: value };
  }, []);

  //action
  const handleFormSubmit = (e) => {
    e.preventDefault();
    dispatch(createCustomer(customer.current));
  };
  const handleFormClose = () => {
    dispatch(hidePrimaryModel());
  };

  //effect
  //show notification
  useEffect(() => {
    if (success) {
      dispatch(addNotify({ type: "SUCCESS", content: success }));
      if (callback) {
        callback(success.data.customer);
      }
      dispatch(resetCustomer());
      dispatch(hidePrimaryModel());
    }
    if (error) {
      dispatch(addNotify({ type: "ERROR", content: error }));
      dispatch(resetCustomer());
    }
  }, [dispatch, success, error, callback]);

  return (
    <div className="new-customer">
      <Tabs id="new-customer-tabs" activeKey={tab} onSelect={(t) => setTab(t)}>
        <Tab eventKey="basic" title="Basic Details">
          <FormBasic
            customer={customer.current}
            changeCallback={changeCallback}
          />
        </Tab>
        <Tab eventKey="advanced" title="Advanced Details">
          <FormAdvanced
            customer={customer.current}
            changeCallback={changeCallback}
          />
        </Tab>
      </Tabs>
      <div className="m-1">
        <button
          className="btn btn-primary float-right save-button ml-2"
          type="submit"
          disabled={isSubmitting}
          onClick={handleFormSubmit}
        >
          Save
        </button>
        <button
          type="button"
          className="btn btn-secondary float-right "
          data-dismiss="modal"
          onClick={handleFormClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default CustomerNew;
