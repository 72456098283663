import { useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { PencilSquare, Trash2Fill, Box2Fill, Box } from "react-bootstrap-icons";
import { hasPrivilege } from "../../../Helper";

import StockItemEdit from "../stock-item/Edit/StockItemEdit";
import ComplexItemEdit from "../complex-item/Edit/ComplexItemEdit";
import BatchEdit from "../batch/BatchEdit";
import { showPrimaryModel } from "../../../redux/features/modals/PrimaryModalSlice";
import { updateProductList } from "../../../redux/features/inventory/ProductListSlice";

const ProductListRight = (props) => {
  const [product, setProduct] = useState(props.product);

  //const navigate = useNavigate();
  const dispatch = useDispatch();
  const { productList } = useSelector((store) => store.inventory_product_list);

  //set info
  const infoList = [
    {
      name: "Item Code",
      value: product.item_sku,
    },
    {
      name: "Description",
      value: product.item_description.substring(0, 100),
    },
    {
      name: "Dimensions",
      value: product.item_width
        ? "Width : " +
          product.item_width +
          " Height : " +
          product.item_height +
          " Depth : " +
          product.item_depth
        : "",
    },
    {
      name: "Category",
      value: product.category.cat_name,
    },
    {
      name: "Brand",
      value: product.brand.brand,
    },
    {
      name: "Sale Tax",
      value: product.sales_tax_id === "1" ? "None" : "Taxable",
    },
  ];

  const info_list_elements = infoList.map((field) => {
    return (
      <div key={field.name} className="row no-gutters info-field">
        <div className="col-sm-4 name">
          <p>{field.name} : </p>
        </div>
        <div className="col-sm-8 value">
          <p>{field.value}</p>
        </div>
      </div>
    );
  });

  //handle edit
  const itemEditCallback = (item) => {
    setProduct(item);
    const updatedData = productList.map((list_item) => {
      if (item.item_id === list_item.item_id) {
        return item;
      }
      return list_item;
    });
    dispatch(updateProductList(updatedData));
  };
  const handleItemEdit = (item) => {
    //if inventory item
    if (item.item_type === "INVENTORY") {
      const modelData = {
        title: "Edit Inventory Item",
        titleIcon: <Box2Fill />,
        content: <StockItemEdit item={item} callback={itemEditCallback} />,
      };
      dispatch(showPrimaryModel(modelData));
    } else if (item.item_type === "COMPLEX") {
      const modelData = {
        title: "Edit Complex Inventory Item",
        titleIcon: <Box2Fill />,
        content: <ComplexItemEdit item={item} callback={itemEditCallback} />,
      };
      dispatch(showPrimaryModel(modelData));
    }
    //else complex item
  };

  const handleBatchEdit = (item) => {
    const modelData = {
      title: "Edit Item Batches",
      titleIcon: <Box />,
      content: <BatchEdit item={item} />,
    };
    dispatch(showPrimaryModel(modelData));
  };

  //handler for delete

  const handleDelete = () => {
    /*
    const modelData = {
      title: "Delete Purchase Order",
      titleIcon: <FileTextFill />,
      content: <PODelete po={purchaseOrder} delete_callback={deleteCallback} />,
    };
    dispatch(showOptionModel(modelData));*/
  };

  //set props
  useEffect(() => {
    setProduct(props.product);
  }, [props.product]);

  return (
    <div className="right-content">
      <div className="header">
        <div className="header-img">
          <img
            src={process.env.REACT_APP_API_URL + product.item_image}
            alt="side header"
          />
        </div>
        <div className="header-text">
          <h4>{product.item_name}</h4>
          <p>{product.item_type}</p>
        </div>
      </div>

      <Scrollbars style={{ width: "100%", height: "100%" }}>
        <div className="info-section">
          <h6>PO Information</h6>
          <div className="row mb-2">
            <div className="col-sm-12">{info_list_elements}</div>
          </div>
        </div>

        <div className="trans-content">
          <h6>Recent Transactions</h6>
          <table className="table table-sm table-hover">
            <thead>
              <tr>
                <th>Batch</th>
                <th>Transaction</th>
                <th>Unit</th>
                <th>Qty</th>
              </tr>
            </thead>
            <tbody>
              {product.transactions.map((row, index) => {
                return (
                  <tr key={index}>
                    <td>{row.batch_id}</td>
                    <td>
                      <p>{row.trans_type}</p>
                      <p style={{ fontSize: "11px" }}>{row.transaction}</p>
                    </td>
                    <td>{product?.unit.unit}</td>
                    <td>{row.transaction_quantity}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Scrollbars>
      <div className="action-section">
        <div className="action-control">
          {hasPrivilege("Inventory", 402) && (
            <button
              className="btn btn-primary b-button"
              onClick={() => handleItemEdit(product)}
            >
              <PencilSquare /> Item
            </button>
          )}
          {hasPrivilege("Inventory", 405) && (
            <button
              className="btn btn-primary b-button"
              onClick={() => handleBatchEdit(product)}
            >
              <PencilSquare /> Batches
            </button>
          )}
          <Dropdown>
            <Dropdown.Toggle
              variant="secondary"
              id="more-action"
              className="b-button"
            >
              More
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ margin: 0 }}>
              {hasPrivilege("Inventory", 403) && (
                <Dropdown.Item eventKey="3" onClick={handleDelete}>
                  <Trash2Fill /> Delete
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};
export default ProductListRight;
