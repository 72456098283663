import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import GRN from "../../../components/workplace/grn/GRN";

import {
  setEditGRN,
  setNewGRN,
} from "../../../redux/features/purchases/GRNSlice";

const GRNEdit = () => {
  const params = useParams();
  const grn_id = params.id;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { grnList } = useSelector((store) => store.goods_received_note_list);

  const grn = grnList.find((grn) => {
    return grn.grn_id === grn_id;
  });

  /* this will dispatch edit PO */
  useEffect(() => {
    if (grn && grn.grn_status === "Draft") {
      dispatch(setEditGRN(grn));
    } else {
      navigate("/dashboard/purchases/grn-list");
    }
    // call when unmount
    return () => {
      dispatch(setNewGRN());
    };
  }, [dispatch, grn, navigate]);

  return <GRN action="Edit" />;
};
export default GRNEdit;
