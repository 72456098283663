import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { hideOptionModel } from "../../../../redux/features/modals/OptionModalSlide";
import { addNotify } from "../../../../redux/features/dashboard/notifierSlice";
import {
  deleteCustomer,
  resetCustomer,
} from "../../../../redux/features/customers/customerSlice";

const CustomerDelete = (props) => {
  const { customer, callback } = props;
  const { isSubmitting, success, error } = useSelector(
    (store) => store.customer
  );
  const dispatch = useDispatch();
  //action
  const handleFormSubmit = (e) => {
    e.preventDefault();
    dispatch(deleteCustomer(customer.cust_id));
  };
  const handleOnClose = () => {
    dispatch(hideOptionModel());
  };

  //effect
  //show notification
  useEffect(() => {
    if (success) {
      dispatch(addNotify({ type: "SUCCESS", content: success }));
      if (callback) {
        callback(success.data.cust_id);
      }
      dispatch(resetCustomer());
      dispatch(hideOptionModel());
    }
    if (error) {
      dispatch(addNotify({ type: "ERROR", content: error }));
      dispatch(resetCustomer());
    }
  }, [dispatch, success, error, callback]);

  return (
    <div>
      <h5>You are going to delete customer.</h5>
      <p>
        <strong>"{customer.cust_display_name}"</strong> no longer available.
        Please Conform your action.
      </p>
      <div className="modal-footer mt-3">
        <button
          type="button"
          className="btn btn-secondary b-button"
          data-dismiss="modal"
          onClick={handleOnClose}
        >
          Dismiss
        </button>
        <button
          type="submit"
          id="user-delete-btn"
          className="btn btn-primary b-button"
          onClick={handleFormSubmit}
          disabled={isSubmitting}
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default CustomerDelete;
